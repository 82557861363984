import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  Form
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import PropTypes from "prop-types";
import {
  emailRex,
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";
import { reduxForm } from "redux-form";
import { searchUser, invitation } from "../../../actions/invitationAction";
import { addTeamUser } from "../../../actions/teamAction";
import Autosuggest from "react-autosuggest";
import { getTeamUsers } from "../../../actions/teamAction";
import _ from "lodash";
class InvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      invite: {},
      emailText: "",
      userEmail: "",
      value: "",
      suggestions: [],
      userData: [],
      loading: false,
      search_loading: false
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.suggestedData &&
      nextProps.suggestedData !== this.props.suggestedData
    ) {
      this.setState({
        userData: nextProps.suggestedData,
        search_loading: false
      });
    }
    if (
      nextProps.isInviteSuccess &&
      nextProps.isInviteSuccess !== this.props.isInviteSuccess
    ) {
      this.setState({
        loading: !this.state.loading
      });
      this.props.handleModal();
    }
    if (
      nextProps.isInviteFailure &&
      nextProps.isInviteFailure !== this.props.isInviteFailure
    ) {
      showError(nextProps.isInviteFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.isInviteNetworkFailure &&
      nextProps.isInviteNetworkFailure !== this.props.isInviteNetworkFailure
    ) {
      showError(this.props.t("error.NETWORK_ERROR"));
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.addUserToTeam &&
      nextProps.addUserToTeam !== this.props.addUserToTeam
    ) {
      showSuccess("User added to team successfully.");
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      this.props.dispatch(getTeamUsers(this.props.teamId, qString));
      this.setState({
        loading: !this.state.loading
      });
      this.props.handleModal();
    }
    if (
      nextProps.addUserToTeamError &&
      nextProps.addUserToTeamError !== this.props.addUserToTeamError
    ) {
      showError(nextProps.addUserToTeamError);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.addUserToTeamNetworkError &&
      nextProps.addUserToTeamNetworkError !==
        this.props.addUserToTeamNetworkError
    ) {
      showError("Some thing went wrong!");
      this.setState({
        loading: false
      });
    }
  };

  /*------here we are calling api------------------- */
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.setState({
      search_loading: true
    });
    if (newValue.length > 0) {
      this.props.dispatch(searchUser(newValue));
    } else {
      this.setState({
        search_loading: false
      });
    }
  };

  /*-------this function calls every time you need to update suggestions----------*/
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  /*-----this functions shows how to calculate suggestions for any given input value ------------------*/
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : this.state.userData.filter(
          user =>
            user.Email_User.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  /*------ When suggestion is clicked, needs to populate the input-----------*/
  getSuggestionValue = suggestion => suggestion.Email_User;

  /*-----------render suggestions----------------*/
  renderSuggestion = suggestion => <div>{suggestion.Email_User}</div>;

  /*------------this function calls every time you need to clear suggestions----------*/
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  //call this function to close password modal
  handleModal = () => {
    this.props.handleModal();
  };
  /*call this function when user hit ENTER key*/
  keyDown = event => {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  };
  cleanString = input => {
    let output = "";
    for (let i = 0; i < input.length; i++) {
      if (input.charCodeAt(i) <= 127) {
        output += input.charAt(i);
      }
    }
    return output;
  };
  /*-----here validating email value and calling invitation api--------------*/
  onSubmit = () => {
    this.setState({
      loading: true
    });
    if (emailRex.test(this.cleanString(this.state.value))) {
      if (!this.props.teamId) {
        let formData = {
          email: this.state.value
        };
        switch (this.props.role_number) {
          case 1:
          case 6:
            formData.role = this.props.role_number;
            break;
          case 2:
          case 3:
            formData.role = this.props.role_number;
            formData.partnerId = this.props.role_id;
            break;
          case 4:
          case 5:
            formData.role = this.props.role_number;
            formData.customerId = this.props.role_id;
            break;
          default:
            formData.role = 6;
        }
        this.props.dispatch(invitation(formData));
      } else {
        let formData = {
          teamId: this.props.teamId,
          email: this.state.value
        };
        this.props.dispatch(addTeamUser(formData));
      }
    } else {
      this.setState({
        loading: false
      });
      showError(this.props.t("error.ENTER_PROPER_EMAIL"));
    }
  };
  /*-----------render method-----------------*/
  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.t("members.SEARCH_EMAIL"),
      value,
      onChange: this.onChange,
      onKeyDown: this.keyDown,
      className: this.state.search_loading ? "suggest-loading" : ""
    };
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.teamId
              ? "Add a new member"
              : this.props.t("members.INVITE_MEMBER")}
          </ModalHeader>
          <ModalBody>
            <Row className="mt-3">
              <Label sm={2} xs={2} className="pl-5">
                Email
              </Label>
              <Col lg={9} xs={9}>
                <FormGroup
                  className={
                    this.state.invite.emailState
                      ? "has-label " + this.state.invite.emailState
                      : "has-label"
                  }
                >
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7} className="ml-auto pl-5">
                <Button
                  color="primary"
                  onClick={this.onSubmit}
                  loading={this.state.loading}
                >
                  {this.props.teamId
                    ? "Add member"
                    : this.props.t("members.SEND_INVITATION")}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
InvitationModal.propTypes = {
  dispatch: PropTypes.func,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  isInviteSuccess: PropTypes.bool,
  isInviteFailure: PropTypes.bool,
  isInviteNetworkFailure: PropTypes.bool,
  suggestedData: PropTypes.array,
  role_number: PropTypes.number,
  role_id: PropTypes.string,
  addUserToTeamError: PropTypes.string,
  addUserToTeamNetworkError: PropTypes.bool,
  meta: PropTypes.object
};
function mapStateToProps(state) {
  return {
    suggestedData: state.invite.suggestedData ? state.invite.suggestedData : [],
    isInviteSuccess: state.invite.isInviteSuccess,
    isInviteFailure: state.invite.isInviteFailure,
    isInviteNetworkFailure: state.invite.isInviteNetworkFailure,
    addUserToTeam: state.team.addUserToTeam,
    addUserToTeamError: state.team.addUserToTeamError,
    addUserToTeamNetworkError: state.team.addUserToTeamNetworkError
  };
}
export default compose(translate, connect(mapStateToProps))(InvitationModal);
