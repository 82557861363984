import React, { Component, Fragment } from "react";
import { Line } from "react-chartjs-2";
import { translate } from "react-multi-lang";
import compose from "compose-function";
import { Card } from "reactstrap";
import PropTypes from "prop-types";

class LineChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleLineChartData: {
        labels: this.props.labels,
        datasets: [
          {
            label: this.props.title,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(239,109,76)",
            borderColor: "rgba(239,109,76)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "EF6D4C",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(239,109,76)",
            pointHoverBorderColor: "rgba(239,109,76)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: this.props.data
          }
        ]
      },
      multiLineChartData: {
        labels: this.props.labels,
        datasets: [
          {
            data: this.props.data1,
            backgroundColor: "rgba(239,109,76)",
            hoverBackgroundColor: "rgba(239,109,60)",
            label: "Open Tickets",
            fill: false,
            lineTension: 0.1,
            borderColor: "rgba(239,109,76)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "EF6D4C",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(239,109,76)",
            pointHoverBorderColor: "rgba(239,109,76)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10
          },
          {
            data: this.props.data2,
            backgroundColor: "rgba(64,83,93)",
            hoverBackgroundColor: "rgba(64,83,80)",
            label: "Closed Tickets",
            fill: false,
            lineTension: 0.1,
            borderColor: "rgba(64,83,93)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "EF6D4C",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(64,83,93)",
            pointHoverBorderColor: "rgba(64,83,93)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10
          }
        ]
      }
    };
  }

  //   componentDidMount() {
  //     const { datasets } = this.refs.chart.chartInstance.data;
  //     console.log(datasets);
  //   }
  render() {
    return (
      <Fragment>
        <Card id="chart" className="p-2">
          <Line
            ref="chart"
            data={
              this.props.isMulti
                ? this.state.multiLineChartData
                : this.state.singleLineChartData
            }
            height={this.props.isMulti ? "80" : "150"}
            options={{
              title: {
                display: true,
                text: this.props.isMulti
                  ? "Open Tickets Vs Closed Tickets"
                  : `${this.props.subTitle} :${this.props.totalValue}`,
                fontSize: 15,
                position: "bottom"
              },
              legend: {
                display: true,
                position: "top"
              }
            }}
          />
        </Card>
      </Fragment>
    );
  }
}

LineChartComponent.propTypes = {
  t: PropTypes.func
};

export default compose(translate)(LineChartComponent);
