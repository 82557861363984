import React, { PureComponent } from "react";
import IssueDetailsContainer from "./Containers/IssueDetailsContainer";

class IssuesPage extends PureComponent {
  render() {
    return (
      <div>
        <IssueDetailsContainer alertId={this.props.match.params.id} />
      </div>
    );
  }
}

export default IssuesPage;
