/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import PartnerUsers from "../../PartnerUsersComponent/Components/PartnerUsersPage";
import { MembersCount } from "../../../../../actions/roleAction";
import { roles_number } from "../../../../../helpers/Utility";
import PropTypes from "prop-types";
import queryString from "query-string";
class PartnerUsersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: {
        showSelectUserRoleModal: false
      }
    };
  }
  componentDidMount = () => {
    this.props.dispatch(MembersCount("partnerId", this.props.partner_id));
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    this.setState({
      isRequesting: nextProps.isRequesting
    });
    if (nextProps.membersCount) {
      let membersCount = [
        {
          role: {
            name: "Partner Admin",
            id: roles_number.partnerAdmin
          },
          members: nextProps.membersCount
            ? nextProps.membersCount.memberCount.partnerAdmin.length
            : 0
        },
        {
          role: { name: "Partner Support", id: roles_number.partnerUser },
          members: nextProps.membersCount
            ? nextProps.membersCount.memberCount.partnerSupport.length
            : 0
        },
        {
          role: { name: "Organisations", id: "customers" },
          members: nextProps.membersCount
            ? nextProps.membersCount.memberCount.customerCount
            : 0
        }
      ];
      this.setState({
        membersCount: membersCount
      });
    }
  };
  //this function we are calling to navigate members page
  showMembers = type => {
    this.props.history.push(
      "/dashboard/members/" +
        type +
        "/" +
        this.props.partner_id +
        "?name=" +
        queryString.parse(this.props.search).name
    );
  };
  //here we are navigating customers page
  navigateCustomers = () => {
    this.props.history.push(
      "/dashboard/organisations/" + this.props.partner_id
    );
  };
  render() {
    const partnerName = queryString
      .parse(this.props.search)
      .name.split("_")
      .join(" ");
    return (
      <div>
        <PartnerUsers
          stateValues={this.state}
          showMembers={this.showMembers}
          navigateCustomers={this.navigateCustomers}
          partnerName={partnerName}
        />
      </div>
    );
  }
}
PartnerUsersContainer.propTypes = {
  history: PropTypes.object,
  partner_id: PropTypes.string,
  isRequesting: PropTypes.bool,
  membersCount: PropTypes.object
};
function mapStateToProps(state) {
  return {
    membersCount: state.roles.membersCount,
    isRequesting: state.roles.isRequesting
  };
}
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "home-form"
    //validate,
    // asyncValidate,
  }),
  connect(mapStateToProps)
)(PartnerUsersContainer);
