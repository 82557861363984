import React from "react";
import { Container, Col, Row, Button } from "reactstrap";
import SideBarToggle from "../../../Common/SideBarToggle";
import PropTypes from "prop-types";
// import Loader from "../../../Common/PageLoader";
import LoaderIcon from "../../../../../assets/img/Loader.gif";
import CardComponent from "../../../Common/CardComponent";
import {
  getRulesCount,
  getIssuesTypeCount,
  getTeamMembersCount,
  issueDetails,
  organisationDashboardDetails
} from "../../../../../helpers/Utility";
import OrgDashboardSettingsModal from "../../../Modals/OrgDashboardSettingsModal";
import BreadcrumbComponent from "../../../Common/BreadCrumbComponent";
import compose from "compose-function";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
function OrganisationDashboardPage(props) {
  /* const for role details */
  const roleDetails = [
    {
      info: organisationDashboardDetails.total_Roles,
      value: 2
    },
    {
      info: organisationDashboardDetails.total_members,
      value: props.customerStatistics ? props.customerStatistics.memberCount : 0
    }
  ];
  /*const for team details */
  const Teams = props.customerStatistics
    ? props.customerStatistics.teamCount
    : 0;
  const teamDetails = [
    {
      info: organisationDashboardDetails.total_Teams,
      value: Teams ? Teams : 0
    },
    {
      info: organisationDashboardDetails.total_members,
      value: props.customerStatistics
        ? props.customerStatistics.teamMemberCount
        : 0
    }
  ];
  /*const for instance details */
  const Instances = props.customerStatistics
    ? props.customerStatistics.instanceCount
    : 0;
  const instanceDetails = [
    {
      info: organisationDashboardDetails.total_Instances,
      value: Instances ? Instances : 0
    },
    {
      info: organisationDashboardDetails.total_Rules,
      // value: props.customerStatistics
      //   ? getRulesCount(props.customerStatistics.Instances)
      //   : 0
      value: props.customerStatistics ? props.customerStatistics.ruleCount : 0
    }
  ];
  /*const for issue details */
  const issuesDetails = [
    {
      info: issueDetails.unAssigned_Name,
      value: props.customerStatistics
        ? getIssuesTypeCount(
            props.customerStatistics.alertCount,
            issueDetails.unAssigned_Number
          )
        : 0
    },
    {
      info: issueDetails.assigned_Name,
      value: props.customerStatistics
        ? getIssuesTypeCount(
            props.customerStatistics.alertCount,
            issueDetails.assigned_Number
          )
        : 0
    },
    {
      info: issueDetails.closed_Name,
      value: props.customerStatistics
        ? getIssuesTypeCount(
            props.customerStatistics.alertCount,
            issueDetails.closed_Number
          )
        : 0
    }
  ];
  // /*const for issue details */
  // const issuesDetails = [
  //   {
  //     info: issueDetails.unAssigned_Name,
  //     value: props.customerStatistics
  //       ? getIssuesTypeCount(
  //           props.customerStatistics.Instances,
  //           issueDetails.unAssigned_Number
  //         )
  //       : 0
  //   },
  //   {
  //     info: issueDetails.assigned_Name,
  //     value: props.customerStatistics
  //       ? getIssuesTypeCount(
  //           props.customerStatistics.Instances,
  //           issueDetails.assigned_Number
  //         )
  //       : 0
  //   },
  //   {
  //     info: issueDetails.closed_Name,
  //     value: props.customerStatistics
  //       ? getIssuesTypeCount(
  //           props.customerStatistics.Instances,
  //           issueDetails.closed_Number
  //         )
  //       : 0
  //   }
  // ];
  return (
    <div>
      {props.stateValues.showOrgDashboardSettingsModal && (
        <OrgDashboardSettingsModal
          isOpen={props.stateValues.showOrgDashboardSettingsModal}
          handleSettingsModal={props.handleSettingsModal}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            {props.isRequesting && (
              <div className="table-tr-loader">
                <img
                  className="loader-inside-dashboards"
                  src={LoaderIcon}
                  alt="loader"
                />
              </div>
            )}
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={4} md={6} xs={12} className="">
                <div>
                  <BreadcrumbComponent
                    active={props.t("common.DASHBOARD")}
                    nonActive1="Organisations"
                    nonActive1Href="/dashboard/organisations"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <span className="text-muted font-weight-bold margin-css">
                  {props.customerStatistics
                    ? props.customerStatistics.Name_Customer
                    : ""}
                </span>
              </Col>
              <Col lg={2} xs={12}>
                {/* <Button
                  color="primary"
                  className="font-weight-bold"
                  onClick={e => props.showSettingsModal()}
                >
                  Settings
                </Button> */}
              </Col>
            </Row>
            <div className="margin-top-max" />
            <Row className="">
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Roles"
                  cardData={roleDetails}
                  icon="fa-user"
                  navigatePage={props.navigateToMembers}
                  roleValue={2}
                  path={props.isRequesting ? "" : "/dashboard/roles"}
                  isShowPointer={props.isRequesting ? false : true}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Teams"
                  cardData={teamDetails}
                  icon="fa-users"
                  navigatePage={props.navigatePage}
                  path={props.isRequesting ? "" : "/dashboard/teams"}
                  isShowPointer={props.isRequesting ? false : true}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Instances"
                  cardData={instanceDetails}
                  icon="fa-lightbulb-o"
                  navigatePage={props.navigatePage}
                  path={props.isRequesting ? "" : "/dashboard/instances"}
                  isShowPointer={props.isRequesting ? false : true}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Issues"
                  cardData={issuesDetails}
                  icon="fa-exclamation-circle"
                  showClasses={true}
                  isShowPointer={false}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Information"
                  icon="fa-info-circle"
                  remove={true} //for Icon
                  isShowPointer={false}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Reporting"
                  icon="fa-flag"
                  remove={true} //for Icon
                  navigatePage={props.navigatePage}
                  path={props.isRequesting ? "" : "/dashboard/report"}
                  isShowPointer={props.isRequesting ? false : true}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
OrganisationDashboardPage.propTypes = {
  stateValues: PropTypes.object,
  showOrgDashboardSettingsModal: PropTypes.bool,
  showMembers: PropTypes.func,
  navigate: PropTypes.func,
  customerStatistics: PropTypes.object,
  navigatePage: PropTypes.func,
  customerStatisticsError: PropTypes.bool,
  handleSettingsModal: PropTypes.func,
  isRequesting: PropTypes.bool
};
export default compose(translate, connect())(OrganisationDashboardPage);
