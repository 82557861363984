/* eslint-disable react/display-name */
import React from "react";
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Card,
  CardBody
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import PropTypes from "prop-types";
import SideBarToggle from "../../../Components/Common/SideBarToggle";
import BackComponent from "../../../Components/Common/BackComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import NotificationSettingsModal from "../../Modals/NotificationSettingsModal";
import {
  confirmPermissions,
  showDays,
  getLocalTime,
  createGMTOffset
} from "../../../../helpers/Utility";
import { getRuleNotification } from "../../../../actions/ruleAction";
import ReactTable from "react-table";
function NotificationPage(props) {
  //function for to show list of notifications
  // const showNotification = (isShowAction, isDeletable, isEditable) => {
  //   if (props.stateValues.isRequesting) {
  //     return (
  //       <tr className="table-tr-loader">
  //         <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
  //       </tr>
  //     );
  //   } else {
  //     if (
  //       props.stateValues.notificationsData &&
  //       props.stateValues.notificationsData.length
  //     ) {
  //       return props.stateValues.notificationsData.map((notification, key) => {
  //         return (
  //           <tr key={key} className={"table-data-rows"}>
  //             <td className="pl-3">
  //               {getLocalTime(
  //                 notification.Start_Time,
  //                 notification.TimeZone ? notification.TimeZone.Zone_Name : null
  //               )}
  //             </td>
  //             <td className="pl-3">
  //               {/* {moment(notification.End_Time).format("hh:mm:ss")} */}
  //               {getLocalTime(
  //                 notification.End_Time,
  //                 notification.TimeZone ? notification.TimeZone.Zone_Name : null
  //               )}
  //             </td>
  //             <td className="pl-3">
  //               {notification.TimeZone
  //                 ? notification.TimeZone.Zone_Name +
  //                   createGMTOffset(notification.TimeZone)
  //                 : "N/A"}
  //             </td>
  //             <td className="pl-3">
  //               {notification.isEveryDay
  //                 ? "Every day"
  //                 : JSON.parse(notification.Days_List).length > 6
  //                 ? "Every day"
  //                 : showDays(JSON.parse(notification.Days_List))}
  //             </td>
  //             <td className="text-center">
  //               {notification.isEmail && (
  //                 <span className="badge badge-primary">Email</span>
  //               )}{" "}
  //               {notification.isEmail
  //                 ? notification.isPushNotification && (
  //                     <span className="badge badge-primary">Push</span>
  //                   )
  //                 : notification.isPushNotification && (
  //                     <span className="badge badge-primary">Push</span>
  //                   )}
  //             </td>
  //             {isShowAction && (
  //               <td className="text-center switch-styles">
  //                 {isDeletable && (
  //                   <Button
  //                     className="btn-sm"
  //                     color="danger"
  //                     round
  //                     onClick={e =>
  //                       props.deleteNotification(
  //                         notification.UID_RuleNotification
  //                       )
  //                     }
  //                   >
  //                     <i className="fa fa-trash" aria-hidden="true" />
  //                   </Button>
  //                 )}{" "}
  //                 {isEditable && (
  //                   <Button
  //                     className="btn-sm"
  //                     color="primary"
  //                     round
  //                     onClick={e => props.updateNotification(notification)}
  //                   >
  //                     <i className="fa fa-pencil-square-o" aria-hidden="true" />
  //                   </Button>
  //                 )}
  //               </td>
  //             )}
  //           </tr>
  //         );
  //       });
  //     } else {
  //       return (
  //         <tr className="table-tr-loader">
  //           <td className="no-data-found-style">No Data Found</td>
  //         </tr>
  //       );
  //     }
  //   }
  // };
  const permissions = props.stateValues.wholeData
    ? props.stateValues.wholeData.permissions
    : [];
  const isDeletable = confirmPermissions(permissions, "isDeletable");
  const isEditable = confirmPermissions(permissions, "isEditable");
  // const isDisable = confirmPermissions(permissions, "isDisable");
  const isShowAction = isDeletable || isEditable;
  /*---------------react-table header----------------------*/
  const headers = {
    startTime: "Start Time",
    endTime: "End Time",
    timeZoneData: "TimeZone",
    days: "Days",
    notification: "Notification Type",
    actions: "Actions"
  };
  /*---------------react-data------------------- */
  const data = props.stateValues.notificationsData
    ? props.stateValues.notificationsData
    : [];
  // console.log(data);
  return (
    <Container>
      {props.stateValues.showCreateNotificationModal && (
        <NotificationSettingsModal
          isOpen={props.stateValues.showCreateNotificationModal}
          handleModal={props.handleNotificationModal}
          teamRuleId={props.stateValues.teamRuleId}
          timezonesData={props.stateValues.timezoneData}
          initialTimeZoneValue={props.initialTimeZoneValue}
          meta={props.stateValues.meta}
        />
      )}
      {props.stateValues.showUpdateNotificationModal && (
        <NotificationSettingsModal
          isOpen={props.stateValues.showUpdateNotificationModal}
          handleModal={props.handleNotificationModalTwo}
          teamRuleId={props.ruleTeamId}
          timezonesData={props.stateValues.timezoneData}
          initialValues={props.stateValues.initialValues}
          initialTimeZoneValue2={props.initialTimeZoneValue}
          meta={props.stateValues.meta}
        />
      )}
      <Row className="mt-4 d-lg-none sidebar-btn-landscape">
        <Col>
          <SideBarToggle />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={10} md={10} xs={10} className="">
          <div>
            <Breadcrumb tag="nav" listTag="div" className="breadcrumb-style">
              <BreadcrumbItem tag="a" href="javascript:void(0);">
                <BackComponent name={props.ruleName} morePrevious={true} />
              </BreadcrumbItem>
              <BreadcrumbItem tag="a" href="javascript:void(0);">
                <BackComponent name={props.teamName} />
              </BreadcrumbItem>
              <BreadcrumbItem active tag="span" className="font-weight-bold">
                Notification Settings
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Col>
        <Col>
          {confirmPermissions(permissions, "isCreatable") && (
            <Button
              color="primary"
              round
              onClick={() => props.handleNotificationModal(props.ruleTeamId)}
            >
              <span className="font-weight-bold">Create</span>
            </Button>
          )}
        </Col>
      </Row>
      {/* <Row className="mt-4">
        <Col className="list-style">
          <Table responsive className="members-table-styles">
            <tbody>
              <tr className="table-header-row">
                <th className="pl-3">Start Time</th>
                <th className="pl-3">End Time</th>
                <th className="pl-3">TimeZone</th>
                <th className="pl-3">Days</th>
                <th className="text-center">Notification Type</th>
                {isShowAction && <th className="text-center">Actions</th>}
              </tr>
            </tbody>
            <tbody>
              {showNotification(isShowAction, isDeletable, isEditable)}
            </tbody>
          </Table>
        </Col>
      </Row> */}
      <Card className="mt-4">
        <CardBody>
          <ReactTable
            loading={!props.stateValues.isRequesting ? false : true}
            loadingText={"Argos..."}
            className="cursor-pointer -striped-highlight"
            data={data}
            columns={[
              {
                columns: [
                  {
                    sortable: false,
                    Header: headers.startTime,
                    accessor: "startTime",
                    Cell: row => (
                      <div>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            {getLocalTime(
                              row.value,
                              row.original.timeZoneData
                                ? row.original.timeZoneData.Zone_Name
                                : null
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  },
                  {
                    sortable: false,
                    Header: headers.endTime,
                    accessor: "endTime",
                    Cell: row => (
                      <div>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            {getLocalTime(
                              row.value,
                              row.original.timeZoneData
                                ? row.original.timeZoneData.Zone_Name
                                : null
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  },
                  {
                    sortable: false,
                    Header: headers.timeZoneData,
                    accessor: "timeZoneData",
                    Cell: row => (
                      <div>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            {row.value
                              ? row.value.Zone_Name + createGMTOffset(row.value)
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    )
                  },
                  {
                    sortable: false,
                    Header: headers.days,
                    accessor: "days",
                    style: { whiteSpace: "unset" },
                    Cell: row => (
                      <div>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            {row.original.notification.isEveryDay
                              ? "Every day"
                              : JSON.parse(row.value).length > 6
                              ? "Every day"
                              : showDays(JSON.parse(row.value))}
                          </div>
                        </div>
                      </div>
                    )
                  },
                  {
                    sortable: false,
                    Header: headers.notification,
                    accessor: "notification",
                    style: { whiteSpace: "unset" },
                    Cell: row => (
                      <div>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            {row.value.isEmail && (
                              <span className="badge badge-primary">Email</span>
                            )}{" "}
                            {row.value.isEmail
                              ? row.value.isPushNotification && (
                                  <span className="badge badge-primary">
                                    Push
                                  </span>
                                )
                              : row.value.isPushNotification && (
                                  <span className="badge badge-primary">
                                    Push
                                  </span>
                                )}{" "}
                            {/* {row.value.isWhatsApp ? (
                              <span className="badge badge-primary">
                                Whatsapp
                              </span>
                            ) : (
                              ""
                            )}{" "} */}
                            {row.value.isTeam ? (
                              <span className="badge badge-primary">Team</span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  },
                  {
                    sortable: false,
                    Header: headers.actions,
                    accessor: "actions",
                    Cell: row => (
                      <div>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            {isShowAction && (
                              <div className="text-center switch-styles">
                                {isDeletable && (
                                  <Button
                                    className="btn-sm"
                                    color="danger"
                                    round
                                    onClick={e =>
                                      props.deleteNotification(
                                        row.value.ruleNotificationId
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                )}{" "}
                                {isEditable && (
                                  <Button
                                    className="btn-sm"
                                    color="primary"
                                    round
                                    onClick={e =>
                                      props.updateNotification(
                                        row.value.notificationData
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                ]
              }
            ]}
            manual
            pages={props.stateValues.meta.pageTotal}
            defaultPageSize={props.stateValues.meta.pageSize}
            pageSizeOptions={props.stateValues.meta.pageSizeOptions}
            onFetchData={(state, instance) => {
              let qString;
              if (state.sorted.length) {
                qString = `?teamRuleId=${props.ruleTeamId}&pageSize=${
                  state.pageSize
                }&pageIndex=${state.page}&field=${state.sorted[0].id}&order=${
                  state.sorted[0].desc ? "desc" : "asc"
                }`;
              } else {
                qString = `?teamRuleId=${props.ruleTeamId}&pageSize=${state.pageSize}&pageIndex=${state.page}`;
              }
              props.dispatch(getRuleNotification(qString));
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
}
NotificationPage.propTypes = {
  stateValues: PropTypes.object,
  initialTimeZoneValue: PropTypes.object,
  dispatch: PropTypes.func,
  ruleTeamId: PropTypes.string
};
export default compose(translate, connect())(NotificationPage);
