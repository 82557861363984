/* eslint-disable default-case */
import { TEAM_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    teamsData: [],
    isRequesting: false,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case TEAM_CONST.GET_ALL_TEAM_REQUEST:
      return {
        ...state,
        teamsData: null,
        isRequesting: true,
        teamsDataError: false,
        teamsDataNetworkError: false
      };
    case TEAM_CONST.GET_ALL_TEAM_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        teamsData: action.payload.response.data,
        teamsDataError: false,
        teamsDataNetworkError: false
      };
    case TEAM_CONST.GET_ALL_TEAM_FAILURE:
      return {
        ...state,
        teamsData: null,
        isRequesting: false,
        teamsDataError: action.payload.response ? true : false,
        teamsDataNetworkError: action.payload.error ? true : false
      };
    case TEAM_CONST.CREATE_TEAM_REQUEST:
      return {
        ...state,
        createTeamSuccess: false,
        createTeamFailure: false,
        createTeamNetworkFailure: false
      };
    case TEAM_CONST.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        createTeamSuccess: action.payload ? true : false,
        createTeamFailure: false,
        createTeamNetworkFailure: false
      };
    case TEAM_CONST.CREATE_TEAM_FAILURE:
      return {
        ...state,
        createTeamSuccess: false,
        createTeamFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        createTeamNetworkFailure: action.payload.error ? true : false
      };
    case TEAM_CONST.DELETE_TEAM_REQUEST:
      return {
        ...state,
        deleteTeamSuccess: false,
        deleteTeamFailure: false
      };
    case TEAM_CONST.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        deleteTeamSuccess: action.payload ? true : false,
        deleteTeamFailure: false
      };
    case TEAM_CONST.DELETE_TEAM_FAILURE:
      return {
        ...state,
        deleteTeamSuccess: false,
        deleteTeamFailure: action.payload.error ? true : false
      };
    case TEAM_CONST.UPDATE_TEAM_REQUEST:
      return {
        ...state,
        updateTeamSuccess: false,
        updateTeamFailure: false,
        updateTeamNetworkFailure: false
      };
    case TEAM_CONST.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        updateTeamSuccess: action.payload ? true : false,
        updateTeamFailure: false,
        updateTeamNetworkFailure: false
      };
    case TEAM_CONST.UPDATE_TEAM_FAILURE:
      return {
        ...state,
        updateTeamSuccess: false,
        updateTeamFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateTeamNetworkFailure: action.payload.error ? true : false
      };
    case TEAM_CONST.GET_TEAM_STATISTICS_REQUEST:
      return {
        ...state,
        teamStatisticsData: null,
        isRequesting: true,
        teamStatisticsError: false
      };
    case TEAM_CONST.GET_TEAM_STATISTICS_SUCCESS:
      return {
        ...state,
        teamStatisticsData: action.payload.response.data,
        isRequesting: false,
        teamStatisticsError: false
      };
    case TEAM_CONST.GET_TEAM_STATISTICS_FAILURE:
      return {
        ...state,
        teamStatisticsData: null,
        isRequesting: false,
        teamStatisticsError: action.payload ? true : false
      };
    case TEAM_CONST.UPDATE_TEAM_STATUS_REQUEST:
      return {
        ...state,
        teamStatusData: null,
        teamStatusError: false
      };
    case TEAM_CONST.UPDATE_TEAM_STATUS_SUCCESS:
      return {
        ...state,
        teamStatusData: action.payload.response.data,
        teamStatusError: false
      };
    case TEAM_CONST.UPDATE_TEAM_STATUS_FAILURE:
      return {
        ...state,
        teamStatusData: null,
        teamStatusError: action.payload ? true : false
      };
    case TEAM_CONST.GET_TEAM_ALERTS_REQUEST:
      return {
        ...state,
        isRequesting: true,
        issuesData: null,
        issuesDataError: false
      };
    case TEAM_CONST.GET_TEAM_ALERTS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        issuesData: action.payload ? action.payload.response.data.task : null,
        issuesDataError: false
      };
    case TEAM_CONST.GET_TEAM_ALERTS_FAILURE:
      return {
        ...state,
        isRequesting: false,
        issuesData: null,
        issuesDataError: action.payload ? true : false
      };
    case TEAM_CONST.ADD_TEAM_USER_REQUEST:
      return {
        ...state,
        addUserToTeam: null,
        addUserToTeamError: "",
        addUserToTeamNetworkError: false
      };
    case TEAM_CONST.ADD_TEAM_USER_SUCCESS:
      return {
        ...state,
        addUserToTeam: action.payload.response
          ? action.payload.response.data.Message
          : null,
        addUserToTeamError: "",
        addUserToTeamNetworkError: false
      };
    case TEAM_CONST.ADD_TEAM_USER_FAILURE:
      return {
        ...state,
        addUserToTeam: null,
        addUserToTeamError: action.payload.response
          ? action.payload.response.data.error.errorDescription
          : "",
        addUserToTeamNetworkError: action.payload.error ? true : false
      };
    case TEAM_CONST.GET_TEAM_USERS_REQUEST:
      return {
        ...state,
        teamUsersList: null,
        teamUsersListError: false,
        isRequesting: true
      };
    case TEAM_CONST.GET_TEAM_USERS_SUCCESS:
      return {
        ...state,
        teamUsersList: action.payload.response.data,
        teamUsersListError: false,
        isRequesting: false
      };
    case TEAM_CONST.GET_TEAM_USERS_FAILURE:
      return {
        ...state,
        teamUsersList: null,
        teamUsersListError: action.payload ? true : false,
        isRequesting: false
      };
    case TEAM_CONST.DELETE_TEAM_USER_REQUEST:
      return {
        ...state,
        removeTeamUser: null,
        removeTeamUserError: false
      };
    case TEAM_CONST.DELETE_TEAM_USER_SUCCESS:
      return {
        ...state,
        removeTeamUser: action.payload
          ? action.payload.response.data.Message
          : null,
        removeTeamUserError: false
      };
    case TEAM_CONST.DELETE_TEAM_USER_FAILURE:
      return {
        ...state,
        removeTeamUser: null,
        removeTeamUserError: action.payload ? true : false
      };
    case TEAM_CONST.ASSIGN_RULE_TO_TEAM_REQUEST:
      return {
        ...state,
        ruleAssignedToTeamSuccess: null,
        ruleAssignedToTeamFailure: null,
        ruleAssignedToTeamNetworkFailure: false
      };
    case TEAM_CONST.ASSIGN_RULE_TO_TEAM_SUCCESS:
      return {
        ...state,
        ruleAssignedToTeamSuccess: action.payload
          ? action.payload.response.data.Message
          : null,
        ruleAssignedToTeamFailure: null,
        ruleAssignedToTeamNetworkFailure: false
      };
    case TEAM_CONST.ASSIGN_RULE_TO_TEAM_FAILURE:
      return {
        ...state,
        ruleAssignedToTeamSuccess: null,
        ruleAssignedToTeamFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : "",
        ruleAssignedToTeamNetworkFailure: action.payload.error ? true : false
      };
    case TEAM_CONST.UNASSIGN_RULE_TO_TEAM_REQUEST:
      return {
        ...state,
        ruleUnAssignedToTeamSuccess: null,
        ruleUnAssignedToTeamFailure: null,
        ruleUnAssignedToTeamNetworkFailure: false
      };
    case TEAM_CONST.UNASSIGN_RULE_TO_TEAM_SUCCESS:
      return {
        ...state,
        ruleUnAssignedToTeamSuccess: action.payload
          ? action.payload.response.data.Message
          : null,
        ruleUnAssignedToTeamFailure: null,
        ruleUnAssignedToTeamNetworkFailure: false
      };
    case TEAM_CONST.UNASSIGN_RULE_TO_TEAM_FAILURE:
      return {
        ...state,
        ruleUnAssignedToTeamSuccess: null,
        ruleUnAssignedToTeamFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : "",
        ruleUnAssignedToTeamNetworkFailure: action.payload.error ? true : false
      };
  }
  return state;
}
