import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Table,
  Button,
} from "reactstrap";
import Previous from "assets/img/png/previous.png";
import Next from "assets/img/png/next.png";
import Circle from "assets/img/png/circle.png";
import moment from "moment";

function TeamsDashboardTable(props) {
  return (
    <div className="team-dashboard-Table">
      <Container>
        <Row>
        <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                   Instance Alerts
                  </p>
                </div>
                {/* <div className="team-dashboard-table-header-button">
                  <Button className="team-dashboard-table-header-button-style">
                    View All
                  </Button>
                </div> */}
              </div>
            </Card>
            <Table className="team-dashboard-Table-border">
              <tbody className="team-dashboard-table-body">
               {
                 props.instanceAlerts && props.instanceAlerts.length> 0 && 
                 props.instanceAlerts.slice(0,10).map((index)=>{
                  return(
                    <tr>
                    <th>{index.InstanceName || ''}</th>
                    <td className="team-dashboard-table-button">
                    <Button className="team-dashboard-table-header-button-style-button mr-4">
                       {index.AlertsPerInstance || '' } <img src={Circle} width="16.67" />
                    </Button>
                    </td>
                  </tr>
                  )
                })
               }
               {
                   <tr className="empty-row-height">
                   <th>{" "}</th>
                   <td className="team-dashboard-table-button">
                   {/* <Button className="team-dashboard-table-header-button-style-button mr-4">
                       <img src={Circle} width="16.67" />
                   </Button> */}
                   </td>
                 </tr>  

               }
              </tbody>
               <thead>
               
            </thead>
            </Table>
            {/* <Card className="team-dashboard-table-footer-card">  
                <div className="team-dashboard-table-header-prev">
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                  </Button>
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                  </Button>
                </div>
            </Card> */}
          </Col>
          <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    Recent Alert
                  </p>
                </div>
                {/* <div className="team-dashboard-table-header-button">
                  <Button className="team-dashboard-table-header-button-style">
                    View All
                  </Button>
                </div> */}
              </div>
            </Card>
            <Table className="team-dashboard-Table-border">
              {/* <thead >
                <tr className="team-dashboard-table-heading-border">
                  <th className="team-dashboard-table-heading-border-first">Company Name</th>
                  <th>Instances</th>
                  <th>Open Ticket</th>
                  <th>Close Tickets</th>
                </tr>
              </thead> */}
              <tbody className="team-dashboard-table-recent-alerts">
              {
                 props.recentAlerts && props.recentAlerts.length> 0 && 
                 props.recentAlerts.slice(0,5).map((index)=>{
                  return(
                    <tr>
                    <td>
                      <div className= {index.Priority && "High"==index.Priority ? "team-dashboard-table-recent-alerts-high" : 
                          "Low"==index.Priority ? "team-dashboard-table-recent-alerts-low" : 
                          "Medium"==index.Priority ? "team-dashboard-table-recent-alerts-medium" : ""
                      }>
                        <p className="team-dashboard-table-recent-alerts-title">
                           {index.RuleName && index.RuleName || ''}
                        </p>
                        <p className="team-dashboard-table-recent-alerts-instance">
                          Instance: <span className="instance-name"> {index.InstanceName && index.InstanceName || '' } 
                           |{" "}{index.CustomerName && index.CustomerName || ''}{" "} </span>
                        </p>
                        <p className="team-dashboard-table-recent-alerts-time">
                          {index.Created_Date && index.Created_Date || '' }{" "}
                          
                        </p>
                      </div>
                    </td>
                  </tr>
                  )
                })
               } 
              </tbody>
            </Table>
            <Card className="team-dashboard-table-footer-card">  
            <div>
            <ul className="d-flex mt-2 ">
                        <li  style={{color: '#CC2323', fontSize: '14px', marginLeft: '30px'}}>High</li>
                            <li style={{color: '#FF8A00', fontSize: '14px', marginLeft: '30px'}} >Medium </li>
                                <li style={{color: '#1E7FF1', fontSize: '14px', marginLeft: '30px'}} > Low</li>
                    </ul>  
                </div>
                {/* <div className="team-dashboard-table-header-prev">
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                  </Button>
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                  </Button>
                </div> */}
                
            </Card>
            {/* <Card className="team-dashboard-table-footer-card-alerts">  
                 
                <ul className="team-dashboard-table-header-footer-alerts">
                        <li  style={{color: '#CC2323', fontSize: '14px', marginLeft: '30px'}}>High</li>
                            <li style={{color: '#FF8A00', fontSize: '14px', marginLeft: '30px'}} >Medium </li>
                                <li style={{color: '#1E7FF1', fontSize: '14px', marginLeft: '30px'}} > Low</li>
                    </ul>  
            </Card> */}
             
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default TeamsDashboardTable;
