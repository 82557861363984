import { checkHttpStatus } from "../utils";

// handle state when request is send and response is awaited
export function getRequest(REQUEST) {
  return {
    type: REQUEST,
  };
}
// handle state and redirection if user is successfully logged in
export function getSuccess(SUCCESS, data) {
  return {
    type: SUCCESS,
    payload: data,
  };
}
// handle state when reset is send and resposen is awaited
export function reset(RESET) {
  return {
    type: RESET,
  };
}
// handle state in case of failure of user login
export function getFailure(FAILURE, error) {
  handleError(error);
  return {
    type: FAILURE,
    payload: error,
  };
}
// handle error from catch
function handleError(error) {
  if (error.error) {
    if (error.error.data) {
      if (error.error.data.message) {
        let responseStatus = error.error.data.message;
        checkHttpStatus(responseStatus);
      }
    } else if (error.error.statusText) {
      if (error.error.statusText.response) {
        if (error.error.statusText.response.status) {
          let responseStatus = error.error.statusText.response;
          checkHttpStatus(responseStatus);
        }
      }
    }
  }
}
