import React, { PureComponent } from "react";
import Partner from "./Containers/PartnerContainer";

class PartnerPage extends PureComponent {
  render() {
    return (
      <div>
        <Partner />
      </div>
    );
  }
}

export default PartnerPage;
