/**
 * @action        : instanceAction
 * @description   :
 * @Created by    : INNOMICK
 */

import { INSTANCE_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [instance description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for get instance by criteria
export function getInstanceByCriteria(formData, qString) {
  let url;
  if (qString) {
    url = `${BASE_URL}/getInstanceByCriteria?name=${formData.name}&id=${formData.id}${qString}`;
  } else {
    url = `${BASE_URL}/getInstanceByCriteria?name=${formData.name}&id=${formData.id}`;
  }
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_REQUEST));
    AXIOS_INSTANCE.get(url)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_FAILURE, {
              error: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for create instance
export function createInstance(formData) {
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.CREATE_INSTANCE_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/CreateInstance", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.CREATE_INSTANCE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.CREATE_INSTANCE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.CREATE_INSTANCE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for instance statistics
export function instanceStatistics(instanceId) {
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.GET_INSTANCE_STATISTICS_REQUEST));
    AXIOS_INSTANCE.get(
      `${BASE_URL}/getInstanceStatistics?instanceId=${instanceId}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.GET_INSTANCE_STATISTICS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.GET_INSTANCE_STATISTICS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.GET_INSTANCE_STATISTICS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for instance logs
export function instanceLogs(instanceId) {
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.GET_INSTANCE_LOG_REQUEST));
    AXIOS_INSTANCE.get(
      `${BASE_URL}/getInstanceLog?instanceId=${instanceId}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.GET_INSTANCE_LOG_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.GET_INSTANCE_LOG_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.GET_INSTANCE_LOG_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update instance
export function updateInstance(formData) {
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.UPDATE_INSTANCE_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/UpdateInstance", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.UPDATE_INSTANCE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.UPDATE_INSTANCE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.UPDATE_INSTANCE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete instance
export function deleteInstance(instanceId) {
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.DELETE_INSTANCE_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/Instance?instanceId=${instanceId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.DELETE_INSTANCE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.DELETE_INSTANCE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.DELETE_INSTANCE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get certificate
export function getInstanceCertificate(instanceId) {
  return dispatch => {
    dispatch(base.getRequest(INSTANCE_CONST.GET_CERTIFICATE_REQUEST));
    AXIOS_INSTANCE.get(
      `${BASE_URL}/getInstanceCertificate?instanceId=${instanceId}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INSTANCE_CONST.GET_CERTIFICATE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INSTANCE_CONST.GET_CERTIFICATE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INSTANCE_CONST.GET_CERTIFICATE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
