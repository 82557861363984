/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";
import TeamPage from "../Components/TeamPage.jsx";
import PropTypes from "prop-types";
import {
  getCurrentCustomer,
  getCurrentRole,
  showError,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../../../../helpers/Utility";
import { getTeams } from "../../../../actions/teamAction";
class TeamContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {},
      password: "",
      loading: false,
      teamsError: false,
      teamsData: null,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }
  componentDidMount = () => {
    if (getCurrentRole() == 4 || getCurrentRole() == 5) {
      this.props.dispatch(getTeams(getCurrentCustomer()));
    } else if (this.props.customerId) {
      // this.props.dispatch(getTeams(this.props.customerId));
    } else {
      this.props.dispatch(getTeams());
    }
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.teamsData) {
      let teamsData = [];
      nextProps.teamsData.Teams.map((team, key) => {
        teamsData.push({
          Name_Team: team.Name_Team,
          members: team.TeamUsers.length,
          description: team.Description_Team,
          status: {
            id: team.UID_Team,
            status: team.Status,
          },
        });
      });
      this.teamsData = teamsData;
      let meta = this.state.meta;
      if (nextProps.teamsData.metaData) {
        meta = {
          page: nextProps.teamsData.metaData.pageNumber,
          pageSize: nextProps.teamsData.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.teamsData.metaData.pageCount,
          total: nextProps.teamsData.metaData.totalItemCount,
        };
      }
      this.setState({
        teamsData: teamsData,
        teamsSearchData: teamsData,
        customerName: nextProps.teamsData
          ? nextProps.teamsData.customerData[0].Name_Customer
          : "",
        wholeTeamsData: nextProps.teamsData,
        meta: meta,
      });
    }
    if (
      nextProps.teamsDataError &&
      nextProps.teamsDataError !== this.props.teamsDataError
    ) {
      this.setState({ teamsError: nextProps.teamsDataError });
      showError(this.props.t("error.TEAMS_GETTING_ERROR"));
    }
    if (
      nextProps.teamsDataNetworkError &&
      nextProps.teamsDataNetworkError !== this.props.teamsDataNetworkError
    ) {
      this.setState({
        teamsError: nextProps.teamsDataNetworkError,
      });
      showError("Something went wrong!");
    }
    this.setState({
      isRequesting: nextProps.isRequesting,
    });
  };
  //here we are navigating team dashboard page
  showDashboard = (team_id) => {
    this.props.history.push("/dashboard/teamDashboard/" + team_id);
  };
  /*--------opening the create team modal----------------- */
  showModal = () => {
    this.setState({
      showCreateTeamModal: true,
    });
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({ showCreateTeamModal: !this.state.showCreateTeamModal });
  };
  //this function we calling to search teams
  searchTeam = (event) => {
    if (this.state.teamsSearchData && event.target.value) {
      const result = this.state.teamsSearchData.filter(
        function (team) {
          return (
            true ===
            team.Name_Team.toLowerCase().includes(
              event.target.value.toLowerCase()
            )
          );
        }.bind(this)
      );
      this.setState({
        teamsData: result,
      });
    } else {
      this.setState({
        teamsData: this.teamsData,
      });
    }
  };
  render() {
    return (
      <div>
        <TeamPage
          stateValues={this.state}
          wholeTeamsData={this.state.wholeTeamsData}
          teamsData={this.state.teamsData}
          searchTeam={this.searchTeam}
          customerId={this.props.customerId}
          customerName={this.state.customerName}
          showCreateTeamModal={this.state.showCreateTeamModal}
          showModal={this.showModal}
          handleModal={this.handleModal}
          initialValues={this.state.initialValues}
          showDashboard={this.showDashboard}
          isRequesting={this.state.isRequesting}
          t={this.props.t}
        />
      </div>
    );
  }
}
TeamContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  teamsData: PropTypes.object,
  teamsDataError: PropTypes.bool,
  teamsDataNetworkError: PropTypes.bool,
  customerId: PropTypes.string,
  t: PropTypes.func,
  deleteTeamSuccess: PropTypes.bool,
  isRequesting: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    teamsData: state.team.teamsData,
    teamsDataError: state.team.teamsDataError,
    teamsDataNetworkError: state.team.teamsDataNetworkError,
    isRequesting: state.team.isRequesting,
  };
}
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(TeamContainer);
