/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";
import Registration from "../Components/RegistrationPage.jsx";
import PropTypes from "prop-types";
import {
  emailRex,
  showSuccess,
  showError,
  mobileNumRex,
} from "../../../../helpers/Utility";
import {
  getUserEmailById,
  updateUser,
} from "../../../../actions/invitationAction";
import { getCountyCodes } from "../../../../actions/timezoneAction";
import _ from "lodash";
class RegistrationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {},
      password: "",
      confirmPassowrd: "",
      loading: false,
      countryCodeData: [],
      phone: "",
      dialCode:"",
      disable: true,
      isFirstName: false,
      isLastName: false,
      isPassword: false,
      isConfirmPassword: false,
      isContactNumber: false,
      isChecked: false,
    };
  }
  componentDidMount = () => {
    this.props.dispatch(getUserEmailById(this.props.invitationId));
    // this.props.dispatch(getCountyCodes());
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.userData) {
      if (nextProps.userData.Status_User) {
        this.props.history.push("/pages/login-page");
      } else {
        this.setState({
          userEmail: nextProps.userData.Email_User,
        });
      }
    }
    if (nextProps.userDataError || nextProps.userDataNetworkError) {
      showError(this.props.t("error.NETWORK_ERROR"));
    }
    if (
      nextProps.isUserDataUpdated &&
      nextProps.isUserDataUpdated !== this.props.isUserDataUpdated
    ) {
      showSuccess(this.props.t("success.USER_DATA_UPDATED_SUCCESS"));
      this.props.history.push("/pages/login-page");
    }
    if (
      nextProps.isUserDataUpdatedError &&
      nextProps.isUserDataUpdatedError !== this.props.isUserDataUpdatedError
    ) {
      this.setState({ showLoader: false, loading: false });
      showError(nextProps.isUserDataUpdatedError);
    }
    if (
      nextProps.countryCodeData &&
      nextProps.countryCodeData !== this.props.countryCodeData
    ) {
      this.setState({
        countryCodeData: nextProps.countryCodeData,
      });
    }
  };
  /**
   * call this function to validate register user first name
   */
  validateFirstName = (e) => {
    let register = this.state.register;
    if (e.target.value.length <= 20 && e.target.value.length >= 3) {
      register["firstNameState"] = "has-success";
      this.setState({
        isFirstName: true,
      });
    } else {
      register["firstNameState"] = "has-danger";
      this.setState({
        isFirstName: false,
        disable: true,
      });
    }
    this.setState({ register });
    document.getElementById("registerCheckBox").checked = false;
    this.setState({
      disable: true,
      isChecked: false,
    });
  };
  /**
   * call this function to validate register user last name
   */
  validateLastName = (e) => {
    let register = this.state.register;
    if (e.target.value.length <= 20 && e.target.value.length >= 3) {
      register["lastNameState"] = "has-success";
      this.setState({
        isLastName: true,
      });
    } else {
      register["lastNameState"] = "has-danger";
      this.setState({
        isLastName: false,
        disable: true,
      });
    }
    this.setState({ register });
    document.getElementById("registerCheckBox").checked = false;
    this.setState({
      disable: true,
      isChecked: false,
    });
  };
  /**
   * call this function to validate register email
   */
  registerEmail = (e) => {
    let register = this.state.register;
    register["email"] = e.target.value;
    if (emailRex.test(e.target.value)) {
      register["emailState"] = "has-success";
    } else {
      register["emailState"] = "has-danger";
    }
    this.setState({ register });
  };
  /**
   * call this function to validate register password
   */
  validatePassword = (e) => {
    let register = this.state.register;
    if (e.target.value.length >= 5) {
      this.setState({
        password: e.target.value,
      });
      register["passwordState"] = "has-success";
      this.setState({
        isPassword: true,
      });
    } else {
      register["passwordState"] = "has-danger";
      this.setState({
        isPassword: false,
        disable: true,
      });
      this.setState({
        isChecked: false,
      });
    }
    document.getElementById("registerCheckBox").checked = false;
    register["confirmPasswordState"] = "has-danger";
    this.setState({ register });
    this.setState({
      disable: true,
      isChecked: false,
    });
  };
  /**call this function to validate confirm password field */
  validateConfirmPassword = (e) => {
    let register = this.state.register;
    // register["confirmPasswordState"] = e.target.value;
    if (e.target.value === this.state.password) {
      register["confirmPasswordState"] = "has-success";
      this.setState({
        confirmPassword: e.target.value,
      });
      this.setState({
        isConfirmPassword: true,
      });
    } else {
      register["confirmPasswordState"] = "has-danger";
      this.setState({
        isConfirmPassword: false,
        disable: true,
      });
    }
    document.getElementById("registerCheckBox").checked = false;
    this.setState({ register });
    this.setState({
      disable: true,
      isChecked: false,
    });
  };
  /*call this funciton to validate contact number */
  // validateContactNumber = (e) => {
  //   let register = this.state.register;
  //   register["contactNumberState"] = e.target.value;
  //   if (mobileNumRex.test(e.target.value)) {
  //     register["contactNumberState"] = "has-success";
  //   } else {
  //     register["contactNumberState"] = "has-danger";
  //   }
  // };
  /* phone number onchange function */
  handleOnChange = (value,data) => {
    let register = this.state.register;
    this.setState({
      dialCode: data.dialCode,
    });
    this.setState({
      phone: value,
    });
    if (value.length > 10) {
      this.setState({
        // phone: value,
        isContactNumber: true,
      });
      register["contactNumberState"] = "has-success";
    } else {
      register["contactNumberState"] = "has-danger";
      this.setState({
        isContactNumber: false,
        // phone: ""
        // disable: true
      });
    }
    this.setState({ register });
    document.getElementById("registerCheckBox").checked = false;
    this.setState({
      disable: true,
      isChecked: false,
    });
  };

  handleDisable = (e) => {
    if (
      e.target.checked &&
      document.getElementById("registerCheckBox").checked
    ) {
      this.setState({
        isChecked: true,
      });
    } else {
      this.setState({
        isChecked: false,
      });
    }
    if (
      this.state.isFirstName &&
      this.state.isLastName &&
      this.state.isPassword &&
      this.state.isConfirmPassword &&
      // this.state.isContactNumber
      /* here again password validation is done because when user made changes to password
       after clicking checkbox we are making sure that both password and confirm password is matched */
      this.state.password === this.state.confirmPassword
    ) {
      if (!this.state.isChecked) {
        this.setState({
          disable: false,
        });
      } else {
        this.setState({
          disable: true,
        });
      }
    }
  };

  onSubmit = (formProps) => {
    let isvalid = true;
    let register = this.state.register;
    let fields = [
      "firstNameState",
      "lastNameState",
      "passwordState",
      "confirmPasswordState",
    ];
    fields.map((fieldName) => {
      if (register[fieldName] !== "has-success") {
        isvalid = false;
        register[fieldName] = "has-danger";
      }
    });
    if (!isvalid) {
      this.setState({
        loading: false,
      });
      this.setState({ register });
      return false;
    } else {
      if (this.state.phone.length >= 10) {
        // //if (this.state.phone.includes("+")) {
        //   this.setState({ showLoader: true, loading: true });
        //   formProps.contactNumber = `${this.state.phone}`;
        //   //we validating confirm field and country code but no need to send data.
        //   delete formProps["confirmPassword"];
        //   delete formProps["countryCode"];
        //   //here we adding userRoleId from params(url)
        //   Object.assign(formProps, { userRoleId: this.props.invitationId });
        //   this.props.dispatch(updateUser(formProps));
        if (this.state.dialCode !== "") {
          this.setState({ showLoader: true, loading: true });
          formProps.contactNumber = `${this.state.phone}`;
          //we validating confirm field and country code but no need to send data.
          delete formProps["confirmPassword"];
          delete formProps["countryCode"];
          //here we adding userRoleId from params(url)
          Object.assign(formProps, { userRoleId: this.props.invitationId });
          this.props.dispatch(updateUser(formProps));
        } else {
          showError("Please select country code");
        }
        // } else {
        //   showError("Please select country code");
        // }
      } else {
        this.setState({ showLoader: true, loading: true });
        //we validating confirm field and country code but no need to send data.
        formProps["contactNumber"] = "";
        delete formProps["confirmPassword"];
        delete formProps["countryCode"];
        //here we adding userRoleId from params(url)
        Object.assign(formProps, { userRoleId: this.props.invitationId });
        this.props.dispatch(updateUser(formProps));
      }
    }
  };
  render() {
    return (
      <div>
        <Registration
          onSubmit={this.onSubmit}
          handleSubmit={this.props.handleSubmit}
          register={this.state.register}
          registerEmail={this.registerEmail}
          validatePassword={this.validatePassword}
          validateConfirmPassword={this.validateConfirmPassword}
          validateFirstName={this.validateFirstName}
          validateLastName={this.validateLastName}
          validateContactNumber={this.validateContactNumber}
          userEmail={this.state.userEmail}
          initialValues={this.props.initialValues}
          loading={this.state.loading}
          countryCodeData={this.state.countryCodeData}
          handleOnChange={this.handleOnChange}
          phone={this.state.phone}
          handleDisable={this.handleDisable}
          disable={this.state.disable}
          stateValues={this.state}
        />
      </div>
    );
  }
}
RegistrationContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  isUserDataUpdated: PropTypes.bool,
  isUserDataUpdatedError: PropTypes.string,
  userData: PropTypes.object,
  invitationId: PropTypes.string,
  countryCodeData: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    userData: state.invite.userData,
    userDataError: state.invite.userDataError,
    userDataNetworkError: state.invite.userDataNetworkError,
    isUserDataUpdated: state.invite.isUserDataUpdated,
    isUserDataUpdatedError: state.invite.isUserDataUpdatedError,
    countryCodeData: state.timezone.countryCodeData,
  };
}
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "RegistrationPage",
  }),
  connect(mapStateToProps)
)(RegistrationContainer);
