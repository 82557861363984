/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Agents from "../Components/AgentPage.jsx";
import {
  getAgents,
  getAgentCertificate,
  updateAgentStatus
} from "../../../../actions/agentAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../../helpers/Utility";
import PropTypes from "prop-types";
import queryString from "query-string";
class AgentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateAgentModal: false,
      isRequesting: true,
      key: null,
      isRequestingTwo: true,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0
      }
    };
  }
  componentDidMount = () => {
    if (this.props.id) {
      // this.props.dispatch(getAgents(this.props.id));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.agentsData) {
      // console.log(nextProps.agentsData.data.data.agents);
      let agentsData = [];
      nextProps.agentsData.data.data.agents.map((agentData, key) => {
        agentsData.push({
          Agent_Name: agentData.Agent_Name,
          status: agentData.Status,
          actions: {
            name: agentData.Agent_Name,
            status: agentData.Status,
            id: agentData.UID_Agent,
            key: key
          }
        });
      });
      this.agentsData = agentsData;
      let meta = this.state.meta;
      if (nextProps.agentsData.data.metaData) {
        meta = {
          page: nextProps.agentsData.data.metaData.pageNumber,
          pageSize: nextProps.agentsData.data.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.agentsData.data.metaData.pageCount,
          total: nextProps.agentsData.data.metaData.totalItemCount
        };
      }
      this.setState({
        agentsData: agentsData,
        agentsSearchData: agentsData,
        wholeData: nextProps.agentsData.data,
        meta: meta
      });
    }
    if (
      nextProps.agentsError &&
      nextProps.agentsError !== this.props.agentsError
    ) {
      showError("Something went wrong");
    }
    if (
      nextProps.agentCertificateSuccess &&
      nextProps.agentCertificateSuccess !== this.props.agentCertificateSuccess
    ) {
      this.setState({
        agentCertificatePath: nextProps.agentCertificateSuccess
      });
      setTimeout(
        function() {
          document.getElementById("linkId") &&
            document.getElementById("linkId").click();
          this.setState({
            instanceCertificatePath: null
          });
        }.bind(this),
        1000
      );
    }
    if (
      nextProps.agentStatusData &&
      nextProps.agentStatusData !== this.props.agentStatusData
    ) {
      showSuccess("Agent status has been changed successfully.");
      if (this.props.id) {
        this.props.dispatch(getAgents(this.props.id));
      }
    }
    if (
      nextProps.agentStatusError &&
      nextProps.agentStatusError !== this.props.agentStatusError
    ) {
      showError("Error in changing agent status.");
    }
    if (nextProps.isRequesting) {
      this.setState({
        isRequesting: nextProps.isRequesting
      });
    } else {
      this.setState({
        isRequesting: nextProps.isRequesting
      });
    }
    if (nextProps.isRequestingTwo) {
      this.setState({
        isRequestingTwo: nextProps.isRequestingTwo
      });
    } else {
      this.setState({
        isRequestingTwo: nextProps.isRequestingTwo
      });
    }
  };
  //this function we are calling to search members
  searchName = event => {
    if (this.state.agentsSearchData && event.target.value) {
      const result = this.state.agentsSearchData.filter(
        function(data) {
          return (
            true ===
            data.Agent_Name.toLowerCase().includes(
              event.target.value.toLowerCase()
            )
          );
        }.bind(this)
      );
      this.setState({
        agentsData: result
      });
    } else {
      this.setState({
        agentsData: this.agentsData
      });
    }
  };
  /*--------opening the create agent modal----------------- */
  showModal = () => {
    this.setState({
      showCreateAgentModal: true
    });
  };
  /*----------call this function to update agent----*/
  updateAgent = (agentName, agentId) => {
    let initialValues = {
      name: agentName,
      agentId: agentId
    };
    this.setState({
      initialValues: initialValues,
      showUpdateAgentModal: true
    });
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showCreateAgentModal: !this.state.showCreateAgentModal
    });
  };
  //call this function to handle(means close or open)update modal
  handleModalTwo = () => {
    this.setState({
      showUpdateAgentModal: !this.state.showUpdateAgentModal
    });
  };
  /*calling this function to call api download key */
  downloadAgentKey = (agentId, key) => {
    this.setState({
      key: key
    });
    this.props.dispatch(getAgentCertificate(agentId));
  };
  /*----------for update partner status---------------- */
  updateAgentStatus = (agentId, status) => {
    if (status) {
      const confirm = window.confirm(
        "Are you sure you want to de-register agent ?"
      );
      if (confirm) {
        const statusData = {
          agentId: agentId,
          status: status ? false : true
        };
        this.props.dispatch(updateAgentStatus(statusData));
      }
    } else {
      const statusData = {
        agentId: agentId,
        status: status ? false : true
      };
      this.props.dispatch(updateAgentStatus(statusData));
    }
  };
  /*------------render method-------------------- */
  render() {
    const name = this.props.names
      ? queryString.parse(this.props.names).teamName
        ? queryString
            .parse(this.props.names)
            .teamName.split("_")
            .join(" ")
        : queryString.parse(this.props.names).instanceName
        ? queryString
            .parse(this.props.names)
            .instanceName.split("_")
            .join(" ")
        : ""
      : "";
    const customerName = this.props.names
      ? queryString
          .parse(this.props.names)
          .customerName.split("_")
          .join(" ")
      : "";
    const customerId = this.props.names
      ? queryString.parse(this.props.names).customerId
      : "";
    return (
      <div>
        <Agents
          instanceId={this.props.id}
          searchName={this.searchName}
          customerName={customerName}
          teamName={name}
          customerId={customerId}
          showDashboard={this.showDashboard}
          names={this.props.names}
          showModal={this.showModal}
          handleModal={this.handleModal}
          handleModalTwo={this.handleModalTwo}
          stateValues={this.state}
          downloadAgentKey={this.downloadAgentKey}
          updateAgentStatus={this.updateAgentStatus}
          updateAgent={this.updateAgent}
          toggle={this.toggle}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isRequesting: state.agent.isRequesting,
    agentsData: state.agent.agentsData,
    agentsError: state.agent.agentsError
      ? state.agent.agentsError
      : state.agent.agentsNetworkError,
    agentCertificateSuccess: state.agent.agentCertificateSuccess,
    agentCertificateFailure: state.agent.agentCertificateFailure,
    isRequestingTwo: state.agent.isRequestingTwo,
    agentStatusData: state.agent.agentStatusData,
    agentStatusError: state.agent.agentStatusError
  };
}
AgentContainer.propTypes = {
  id: PropTypes.string,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  isRequesting: PropTypes.bool,
  isRequestingTwo: PropTypes.bool,
  agentsData: PropTypes.array,
  agentsNetworkError: PropTypes.bool,
  agentsError: PropTypes.bool,
  agentCertificateSuccess: PropTypes.string,
  agentStatusData: PropTypes.bool,
  agentStatusError: PropTypes.bool
};
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(AgentContainer);
