import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from "reactstrap";
import { Pie } from "react-chartjs-2";
import Assigned from "assets/img/png/assigned.png";
import Closed from "assets/img/png/closed.png";
import Unassigned from "assets/img/png/unassigned.png";

const options = {
  legend: {
    display: false,
    position: "bottom",
    labels: {
      boxWidth: 10,
    },
  },
};
 
function DashboardPieChart({
  table,
  chartFunction,
  teamOverviewFunction, 
  assigned,
  closed,
  unassigned,
  teamOverview,
  teamOverViewPieData,
  teamOverviewPieIndex }) {
    
    // console.log(" pie chart data ", teamOverview ," team overview pie data ", teamOverViewPieData )
    const recentAlerts = teamOverview && teamOverview.recentAlerts &&
          teamOverview.recentAlerts.length > 0 && teamOverview.recentAlerts ? teamOverview.recentAlerts : null ;
    
          const recentAlertss = teamOverview && teamOverview.recentAlerts &&
          teamOverview.recentAlerts.length > 0 && teamOverview.recentAlerts ? teamOverview.recentAlerts[teamOverviewPieIndex].messages : null ;   

    const messages = teamOverViewPieData &&
     teamOverViewPieData[0] &&
      teamOverViewPieData[0].messages ? teamOverViewPieData[0].messages : null ;
 
  const datateam = {
    labels: [],
    datasets: [
      {
        label: "My First Dataset",
        data: [],
        backgroundColor: ["#FB7C53", "#4060D0"],
        hoverOffset: 4,
        position: "bottom",
      },
    ],
  };

  const data = {
    labels: ["Assign", "Closed", "Unassigned"],
    datasets: [
      {
        label: "My First Dataset",
        data: [],
        backgroundColor: ["#FB7C53", "#4060D0", "#3497DF"],
        hoverOffset: 4,
        position: "bottom",
      },
    ],
  };

  //Getting selected option and push respected data in pie chart data
  if (recentAlertss && recentAlertss.length > 0 ) {
    recentAlertss.map((index) => { 
      datateam.datasets[0].data.push(index.TeamUserCount);
      datateam.datasets[0].backgroundColor.push(
        "#" + Math.floor(Math.random() * 16777215).toString(16)
      );
      datateam.labels.push(index.TeamName);
    });
  }

  const teamOverviewSelectOption = (event)=> {          
    teamOverviewFunction(event.target.value)
    // recentAlerts.map((data, index)=>{
      //   if(event.target.value == index){
         
      //   }
      // })
  }

  //Assigning Assigned, closed and Unassigned to state if Id matached
  const alertsOverviewSelectOption = (event)=> {
    table.map((index) => {
      if (index.customer.UID_Customer == event.target.value) {
        const data = index.customer.count;
        chartFunction(
          data.Assigned,
          data.Closed,
          data.Unassigned
        );
      }
    });
  };

  //pushing data to stack graph
  // data.datasets[0].data.push(20)
  // data.datasets[0].data.push(30)
  // data.datasets[0].data.push(40)

  data.datasets[0].data.push(assigned);
  data.datasets[0].data.push(closed);
  data.datasets[0].data.push(unassigned);

  const renderTeamsOverviewOption=()=>{
    return teamOverview && 
    teamOverview.recentAlerts &&
    teamOverview.recentAlerts.length > 0 &&            
    teamOverview.recentAlerts.map((data, index)=>{
    return(
      <option value={ index } >{ data.name }</option> 
        )
    })
  }

  const renderAlertsOverviewOption=()=>{
   return  table &&
    table.length > 0 &&
    table.map((data, index) => {
      const item= data
            && data.customer
            && data.customer 
            && data.customer.UID_Customer 
            && data.customer
      return (
        <option 
          value={ item  && item.UID_Customer}>
          { item  && item.Name_Customer}
        </option>
      );
    })
  }

  return (
    <div className="team-dashboard-piechart">
      <Container fluid>
        <Row>
          <Col md="6">
            <Card className="card-tasks">
              <CardHeader className="d-flex">
                <p className="">Teams Overview</p>
                <div className="ml-auto mr-4  card-items-inline-form d-flex">
                <div><p style={{ marginTop: '5px'}}>Choose company</p></div>
                <div>
                  <Input type="select" className="float-right dadhboard-header-input"
                    onChange={(event) => teamOverviewSelectOption(event)}
                  >
                  {renderTeamsOverviewOption()}
                  </Input>
                  </div>
                </div>
              </CardHeader>
              <hr style={{ marginTop: "0px" }} />
              <CardBody>
                <Pie data={datateam} options={options} />
              </CardBody>
              {/* <CardFooter>
                  <ul>
                    <li style={{color:'green', fontSize:'15px'}}>Adele</li>
                    <li style={{color:'red', fontSize:'15px'}}>Adele</li>
                    <li style={{color:'yellow', fontSize:'15px'}}>Adele</li>
                </ul>  
                </CardFooter> */}
              <CardFooter>
                <div className="status-right text-center">
                  {/* <span className="dashboard-assigned dashboar-high">
                    <i className="dahboard-alert-high">
                      <img src={Assigned} width="10" />
                    </i>
                    Total Roles
                  </span>
                  <span className="dashboard-closed dashboar-medium">
                    <i className="dahboard-alert-medium">
                      <img src={Closed} width="10" />
                    </i>
                     Total Teams
                  </span> */}
                  {/* <span className="dashboard-unasigned dashboar-low">
                    <i className="dahboard-alert-low">
                      <img src={Unassigned} width="10" />
                    </i>
                    Unassigned
                  </span> */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-tasks">
              <CardHeader className="d-flex">
                <p className="">Alerts Overview</p>
                <div className="ml-auto mr-4  card-items-inline-form d-flex">
                  <div>
                    <p style={{ marginTop: "5px" }}>Choose company</p>
                  </div>
                  <div>
                    <Input
                      type="select"
                      className="float-right  
                    dadhboard-header-input"
                      onChange={(event) => alertsOverviewSelectOption(event)}
                    >
                        {renderAlertsOverviewOption()}

                    </Input>
                  </div>
                </div>
              </CardHeader>
              <hr style={{ marginTop: "0px" }} />
              <CardBody>
                <Pie data={data} options={options} />
              </CardBody>
              {/* <CardFooter>
                 <ul>
                    <li style={{color:'green', fontSize:'15px'}}>Adele</li>
                    <li style={{color:'red', fontSize:'15px'}}>Adele</li>
                    <li style={{color:'yellow', fontSize:'15px'}}>Adele</li>
                </ul> *
                </CardFooter> */}
              <CardFooter>
                <div className="status-right text-center">
                  <span className="dashboard-assigned dashboar-high">
                    <i className="dahboard-alert-high">
                      <img src={Assigned} width="10" />
                    </i>
                    Assigned
                  </span>
                  <span className="dashboard-closed dashboar-medium">
                    <i className="dahboard-alert-medium">
                      <img src={Closed} width="10" />
                    </i>
                    Closed
                  </span>
                  <span className="dashboard-unasigned dashboar-low">
                    <i className="dahboard-alert-low">
                      <img src={Unassigned} width="10" />
                    </i>
                    Unassigned
                  </span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default DashboardPieChart;
