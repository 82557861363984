import React from "react";

import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from "reactstrap";
import { Bar } from "react-chartjs-2";
import Assigned from "assets/img/png/assigned.png";
import Unassigned from "assets/img/png/unassigned.png";

function DashboardbarChart({
  barData,
  getAlertsActivitySelectedIndex,
  alertsActivityIndex,
}) {
  // console.log(" bar data", getAlertsActivitySelectedIndex);

  const monthNames = [
    "Jan  ",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    // labels: ["1Jan","2Jan","3Jan","4Jan","5Jan","6Jan","7Jan","8Jan","9Jan","10Jan","11Jan","12Jan", "13Jan","14Jan", "15Jan" ],
    labels: [],
    datasets: [
      {
        label: "Closed Alerts",
        // data: [3, 6, 2, 4, 8,5, 3,2, 4, 6 , 7 ,6 , 3, 2, 1   ],
        data: [],
        backgroundColor: "#FA7A50",
        borderWidth: 1,
      },
      {
        label: "Opened  Alerts",
        // data: [5, 5, 3, 5, 7, 4, 5, 4, 7, 5, 4, 2, 3 , 5, 2, 3, 2 ],
        data: [],
        backgroundColor: "#1E7FF1",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        boxWidth: 10,
      },
    },
  };

  if (barData && barData.length > 0 ) {
    barData[alertsActivityIndex].alertData && barData[alertsActivityIndex].alertData.map((index, key) => {
      if (
        monthNames.some((res) => res.includes(index.Created_Date.slice(0, 3)))
      ) {
        data.labels.push(index.Created_Date.slice(0, 3));
      } else {
        const dayData = new Date(index.Created_Date).toLocaleString(undefined, {
          day: "2-digit",
        });
        data.labels.push(
          dayData + " " + monthNames[new Date(index.Created_Date).getMonth()]
        );
      }
      // data.datasets[0].data.push(key)
      // data.datasets[1].data.push(key+1);
      data.datasets[0].data.push(index.Closed);
      data.datasets[1].data.push(index.Opened);
    });
  }

  const onAlertsActivityOptionSelect = (event) => {
    console.log("selected index", event.target.value);
    getAlertsActivitySelectedIndex(event.target.value);
  };

  const renderAlertsActivityOptions = () => {
    if (barData && barData.length > 0) {
      return barData.map((data, index) => {
        return <option value={index}>{data.customer}</option>;
      });
    }
  };

  return (
    <div className="team-dahboard-barchart">
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <CardHeader className="d-flex">
                <p className="">Alerts Activity</p>
                <div className="ml-auto mr-4  card-items-inline-form d-flex">
                  <div>
                    <p style={{ marginTop: "5px" }}>Choose company</p>
                  </div>
                  <div>
                    <Input
                      type="select"
                      className="float-right dadhboard-header-input"
                      onChange={(event) => onAlertsActivityOptionSelect(event)}
                    >
                      {renderAlertsActivityOptions()}
                    </Input>
                  </div>
                </div>
              </CardHeader>
              <hr style={{ marginTop: "0px" }} />
              <CardBody>
                <Bar data={data} height="100" options={options} />
              </CardBody>
              <CardFooter>
                <div className="status-right text-center">
                  <span className="dashboard-unasigned dashboar-low">
                    <i className="dahboard-alert-low">
                      <img src={Unassigned} width="10" />
                    </i>
                    Open Alerts
                  </span>
                  <span className="dashboard-assigned dashboar-high">
                    <i className="dahboard-alert-high">
                      <img src={Assigned} width="10" />
                    </i>
                    Closed Alerts
                  </span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DashboardbarChart;
