/* eslint-disable prettier/prettier */
/**
 * @action        : dashboardAction
 * @description   : Handles get user data
 * @Created by    : INNOMICK
 */

import { DASHBOARD_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";
import * as base from "./baseAction";

// action for get user dashboard data
export function getDashboardData(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_USER_STATISTICS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getDashBoard${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_USER_STATISTICS_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_USER_STATISTICS_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_USER_STATISTICS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

export function getDashboardInfo(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_DASHBOARD_INFO_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getDashboardInfo${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_DASHBOARD_INFO_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_DASHBOARD_INFO_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_DASHBOARD_INFO_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getPartnerDashboard(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_PARTNER_DASHBOARD_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getPartnerDashboard${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_PARTNER_DASHBOARD_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_PARTNER_DASHBOARD_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_PARTNER_DASHBOARD_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getCustomerWiseData(qString) {
  return dispatch => {
    dispatch(base.getRequest( DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_REQUEST ));
    AXIOS_INSTANCE.get(`${BASE_URL}/getCustomerWiseData${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getTeamUsers(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_TEAM_USERS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getTeamUsers${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_TEAM_USERS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_TEAM_USERS_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_TEAM_USERS_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getTeamAlerts(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_TEAM_ALERTS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getTeamAlerts${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_TEAM_ALERTS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_TEAM_ALERTS_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_TEAM_ALERTS_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getAlertAndNotificationCount(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getAlertAndNotificationCount${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getTeamUserAlerts(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_TEAM_USER_ALERTS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getTeamUserAlerts${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_TEAM_USER_ALERTS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_TEAM_USER_ALERTS_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_TEAM_USER_ALERTS_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}

export function getOrganisationAlerts(qString) {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getOrganisationAlerts${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}


export function getUserInfo() {
  return dispatch => {
    dispatch(base.getRequest(DASHBOARD_CONST.GET_USER_INFO_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getUserInfo`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_USER_INFO_SUCCESS, {
              response: {
                data: response.data
              }
            })
          )
        }
        else {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_USER_INFO_FAILURE, {
              response: {
                data: response
              }
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(DASHBOARD_CONST.GET_USER_INFO_FAILURE, {
            error: {
              data: error
            }
          })
        )
      })
  }
}
