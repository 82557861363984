import React, { PureComponent } from "react";
import UserProfile from "./Containers/UserProfileContainer";

class UserProfilePage extends PureComponent {
  render() {
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div>
        <UserProfile userData={userData} />
      </div>
    );
  }
}

export default UserProfilePage;
