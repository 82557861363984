import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import { translate } from "react-multi-lang";
import {
  showRulesPriority,
  dateFormat,
  timeFormat,
  dateFormatWithTime,
  showError,
  showSuccess,
  getStartDateSevenDaysAgo
} from "../../../helpers/Utility";
import { renderTextField } from "../Common/InputRenderer";
import {
  createAlertComment,
  getAlertDetails,
  assignAlertToUser,
  unAssignAlert,
  getUserList
} from "../../../actions/alertAction";
import { getNotifications } from "../../../actions/notificationAction";
import { reduxForm, Field, reset } from "redux-form";
import PropTypes from "prop-types";
import Button from "reactstrap-button-loader";
import UserImage from "../../../assets/img/png/DefaultUser.png";

class NotificationIssueDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dropdownOpen: false,
      disable: true,
      startDate: getStartDateSevenDaysAgo()
    };
  }
  componentDidMount = () => {
    const issuesData = this.state.issueDetails
      ? this.state.issueDetails
      : this.props.issueDetails;
    this.props.dispatch(getUserList(issuesData.UID_Instance));
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.commentDataSuccess &&
      nextProps.commentDataSuccess !== this.props.commentDataSuccess
    ) {
      showSuccess(this.props.t("success.COMMENT_SUCCESS"));
      this.setState({
        loading: false
      });
      this.props.dispatch(getAlertDetails(this.props.issueDetails.UID_Alert));
    }
    if (
      nextProps.commentDataError &&
      nextProps.commentDataError !== this.props.commentDataError
    ) {
      this.setState({
        loading: false
      });
      showError("Error in saving comment");
    }
    if (
      nextProps.issueDetailsData &&
      nextProps.issueDetailsData !== this.props.issueDetailsData
    ) {
      this.setState({
        issueDetails: nextProps.issueDetailsData
      });
      this.props.dispatch(reset("createComment-form"));
    }
    if (
      nextProps.assignAlertSuccess &&
      nextProps.assignAlertSuccess !== this.props.assignAlertSuccess
    ) {
      showSuccess(nextProps.assignAlertSuccess);

      const query = `?date=${this.state.startDate}`;
      this.props.dispatch(getNotifications(query));

      this.props.dispatch(getAlertDetails(this.props.issueDetails.UID_Alert));
    }
    if (
      nextProps.unassignAlertSuccess &&
      nextProps.unassignAlertSuccess !== this.props.unassignAlertSuccess
    ) {
      showSuccess(nextProps.unassignAlertSuccess);
      const query = `?date=${this.state.startDate}`;
      this.props.dispatch(getNotifications(query));
      this.props.dispatch(getAlertDetails(this.props.issueDetails.UID_Alert));
    }
    if (
      nextProps.assignAlertError &&
      nextProps.assignAlertError !== this.props.assignAlertError
    ) {
      showError("Error in assigning alert to user.");
    }
    if (
      nextProps.unassignAlertError &&
      nextProps.unassignAlertError !== this.props.unassignAlertError
    ) {
      showError(nextProps.unassignAlertError);
    }
    if (
      nextProps.unassignAlertNetworkError &&
      nextProps.unassignAlertNetworkError !==
        this.props.unassignAlertNetworkError
    ) {
      showError("Error in un assign alert.");
    }
    //for userlist
    if (
      nextProps.userListData &&
      nextProps.userListData !== this.props.userListData
    ) {
      nextProps.userListData.map(user => {
        if (
          user.UID_User ===
          JSON.parse(localStorage.getItem("userDetails")).UID_User
        ) {
          this.setState({
            userData: user
          });
        }
      });
    }
  };
  //call this function to close modal
  handleModal = () => {
    this.props.handleModal();
    const query = `?date=${this.state.startDate}`;
    this.props.dispatch(getNotifications(query));
  };
  /**call this function to render list of comments */
  renderComments = data => {
    if (data.length) {
      return data.map(alert => {
        return (
          <Row key={alert.UID_Alert} className="mobile-display-style">
            <Col lg={1} md={1} xs={1} className="text-left">
              <img
                src={
                  alert.User
                    ? alert.User.Profile_Image
                      ? alert.User.Profile_Image
                      : UserImage
                    : UserImage
                }
                alt="user"
                className="rounded-circle img-fluid user-css-styles"
              />
            </Col>
            <Col lg={11} md={11} xs={11}>
              <span className="font-weight-bold">
                {alert.User.First_Name} {alert.User.Last_Name}
                <small className="ml-2">
                  <span className="badge-pill badge-secondary">
                    {dateFormatWithTime(alert.Created_Date)}
                  </span>
                </small>
              </span>
              <p>{alert.Comment_Data}</p>
            </Col>
          </Row>
        );
      });
    }
  };
  /**call this function to check any space is there and handling button disable or enable */
  createComment = e => {
    let comment = e.target.value.trim();
    if (comment.length > 0) {
      this.setState({
        disable: false
      });
    } else {
      this.setState({
        disable: true
      });
    }
  };

  /**call this function to change status(assign and un assign) of alert*/
  changeAlertStatus = userId => {
    if (userId) {
      const formData = {
        alertId: this.props.issueDetails.UID_Alert,
        userId: userId
      };
      this.props.dispatch(assignAlertToUser(formData));
    } else {
      const formData = {
        alertId: this.props.issueDetails.UID_Alert
      };
      this.props.dispatch(unAssignAlert(formData));
    }
  };
  /*for dropdown toggle*/
  dropdownToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  /*-----------------here we are calling creating comment for issue api----------------- */
  onSubmit = formProps => {
    formProps = {
      alertId: this.props.issueDetails.UID_Alert,
      comment: formProps.comment
    };
    if (!formProps.comment) {
      showError("Comment is mandatory");
    } else {
      this.setState({
        loading: true
      });
      this.props.dispatch(createAlertComment(formProps));
    }
  };
  render() {
    const issuesData = this.state.issueDetails
      ? this.state.issueDetails
      : this.props.issueDetails;

    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center issue-details-modal-style"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            <i className="fa fa-exclamation-circle" />{" "}
            {this.props.t("notifications.ISSUE_DETAILS")}
          </ModalHeader>
          <ModalBody className="remove-paddings">
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3">
                <Col
                  lg={8}
                  md={8}
                  xs={12}
                  className="text-left comment-scrollbar"
                >
                  <span className="font-weight-bold">
                    {this.props.t("notifications.NAME")}
                  </span>
                  <br />
                  <span className="mx-auto">
                    {issuesData ? issuesData.Rule.Name_Rule : ""}
                  </span>
                  <br />
                  <span className="font-weight-bold">
                    {this.props.t("notifications.DESCRIPTION")}
                  </span>
                  <br />
                  <span>
                    {issuesData ? issuesData.Rule.Description_Rule : ""}
                  </span>
                  <br />
                  <span className="font-weight-bold">
                    {this.props.t("notifications.COMMENTS")}
                  </span>
                  <div
                    className={
                      issuesData.AlertComments.length > 4
                        ? "issues-comments-styles"
                        : ""
                    }
                  >
                    {issuesData && issuesData.AlertComments
                      ? this.renderComments(issuesData.AlertComments)
                      : ""}
                  </div>
                  <div className="d-flex">
                    <img
                      src={
                        this.state.userData
                          ? this.state.userData.Profile_Image
                            ? this.state.userData.Profile_Image
                            : UserImage
                          : UserImage
                      }
                      alt="user"
                      className="rounded-circle img-fluid user-css-styles"
                    />
                    <Field
                      component={renderTextField}
                      name="comment"
                      type="textarea"
                      className="textarea-styles"
                      placeholder=" Add a comment"
                      onChange={e => this.createComment(e)}
                      // onKeyDown={e => this.keyDown(e)}
                    />
                  </div>
                  <div className="float-right">
                    <Button
                      color="primary"
                      type="submit"
                      loading={this.state.loading}
                      disabled={this.state.disable}
                    >
                      {this.props.t("notifications.COMMENT")}
                    </Button>
                  </div>
                </Col>
                <Col lg={4} md={4} xs={12} className="text-left list-style">
                  <span className="font-weight-bold">
                    {this.props.t("notifications.STATUS")}
                  </span>
                  <br />
                  <Dropdown
                    nav
                    isOpen={this.state.dropdownOpen}
                    toggle={
                      issuesData.Status === 2 ? "" : e => this.dropdownToggle()
                    }
                    className={
                      issuesData.Status === 2
                        ? "issue-dropdown-css-two mb-3"
                        : "mobile-style-css issue-dropdown-css mb-3"
                    }
                  >
                    <DropdownToggle
                      caret
                      nav
                      className={
                        issuesData.Status === 2 ? "text-muted cursor-none" : ""
                      }
                    >
                      <span>
                        {issuesData.Status === 0
                          ? "Unassigned"
                          : issuesData.Status === 1
                          ? "Assigned"
                          : "Closed"}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        tag="a"
                        className="text-muted font-weight-bold cursor-pointer"
                        onClick={() => this.changeAlertStatus(null)}
                      >
                        Unassign
                      </DropdownItem>
                      {this.state.userData ? (
                        <DropdownItem
                          tag="a"
                          className="text-muted font-weight-bold cursor-pointer"
                          onClick={() =>
                            this.changeAlertStatus(this.state.userData.UID_User)
                          }
                        >
                          {this.state.userData.First_Name}{" "}
                          {this.state.userData.Last_Name}
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                    </DropdownMenu>
                  </Dropdown>
                  <span className="font-weight-bold">
                    {this.props.t("notifications.ASSIGNEE")}
                  </span>
                  <p>
                    {issuesData.Assigned_To
                      ? issuesData.User.First_Name +
                        " " +
                        issuesData.User.Last_Name
                      : "N/A"}
                  </p>
                  <span className="font-weight-bold">
                    {this.props.t("notifications.CUSTOMER")}
                  </span>
                  <p>{issuesData.Instance.Customer.Name_Customer}</p>
                  <span className="font-weight-bold">
                    {this.props.t("notifications.PRIORITY")}
                  </span>
                  <p>
                    <img
                      className="mr-2 mb-1"
                      src={showRulesPriority(issuesData.Rule.Priority_Rule)}
                      alt="PriorityImage"
                      width="20"
                    />
                    {issuesData.Rule.Priorities[0].Name_Priority}
                  </p>
                  <span className="font-weight-bold">
                    {this.props.t("notifications.CREATED_ON")}:
                  </span>
                  <p>
                    {dateFormat(issuesData.Created_Date)}
                    <br />
                    {timeFormat(issuesData.Created_Date)}
                  </p>
                  <span className="font-weight-bold">
                    {this.props.t("notifications.UPDATED_ON")}:
                  </span>
                  <p>
                    {dateFormat(issuesData.Updated_Date)}
                    <br />
                    {timeFormat(issuesData.Updated_Date)}
                  </p>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    userListData: state.alert.getUserListSuccess,
    issueDetailsData: state.alert.issueDetailsData,
    commentDataSuccess: state.alert.commentDataSuccess,
    commentDataError: state.alert.commentDataNetworkError
      ? state.alert.commentDataNetworkError
      : state.alert.commentDataDataError,
    assignAlertSuccess: state.alert.assignAlertSuccess,
    unassignAlertSuccess: state.alert.unassignAlertSuccess,
    assignAlertError: state.alert.assignAlertError
      ? state.alert.assignAlertError
      : state.alert.assignAlertNetworkError,
    unassignAlertError: state.alert.unassignAlertError,
    unassignAlertNetworkError: state.alert.unassignAlertNetworkError
  };
}
NotificationIssueDetailsModal.propTypes = {
  handleModal: PropTypes.func,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  issueDetails: PropTypes.object,
  issueDetailsData: PropTypes.object,
  commentDataSuccess: PropTypes.bool,
  commentDataError: PropTypes.bool,
  assignAlertSuccess: PropTypes.string,
  unassignAlertSuccess: PropTypes.string,
  assignAlertError: PropTypes.bool,
  unassignAlertError: PropTypes.string,
  unassignAlertNetworkError: PropTypes.bool,
  userListData: PropTypes.array
};
export default compose(
  translate,
  reduxForm({
    form: "createComment-form"
  }),
  connect(mapStateToProps)
)(NotificationIssueDetailsModal);
