/* eslint-disable react/display-name */
import React, { Fragment } from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Card,
  CardBody
} from "reactstrap";
import SideBarToggle from "../../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { roles_number } from "../../../../../helpers/Utility";
import LoaderIcon from "../../../../../assets/img/Loader.gif";
import BreadcrumbComponent from "../../../Common/BreadCrumbComponent";
import ReactTable from "react-table";

function PartnerUsersPage(props) {
  const showCount = (adminCount, supportCount, customerCount) => {
    if (props.stateValues.isRequesting) {
      return (
        <tr className="table-tr-loader">
          <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
        </tr>
      );
    } else {
      return (
        <Fragment>
          <tr
            className={"table-data-rows"}
            onClick={e => props.showMembers(roles_number.partnerAdmin)}
          >
            <td className="pl-3">Partner Admin</td>
            <td className="text-center">{adminCount}</td>
          </tr>
          <tr
            className={"table-data-rows"}
            onClick={e => props.showMembers(roles_number.partnerUser)}
          >
            <td className="pl-3">Partner Support</td>
            <td className="text-center">{supportCount}</td>
          </tr>
          <tr
            className={"table-data-rows"}
            onClick={e => props.navigateCustomers()}
          >
            <td className="pl-3">Organisations</td>
            <td className="text-center">{customerCount}</td>
          </tr>
        </Fragment>
      );
    }
  };
  // const adminCount = props.stateValues.membersCount
  //   ? props.stateValues.membersCount.memberCount.partnerAdmin.length
  //   : 0;
  // const supportCount = props.stateValues.membersCount
  //   ? props.stateValues.membersCount.memberCount.partnerSupport.length
  //   : 0;
  // const customerCount = props.stateValues.membersCount
  //   ? props.stateValues.membersCount.memberCount.customerCount
  //   : 0;
  /*--------------react table data--------------------- */
  const data = props.stateValues.membersCount
    ? props.stateValues.membersCount
    : [];
  /*--------------react table header--------------------- */
  const headers = {
    role: "Roles/Organisations",
    members: "members/count"
  };
  return (
    <div>
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={3} xs={6} className="padding-one">
                <p className="font-weight-bold">Roles</p>
              </Col>
              <Col lg={5} xs={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    placeholder="Search for Role"
                    className="search-bar-css shadow-css"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="search-bar-css icon-css shadow-css">
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <BreadcrumbComponent
                  active="Roles"
                  nonActive1={props.partnerName}
                  nonActive1Href={"/dashboard/partner"}
                />
              </Col>
            </Row>
            {/* <Row className="mt-4">
              <Col className="list-style">
                <Table responsive className="members-table-styles">
                  <tr className="table-header-row">
                    <th className="pl-3">Roles / Organisations</th>
                    <th className="text-center">Members</th>
                  </tr>
                  <tbody>
                    {showCount(adminCount, supportCount, customerCount)}
                  </tbody>
                </Table>
              </Col>
            </Row> */}

            <Card className="mt-4">
              <CardBody>
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  // loading={!this.props.membersCount ? false : true}
                  loadingText={"Argos..."}
                  showPagination={false}
                  className="cursor-pointer"
                  getTrProps={(state, row) => ({
                    onClick: () =>
                      row.original.role.id === "customers"
                        ? props.navigateCustomers()
                        : props.showMembers(row.original.role.id)
                  })}
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          sortable: false,
                          Header: headers.role,
                          accessor: "role",
                          Cell: row => (
                            <div
                            // onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value.name}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.members,
                          accessor: "members",
                          Cell: row => (
                            <div
                            // onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  defaultPageSize={3}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
PartnerUsersPage.propTypes = {
  stateValues: PropTypes.object,
  showSelectUserRoleModal: PropTypes.bool,
  userRolesData: PropTypes.object,
  handleModal: PropTypes.func,
  showMembers: PropTypes.func,
  navigateCustomers: PropTypes.func
};
export default PartnerUsersPage;
