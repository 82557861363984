/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { getUserRoles } from "../actions/invitationAction";
import _ from "lodash";
import HighPriorityImage from "../assets/img/png/HighPriorityImage.png";
import MediumPriorityImage from "../assets/img/png/MediumPriorityImage.png";
import LowPriorityImage from "../assets/img/png/LowPriorityImage.png";
/**
 * call this regular experssion constant to validate email for login page
 */
export const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * call this regular expression constant to validate mobile number
 */
export const mobileNumRex = /^\d{10}$/;
export const numberRegex = /^[0-9]*$/;
/**call this const to show error notification */
export const showError = msg =>
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_LEFT
  });
/**call this const to show success notification */
export const showSuccess = msg =>
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_LEFT
  });
/*-------------------react-table meta content------------------------*/
export const INDEX_PAGE_SIZE_DEFAULT = 10;
export const INDEX_PAGE_SIZE_OPTIONS = [5, 10, 20, 30, 50];
/*--------------call this function go get date format-----------------*/

export function getMonday(date) {
  date = new Date();
  var day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return moment(new Date(date.setDate(diff)))
    .local()
    .format("YYYY-MM-DD");
}

export function dateFormat(rawDate) {
  // let modifiedDate = rawDate.replace(/.$/, ""); //here we are removing last character Z(2020-01-27T21:00:01.000Z)
  // let formatedDate = new Date(modifiedDate);
  let formatedDate = moment(rawDate)
    .local()
    .format("DD-MM-YYYY");
  return formatedDate !== "01-01-1753" && formatedDate !== "01-01-1970"
    ? formatedDate
    : "";
}
/*--------------call this function go get time format-----------------*/
export function timeFormat(rawDate) {
  // let modifiedDate = rawDate.replace(/.$/, ""); //here we are removing last character Z(2020-01-27T21:00:01.000Z)
  // let formatedDate = new Date(modifiedDate);
  let formatedTime = moment(rawDate)
    .local()
    .format("HH:mm:ss");
  return formatedTime;
}
/*----------call this function go get date with time format(need to remove)--------------*/
export function dateFormatWithTime(date) {
  let formatedDate = new Date(date);
  formatedDate =
    moment(formatedDate)
      .local()
      .format("DD MMMM") +
    " " +
    moment(formatedDate)
      .local()
      .format("HH:mm");
  return formatedDate;
}
/**-------------demo emails for suggestions(we will remove)--------------------------*/
export const userRoles = {
  partner_roles: [2, 3],
  customer_roles: [4, 5],
  non_admin_roles: [2, 3, 4, 5, 7],
  non_parntner_roles: [4, 5, 7],
  non_customer_roles: [1, 2, 3, 6, 7],
  non_customeruser_roles: [1, 2, 3, 6],
  non_localuser_roles: [1, 2, 3, 4, 5]
};

export const roles_number = {
  superAdmin: 1,
  partnerAdmin: 2,
  partnerUser: 3,
  customerAdmin: 4,
  customerUser: 5,
  globalUser: 6,
  localUser: 7
};
/*-------------user module visibility purpose -----------------------*/
export const isPartnerVisible = () => {
  const ModuleData = JSON.parse(localStorage.getItem("userModule"));
  if (ModuleData) {
    if (ModuleData.partner.length) {
      return true;
    } else {
      return false;
    }
  }
};
export const isCustomerVisible = () => {
  const ModuleData = JSON.parse(localStorage.getItem("userModule"));
  if (ModuleData) {
    if (ModuleData.customer.length) {
      return true;
    } else {
      return false;
    }
  }
};
export const isTeamVisible = () => {
  const ModuleData = JSON.parse(localStorage.getItem("userModule"));
  if (ModuleData) {
    if (ModuleData.team.length) {
      return true;
    } else {
      return false;
    }
  }
};
export const isGlobalUserVisible = () => {
  const ModuleData = JSON.parse(localStorage.getItem("userModule"));
  if (ModuleData) {
    if (ModuleData.globalUser.length) {
      return true;
    } else {
      return false;
    }
  }
};
export const isIssueVisible = () => {
  const ModuleData = JSON.parse(localStorage.getItem("userModule"));
  if (ModuleData) {
    if (ModuleData.alert.length) {
      return true;
    } else {
      return false;
    }
  }
};
/*-----get current user data---------------------------- */
export const getCurrentRole = () => {
  const roleData = JSON.parse(localStorage.getItem("user_role"));
  if (roleData) {
    return roleData.Role_Order;
  }
};
export const getCurrentCustomer = () => {
  const roleData = JSON.parse(localStorage.getItem("user_role"));
  return roleData.UID_Customer;
};

export const getCurrentPartner = () => {
  const roleData = JSON.parse(localStorage.getItem("user_role"));
  return roleData.UID_Partner;
};

// export const searchFunctionality = members => {
//   const result = members.filter(
//     function(member) {
//       return (
//         true ===
//         member.Name_Customer.toLowerCase().includes(
//           event.target.value.toLowerCase()
//         )
//       );
//     }.bind(this)
//   );
//   return result;
// };

export const getRulesCount = data => {
  let total = 0;
  data.forEach(element => {
    total = total + element.Rules.length;
  });
  return total;
};
export const getTeamMembersCount = data => {
  let total = 0;
  data.forEach(element => {
    total = total + element.TeamUsers.length;
  });
  return total;
};
/*--------this object contains the organisation dashboard details------------------*/
export const organisationDashboardDetails = {
  total_Roles: "Total Roles",
  total_members: "Total Members",
  total_numbers: "Total Numbers",
  total_Teams: "Total Teams",
  total_Instances: "Total Instances",
  total_Rules: "Total Rules",
  total_Agents: "Total Agents"
};
/*--------this object contains the issues names and issue number-------------------*/
export const issueDetails = {
  unAssigned_Number: 0,
  assigned_Number: 1,
  closed_Number: 2,
  unAssigned_Name: "UnAssigned",
  assigned_Name: "Assigned",
  closed_Name: "Closed"
};
/*-----------------this function we were call to count total issues--------------*/
export const getIssuesCount = data => {
  let total = 0;
  data.forEach(element => {
    total = total + element.Alerts.length;
  });
  return total;
};
/*--------this function we were call to get issues types(unassigned, assigned, closed) count----- */
export const getIssuesTypeCount = (data, type) => {
  let total = 0;
  //closed
  if (type === 2) {
    data.forEach(element => {
      if (element.Status === type) {
        total = total + element.alertCount;
      }
    });
    return total;
  }
  //assigned
  else if (type === 1) {
    data.forEach(element => {
      if (element.Status === type) {
        total = total + element.alertCount;
      }
    });
    return total;
  }
  // unassigned
  else {
    data.forEach(element => {
      if (element.Status === type) {
        total = total + element.alertCount;
      }
    });
    return total;
  }
};
/*--------this function we were call to get team issues types(unassigned, assigned, closed) count----- */
export const getTeamIssuesTypeCount = (data, type) => {
  let total = 0;
  //closed
  if (type === 2) {
    data.forEach(element => {
      if (element.Status === type) {
        total = total + element.alertCount;
      }
    });
    return total;
  }
  //assigned
  else if (type === 1) {
    data.forEach(element => {
      if (element.Status === type) {
        total = total + element.alertCount;
      }
    });
    return total;
  }
  // unassigned
  else {
    data.forEach(element => {
      if (element.Status === type) {
        total = total + element.alertCount;
      }
    });
    return total;
  }
};
/*--------this function we were call to get instance issues types(unassigned, assigned, closed) count----- */
export const getInstanceIssuesTypeCount = (Alerts, type) => {
  let total = 0;
  if (type === 2) {
    Alerts.forEach(rule => {
      if (rule.Status === 2) {
        total = total + 1;
      }
    });
    return total;
  } else if (type === 1) {
    Alerts.forEach(rule => {
      if (rule.Status === type) {
        total = total + 1;
      }
    });
    return total;
  } else {
    Alerts.forEach(rule => {
      if (rule.Status === type) {
        total = total + 1;
      }
    });
    return total;
  }
};
/*---------------this function we were use to get issue types-------------*/
export const showIssuesStatus = status => {
  if (status === 0) {
    return issueDetails.unAssigned_Name;
  } else if (status === 1) {
    return issueDetails.assigned_Name;
  } else {
    return issueDetails.closed_Name;
  }
};
/*-------------call this function to confirm permission-------------------*/
export const confirmPermissions = (data, permission) => {
  if (data.includes(permission)) {
    return true;
  } else {
    return false;
  }
};
/*---------------call this function to show rule priority-------------*/
// export const showRulesPriority = (priority) => {
//   if (priority === 0) {
//     return "fa fa-arrow-circle-down fa-2x text-success";
//   } else if (priority === 1) {
//     return "fa fa-arrow-circle-up fa-2x text-warning";
//   } else {
//     return "fa fa-arrow-circle-up fa-2x text-danger";
//   }
// };
/*---------------call this function to show rule priority-------------*/
export const showRulesPriority = priority => {
  if (priority === 0) {
    return LowPriorityImage;
  } else if (priority === 1) {
    return MediumPriorityImage;
  } else {
    return HighPriorityImage;
  }
};
/*----------const for days--------------*/
export const daysArray = [
  {
    value: 1,
    label: "Monday"
  },
  {
    value: 2,
    label: "Tuesday"
  },
  {
    value: 3,
    label: "Wednesday"
  },
  {
    value: 4,
    label: "Thursday"
  },
  {
    value: 5,
    label: "Friday"
  },
  {
    value: 6,
    label: "Saturday"
  },
  {
    value: 0,
    label: "Sunday"
  }
];
/*---------function for get local time format from UTC time format------ */
// export const getLocalTime = (utcDate, zoneName) => {
//   if (utcDate) {
//     if (!zoneName) {
//       let date = new Date(utcDate);
//       // let localDate = moment(date).format("HH:mm");
//       // return localDate;
//       let hours = date.format("HH");
//       let minutes = date.format("mm");
//       // console.log(minutes < 10 ? minutes : minutes);
//       let ampm = hours >= 12 ? "pm" : "am";
//       hours = hours % 12;
//       hours = hours ? hours : 12; // the hour '0' should be '12'
//       // minutes = minutes < 10 ? "0" + minutes : minutes;
//       let strTime = hours + ":" + minutes + " " + ampm;
//       return strTime;
//     } else {
//       let date = momentTimeZone(utcDate);
//       // let localDate = date.tz(zoneName).format("HH:mm");
//       let hours = date.tz(zoneName).format("HH");
//       let minutes = date.tz(zoneName).format("mm");
//       // console.log(minutes < 10 ? minutes : minutes);
//       let ampm = hours >= 12 ? "pm" : "am";
//       hours = hours % 12;
//       hours = hours ? hours : 12; // the hour '0' should be '12'
//       // minutes = minutes < 10 ? "0" + minutes : minutes;
//       let strTime = hours + ":" + minutes + " " + ampm;
//       return strTime;
//       // return localDate;
//     }
//   } else {
//     return "All day";
//   }
// };

export const getLocalTime = (utcDate, zoneName) => {
  if (utcDate) {
    if (!zoneName) {
      // let date = new Date(utcDate);
      // let localDate = moment(date).format("HH:mm");
      let hours = moment(utcDate).format("HH");
      let minutes = moment(utcDate).format("mm");
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    } else {
      let date = momentTimeZone.tz(utcDate, zoneName);
      let hours = date.format("HH");
      let minutes = date.format("mm");
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
      // return localDate;
    }
  } else {
    return "All day";
  }
};
/*---------------function for to show days based on api response---------*/
export const showDays = daysArray => {
  return daysArray.map(value => {
    if (value === 1) {
      return "Monday ";
    } else if (value === 2) {
      return "Tuesday ";
    } else if (value === 3) {
      return "Wednesday ";
    } else if (value === 4) {
      return "Thursday ";
    } else if (value === 5) {
      return "Friday ";
    } else if (value === 6) {
      return "Saturday ";
    } else {
      return "Sunday ";
    }
  });
};
//daysArray.length > 1 ? "/Saturday" :
/*---------------function for to show days based on api response---------*/
export const getDaysArray = daysArray => {
  let dayArray = [];
  daysArray.map(value => {
    if (value === 1) {
      dayArray.push({
        value: 1,
        label: "Monday"
      });
    } else if (value === 2) {
      dayArray.push({
        value: 2,
        label: "Tuesday"
      });
    } else if (value === 3) {
      dayArray.push({
        value: 3,
        label: "Wednesday"
      });
    } else if (value === 4) {
      dayArray.push({
        value: 4,
        label: "Thursday"
      });
    } else if (value === 5) {
      dayArray.push({
        value: 5,
        label: "Friday"
      });
    } else if (value === 6) {
      dayArray.push({
        value: 6,
        label: "Saturday"
      });
    } else {
      dayArray.push({
        value: 0,
        label: "Sunday"
      });
    }
  });
  return dayArray;
};
/*--------------table function---------------*/
export const tableSearchFunction = () => {
  let input, filter, table, tr, td, i;
  table = document.getElementById("myTable");
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
};
/*---------------using add data(zero) in left side----------------- */
export const leftPad = number => {
  return (number < 10 && number >= 0 ? "0" : "") + number;
};
/*------------------for create timezone offset----------------------*/
export const createGMTOffset = timeZoneValue => {
  let timeZoneOffset = timeZoneValue.GMT_Offset / 60;
  let symbol = timeZoneOffset < 0 ? "" : "+";
  let hours = leftPad(
    Math.floor(timeZoneOffset / 60) + ":" + leftPad(timeZoneOffset % 60)
  );
  return "(GMT" + symbol + hours + ")";
};
/*------------function for get base64 format for selected file-------*/
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    cb(reader.result);
  };
  reader.onerror = function(error) {};
};
/*function for get ordered(asc) array items*/
export const getOrderedItems = (rawArray, conditionName) => {
  if (conditionName) {
    return _.orderBy(rawArray, [conditionName], ["desc"]); // Use Lodash to sort array by 'condition'
  }
  // } else {
  //   return _.orderBy(
  //     rawArray,
  //     [item => item.AdministrativeUnit.Label],
  //     ["asc"]
  //   ); // Use Lodash to sort array by 'Label'
  // }
};
/*----funcction for get notifiaction data */
export const getNotificationData = notificationsArray => {
  const types = _.mapValues(_.groupBy(notificationsArray, "isAllDay"), clist =>
    clist.map(notification =>
      _.omit(notification, [
        "UID_TeamRule",
        "UID_TimeZone",
        "Start_Time",
        "End_Time",
        "Days_List",
        "Created_Date",
        "Updated_Date"
      ])
    )
  );
  let notificationData = {
    isAllDay: {},
    isNotAllDay: {}
  };
  if (types) {
    if (types.true && types.true.length) {
      types.true.map((type, key) => {
        notificationData.isAllDay.isAllDay = true;
        if (type.isEmail) {
          notificationData.isAllDay.isEmail = true;
        }
        if (type.isPushNotification) {
          notificationData.isAllDay.isPushNotification = true;
        }
        if (type.isWhatsApp) {
          notificationData.isAllDay.isWhatsApp = true;
        }
        if (type.isTeam) {
          notificationData.isAllDay.isTeam = true;
        }
      });
    }
    if (types.false && types.false.length) {
      types.false.map((type, key) => {
        notificationData.isNotAllDay.isAllDay = false;
        if (type.isEmail) {
          notificationData.isNotAllDay.isEmail = true;
        }
        if (type.isPushNotification) {
          notificationData.isNotAllDay.isPushNotification = true;
        }
        if (type.isWhatsApp) {
          notificationData.isNotAllDay.isWhatsApp = true;
        }
        if (type.isTeam) {
          notificationData.isNotAllDay.isTeam = true;
        }
      });
    }
  }
  return notificationData;
};

/*----funcction to get dashbaord type */
export const isCustomerDashboard = data => {
  if (data && data.customerData && data.customerData.length) {
    return true;
  } else {
    return false;
  }
};

export const isPartnerDashboard = data => {
  if (
    (data && data.partnerData && data.partnerData.length) ||
    (data && data.superAdmin && data.superAdmin.length)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isTeamDashboard = data => {
  if (data.teamsData && data.teamsData.length) {
    return true;
  } else {
    return false;
  }
};

export const getStartDateSevenDaysAgo = () => {
  const today = moment();
  const startDate = today
    .subtract(7, "days")
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
  return startDate;
};
