/**
 * @action        : perspectiveAction
 * @description   : for accessing perspective data
 * @Created by    : INNOMICK
 */

import { PERSPECTIVE_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";
import * as base from "./baseAction";

// action for perspective
export function getPerspectiveData() {
  let urlPromise = AXIOS_INSTANCE.get(BASE_URL + "/Perspectives");

  return dispatch => {
    dispatch(base.getRequest(PERSPECTIVE_CONST.GET_PERSPECTIVES_REQUEST));
    urlPromise
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PERSPECTIVE_CONST.GET_PERSPECTIVES_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PERSPECTIVE_CONST.GET_PERSPECTIVES_FAILURE, {
              reponse: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PERSPECTIVE_CONST.GET_PERSPECTIVES_FAILURE, {
            error: {
              statusText: error,
              netWorkError: true
            }
          })
        );
      });
  };
}


// action for create perspective
export function createPerspective(formData) {
  return dispatch => {
    dispatch(base.getRequest(PERSPECTIVE_CONST.CREATE_PERSPECTIVE_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/Perspective", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PERSPECTIVE_CONST.CREATE_PERSPECTIVE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PERSPECTIVE_CONST.CREATE_PERSPECTIVE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PERSPECTIVE_CONST.CREATE_PERSPECTIVE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update Perspective
export function updatePerspective(formData) {
  return dispatch => {
    dispatch(base.getRequest(PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updatePerspective", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete Perspective
export function deletePerspective(formData) {
  return dispatch => {
    dispatch(base.getRequest(PERSPECTIVE_CONST.DELETE_PERSPECTIVE_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/deletePerspective", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PERSPECTIVE_CONST.DELETE_PERSPECTIVE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PERSPECTIVE_CONST.DELETE_PERSPECTIVE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PERSPECTIVE_CONST.DELETE_PERSPECTIVE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}