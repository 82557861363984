import React, { PureComponent } from "react";
import PartnerUsers from "../PartnerUsersComponent/Containers/PartnerUsersContainer";

class PartnerPage extends PureComponent {
  render() {
    return (
      <div>
        <PartnerUsers
          search={this.props.location.search}
          partner_id={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default PartnerPage;
