/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody
} from "reactstrap";
import { Button } from "components";
import addUser from "assets/img/svg/add-user.svg";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import { confirmPermissions } from "../../../../helpers/Utility";
import BreadCrumbComponent from "../../Common/BreadCrumbComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import InvitationModal from "../../Modals/InvitationModal";
import { getTeamUsers } from "../../../../actions/teamAction";
import ReactTable from "react-table";
function TeamMembersPage(props) {
  // //function for show members details
  const showMembersDetails = (isDeletable, isInvite, showAction) => {
    if (props.teamUsersList && props.teamUsersList.length) {
      return props.teamUsersList.map((data, key) => {
        return (
          <tr key={key} className="table-data-rows-two">
            <td>
              {data.User.Status_User
                ? data.User.First_Name + " " + data.User.Last_Name
                : "N/A"}
            </td>
            <td>{data.User.Email_User}</td>
            <td>{data.User.Status_User ? "Active" : "Pending"}</td>

            {showAction && (
              <td>
                {isDeletable && (
                  <Button
                    className="btn-sm"
                    color="danger"
                    round
                    onClick={e => props.deleteUser(data.UID_TeamUser)}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </Button>
                )}{" "}
                {isInvite &&
                  (data.User.Status_User ? (
                    ""
                  ) : (
                    <Button
                      className="btn-sm"
                      color="primary"
                      round
                      onClick={e => props.reInvite(data.User.Email_User, key)}
                    >
                      {props.loading && props.rowKey === key ? (
                        <i className="fa fa-spinner fa-pulse" />
                      ) : (
                        <i
                          className="fa fa-share fa-1x cursor-pointer"
                          aria-hidden="true"
                          text="white"
                        />
                      )}
                    </Button>
                  ))}
              </td>
            )}
          </tr>
        );
      });
    } else {
      if (props.isRequesting) {
        return (
          <tr className="table-tr-loader">
            <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
          </tr>
        );
      } else {
        return (
          <tr className="table-tr-loader">
            <td className="no-data-found-style">No Data Found</td>
          </tr>
        );
      }
    }
  };
  // const team_Name = this.props.userDataPermission
  //   ? this.props.userDataPermission.teamData
  //     ? this.props.userDataPermission.teamData.Name_Team
  //     : ""
  //   : "";
  // const organisation_Name = this.props.userDataPermission
  //   ? this.props.userDataPermission.customerData
  //     ? this.props.userDataPermission.customerData.Name_Customer
  //     : ""
  //   : "";
  // const organisation_Id = this.props.userDataPermission
  //   ? this.props.userDataPermission.customerData
  //     ? this.props.userDataPermission.customerData.UID_Customer
  //     : ""
  //   : "";
  const permissions = props.userDataPermission
    ? props.userDataPermission.permissions
    : [];
  const isDeletable = confirmPermissions(permissions, "isDeletable");
  const isInvite = confirmPermissions(permissions, "isAddable");
  const showAction = isDeletable && isInvite;
  /*-react-table data-*/
  const data = props.teamUsersList ? props.teamUsersList : [];
  /*-------react-table headers ---------*/
  const headers = {
    First_Name: "member name",
    Email_User: "Email Id",
    status: "Status",
    actions: "Actions"
  };
  return (
    <div>
      {props.showAddTeamUserModal && (
        <InvitationModal
          isOpen={props.showAddTeamUserModal}
          handleModal={props.handleAddModal}
          teamId={props.teamId}
          meta={props.meta}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row>
              <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                <SideBarToggle />
              </Col>
              <Col className="d-md-none">
                {confirmPermissions(permissions, "isAddable") && (
                  <Button
                    color="primary"
                    onClick={e => props.addTeamUser()}
                    className="mobile-button"
                  >
                    <img
                      src={addUser}
                      alt="add_user"
                      className="mr-2 add-member-icon"
                    />
                    <span className="font-weight-bold">
                      {props.t("members.ADD_MEMBER")}
                    </span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                <p className="font-weight-bold">{props.t("members.TITLE")}</p>
              </Col>
              <Col lg={5} md={5} xs={6} sm={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    id="myInput"
                    placeholder={props.t("members.SEARCH")}
                    className="search-bar-css shadow-css"
                    onKeyUp={e => props.searchName(e)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      className="search-bar-css icon-css shadow-css cursor-pointer"
                      onClick={e => props.onClickSearchButton()}
                    >
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={12}
                sm={12}
                className="offset-sm-2 offset-xs-2 order-first order-lg-last order-md-last"
              >
                {confirmPermissions(permissions, "isAddable") && (
                  <Button
                    color="primary"
                    onClick={e => props.addTeamUser()}
                    className="create-team-button"
                  >
                    <img
                      src={addUser}
                      alt="add_user"
                      className="mr-2 add-member-icon"
                    />
                    <span className="font-weight-bold">
                      {props.t("members.ADD_MEMBER")}
                    </span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xs={12} className="">
                <div>
                  <BreadCrumbComponent
                    active={props.t("members.MEMBERS")}
                    nonActive1={props.teamName + " " + "(Team)"}
                    nonActive1Href={"/dashboard/teamDashboard/" + props.teamId}
                    nonActive2={props.customerName}
                    nonActive2Href={
                      "/dashboard/organisationDashboard/" + props.customerId
                    }
                  />
                </div>
              </Col>
            </Row>
            <Card className="mt-4">
              <CardBody>
                <ReactTable
                  loading={!props.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  className="cursor-pointer"
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.First_Name,
                          accessor: "First_Name",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          Header: headers.Email_User,
                          accessor: "Email_User",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.status,
                          accessor: "status",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    {row.value ? "Active" : "Pending"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.actions,
                          accessor: "actions",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="ml-5">
                                    {showAction && (
                                      <div>
                                        {isDeletable && (
                                          <Button
                                            className="btn-sm"
                                            color="danger"
                                            round
                                            onClick={e =>
                                              props.deleteUser(row.value.id)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            />
                                          </Button>
                                        )}{" "}
                                        {isInvite &&
                                          (row.value.status ? (
                                            ""
                                          ) : (
                                            <Button
                                              className="btn-sm"
                                              color="primary"
                                              round
                                              onClick={e =>
                                                props.reInvite(
                                                  row.value.email,
                                                  row.value.key
                                                )
                                              }
                                            >
                                              {props.loading &&
                                              props.rowKey === row.value.key ? (
                                                <i className="fa fa-spinner fa-pulse" />
                                              ) : (
                                                <i
                                                  className="fa fa-share fa-1x cursor-pointer"
                                                  aria-hidden="true"
                                                  text="white"
                                                />
                                              )}
                                            </Button>
                                          ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  manual
                  pages={props.meta.pageTotal}
                  defaultPageSize={props.meta.pageSize}
                  pageSizeOptions={props.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                    }
                    if (props.teamId && qString) {
                      props.dispatch(getTeamUsers(props.teamId, qString));
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
}
TeamMembersPage.propTypes = {
  searchName: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  teamUsersList: PropTypes.array,
  teamId: PropTypes.string,
  userDataPermission: PropTypes.object,
  meta: PropTypes.object
};
export default compose(translate, connect())(TeamMembersPage);
