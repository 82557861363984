import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
  FormGroup
} from "reactstrap";
import { Field } from "redux-form";
import { renderTextField } from "../../Common/InputRenderer";
import nowLogo from "assets/img/png/Argos.png";
import footerLogo from "assets/img/png/argos_footer.png";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "reactstrap-button-loader";
import compose from "compose-function";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <div>
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="logo-container logo-container">
                      <img src={nowLogo} alt="now-logo" />
                    </div>
                    <Card className="card-login custom-card">
                      <CardBody>
                        <p className="text-center text-white py-3">
                          {this.props.t("login.FORGOT_PASSWORD_TITLE")}
                        </p>
                        <Row>
                          <Col md="10" className="mx-auto">
                            <p className="text-center text-white py-3">
                              {this.props.t("login.FORGOT_PASSWORD_SUB_TITLE")}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10" className="mx-auto">
                            <FormGroup
                              className={
                                this.props.forgotPassword.emailState
                                  ? "has-label " +
                                    this.props.forgotPassword.emailState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="text"
                                name="email"
                                placeholder={this.props.t(
                                  "login.EMAIL_PLACEHOLDER"
                                )}
                                onFocus={e =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={e => this.props.validateEmail(e)}
                              />
                            </FormGroup>
                            <Button
                              block
                              color="primary"
                              type="submit"
                              className="mb-3"
                              loading={this.props.loading}
                            >
                              {this.props.t("login.SEND")}
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter />
                    </Card>
                  </Form>
                  {/* <div className="logo-container mt-4">
                    <img src={footerLogo} alt="now-logo" />
                  </div> */}
                  <div className="footer-text">
                    <Link to="/pages/privacy-policy">
                      {this.props.t("login.FORGOT_PRIVACY_POLICY")}
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#40535D" }}
        />
      </div>
    );
  }
}
ForgotPasswordPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  forgotPassword: PropTypes.object
};
export default compose(
  translate,
  withRouter,
  connect()
)(ForgotPasswordPage);
