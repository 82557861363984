/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody
} from "reactstrap";
import { Button } from "components";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import BreadCrumbComponent from "../../Common/BreadCrumbComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import queryString from "query-string";
import { confirmPermissions } from "../../../../helpers/Utility";
import CreateAgentModal from "../../Modals/CreateAgentModal";
import ReactTable from "react-table";
import { getAgents } from "../../../../actions/agentAction";

function AgentPage(props) {
  /*calling function to copy Key */
  const copyKey = agentKey => {
    let textArea = document.createElement("textarea");
    textArea.value = agentKey;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  //this function we are using to render agents
  // const showAgents = isEditable => {
  //   if (props.stateValues.isRequesting) {
  //     return (
  //       <tr className="table-tr-loader">
  //         <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
  //       </tr>
  //     );
  //   } else {
  //     if (props.stateValues.agentsData && props.stateValues.agentsData.length) {
  //       return props.stateValues.agentsData.map((agent, key) => {
  //         return (
  //           <tr key={key} className="table-data-rows-two table-tr-loader">
  //             <td>{agent.Agent_Name}</td>
  //             <td className="text-center">
  //               {agent.Status ? "Registered" : "De-Registered"}
  //             </td>
  //             <td className="text-center">
  //               <span>
  //                 <span
  //                   className={
  //                     agent.Status
  //                       ? "badge badge-primary cursor-pointer"
  //                       : "badge badge-secondary background-style"
  //                   }
  //                   onClick={
  //                     agent.Status
  //                       ? () =>
  //                           props.updateAgentStatus(
  //                             agent.UID_Agent,
  //                             agent.Status
  //                           )
  //                       : ""
  //                   }
  //                 >
  //                   {agent.Status ? "De-register" : "Re-Register"}
  //                 </span>{" "}
  //                 <span
  //                   className="badge badge-primary cursor-pointer"
  //                   onClick={() => copyKey(agent.UID_Agent)}
  //                 >
  //                   <i className="fa fa-copy" /> Copy Key
  //                 </span>{" "}
  //                 <span
  //                   title="download agent registration certificate"
  //                   id="forDownloadKey"
  //                   className="badge badge-primary cursor-pointer"
  //                   onClick={() => {
  //                     if (
  //                       window.confirm(
  //                         "cert will be invalidated, are you sure?"
  //                       )
  //                     ) {
  //                       props.downloadAgentKey(agent.UID_Agent, key);
  //                     }
  //                   }}
  //                 >
  //                   <i className="fa fa-download" /> Download
  //                 </span>{" "}
  //                 {props.stateValues.isRequestingTwo &&
  //                 props.stateValues.key === key ? (
  //                   <i className="fa fa-spinner fa-pulse icon-position-style" />
  //                 ) : (
  //                   ""
  //                 )}
  //               </span>{" "}
  //               {isEditable && (
  //                 <span
  //                   className="badge badge-primary cursor-pointer"
  //                   onClick={() =>
  //                     props.updateAgent(agent.Agent_Name, agent.UID_Agent)
  //                   }
  //                 >
  //                   <i className="fa fa-pencil-square-o" /> update
  //                 </span>
  //               )}
  //             </td>
  //           </tr>
  //         );
  //       });
  //     } else {
  //       return (
  //         <tr className="table-tr-loader">
  //           <td className="no-data-found-style">No Data Found</td>
  //         </tr>
  //       );
  //     }
  //   }
  // };
  const permissions = props.stateValues.wholeData
    ? props.stateValues.wholeData.data.permissions
    : [];
  const isCreatable = confirmPermissions(
    permissions ? permissions : [],
    "isCreatable"
  );
  const isEditable = confirmPermissions(
    permissions ? permissions : [],
    "isEditable"
  );
  const downloadCertificate = path => {
    let open = window.open(path, "_blank");
    if (open == null || typeof open == "undefined") {
      alert(
        "Turn off your pop-up blocker!\n\nWe try to open the following url:\n" +
          path
      );
    }
  };
  /*---------------------react-table data-------------------- */
  // console.log(props.stateValues.agentsData);
  const data = props.stateValues.agentsData ? props.stateValues.agentsData : [];
  /*---------------------react-table header------------------ */
  const headers = {
    Agent_Name: "Agent Name",
    status: "Status",
    actions: "Actions"
  };
  return (
    <div>
      {props.stateValues.showCreateAgentModal && (
        <CreateAgentModal
          isOpen={props.stateValues.showCreateAgentModal}
          handleModal={props.handleModal}
          instanceId={props.instanceId}
        />
      )}
      {props.stateValues.showUpdateAgentModal && (
        <CreateAgentModal
          isOpen={props.stateValues.showUpdateAgentModal}
          handleModal={props.handleModalTwo}
          instanceId={props.instanceId}
          initialValues={props.stateValues.initialValues}
        />
      )}
      {props.stateValues.agentCertificatePath && (
        <a
          id="linkId"
          // href={
          //   props.stateValues.agentCertificatePath
          //     ? props.stateValues.agentCertificatePath
          //     : "#"
          // }
          // target="_blank"
          onClick={e =>
            downloadCertificate(props.stateValues.agentCertificatePath)
          }
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row>
              <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                <SideBarToggle />
              </Col>
              <Col className="d-lg-none d-md-none">
                {isCreatable && (
                  <Button color="primary" onClick={() => props.showModal()}>
                    <span className="font-weight-bold">Create Agent</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                <p className="font-weight-bold">Agents</p>
              </Col>
              <Col lg={5} md={5} xs={6} sm={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    placeholder="Search for agent"
                    className="search-bar-css shadow-css"
                    onKeyUp={e => props.searchName(e)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      className="search-bar-css icon-css shadow-css cursor-pointer"
                      // onClick={e => props.onClickSearchButton()}
                    >
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={12}
                sm={12}
                className="offset-sm-2 offset-xs-2 order-first order-lg-last order-md-last"
              >
                {isCreatable && (
                  <Button
                    color="primary"
                    onClick={() => props.showModal()}
                    className="create-team-button"
                  >
                    <span className="font-weight-bold">Create Agent</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12} className="">
                <div>
                  <BreadCrumbComponent
                    active="Agents"
                    nonActive1={props.teamName}
                    nonActive1Href={
                      "/dashboard/instanceDashboard/" +
                      props.instanceId +
                      "?customer=" +
                      queryString.parse(props.names).customerName +
                      "&instance=" +
                      queryString.parse(props.names).instanceName
                    }
                    nonActive2={props.customerName}
                    nonActive2Href={
                      "/dashboard/organisationDashboard/" + props.customerId
                    }
                  />
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col
                className={
                  queryString.parse(props.names).teamName
                    ? "list-style"
                    : "list-style mt-3"
                }
              >
                <Table responsive className="members-table-styles">
                  <tr className="table-header-row">
                    <th>Agent Name</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Actions</th>
                  </tr>
                  <tbody>{showAgents(isEditable)}</tbody>
                </Table>
              </Col>
            </Row> */}
            <Card className="mt-4">
              <CardBody className="raeactTablex">
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  className=""
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.Agent_Name,
                          accessor: "Agent_Name",
                          Cell: row => (
                            <div
                            // onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.status,
                          accessor: "status",
                          Cell: row => (
                            <div
                            // onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value ? "Registered" : "De-Registered"}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.actions,
                          accessor: "actions",
                          Cell: row => (
                            <div
                            // onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    <span>
                                      {isEditable && (
                                        <span
                                          className={
                                            row.value.status
                                              ? "badge badge-primary cursor-pointer"
                                              : "badge badge-secondary background-style"
                                          }
                                          onClick={
                                            row.value.status
                                              ? () =>
                                                  props.updateAgentStatus(
                                                    row.value.id,
                                                    row.value.status
                                                  )
                                              : ""
                                          }
                                        >
                                          {row.value.status
                                            ? "De-register"
                                            : "Re-Register"}
                                        </span>
                                      )}{" "}
                                      {
                                        <span
                                          className="badge badge-primary cursor-pointer"
                                          onClick={() => copyKey(row.value.id)}
                                        >
                                          <i className="fa fa-copy" /> Copy Key
                                        </span>
                                      }{" "}
                                      {/* <span
                                        title="download agent registration certificate"
                                        id="forDownloadKey"
                                        className="badge badge-primary cursor-pointer"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "cert will be invalidated, are you sure?"
                                            )
                                          ) {
                                            props.downloadAgentKey(
                                              row.value.id,
                                              row.value.key
                                            );
                                          }
                                        }}
                                      >
                                        <i className="fa fa-download" />{" "}
                                        Download
                                      </span>{" "} */}
                                      {isEditable && (
                                        <span
                                          title="download agent registration certificate"
                                          id="forDownloadKey"
                                          className="badge badge-primary cursor-pointer"
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                "cert will be invalidated, are you sure?"
                                              )
                                            ) {
                                              props.downloadAgentKey(
                                                row.value.id,
                                                row.value.key
                                              );
                                            }
                                          }}
                                        >
                                          <i className="fa fa-download" />{" "}
                                          Download
                                        </span>
                                      )}{" "}
                                      {props.stateValues.isRequestingTwo &&
                                      props.stateValues.key ===
                                        row.value.key ? (
                                        <i className="fa fa-spinner fa-pulse icon-position-style" />
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    {isEditable && (
                                      <span
                                        className="badge badge-primary cursor-pointer"
                                        onClick={() =>
                                          props.updateAgent(
                                            row.value.name,
                                            row.value.id
                                          )
                                        }
                                      >
                                        <i className="fa fa-pencil-square-o" />{" "}
                                        update
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  manual
                  pages={props.stateValues.meta.pageTotal}
                  defaultPageSize={props.stateValues.meta.pageSize}
                  pageSizeOptions={props.stateValues.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                    }
                    if (props.instanceId && qString) {
                      props.dispatch(getAgents(props.instanceId, qString));
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}

AgentPage.propTypes = {
  searchName: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  agentsData: PropTypes.array,
  teamId: PropTypes.string,
  error: PropTypes.bool,
  wholeData: PropTypes.object,
  showModal: PropTypes.func,
  stateValues: PropTypes.object,
  handleModal: PropTypes.func,
  handleModalTwo: PropTypes.func,
  updateAgent: PropTypes.func
};

export default compose(translate, connect())(AgentPage);
