import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SideBarToggle from "../../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import CardComponent from "../../../Common/CardComponent";
import {
  getTeamIssuesTypeCount,
  issueDetails,
  organisationDashboardDetails,
  confirmPermissions,
} from "../../../../../helpers/Utility";
import BreadcrumbComponent from "../../../Common/BreadCrumbComponent";
import CreatTeamModal from "../../../Modals/CreateTeamModal";
// import InvitationModal from "../../../Modals/InvitationModal";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import LoaderIcon from "../../../../../assets/img/Loader.gif";

function TeamDashboard(props) {
  const permissions = props.teamStatistics
    ? props.teamStatistics.permission
    : [];
  const isDeletable = confirmPermissions(permissions, "isDeletable");
  const isEditable = confirmPermissions(permissions, "isEditable");
  const isAnyOneEditDelete = isDeletable || isEditable;
  const {
    Name_Team,
    Description_Team,
    Status,
    UID_Team,
    Customer,
  } = props.teamStatistics ? props.teamStatistics.teamData : {};
  const Customer_Name = Customer ? Customer.Name_Customer : "";
  const CustomerId = Customer ? Customer.UID_Customer : "";
  const membersDetails = [
    {
      info: organisationDashboardDetails.total_members,
      value: props.teamStatistics ? props.teamStatistics.userCount : 0,
    },
  ];
  const teamDetails = [
    {
      info: organisationDashboardDetails.total_Rules,
      value: props.teamStatistics ? props.teamStatistics.ruleCount : 0,
    },
  ];
  const issuesDetails = [
    {
      info: issueDetails.unAssigned_Name,
      value: props.teamStatistics
        ? getTeamIssuesTypeCount(
            props.teamStatistics.alertCount,
            issueDetails.unAssigned_Number
          )
        : 0,
    },
    {
      info: issueDetails.assigned_Name,
      value: props.teamStatistics
        ? getTeamIssuesTypeCount(
            props.teamStatistics.alertCount,
            issueDetails.assigned_Number
          )
        : 0,
    },
    {
      info: issueDetails.closed_Name,
      value: props.teamStatistics
        ? getTeamIssuesTypeCount(
            props.teamStatistics.alertCount,
            issueDetails.closed_Number
          )
        : 0,
    },
  ];
  return (
    <div>
      {props.stateValues.showUpdateTeamModal && (
        <CreatTeamModal
          isOpen={props.stateValues.showUpdateTeamModal}
          handleModal={props.handleModal}
          initialValues={props.stateValues.initialValues}
          teamId={UID_Team}
          customerId={CustomerId}
        />
      )}
      {/* {props.stateValues.showAddTeamUserModal && (
        <InvitationModal
          isOpen={props.stateValues.showAddTeamUserModal}
          handleModal={props.handleAddModal}
          teamId={UID_Team}
        />
      )} */}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            {props.stateValues.isRequesting && (
              <div className="table-tr-loader">
                <img
                  className="loader-inside-dashboards"
                  src={LoaderIcon}
                  alt="loader"
                />
              </div>
            )}
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={6} md={6} xs={10} className="">
                <div>
                  <BreadcrumbComponent
                    active={props.t("common.DASHBOARD")}
                    nonActive1={Name_Team}
                    nonActive1Href={
                      "/dashboard/teams/" +
                      CustomerId +
                      "?customerName=" +
                      Customer_Name.split(" ").join("_")
                    }
                    nonActive2={Customer_Name}
                    nonActive2Href={
                      "/dashboard/organisationDashboard/" + CustomerId
                    }
                  />
                </div>
              </Col>
            </Row>
            <Card className="mt-3">
              <CardBody>
                <Row className="">
                  <Col lg={8} md={8} xs={12}>
                    <span className="text-muted font-weight-bold">
                      {props.t("teams.NAME_OF_TEAM")}:{" "}
                    </span>
                    <span className="text-muted">{Name_Team}</span>
                    <br />
                    <span className="text-muted font-weight-bold mt-5">
                      {props.t("common.DESCRIPTION")}:{" "}
                    </span>
                    <span className="text-muted">{Description_Team}</span>
                  </Col>
                  {isEditable && (
                    <Col lg={3} md={3} xs={12}>
                      <div
                        className={
                          isEditable
                            ? "pull-right mobile-switch-style-css switch-style-css"
                            : "pull-right mobile-switch-style-css switch-style-css invisible"
                        }
                      >
                        <span className="text-muted font-weight-bold mr-2 status-styles">
                          {props.t("teams.ACTIVE")}
                        </span>
                        <Switch
                          onText={
                            <i className="now-ui-icons ui-1_check text-primary" />
                          }
                          offText={
                            <i className="now-ui-icons ui-1_simple-remove" />
                          }
                          value={Status ? true : false}
                          onChange={(e) =>
                            props.changeTeamStatus(UID_Team, Status)
                          }
                        />
                      </div>
                    </Col>
                  )}

                  {isAnyOneEditDelete && (
                    <Col lg={1} md={1} xs={12} className="list-style">
                      <div className="pull-right dummy-class">
                        <Dropdown
                          nav
                          isOpen={props.stateValues.dropdownOpen}
                          toggle={(e) => props.dropdownToggle(e)}
                          className="mobile-style-css"
                        >
                          <DropdownToggle caret nav>
                            <i className="now-ui-icons design_bullet-list-67 font-weight-bold actions-icon-style" />
                            <p />
                          </DropdownToggle>
                          <DropdownMenu right>
                            {isEditable && (
                              <DropdownItem
                                tag="a"
                                className="text-muted font-weight-bold cursor-pointer"
                                onClick={(e) =>
                                  props.editTeam(
                                    props.teamStatistics
                                      ? props.teamStatistics.teamData
                                      : {}
                                  )
                                }
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                />
                                {props.t("teams.UPDATE_TEAM")}
                              </DropdownItem>
                            )}
                            {/* <DropdownItem
                              tag="a"
                              className="text-muted font-weight-bold cursor-pointer"
                              onClick={e => props.addTeamUser()}
                            >
                              <i className="fa fa-user" aria-hidden="true" />
                              Add team user
                            </DropdownItem> */}
                            {isDeletable && (
                              <DropdownItem
                                tag="a"
                                className="text-danger font-weight-bold cursor-pointer"
                                onClick={(e) => props.deleteTeam()}
                              >
                                <i className="fa fa-trash" aria-hidden="true" />{" "}
                                {props.t("teams.DELETE_TEAM")}
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
            <div className="margin-top-max-two" />
            <Row className="">
              <Col lg={3} md={3} xs={12} className="mx-auto">
                <CardComponent
                  title="Members"
                  cardData={membersDetails}
                  icon="fa-users"
                  navigatePage={props.navigatePage}
                  teamName={Name_Team}
                  customerName={Customer_Name}
                  customerId={CustomerId}
                  path={
                    props.stateValues.isRequesting ? "" : "/dashboard/teamUsers"
                  }
                  isShowPointer={props.stateValues.isRequesting ? false : true}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Rules"
                  cardData={teamDetails}
                  icon="fa-gavel"
                  navigatePage={props.navigatePage}
                  teamName={Name_Team}
                  customerName={Customer_Name}
                  customerId={CustomerId}
                  path={
                    props.stateValues.isRequesting ? "" : "/dashboard/rules"
                  }
                  isShowPointer={props.stateValues.isRequesting ? false : true}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Issues"
                  cardData={issuesDetails}
                  icon="fa-exclamation-circle"
                  showClasses={true}
                  navigatePage={props.navigatePage}
                  teamName={Name_Team}
                  customerName={Customer_Name}
                  customerId={CustomerId}
                  path={
                    props.stateValues.isRequesting ? "" : "/dashboard/issues"
                  }
                  isShowPointer={props.stateValues.isRequesting ? false : true}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
TeamDashboard.propTypes = {
  stateValues: PropTypes.object,
  showMembers: PropTypes.func,
  navigate: PropTypes.func,
  navigateToMembers: PropTypes.func,
  customerStatistics: PropTypes.object,
  navigatePage: PropTypes.func,
  deleteTeam: PropTypes.func,
  editTeam: PropTypes.func,
  dropdownToggle: PropTypes.func,
  teamStatistics: PropTypes.object,
  showUpdateTeamModal: PropTypes.bool,
  handleModal: PropTypes.func,
  team_id: PropTypes.string,
  changeTeamStatus: PropTypes.func,
};
export default compose(
  translate,
  connect()
)(TeamDashboard);
