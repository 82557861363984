/**
 * @constants
 * @description  : Hold CONSTANTS and APIs.
 * @Created by   : Algo Consulting Group.
 */

export const AUTH_CONST = {
  REGISTER_RESET: "REGISTER_RESET",
  TOKEN_SUCCESS: "TOKEN_SUCCESS",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_RESET: "LOGIN_RESET",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  USER_MODULE_REQUEST: "USER_MODULE_REQUEST",
  USER_MODULE_SUCCESS: "USER_MODULE_SUCCESS",
  USER_MODULE_FAILURE: "USER_MODULE_FAILURE",

  STORE_USER_PROFILE_REQUEST: "STORE_USER_PROFILE_REQUEST",
  STORE_USER_PROFILE_SUCCESS: "STORE_USER_PROFILE_SUCCESS",
  STORE_USER_PROFILE_FAILURE: "STORE_USER_PROFILE_FAILURE",

  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  GET_USER_STATISTICS_REQUEST: "GET_USER_STATISTICS_REQUEST",
  GET_USER_STATISTICS_SUCCESS: "GET_USER_STATISTICS_SUCCESS",
  GET_USER_STATISTICS_FAILURE: "GET_USER_STATISTICS_FAILURE",

  UPDATE_PROFILE_IMAGE_REQUEST: "UPDATE_PROFILE_IMAGE_REQUEST",
  UPDATE_PROFILE_IMAGE_SUCCESS: "UPDATE_PROFILE_IMAGE_SUCCESS",
  UPDATE_PROFILE_IMAGE_FAILURE: "UPDATE_PROFILE_IMAGE_FAILURE",

  GET_PROFILE_IMAGE_REQUEST: "GET_PROFILE_IMAGE_REQUEST",
  GET_PROFILE_IMAGE_SUCCESS: "GET_PROFILE_IMAGE_SUCCESS",
  GET_PROFILE_IMAGE_FAILURE: "GET_PROFILE_IMAGE_FAILURE"
};

export const INVITE_CONST = {
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  INVITE_REQUEST: "INVITE_REQUEST",
  INVITE_SUCCESS: "INVITE_SUCCESS",
  INVITE_FAILURE: "INVITE_FAILURE",

  GET_EMAIL_REQUEST: "GET_EMAIL_REQUEST",
  GET_EMAIL_SUCCESS: "GET_EMAIL_SUCCESS",
  GET_EMAIL_FAILURE: "GET_EMAIL_FAILURE",

  SEARCH_USER_REQUEST: "SEARCH_USER_REQUEST",
  SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",
  SEARCH_USER_FAILURE: "SEARCH_USER_FAILURE",

  GET_USER_ROLE_REQUEST: "GET_USER_ROLE_REQUEST",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",
  GET_USER_ROLE_FAILURE: "GET_USER_ROLE_FAILURE",

  SEND_ROLE_DATA_REQUEST: "SEND_ROLE_DATA_REQUEST",
  SEND_ROLE_DATA_SUCCESS: "SEND_ROLE_DATA_SUCCESS",
  SEND_ROLE_DATA_FAILURE: "SEND_ROLE_DATA_FAILURE",

  GET_USERSCOPE_REQUEST: "GET_USERSCOPE_REQUEST",
  GET_USERSCOPE_SUCCESS: "GET_USERSCOPE_SUCCESS",
  GET_USERSCOPE_FAILURE: "GET_USERSCOPE_FAILURE"
};

export const ROLES_CONST = {
  USERS_BY_ROLE_REQUEST: "USERS_BY_ROLE_REQUEST",
  USERS_BY_ROLE_SUCCESS: "USERS_BY_ROLE_SUCCESS",
  USERS_BY_ROLE_FAILURE: "USERS_BY_ROLE_FAILURE",

  DELETE_USERS_ROLE_REQUEST: "DELETE_USERS_ROLE_REQUEST",
  DELETE_USERS_ROLE_SUCCESS: "DELETE_USERS_ROLE_SUCCESS",
  DELETE_USERS_ROLE_FAILURE: "DELETE_USERS_ROLE_FAILURE",

  MEMBERS_COUNT_REQUEST: "MEMBERS_COUNT_REQUEST",
  MEMBERS_COUNT_SUCCESS: "MEMBERS_COUNT_SUCCESS",
  MEMBERS_COUNT_FAILURE: "MEMBERS_COUNT_FAILURE"
};

export const PARTNER_CONST = {
  GET_ALL_PARTNER_REQUEST: "GET_ALL_PARTNER_REQUEST",
  GET_ALL_PARTNER_SUCCESS: "GET_ALL_PARTNER_SUCCESS",
  GET_ALL_PARTNER_FAILURE: "GET_ALL_PARTNER_FAILURE",

  GET_ALL_PARTNER_CUSTOMERS_REQUEST: "GET_ALL_PARTNER_CUSTOMERS_REQUEST",
  GET_ALL_PARTNER_CUSTOMERS_SUCCESS: "GET_ALL_PARTNER_CUSTOMERS_SUCCESS",
  GET_ALL_PARTNER_CUSTOMERS_FAILURE: "GET_ALL_PARTNER_CUSTOMERS_FAILURE",

  CREATE_PARTNER_REQUEST: "CREATE_PARTNER_REQUEST",
  CREATE_PARTNER_SUCCESS: "CREATE_PARTNER_SUCCESS",
  CREATE_PARTNER_FAILURE: "CREATE_PARTNER_FAILURE",

  UPDATE_PARTNER_REQUEST: "UPDATE_PARTNER_REQUEST",
  UPDATE_PARTNER_SUCCESS: "UPDATE_PARTNER_SUCCESS",
  UPDATE_PARTNER_FAILURE: "UPDATE_PARTNER_FAILURE",

  DELETE_PARTNER_REQUEST: "DELETE_PARTNER_REQUEST",
  DELETE_PARTNER_SUCCESS: "DELETE_PARTNER_SUCCESS",
  DELETE_PARTNER_FAILURE: "DELETE_PARTNER_FAILURE",

  UPDATE_PARTNER_STATUS_REQUEST: "UPDATE_PARTNER_STATUS_REQUEST",
  UPDATE_PARTNER_STATUS_SUCCESS: "UPDATE_PARTNER_STATUS_SUCCESS",
  UPDATE_PARTNER_STATUS_FAILURE: "UPDATE_PARTNER_STATUS_FAILURE"
};

export const CUSTOMER_CONST = {
  GET_ALL_CUSTOMER_REQUEST: "GET_ALL_CUSTOMER_REQUEST",
  GET_ALL_CUSTOMER_SUCCESS: "GET_ALL_CUSTOMER_SUCCESS",
  GET_ALL_CUSTOMER_FAILURE: "GET_ALL_CUSTOMER_FAILURE",

  GET_CUSTOMER_STATISTICS_REQUEST: "GET_CUSTOMER_STATISTICS_REQUEST",
  GET_CUSTOMER_STATISTICS_SUCCESS: "GET_CUSTOMER_STATISTICS_SUCCESS",
  GET_CUSTOMER_STATISTICS_FAILURE: "GET_CUSTOMER_STATISTICS_FAILURE",

  GET_CUSTOMER_ROLES_REQUEST: "GET_CUSTOMER_ROLES_REQUEST",
  GET_CUSTOMER_ROLES_SUCCESS: "GET_CUSTOMER_ROLES_SUCCESS",
  GET_CUSTOMER_ROLES_FAILURE: "GET_CUSTOMER_ROLES_FAILURE",

  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",

  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",

  UPDATE_CUSTOMER_STATUS_REQUEST: "UPDATE_CUSTOMER_STATUS_REQUEST",
  UPDATE_CUSTOMER_STATUS_SUCCESS: "UPDATE_CUSTOMER_STATUS_SUCCESS",
  UPDATE_CUSTOMER_STATUS_FAILURE: "UPDATE_CUSTOMER_STATUS_FAILURE"
};

export const TEAM_CONST = {
  GET_ALL_TEAM_REQUEST: "GET_ALL_TEAM_REQUEST",
  GET_ALL_TEAM_SUCCESS: "GET_ALL_TEAM_SUCCESS",
  GET_ALL_TEAM_FAILURE: "GET_ALL_TEAM_FAILURE",

  CREATE_TEAM_REQUEST: "CREATE_TEAM_REQUEST",
  CREATE_TEAM_SUCCESS: "CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE: "CREATE_TEAM_FAILURE",

  DELETE_TEAM_REQUEST: "DELETE_TEAM_REQUEST",
  DELETE_TEAM_SUCCESS: "DELETE_TEAM_SUCCESS",
  DELETE_TEAM_FAILURE: "DELETE_TEAM_FAILURE",

  UPDATE_TEAM_REQUEST: "UPDATE_TEAM_REQUEST",
  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAILURE: "UPDATE_TEAM_FAILURE",

  GET_TEAM_STATISTICS_REQUEST: "GET_TEAM_STATISTICS_REQUEST",
  GET_TEAM_STATISTICS_SUCCESS: "GET_TEAM_STATISTICS_SUCCESS",
  GET_TEAM_STATISTICS_FAILURE: "GET_TEAM_STATISTICS_FAILURE",

  UPDATE_TEAM_STATUS_REQUEST: "UPDATE_TEAM_STATUS_REQUEST",
  UPDATE_TEAM_STATUS_SUCCESS: "UPDATE_TEAM_STATUS_SUCCESS",
  UPDATE_TEAM_STATUS_FAILURE: "UPDATE_TEAM_STATUS_FAILURE",

  GET_TEAM_ALERTS_REQUEST: "GET_TEAM_ALERTS_REQUEST",
  GET_TEAM_ALERTS_SUCCESS: "GET_TEAM_ALERTS_SUCCESS",
  GET_TEAM_ALERTS_FAILURE: "GET_TEAM_ALERTS_FAILURE",

  ADD_TEAM_USER_REQUEST: "ADD_TEAM_USER_REQUEST",
  ADD_TEAM_USER_SUCCESS: "ADD_TEAM_USER_SUCCESS",
  ADD_TEAM_USER_FAILURE: "ADD_TEAM_USER_FAILURE",

  GET_TEAM_USERS_REQUEST: "GET_TEAM_USERS_REQUEST",
  GET_TEAM_USERS_SUCCESS: "GET_TEAM_USERS_SUCCESS",
  GET_TEAM_USERS_FAILURE: "GET_TEAM_USERS_FAILURE",

  DELETE_TEAM_USER_REQUEST: "DELETE_TEAM_USER_REQUEST",
  DELETE_TEAM_USER_SUCCESS: "DELETE_TEAM_USER_SUCCESS",
  DELETE_TEAM_USER_FAILURE: "DELETE_TEAM_USER_FAILURE",

  ASSIGN_RULE_TO_TEAM_REQUEST: "ASSIGN_RULE_TO_TEAM_REQUEST",
  ASSIGN_RULE_TO_TEAM_SUCCESS: "ASSIGN_RULE_TO_TEAM_SUCCESS",
  ASSIGN_RULE_TO_TEAM_FAILURE: "ASSIGN_RULE_TO_TEAM_FAILURE",

  UNASSIGN_RULE_TO_TEAM_REQUEST: "UNASSIGN_RULE_TO_TEAM_REQUEST",
  UNASSIGN_RULE_TO_TEAM_SUCCESS: "UNASSIGN_RULE_TO_TEAM_SUCCESS",
  UNASSIGN_RULE_TO_TEAM_FAILURE: "UNASSIGN_RULE_TO_TEAM_FAILURE"
};

export const ALERT_CONST = {
  CREATE_ALERT_COMMENT_REQUEST: "CREATE_ALERT_COMMENT_REQUEST",
  CREATE_ALERT_COMMENT_SUCCESS: "CREATE_ALERT_COMMENT_SUCCESS",
  CREATE_ALERT_COMMENT_FAILURE: "CREATE_ALERT_COMMENT_FAILURE",

  GET_ALERT_DETAILS_REQUEST: "GET_ALERTS_DETAILS_REQUEST",
  GET_ALERT_DETAILS_SUCCESS: "GET_ALERTS_DETAILS_SUCCESS",
  GET_ALERT_DETAILS_FAILURE: "GET_ALERTS_DETAILS_FAILURE",

  GET_ALERTS_REQUEST: "GET_ALERTS_REQUEST",
  GET_ALERTS_SUCCESS: "GET_ALERTS_SUCCESS",
  GET_ALERTS_FAILURE: "GET_ALERTS_FAILURE",

  ASSIGN_ALERT_TO_USER_REQUEST: "ASSIGN_ALERT_TO_USER_REQUEST",
  ASSIGN_ALERT_TO_USER_SUCCESS: "ASSIGN_ALERT_TO_USER_SUCCESS",
  ASSIGN_ALERT_TO_USER_FAILURE: "ASSIGN_ALERT_TO_USER_FAILURE",

  UNASSIGN_ALERT_TO_USER_REQUEST: "UNASSIGN_ALERT_TO_USER_REQUEST",
  UNASSIGN_ALERT_TO_USER_SUCCESS: "UNASSIGN_ALERT_TO_USER_SUCCESS",
  UNASSIGN_ALERT_TO_USER_FAILURE: "UNASSIGN_ALERT_TO_USER_FAILURE",

  GET_USER_LIST_REQUEST: "GET_USER_LIST_REQUEST",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "GET_USER_LIST_FAILURE"
};

export const AGENT_CONST = {
  GET_AGENT_REQUEST: "GET_AGENT_REQUEST",
  GET_AGENT_SUCCESS: "GET_AGENT_SUCCESS",
  GET_AGENT_FAILURE: "GET_AGENT_FAILURE",

  CREATE_AGENT_REQUEST: "CREATE_AGENT_REQUEST",
  CREATE_AGENT_SUCCESS: "CREATE_AGENT_SUCCESS",
  CREATE_AGENT_FAILURE: "CREATE_AGENT_FAILURE",

  DELETE_AGENT_REQUEST: "DELETE_AGENT_REQUEST",
  DELETE_AGENT_SUCCESS: "DELETE_AGENT_SUCCESS",
  DELETE_AGENT_FAILURE: "DELETE_AGENT_FAILURE",

  UPDATE_AGENT_NAME_REQUEST: "UPDATE_AGENT_NAME_REQUEST",
  UPDATE_AGENT_NAME_SUCCESS: "UPDATE_AGENT_NAME_SUCCESS",
  UPDATE_AGENT_NAME_FAILURE: "UPDATE_AGENT_NAME_FAILURE",

  GET_CERTIFICATE_REQUEST: "GET_CERTIFICATE_REQUEST",
  GET_CERTIFICATE_SUCCESS: "GET_CERTIFICATE_SUCCESS",
  GET_CERTIFICATE_FAILURE: "GET_CERTIFICATE_FAILURE",

  UPDATE_AGENT_STATUS_REQUEST: "UPDATE_AGENT_STATUS_REQUEST",
  UPDATE_AGENT_STATUS_SUCCESS: "UPDATE_AGENT_STATUS_SUCCESS",
  UPDATE_AGENT_STATUS_FAILURE: "UPDATE_AGENT_STATUS_FAILURE"
};

export const RULE_CONST = {
  GET_RULES_BY_CRITERIA_REQUEST: "GET_RULES_BY_CRITERIA_REQUEST",
  GET_RULES_BY_CRITERIA_SUCCESS: "GET_RULES_BY_CRITERIA_SUCCESS",
  GET_RULES_BY_CRITERIA_FAILURE: "GET_RULES_BY_CRITERIA_FAILURE",

  GET_RULES_FOR_INSTANCE_REQUEST: "GET_RULES_FOR_INSTANCE_REQUEST",
  GET_RULES_FOR_INSTANCE_SUCCESS: "GET_RULES_FOR_INSTANCE_SUCCESS",
  GET_RULES_FOR_INSTANCE_FAILURE: "GET_RULES_FOR_INSTANCE_FAILURE",

  CREATE_RULE_REQUEST: "CREATE_RULE_REQUEST",
  CREATE_RULE_SUCCESS: "CREATE_RULE_SUCCESS",
  CREATE_RULE_FAILURE: "CREATE_RULE_FAILURE",

  GET_TEAM_BY_RULE_REQUEST: "GET_TEAM_BY_RULE_REQUEST",
  GET_TEAM_BY_RULE_SUCCESS: "GET_TEAM_BY_RULE_SUCCESS",
  GET_TEAM_BY_RULE_FAILURE: "GET_TEAM_BY_RULE_FAILURE",

  UPDATE_RULE_REQUEST: "UPDATE_RULE_REQUEST",
  UPDATE_RULE_SUCCESS: "UPDATE_RULE_SUCCESS",
  UPDATE_RULE_FAILURE: "UPDATE_RULE_FAILURE",

  DELETE_RULE_REQUEST: "DELETE_RULE_REQUEST",
  DELETE_RULE_SUCCESS: "DELETE_RULE_SUCCESS",
  DELETE_RULE_FAILURE: "DELETE_RULE_FAILURE",

  GET_RULE_NOTIFICATION_REQUEST: "GET_RULE_NOTIFICATION_REQUEST",
  GET_RULE_NOTIFICATION_SUCCESS: "GET_RULE_NOTIFICATION_SUCCESS",
  GET_RULE_NOTIFICATION_FAILURE: "GET_RULE_NOTIFICATION_FAILURE",

  CREATE_RULE_NOTIFICATION_REQUEST: "CREATE_RULE_NOTIFICATION_REQUEST",
  CREATE_RULE_NOTIFICATION_SUCCESS: "CREATE_RULE_NOTIFICATION_SUCCESS",
  CREATE_RULE_NOTIFICATION_FAILURE: "CREATE_RULE_NOTIFICATION_FAILURE",

  UPDATE_RULE_NOTIFICATION_REQUEST: "UPDATE_RULE_NOTIFICATION_REQUEST",
  UPDATE_RULE_NOTIFICATION_SUCCESS: "UPDATE_RULE_NOTIFICATION_SUCCESS",
  UPDATE_RULE_NOTIFICATION_FAILURE: "UPDATE_RULE_NOTIFICATION_FAILURE",

  DELETE_RULE_NOTIFICATION_REQUEST: "DELETE_RULE_NOTIFICATION_REQUEST",
  DELETE_RULE_NOTIFICATION_SUCCESS: "DELETE_RULE_NOTIFICATION_SUCCESS",
  DELETE_RULE_NOTIFICATION_FAILURE: "DELETE_RULE_NOTIFICATION_FAILURE"
};

export const INSTANCE_CONST = {
  GET_INSTANCE_BY_CRITERIA_REQUEST: "GET_INSTANCE_BY_CRITERIA_REQUEST",
  GET_INSTANCE_BY_CRITERIA_SUCCESS: "GET_INSTANCE_BY_CRITERIA_SUCCESS",
  GET_INSTANCE_BY_CRITERIA_FAILURE: "GET_INSTANCE_BY_CRITERIA_FAILURE",

  CREATE_INSTANCE_REQUEST: "CREATE_INSTANCE_REQUEST",
  CREATE_INSTANCE_SUCCESS: "CREATE_INSTANCE_SUCCESS",
  CREATE_INSTANCE_FAILURE: "CREATE_INSTANCE_FAILURE",

  GET_INSTANCE_STATISTICS_REQUEST: "GET_INSTANCE_STATISTICS_REQUEST",
  GET_INSTANCE_STATISTICS_SUCCESS: "GET_INSTANCE_STATISTICS_SUCCESS",
  GET_INSTANCE_STATISTICS_FAILURE: "GET_INSTANCE_STATISTICS_FAILURE",

  GET_INSTANCE_LOG_REQUEST: "GET_INSTANCE_LOG_REQUEST",
  GET_INSTANCE_LOG_SUCCESS: "GET_INSTANCE_LOG_SUCCESS",
  GET_INSTANCE_LOG_FAILURE: "GET_INSTANCE_LOG_FAILURE",

  UPDATE_INSTANCE_REQUEST: "UPDATE_INSTANCE_REQUEST",
  UPDATE_INSTANCE_SUCCESS: "UPDATE_INSTANCE_SUCCESS",
  UPDATE_INSTANCE_FAILURE: "UPDATE_INSTANCE_FAILURE",

  DELETE_INSTANCE_REQUEST: "DELETE_INSTANCE_REQUEST",
  DELETE_INSTANCE_SUCCESS: "DELETE_INSTANCE_SUCCESS",
  DELETE_INSTANCE_FAILURE: "DELETE_INSTANCE_FAILURE",

  GET_CERTIFICATE_REQUEST: "GET_CERTIFICATE_REQUEST",
  GET_CERTIFICATE_SUCCESS: "GET_CERTIFICATE_SUCCESS",
  GET_CERTIFICATE_FAILURE: "GET_CERTIFICATE_FAILURE"
};

export const TIMEZONE_CONST = {
  GET_TIMEZONE_REQUEST: "GET_TIMEZONE_REQUEST",
  GET_TIMEZONE_SUCCESS: "GET_TIMEZONE_SUCCESS",
  GET_TIMEZONE_FAILURE: "GET_TIMEZONE_FAILURE",

  GET_COUNTRY_CODE_REQUEST: "GET_COUNTRY_CODE_REQUEST",
  GET_COUNTRY_CODE_SUCCESS: "GET_COUNTRY_CODE_SUCCESS",
  GET_COUNTRY_CODE_FAILURE: "GET_COUNTRY_CODE_FAILURE"
};

export const PERSPECTIVE_CONST = {
  GET_PERSPECTIVES_REQUEST: "GET_PERPECTIVES_REQUEST",
  GET_PERSPECTIVES_SUCCESS: "GET_PERPECTIVES_SUCCESS",
  GET_PERSPECTIVES_FAILURE: "GET_PERPECTIVES_FAILURE",

  CREATE_PERSPECTIVE_REQUEST: "CREATE_PERSPECTIVE_REQUEST",
  CREATE_PERSPECTIVE_SUCCESS: "CREATE_PERSPECTIVE_SUCCESS",
  CREATE_PERSPECTIVE_FAILURE: "CREATE_PERSPECTIVE_FAILURE",

  UPDATE_PERSPECTIVE_REQUEST: "UPDATE_PERSPECTIVE_REQUEST",
  UPDATE_PERSPECTIVE_SUCCESS: "UPDATE_PERSPECTIVE_SUCCESS",
  UPDATE_PERSPECTIVE_FAILURE: "UPDATE_PERSPECTIVE_FAILURE",

  DELETE_PERSPECTIVE_REQUEST: "DELETE_PERSPECTIVE_REQUEST",
  DELETE_PERSPECTIVE_SUCCESS: "DELETE_PERSPECTIVE_SUCCESS",
  DELETE_PERSPECTIVE_FAILURE: "DELETE_PERSPECTIVE_FAILURE"
};

export const DASHBOARD_CONST = {
  GET_USER_STATISTICS_REQUEST: "GET_USER_STATISTICS_REQUEST",
  GET_USER_STATISTICS_SUCCESS: "GET_USER_STATISTICS_SUCCESS",
  GET_USER_STATISTICS_FAILURE: "GET_USER_STATISTICS_FAILURE",

  GET_TEAM_DASHBOARD_REQUEST: "GET_TEAM_DASHBOARD_REQUEST",
  GET_TEAM_DASHBOARD_SUCCESS: "GET_TEAM_DASHBOARD_SUCCESS",
  GET_TEAM_DASHBOARD_FAILURE: "GET_TEAM_DASHBOARD_FAILURE",

  GET_ORGANIZATION_ALERTS_REQUEST: "GET_ORGANIZATION_ALERTS_REQUEST",
  GET_ORGANIZATION_ALERTS_SUCCESS: "GET_ORGANIZATION_ALERTS_SUCCESS",
  GET_ORGANIZATION_ALERTS_FAILURE: "GET_ORGANIZATION_ALERTS_FAILURE",

  GET_ALERT_AND_NOTIFICATION_REQUEST: "GET_ALERT_AND_NOTIFICATION_REQUEST",
  GET_ALERT_AND_NOTIFICATION_SUCCESS: "GET_ALERT_AND_NOTIFICATION_SUCCESS",
  GET_ALERT_AND_NOTIFICATION_FAILURE: "GET_ALERT_AND_NOTIFICATION_FAILURE",
  
  GET_DASHBOARD_INFO_REQUEST: "GET_DASHBOARD_INFO_REQUEST",
  GET_DASHBOARD_INFO_SUCCESS: "GET_DASHBOARD_INFO_SUCCESS",
  GET_DASHBOARD_INFO_FAILURE: "GET_DASHBOARD_INFO_FAILURE",

  
  GET_CUSTOMER_WISE_DATA_REQUEST: "GET_CUSTOMER_WISE_DATA_REQUEST",
  GET_CUSTOMER_WISE_DATA_SUCCESS: "GET_CUSTOMER_WISE_DATA_SUCCESS",
  GET_CUSTOMER_WISE_DATA_FAILURE: "GET_CUSTOMER_WISE_DATA_FAILURE",

  GET_PARTNER_DASHBOARD_REQUEST: "GET_PARTNER_DASHBOARD_REQUEST",
  GET_PARTNER_DASHBOARD_SUCCESS: "GET_PARTNER_DASHBOARD_SUCCESS",
  GET_PARTNER_DASHBOARD_FAILURE: "GET_PARTNER_DASHBOARD_FAILURE",

  GET_TEAM_USERS_REQUEST: "GET_TEAM_USERS_REQUEST",
  GET_TEAM_USERS_SUCCESS: "GET_TEAM_USERS_SUCCESS",
  GET_TEAM_USERS_FAILURE: "GET_TEAM_USERS_FAILURE",

  GET_TEAM_ALERTS_REQUEST: "GET_TEAM_ALERTS_REQUEST",
  GET_TEAM_ALERTS_SUCCESS: "GET_TEAM_ALERTS_SUCCESS",
  GET_TEAM_ALERTS_FAILURE: "GET_TEAM_ALERTS_FAILURE",

  GET_TEAM_USER_ALERTS_REQUEST: "GET_TEAM_USER_ALERTS_REQUEST",
  GET_TEAM_USER_ALERTS_SUCCESS: "GET_TEAM_USER_ALERTS_SUCCESS",
  GET_TEAM_USER_ALERTS_FAILURE: "GET_TEAM_USER_ALERTS_FAILURE",

  GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",
  
};

export const NOTIFICATIONS_CONST = {
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  DELETE_NOTIFICATIONS_REQUEST: "DELETE_NOTIFICATIONS_REQUEST",
  DELETE_NOTIFICATIONS_SUCCESS: "DELETE_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATIONS_FAILURE: "DELETE_NOTIFICATIONS_FAILURE",

  UPDATE_NOTIFICATIONS_REQUEST: "UPDATE_NOTIFICATIONS_REQUEST",
  UPDATE_NOTIFICATIONS_SUCCESS: "UPDATE_NOTIFICATIONS_SUCCESS",
  UPDATE_NOTIFICATIONS_FAILURE: "UPDATE_NOTIFICATIONS_FAILURE"
};
