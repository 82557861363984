/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import IssuesPage from "../Components/IssuesPage.jsx";
import { getTeamAlerts } from "../../../../actions/teamAction";
import { getAlertDetails } from "../../../../actions/alertAction";
import { getPerspectiveData } from "../../../../actions/perspectiveAction";
import PropTypes from "prop-types";
import { showError } from "../../../../helpers/Utility.jsx";
import queryString from "query-string";
class IssuesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {},
      showIssueDetailsModal: false,
      issuesDataError: false,
      key: null,
      alertId: null,
      formData: {},
      selectedDays: 7
    };
    this.issuesData = [];
    this.intervalID = 0;
  }

  componentDidMount = () => {
    this.props.dispatch(getPerspectiveData()); // perspective action creator that makes get request to api to get perspective data
    this.getData(); // function call for getting the teamAlerts data based on form data
    this.intervalID = setInterval(this.getData, 60000); //updates for every 1 minute
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalID); // auto refresh alerts for 1 minute stops in background when routed to other pages
  };

  /*---------function for getting the Alerts data based on form data of type and ID ---------*/
  getData = () => {
    let formData = {};
    if (document.getElementById("exampleSelect").value !== "perspective") {
      formData = {
        type: "perspective",
        id: document.getElementById("exampleSelect").value,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    } else if (this.props.id && this.props.names) {
      formData = {
        type: queryString.parse(this.props.names).teamName
          ? "team"
          : "instance",
        id: this.props.id,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    } else if (queryString.parse(this.props.id).perspectiveId) {
      formData = {
        type: "perspective",
        id: queryString.parse(this.props.id).perspectiveId,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    } else {
      let UID = JSON.parse(localStorage.getItem("userDetails")).UID_User;
      formData = {
        type: "user",
        id: UID,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    }
    this.setState({ formData });
    this.props.dispatch(getTeamAlerts(formData));
  };

  // perspective dropdown function
  filterPerspective = event => {
    let formData;
    if (event.target.value !== "perspective") {
      formData = {
        type: "perspective",
        id: event.target.value,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    } else if (this.props.id && this.props.names) {
      formData = {
        type: queryString.parse(this.props.names).teamName
          ? "team"
          : "instance",
        id: this.props.id,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    } else {
      let UID = JSON.parse(localStorage.getItem("userDetails")).UID_User;
      formData = {
        type: "user",
        id: UID,
        day: this.state.selectedDays === "all" ? "" : this.state.selectedDays
      };
    }
    this.setState({ formData });
    this.props.dispatch(getTeamAlerts(formData));
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    this.setState({
      isRequestingTwo: nextProps.isRequestingTwo,
      isRequesting: nextProps.isRequesting
    });
    if (
      nextProps.issuesData &&
      nextProps.issuesData !== this.props.issuesData
    ) {
      if (this.state.searchValue) {
        this.filterIssues(nextProps.issuesData, this.state.searchValue);
      } else {
        this.setState({
          issuesData: nextProps.issuesData,
          issuesSearchdata: nextProps.issuesData
        });
      }
      this.issuesData = nextProps.issuesData;
    }
    if (
      nextProps.issuesDataError &&
      nextProps.issuesDataError !== this.props.issuesDataError
    ) {
      this.setState({
        issuesDataError: true
      });
      showError(this.props.t("error.ISSUES_GETTING_ERROR"));
    }
    /**issue details api response */
    if (
      nextProps.issueDetailsData &&
      nextProps.issueDetailsData !== this.props.issueDetailsData
    ) {
      this.setState({
        issueDetails: nextProps.issueDetailsData, //issue details data
        showIssueDetailsModal: true //here we are opening issue details modal
      });
    }
    // perspective details api response
    if (
      nextProps.perspectiveData &&
      nextProps.perspectiveData.data !== this.props.perspectiveData
    ) {
      this.setState({
        perspectiveData: nextProps.perspectiveData.data //perspective data
      });
    }
  };

  // Apply the search filter
  filterIssues = (data, value) => {
    if (data && value) {
      const result = data.filter(issue => {
        return (
          true ===
          issue.Rule.Name_Rule.toLowerCase().includes(value.toLowerCase())
        );
      });
      this.setState({
        issuesData: result
      });
    } else {
      this.setState({
        issuesData: this.issuesData
      });
    }
  };

  //this function we are calling to search issues
  searchIssue = event => {
    const value = event.target.value;
    this.setState({
      searchValue: value
    });

    this.filterIssues(this.state.issuesSearchdata, value);
  };

  //call this function to get closed issues based on filters
  getClosedAlertsData = days => {
    this.setState({ selectedDays: days });
    let formData = {};
    if (document.getElementById("exampleSelect").value !== "perspective") {
      formData = {
        type: "perspective",
        id: document.getElementById("exampleSelect").value,
        day: days === "all" ? "" : days
      };
    } else if (this.props.id && this.props.names) {
      formData = {
        type: queryString.parse(this.props.names).teamName
          ? "team"
          : "instance",
        id: this.props.id,
        day: days === "all" ? "" : days
      };
    } else {
      let UID = JSON.parse(localStorage.getItem("userDetails")).UID_User;
      formData = {
        type: "user",
        id: UID,
        day: days === "all" ? "" : days
      };
    }
    this.setState({ formData });
    this.props.dispatch(getTeamAlerts(formData));
  };

  /*call this to show issue details modal */
  showIssueModal = (alertId, key) => {
    this.props.dispatch(getAlertDetails(alertId)); //based this api result we are opening modal
    this.setState({
      key: key,
      alertId: alertId
    });
  };

  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({ showIssueDetailsModal: !this.state.showIssueDetailsModal });
  };

  //here we are navigating issue details page
  showIssueDetailsPage = alert_id => {
    this.props.history.push("/dashboard/issueDetails/" + alert_id);
  };

  render() {
    const name = this.props.names
      ? queryString.parse(this.props.names).teamName
        ? queryString
            .parse(this.props.names)
            .teamName.split("_")
            .join(" ")
        : queryString.parse(this.props.names).instanceName
        ? queryString
            .parse(this.props.names)
            .instanceName.split("_")
            .join(" ")
        : ""
      : "";
    const customerName = this.props.names
      ? queryString
          .parse(this.props.names)
          .customerName.split("_")
          .join(" ")
      : "";
    const customerId = this.props.names
      ? queryString.parse(this.props.names).customerId
      : "";
    return (
      <div>
        <IssuesPage
          stateValues={this.state}
          id={this.props.id}
          names={this.props.names}
          searchIssue={this.searchIssue}
          issuesData={this.state.issuesData}
          showIssueDetailsPage={this.showIssueDetailsPage}
          showIssueModal={this.showIssueModal}
          handleModal={this.handleModal}
          customerName={customerName}
          teamName={name}
          customerId={customerId}
          isRequesting={this.state.isRequesting}
          isRequestingTwo={this.state.isRequestingTwo}
          keyValue={this.state.key}
          alertId={this.state.alertId}
          filterPerspective={this.filterPerspective}
          getClosedAlertsData={this.getClosedAlertsData}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isRequesting: state.team.isRequesting,
    issuesData: state.team.issuesData,
    issuesDataError: state.team.issuesDataError,
    issueDetailsData: state.alert.issueDetailsData,
    isRequestingTwo: state.alert.isRequestingTwo,
    perspectiveData: state.perspective.perspectiveData
  };
}
IssuesContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  issuesDataError: PropTypes.bool,
  issuesData: PropTypes.array,
  id: PropTypes.string,
  t: PropTypes.func,
  isRequestingTwo: PropTypes.bool,
  isRequesting: PropTypes.bool,
  names: PropTypes.string,
  issueDetailsData: PropTypes.object,
  perspectiveData: PropTypes.object
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "issues-form"
  }),
  connect(mapStateToProps)
)(IssuesContainer);
