import { NOTIFICATIONS_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    isRequesting: false,
    isRequestingTwo: false,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case NOTIFICATIONS_CONST.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsData: null,
        isRequesting: true,
        notificationsError: false
      };
    case NOTIFICATIONS_CONST.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        notificationsData: action.payload.response.data,
        notificationsError: false
      };
    case NOTIFICATIONS_CONST.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isRequesting: false,
        notificationsData: null,
        notificationsError: action.payload.error ? true : false
      };
    case NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        deleteNotificationSuccess: false,
        deleteNotificationFailure: false
      };
    case NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        deleteNotificationSuccess: action.payload ? true : false,
        deleteNotificationFailure: false
      };
    case NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        deleteNotificationSuccess: false,
        deleteNotificationFailure: action.payload.error ? true : false
      };
    case NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        updateNotificationSuccess: false,
        updateNotificationFailure: false,
        updateNotificationNetworkFailure: false
      };
    case NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        updateNotificationSuccess: action.payload ? true : false,
        updateNotificationFailure: false,
        updateNotificationNetworkFailure: false
      };
    case NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        updateNotificationSuccess: false,
        updateNotificationFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateNotificationNetworkFailure: action.payload.error ? true : false
      };
  }
  return state;
}
