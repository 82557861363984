import React, { PureComponent } from "react";
import Dashboard from "./Containers/DashboardContainer";

class DashboardPage extends PureComponent {
  render() {
    return (
      <div>
        <Dashboard />
      </div>
    );
  }
}

export default DashboardPage;
