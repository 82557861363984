import React, { PureComponent } from "react";
import UserRole from "./Containers/UserRoleContainer";

class UserRolePage extends PureComponent {
  render() {
    return (
      <div>
        <UserRole
          name={this.props.location.search}
          customerId={this.props.match.params.id}
          roleNumber={Number(this.props.match.params.role)}
        />
      </div>
    );
  }
}
//ijijiijii
export default UserRolePage;
