import React, { PureComponent } from "react";
import ForgotPassword from "./Containers/ForgotPasswordContainer";

class ForgotPasswordPage extends PureComponent {
  render() {
    return (
      <div>
        <ForgotPassword />
      </div>
    );
  }
}

export default ForgotPasswordPage;
