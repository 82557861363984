/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { translate } from "react-multi-lang";
import PropTypes from "prop-types";
import Dashboard from "../Components/DashboardComponent.jsx";

import {
  getDashboardData,
  getOrganisationAlerts,
  getAlertAndNotificationCount,
  getDashboardInfo,
  getCustomerWiseData,
  getPartnerDashboard,
  getTeamUsers,
  getTeamAlerts,
  getTeamUserAlerts,
  getUserInfo
} from "../../../../actions/dashboardAction";

import {
  isPartnerVisible,
  isCustomerVisible,
  isCustomerDashboard,
  isPartnerDashboard,
  isTeamDashboard,
  getMonday,
  isTeamVisible,
  isIssueVisible,
  isGlobalUserVisible,
} from "helpers/Utility";

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      task: {},
      partnerAlert: {},
      partnerTable: {},
      organizationGraphData: {},
      partnerRecentAlertsData: {},
      teamAlerts: {},
      teamActivity: {},
      teamOverviewPieData: 0,
      inputData: "",
      pieData: {},
      assigned: "",
      closed: "",
      unassigned: "",
      partnerTeamOverviewData: {},
      loader : false,
      dashboard: {},
      customerIndex: 0,
      teamIndex: 0,
      alertsActivityIndex: 0,
      teamOverviewPieIndex: 0,
    };
  }

   

  componentDidMount = () => {
    const date = new Date();

    const defaultDate = moment(new Date(date.getFullYear(), date.getMonth(), 1))
      .local()
      .format("YYYY-MM-DD");

    const qString = `?days=${defaultDate}`;
    this.props.dispatch(getUserInfo())
      this.setState({loader: true})
      this.props.dispatch(getDashboardInfo(qString));
      this.props.dispatch(getCustomerWiseData(qString));
      this.props.dispatch(getPartnerDashboard(qString));
      this.props.dispatch(getTeamUsers(qString));
      //TEAM DASHBOARD Dispatch
      this.props.dispatch(getTeamAlerts(qString));
      this.props.dispatch(getTeamUserAlerts(qString));
    //organization dispatch
      this.props.dispatch(getOrganisationAlerts(qString));
      this.props.dispatch(getAlertAndNotificationCount(qString));
    
      // this.props.dispatch(getTeamDashboardData(qString));
      // this.props.dispatch(getTeamDashboardGraphData(qString));
    
  };

  redirectViewAll = () => {
    this.props.history.push("/dashboard/organisations");
  };

  componentDidUpdate(prevProps, prevState) {
    const qString = `?days=${this.state.inputData}`;

    
    if (isPartnerDashboard(this.state.dashboard)) {
       
      if (prevState.inputData !== this.state.inputData) {
        this.setState({loader: true})
        this.props.dispatch(getDashboardInfo(qString));
        this.props.dispatch(getCustomerWiseData(qString));
        this.props.dispatch(getPartnerDashboard(qString));
        this.props.dispatch(getTeamUsers(qString));
        //teams data
        // this.props.dispatch(getTeamAlerts(qString));
        // this.props.dispatch(getTeamUserAlerts(qString));
         
      }
    }
    
    if (isCustomerDashboard(this.state.dashboard)) {
       
      if (prevState.inputData !== this.state.inputData) {
        this.setState({loader: true})
        this.props.dispatch(getOrganisationAlerts(qString));
        this.props.dispatch(getAlertAndNotificationCount(qString));
      }
    }


    if (isTeamDashboard(this.state.dashboard)) {
      if (prevState.inputData !== this.state.inputData) {
        this.setState({loader: true})
        this.props.dispatch(getTeamAlerts(qString));
        this.props.dispatch(getTeamUserAlerts(qString));
      }
    }
  }

  getTeamOVerviewData = (data) => {
    console.log(" selected team", data)
    this.setState({
      teamOverviewPieIndex: data,
    });
  };

  getPieChartData = (assigned, closed, unassigned) => {
    this.setState({
      assigned: assigned,
      closed: closed,
      unassigned: unassigned,
    });
  };

  getInputData = (inputString) => {
    this.setState({ inputData: inputString });
  };

  getInstanceOverviewSelectedIndex=(data)=>{
    this.setState({ customerIndex : data})
  }

  getTeamOverViewSelectedIndex=(data)=>{
    this.setState({ teamIndex : data})
  }

  getAlertsActivitySelectedIndex=(data)=>{
    console.log("get alerts acitvity data", data)
    this.setState({ alertsActivityIndex: data })
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {

    if(
        nextProps.dashboard &&
        nextProps.dashboard !== this.props.dashboard
      ) {
        this.setState({
          dashboard: nextProps.dashboard
        })
      }
    
    if (isPartnerDashboard(nextProps.dashboard)) {
      if (
        nextProps.partnerdashBoardAlert &&
        nextProps.partnerdashBoardAlert !== this.props.partnerAlert
      ) {
      
        this.setState({
          partnerAlert: nextProps.partnerdashBoardAlert.task,
          loader: nextProps.isRequesting
        });
      }
      // console.log( "test",nextProps.partnerdashBoardAlert.task && nextProps.partnerdashBoardAlert.task.length > 0 && nextProps.partnerdashBoardAlert.task[0] )
      if (
        nextProps.partnerDashboardTable &&
        nextProps.partnerDashboardTable !== this.props.partnerTable
      ) {
        // console.log( "test",nextProps.partnerDashboardTable.task && nextProps.partnerDashboardTable.task.length > 0 && nextProps.partnerDashboardTable.task[0] )
        if (
          nextProps.partnerDashboardTable &&
          nextProps.partnerDashboardTable.task
        ) {
          let data = nextProps.partnerDashboardTable.task;

          // console.log(
          //   "repe---",
          //   data[0] &&
          //     data[0].customer &&
          //     data[0].customer.count &&
          //     data[0].customer.count.Assigned &&
          //     data[0].customer.count.Assigned
          // );
          // nextProps.partnerDashboardTable.task.map((index, key) => {
          //   if (0 == key) {
          //     this.setState({
          //       assigned:    10,
          //       closed:    20,
          //       unsigned:    30
          //       // assigned:   index.customer.count.Assigned ,
          //       // closed:   index.customer.count.Closed,
          //       // unsigned:   index.customer.count.Unassigned
          //     })
          //   }
          // })
          this.setState({
            partnerTable: nextProps.partnerDashboardTable.task,
            assigned:
              data[0] &&
              data[0].customer &&
              data[0].customer.count &&
              data[0].customer.count.Assigned &&
              data[0].customer.count.Assigned,
            closed:
              data[0] &&
              data[0].customer &&
              data[0].customer.count &&
              data[0].customer.count.Closed &&
              data[0].customer.count.Closed,
            unassigned:
              data[0] &&
              data[0].customer &&
              data[0].customer.count &&
              data[0].customer.count.Unassigned &&
              data[0].customer.count.Unassigned,
          });
        }
      }

      if (
        nextProps.partnerRecentAlerts &&
        nextProps.partnerRecentAlerts !== this.props.partnerRecentAlertsData
      ) {
        // console.log(
        //   "partnere recent alerts state",
        //   nextProps.partnerRecentAlerts
        // );
        this.setState({
          partnerRecentAlertsData: nextProps.partnerRecentAlerts.task,
        });
      }

      if (
        nextProps.partnerTeamOverview &&
        nextProps.partnerTeamOverview.task &&
        nextProps.partnerTeamOverview.task.recentAlerts.length > 0 &&
        nextProps.partnerTeamOverview !== this.props.partnerTeamOverviewData
      ) {
        // console.log("team ---- overview", nextProps.partnerTeamOverview);

        this.setState({
          partnerTeamOverviewData: nextProps.partnerTeamOverview.task,
          teamOverviewPieData:
            nextProps.partnerTeamOverview &&
            nextProps.partnerTeamOverview.task &&
            nextProps.partnerTeamOverview.task.recentAlerts.length > 0 &&
            nextProps.partnerTeamOverview.task.recentAlerts[0],
        });
      }

      // Team Dashboard code starting
      if (
        nextProps.teamDashboardAlerts &&
        nextProps.teamDashboardAlerts !== this.props.teamAlerts
      ) {
        this.setState({
          teamAlerts: nextProps.teamDashboardAlerts.task,
        });
      }
      if (
        nextProps.teamDashboardActivity &&
        nextProps.teamDashboardActivity !== this.props.teamActivity
      ) {
        this.setState({
          teamActivity: nextProps.teamDashboardActivity,
        });
      }
      //Team Dashbaird COde End
    } else if (isCustomerDashboard(nextProps.dashboard)) {
      if (
        nextProps.dashboardData &&
        nextProps.dashboardData !== this.props.task
      ) {
         
        this.setState({
          task: nextProps.dashboardData,
          loader: nextProps.isRequesting
        });
      }

      if (
        nextProps.organizationDashboardGraph &&
        nextProps.organizationDashboardGraph !==
          this.props.organizationGraphData
      ) {
        this.setState({
          organizationGraphData: nextProps.organizationDashboardGraph.task,
        });
      }
    } else {
    }
  };

  getMyRole = () => {
    const data = JSON.parse(localStorage.getItem("user_role"));
    if (data && data["Customers.Name_Customer"]) {
      return `${data["Roles.Display_Name"]}(${
        data["Customers.Name_Customer"]
      })`;
    } else if (data && data["Partners.Name_Partner"]) {
      return `${data["Roles.Display_Name"]}(${data["Partners.Name_Partner"]})`;
    } else {
      return `${data["Roles.Display_Name"]}`;
    }
  };

  render() {
    return (
      <div>
        <Dashboard
          permissions={this.state.permissions}
          inputData={this.getInputData}
          pieChartData={this.getPieChartData}
          teamOverviewData={this.state.teamOverviewPieData}
          teamOverviewFunction={this.getTeamOVerviewData}
          instanceData={this.state.task}
          graphData={this.state.organizationGraphData}
          partnerAlertData={this.state.partnerAlert}
          partnerTableData={this.state.partnerTable}
          partnerRecentData={this.state.partnerRecentAlertsData}
          assigned={this.state.assigned}
          closed={this.state.closed}
          unassigned={this.state.unassigned}
          teamOverview={this.state.partnerTeamOverviewData}
          teamAlerts={this.state.teamAlerts}
          teamActivityData={this.state.teamActivity}
          redirectViewAll={this.redirectViewAll}
          loader={this.state.loader}
          dashboard={this.state.dashboard}
          instanceOverViewSelectedIndex={ this.getInstanceOverviewSelectedIndex }
          customerIndex={ this.state.customerIndex}
          getTeamOverViewSelectedIndex={this.getTeamOverViewSelectedIndex}
          teamIndex={this.state.teamIndex}
          alertsActivityIndex={this.state.alertsActivityIndex}
          getAlertsActivitySelectedIndex={this.getAlertsActivitySelectedIndex}
          teamOverviewPieIndex={this.state.teamOverviewPieIndex}
          // userRolesData={this.state.userRoles}
          // showMembers={this.showMembers}
          // handleModal={this.handleModal}
          // userScope={this.state.userScope}
          // roles_count={this.state.roles_count}
          // getMyRole={this.getMyRole}
        />
      </div>
    );
  }
}
DashboardContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  isUserScopeFail: PropTypes.bool,
  isUserScope: PropTypes.object,
};

function mapStateToProps(state) {
  // console.log("dashboard activity", state.dashboard.dashboardDataSuccess);
  return {
    // isUserScope: state.invite.isUserScopeSuccess,
    // isUserScopeFail: state.invite.isUserScopeFailure,
    teamDashboardActivity: state.dashboard.teamDashboardActivityDataSuccess, // false
    teamDashboardAlerts: state.dashboard.teamDashboardDataSuccess, //false
    partnerTeamOverview: state.dashboard.partnerTeamOverviewDataSuccess, // task
    partnerRecentAlerts: state.dashboard.partnerRecentAlertsDataSuccess, // task
    partnerDashboardTable: state.dashboard.partnerDashboardTableDataSuccess, //task
    partnerdashBoardAlert: state.dashboard.partnerDashboardAlertDataSuccess, // task
    dashboardData: state.dashboard.orgDashboardSuccess, //
    organizationDashboardGraph: state.dashboard.orgDashboardGraphDataSuccess,
    isRequesting :  state.dashboard.isRequesting,
    dashboard: state.dashboard.dashboardDataSuccess
  };
}

export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "home-form",
  }),
  connect(mapStateToProps)
)(DashboardContainer);
