import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import { Button } from "components";
import SideBarToggle from "../../Common/SideBarToggle";
import CreatePerspectiveModal from "../../Modals/CreatePerspectiveModal";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import { deletePerspective } from "../../../../actions/perspectiveAction"
import ReactTable from "react-table";
class PerspectivesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreatePerspectiveModal: false,
      showUpdatePerspectiveModal: false
    };
  }
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({ showCreatePerspectiveModal: !this.state.showCreatePerspectiveModal });
  };

  handleEdit = () => {
    this.setState({ showUpdatePerspectiveModal: !this.state.showUpdatePerspectiveModal });
  };
 
  navigatePages = (perspective) => {
    if (this.props.path) {
        this.props.navigatePage(
          this.props.path,
          perspective.UID_Perspective
        );
    } else {
      return false;
    }
  };

  updatePerspective = (perspective) => {
    const PerspectiveFilters = perspective.PerspectiveFilters

    // Transforming the perspectiveData array
      const transformedData = {};
      PerspectiveFilters.forEach(value => {
          const { UID_Customer, UID_Instance } = value;
          if (!transformedData[UID_Customer]) {
              transformedData[UID_Customer] = {
                  perspectiveType: perspective.Type_Perspective,
                  id: UID_Customer,
                  instanceId: [UID_Instance]
              };
          } else {
              transformedData[UID_Customer].instanceId.push(UID_Instance);
          }
      });

      // Converting transformedData object into an array
      const perpectiveData = Object.values(transformedData);

      const initialValues = {
        name: perspective.Name_Perspective,
        perspectiveId: perspective.UID_Perspective,
        perspectiveData: perpectiveData
        
        
      };

    this.setState({
      initialValues: initialValues,
      showUpdatePerspectiveModal: true
    });
  }

  render() {
    const data = this.props.perspectiveData ? this.props.perspectiveData : [];
    /*-------react-table headers ---------*/
    const headers = {
      Perspective_Name: "Perspective",
      actions: "Actions"
    };

    return (
      <div>
        {this.state.showCreatePerspectiveModal && (
          <CreatePerspectiveModal
            isOpen={this.state.showCreatePerspectiveModal}
            handleModal={this.handleModal}
            customersData={this.props.customersData}
          />
        )}
        {this.state.showUpdatePerspectiveModal && (
        <CreatePerspectiveModal
          isOpen={this.state.showUpdatePerspectiveModal}
          handleModal={this.handleEdit}
          customersData={this.props.customersData}
          initialValues={this.state.initialValues}
        />
      )}
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="mt-3 d-lg-none sidebar-btn-landscape">
                  <SideBarToggle />
                </Col>
                <Col className="d-lg-none d-md-none">
                    <Button
                      color="primary"
                      onClick={(e) =>
                        this.setState({
                          showCreatePerspectiveModal: !this.state.showCreatePerspectiveModal,
                        })
                      }
                      className="mobile-button"
                    >
                      <span className="font-weight-bold">
                        {this.props.t("perspective.CREATE_PERSPECTIVE")}
                      </span>
                    </Button>
                </Col>
              </Row>
              <Row className="mt-4 perspective-head-card">
                <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                  <p className="font-weight-bold">
                    {this.props.t("perspective.TITLE")}
                  </p>
                </Col>
                <Col
                  lg={3}
                  md={3}
                  xs={12}
                  sm={12}
                  className="perspective-button ">
                  
                    <Button
                      color="primary"
                      onClick={(e) =>
                        this.setState({
                          showCreatePerspectiveModal: !this.state.showCreatePerspectiveModal,
                        })
                      }
                      className="create-team-button"
                    >

                      <span className="font-weight-bold">
                        {this.props.t("perspective.CREATE_PERSPECTIVE")}
                      </span>
                    </Button>
                  {/* )} */}
                </Col>
              </Row>
              <Card className="mt-4">
                <CardBody>
                  <ReactTable
                    loading={!this.props.isRequesting ? false : true}
                    loadingText={"Perspectives..."}
                    className="cursor-pointer"
                    data={data}
                    columns={[
                      {
                        columns: [
                          {
                            sortable: false,
                            Header: headers.Perspective_Name,
                            accessor: "Name_Perspective",
                            
                            Cell: (row) => (
                              <div onClick={() => this.navigatePages(row.original)}>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    {row.value}
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            sortable: false,
                            Header: headers.actions,
                            accessor: "UID_Perspective",
                            Cell: (row) => (
                              <div>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    <div>
                                        <div>
                                          <Button
                                                className="btn-sm"
                                                color="primary"
                                                round
                                                onClick={(e) =>
                                                  this.updatePerspective(
                                                    row.row._original
                                                  )
                                                }
                                              >
                                                  <i
                                                    className="fa fa-edit fa-1x cursor-pointer"
                                                    aria-hidden="true"
                                                    text="white"
                                                  />
                                              </Button>

                                            <Button
                                              className="btn-sm"
                                              color="danger"
                                              round
                                              onClick={(e) =>
                                                
                                                this.props.dispatch(deletePerspective({
                                                  perapectiveId: row.value
                                                }))
                                                
                                              }
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              />
                                            </Button>
                                          
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                        ],
                      },
                    ]}
                    manual
                    pages={this.props.meta.pageCount}
                    defaultPageSize={this.props.meta.pageSize}
                    pageSizeOptions={this.props.meta.pageSizeOptions}
                    onFetchData={(state, instance) => {
                      let qString;
                      if (state.sorted.length) {
                        qString = `&pageSize=${state.pageSize}&pageIndex=${
                          state.page
                        }&field=${state.sorted[0].id}&order=${
                          state.sorted[0].desc ? "desc" : "asc"
                        }`;
                      } else {
                        qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                      }
                      if ( qString) {
                        this.props.callApi(qString);
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#fff" }}
        />
      </div>
    );
  }
}
PerspectivesPage.propTypes = {
  callApi: PropTypes.func,
  meta: PropTypes.object,
  isRequesting: PropTypes.bool,
  perspectiveData: PropTypes.array,
  path: PropTypes.string,
  customersData: PropTypes.array
};
export default compose(translate, connect())(PerspectivesPage);
