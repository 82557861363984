/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import RuleDashboard from "../../Dashboard/Components/RuleDashboard";
import PropTypes from "prop-types";
import { getRuleTeam, deleteRule } from "../../../../../actions/ruleAction";
import { getAlerts } from "../../../../../actions/alertAction";
import {
  getTeams,
  unAssignRuleToTeam,
} from "../../../../../actions/teamAction";
import queryString from "query-string";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
  getNotificationData,
} from "../../../../../helpers/Utility";
import _ from "lodash";

class RuleDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssignTeamsModal: false,
      showUpdateRuleModal: false,
      showCreateNotificationModal: false,
      teamsDataByRule: [],
      alertsData: {},
      teamsData: null,
      initialValues: {},
      tooltipOpen: false,
      dropdownOpen: false,
      isRequesting: false,
      teamRuleId: null,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }
  componentDidMount = () => {
    let customerId = queryString.parse(this.props.searchParams).customerId;
    this.props.dispatch(getTeams(customerId));

    if (this.props.rule_id) {
      let formData = {
        type: "rule",
        id: this.props.rule_id,
      };
      this.props.dispatch(getAlerts(formData));
    }

    // Here we are extracting selected page and previous url path from url
    let searchString = this.props.history.location.search;
    let previousPathParse = searchString
      ? queryString.parse(searchString).currentPath
      : false;
    let previousPageParse = searchString
      ? queryString.parse(searchString).selectedPage
      : false;
    let previousPathExtract = _.replace(previousPathParse, /[~]/g, "&");
    let previousPath =
      previousPathExtract + `&selectedPage=${previousPageParse}`;
    this.setState({ previousPath });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.teamsData && nextProps.teamsData.Teams) {
      this.setState({
        teamsData: nextProps.teamsData.Teams, // teams data
      });
    }

    this.setState({
      isRequesting: nextProps.isRequesting,
    });
    if (nextProps.alertsData) {
      this.setState({
        alertsData: nextProps.alertsData,
      });
    }
    if (
      nextProps.alertsDataError &&
      nextProps.alertsDataError !== this.props.alertsDataError
    ) {
      showError("Something went wrong.");
    }
    if (nextProps.teamsDataByRule) {
      let teamsDataByRule = [];
      nextProps.teamsDataByRule.data.teamData.map((teamData, key) => {
        // this.getNotificationData(teamData.RuleNotifications);
        teamsDataByRule.push({
          Name_Team: teamData.Team.Name_Team,
          description: teamData.Team.Descrption_Team,
          actions: {
            teamRuleId: teamData.UID_TeamRule,
            teamName: teamData.Team.Name_Team,
            ruleName: teamData.Rule.Name_Rule,
          },
          types: getNotificationData(teamData.RuleNotifications),
        });
      });
      this.teamsDataByRule = teamsDataByRule;
      let meta = this.state.meta;
      // console.log(nextProps.teamsDataByRule.data.metaData);
      // if (nextProps.teamsDataByRule.metaData) {
      //   meta = {
      //     page: nextProps.teamsDataByRule.metaData.pageNumber,
      //     pageSize: nextProps.teamsDataByRule.metaData.pageSize,
      //     pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
      //     pageTotal: nextProps.teamsDataByRule.metaData.pageCount,
      //     total: nextProps.teamsDataByRule.metaData.totalItemCount
      //   };
      // }
      if (nextProps.teamsDataByRule.data.metaData) {
        meta = {
          page: nextProps.teamsDataByRule.data.metaData.pageNumber,
          pageSize: nextProps.teamsDataByRule.data.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.teamsDataByRule.data.metaData.pageCount,
          total: nextProps.teamsDataByRule.data.metaData.totalItemCount,
        };
      }
      this.setState({
        teamsDataByRule: teamsDataByRule,
        wholeData: nextProps.teamsDataByRule.data,
        meta: meta,
      });
    }
    if (
      nextProps.teamsDataByRuleError &&
      nextProps.teamsDataByRuleError !== this.props.teamsDataByRuleError
    ) {
      showError(this.props.t("error.DASHBOARD_ERROR"));
    }
    if (
      nextProps.deleteRuleSuccess &&
      nextProps.deleteRuleSuccess !== this.props.deleteRuleSuccess
    ) {
      showSuccess("Rule deleted successfully.");
      this.props.history.goBack();
    }
    if (
      nextProps.deleteRuleFailure &&
      nextProps.deleteRuleFailure !== this.props.deleteRuleFailure
    ) {
      showError("Something went wrong.");
    }
    if (
      nextProps.ruleUnAssignedToTeamSuccess &&
      nextProps.ruleUnAssignedToTeamSuccess !==
        this.props.ruleUnAssignedToTeamSuccess
    ) {
      showSuccess(nextProps.ruleUnAssignedToTeamSuccess);
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.state.meta.pageSize
      )
        ? this.state.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.state.meta.page -
        1}`;
      this.props.dispatch(getRuleTeam("ruleId", this.props.rule_id, qString));
    }
  };
  // Show Assign Teams Modal function
  showAssignTeamsModal = () => {
    this.setState({ showAssignTeamsModal: true });
  };

  // Handle Assign Teams Modal function
  handleAssignTeamsModal = () => {
    this.setState({ showAssignTeamsModal: !this.state.showAssignTeamsModal });
  };

  /*--------------this navigate function we are using to navigate pages from dashboard -----------*/
  navigatePage = (path, InstanceName, customerName, customerId) => {
    const Instance = InstanceName.split(" ").join("_");
    const customer = customerName.split(" ").join("_");
    // const Instance = queryString.parse(this.props.searchParams).instance;
    // const customer = queryString.parse(this.props.searchParams).customer;
    this.props.history.push(
      path +
        "/" +
        this.props.rule_id +
        "?instanceName=" +
        Instance +
        "&customerName=" +
        customer +
        "&customerId=" +
        customerId
    );
  };
  navigateNotification = (teamRuleId, teamRuleName, ruleName) => {
    const rule = ruleName.split(" ").join("_");
    const team = teamRuleName.split(" ").join("_");
    this.props.history.push(
      "/dashboard/notification/" +
        teamRuleId +
        "?rule=" +
        rule +
        "&teamName=" +
        team
    );
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showUpdateRuleModal: !this.state.showUpdateRuleModal,
    });
  };
  handleNotificationModal = (teamRuleId) => {
    this.setState({
      showCreateNotificationModal: !this.state.showCreateNotificationModal,
      teamRuleId: teamRuleId,
    });
  };
  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  /*----------for detele team from rule-------- */
  removeTeamFromRule = (teamRuleId) => {
    const confirm = window.confirm("Are you sure you want to delete team ?");
    if (confirm) {
      this.props.dispatch(unAssignRuleToTeam(teamRuleId));
    } else {
      return false;
    }
  };
  /*---------for delete and update rule ------------------*/
  deleteRule = () => {
    const confirm = window.confirm("Are you sure you want to delete rule ?");
    if (confirm) {
      this.props.dispatch(deleteRule(this.props.rule_id));
    } else {
      return false;
    }
  };
  editRule = (ruleData) => {
    let initialValues = {
      name: ruleData.Name_Rule,
      description: ruleData.Description_Rule ? ruleData.Description_Rule : "",
      priority: ruleData.Priority_Rule.toString(),
      ruleEnabled: ruleData.Rule_Enabled,
      ruleId: ruleData.UID_Rule,
    };
    this.setState({
      initialValues: initialValues,
      showUpdateRuleModal: true,
    });
  };
  /**calling this function for toggling tooltip */
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  /*---------render method---------------------- */
  render() {
    const searchParamsObject = queryString.parse(this.props.searchParams);
    const { customerName, customerId } = searchParamsObject;
    const name = searchParamsObject.instanceName
      ? searchParamsObject.instanceName
      : searchParamsObject.teamName;
    const customer = customerName.split("_").join(" ");
    const instance_team_name = name.split("_").join(" ");
    return (
      <div>
        <RuleDashboard
          stateValues={this.state}
          navigatePage={this.navigatePage}
          navigateNotification={this.navigateNotification}
          rule_id={this.props.rule_id}
          dropdownToggle={this.dropdownToggle}
          toggle={this.toggle}
          deleteRule={this.deleteRule}
          editRule={this.editRule}
          handleModal={this.handleModal}
          handleNotificationModal={this.handleNotificationModal}
          searchParams={this.props.searchParams}
          t={this.props.t}
          customerName={customer}
          name={instance_team_name}
          customerId={customerId}
          showAssignTeamsModal={this.showAssignTeamsModal}
          handleAssignTeamsModal={this.handleAssignTeamsModal}
          removeTeamFromRule={this.removeTeamFromRule}
        />
      </div>
    );
  }
}
/*---------Props validation---------------------- */
RuleDashboardContainer.propTypes = {
  history: PropTypes.object,
  rule_id: PropTypes.string,
  goBack: PropTypes.func,
  deleteRuleSuccess: PropTypes.bool,
  deleteRuleFailure: PropTypes.bool,
  teamsDataByRule: PropTypes.array,
  teamsDataByRuleError: PropTypes.bool,
  alertsData: PropTypes.object,
  alertsDataError: PropTypes.bool,
  dispatch: PropTypes.func,
  searchParams: PropTypes.string,
  ruleUnAssignedToTeamSuccess: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    deleteRuleSuccess: state.rule.deleteRuleSuccess,
    deleteRuleFailure: state.rule.deleteRuleFailure,
    teamsDataByRule: state.rule.teamsDataByRule,
    teamsDataByRuleError: state.rule.teamsDataByRuleError,
    alertsData: state.alert.alertsData,
    alertsDataError: state.alert.alertsDataError
      ? state.alert.alertsDataError
      : state.alert.alertsDataNetworkError,
    isRequesting: state.rule.isRequesting,
    teamsData: state.team.teamsData,
    ruleUnAssignedToTeamSuccess: state.team.ruleUnAssignedToTeamSuccess,
  };
}
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(RuleDashboardContainer);
