/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import TeamDashboard from "../Components/TeamDashboard";
import PropTypes from "prop-types";
import {
  teamStatistics,
  deleteTeam,
  updateTeamStatus
} from "../../../../../actions/teamAction";
import { showError, showSuccess } from "../../../../../helpers/Utility";

class TeamDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddTeamUserModal: false,
      showUpdateTeamModal: false
    };
  }
  componentDidMount = () => {
    if (this.props.team_id) {
      this.props.dispatch(teamStatistics(this.props.team_id));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.teamStatisticsData) {
      this.setState({
        teamStatisticsData: nextProps.teamStatisticsData,
        customerId: nextProps.teamStatisticsData
          ? nextProps.teamStatisticsData.teamData.Customer.UID_Customer
          : "",
        customerName: nextProps.teamStatisticsData
          ? nextProps.teamStatisticsData.teamData.Customer.Name_Customer
          : ""
      });
    }
    if (
      nextProps.teamStatisticsError &&
      nextProps.teamStatisticsError !== this.props.teamStatisticsError
    ) {
      this.setState({
        teamStatisticsError: nextProps.teamStatisticsError
      });
      showError(this.props.t("error.DASHBOARD_ERROR"));
    }
    if (
      nextProps.deleteTeamSuccess &&
      nextProps.deleteTeamSuccess !== this.props.deleteTeamSuccess
    ) {
      showSuccess(this.props.t("success.TEAM_DELETION_SUCCESS"));
      this.props.history.push(
        "/dashboard/teams/" +
          this.state.customerId +
          "?customerName=" +
          this.state.customerName.split(" ").join("_")
      );
    }
    if (
      nextProps.deleteTeamFailure &&
      nextProps.deleteTeamFailure !== this.props.deleteTeamFailure
    ) {
      showError("Something went wrong");
    }
    if (
      nextProps.teamStatusData &&
      nextProps.teamStatusData !== this.props.teamStatusData
    ) {
      showSuccess(this.props.t("success.TEAM_STATUS_SUCCESS"));
      this.props.dispatch(teamStatistics(this.props.team_id));
    }
    if (
      nextProps.teamStatusError &&
      nextProps.teamStatusError !== this.props.teamStatusError
    ) {
      showError(this.props.t("error.TEAM_STATUS_ERROR"));
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
  };

  /*--------------this navigate function we are using to navigate pages from dashboard -----------*/
  navigatePage = (path, teamName, customerName, customerId) => {
    const team = teamName.split(" ").join("_");
    const customer = customerName.split(" ").join("_");
    this.props.history.push(
      path +
        "/" +
        this.props.team_id +
        "?teamName=" +
        team +
        "&customerName=" +
        customer +
        "&customerId=" +
        customerId
    );
  };
  navigateToMembers = (path, roleValue) => {
    this.props.history.push(path + "/" + roleValue + "/" + this.props.team_id);
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({ showUpdateTeamModal: !this.state.showUpdateTeamModal });
  };
  handleAddModal = () => {
    this.setState({ showAddTeamUserModal: !this.state.showAddTeamUserModal });
  };
  /*----------for update team status---------------- */
  changeTeamStatus = (teamId, status) => {
    if (status) {
      const confirm = window.confirm("No notification will send to this team");
      if (confirm) {
        const teamData = {
          teamId: teamId,
          status: status ? false : true
        };
        this.props.dispatch(updateTeamStatus(teamData));
      }
    } else {
      const teamData = {
        teamId: teamId,
        status: status ? false : true
      };
      this.props.dispatch(updateTeamStatus(teamData));
    }
  };
  /*---------for delete and update team ------------------*/
  dropdownToggle = e => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  deleteTeam = () => {
    const confirm = window.confirm("Are you sure you want to delete Team ?");
    if (confirm) {
      this.props.dispatch(deleteTeam(this.props.team_id));
    } else {
      return false;
    }
  };
  /*--------initial Values-------*/
  editTeam = teamData => {
    let initialValues = {
      name: teamData.Name_Team,
      description: teamData.Description_Team ? teamData.Description_Team : "",
      teamLink: teamData.Microsoft_Team_Link
        ? teamData.Microsoft_Team_Link
        : "",
      teamId: teamData.UID_Team,
      customerId: teamData.UID_Customer
    };
    this.setState({
      initialValues: initialValues,
      showUpdateTeamModal: true
    });
  };
  addTeamUser = () => {
    this.setState({
      showAddTeamUserModal: true
    });
  };
  /*---------render method---------------------- */
  render() {
    return (
      <div>
        <TeamDashboard
          stateValues={this.state}
          navigatePage={this.navigatePage}
          navigateToMembers={this.navigateToMembers}
          teamStatistics={this.state.teamStatisticsData}
          team_id={this.props.team_id}
          dropdownToggle={this.dropdownToggle}
          deleteTeam={this.deleteTeam}
          editTeam={this.editTeam}
          addTeamUser={this.addTeamUser}
          changeTeamStatus={this.changeTeamStatus}
          handleModal={this.handleModal}
          handleAddModal={this.handleAddModal}
          isRequesting={this.state.isRequesting}
          searchParams={this.props.searchParams}
          t={this.props.t}
        />
      </div>
    );
  }
}
/*---------Props validation---------------------- */
TeamDashboardContainer.propTypes = {
  history: PropTypes.object,
  team_id: PropTypes.string,
  goBack: PropTypes.func,
  deleteTeamSuccess: PropTypes.bool,
  deleteTeamFailure: PropTypes.bool,
  teamStatisticsData: PropTypes.object,
  teamStatisticsError: PropTypes.bool,
  teamStatusError: PropTypes.bool,
  teamStatusData: PropTypes.bool,
  dispatch: PropTypes.func,
  searchParams: PropTypes.string
};
function mapStateToProps(state) {
  return {
    deleteTeamSuccess: state.team.deleteTeamSuccess,
    deleteTeamFailure: state.team.deleteTeamFailure,
    teamStatisticsData: state.team.teamStatisticsData,
    teamStatisticsError: state.team.teamStatisticsError,
    teamStatusData: state.team.teamStatusData,
    teamStatusError: state.team.teamStatusError,
    isRequesting: state.team.isRequesting
  };
}
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(TeamDashboardContainer);
