/* eslint-disable default-case */
import { RULE_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    isRequesting: false,
    isRequestingTwo: false,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case RULE_CONST.GET_RULES_BY_CRITERIA_REQUEST:
      return {
        ...state,
        rulesData: null,
        isRequesting: true,
        partnersDataError: false
      };
    case RULE_CONST.GET_RULES_BY_CRITERIA_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        // rulesData: action.payload.response.data.isTeam
        //   ? action.payload.response.data
        //   : null,
        // ruleDataOne: !action.payload.response.data.isTeam
        //   ? action.payload.response.data
        //   : null
        rulesData: action.payload.response.data,
        ruleNetworkError: false
      };
    case RULE_CONST.GET_RULES_BY_CRITERIA_FAILURE:
      return {
        ...state,
        isRequesting: false,
        rulesData: null,
        ruleNetworkError: action.payload.error ? true : false
      };
    case RULE_CONST.GET_RULES_FOR_INSTANCE_REQUEST: //getting rules for instance only(modal purpose)
      return {
        ...state,
        instanceRulesData: null,
        isRequesting: true,
        instanceRulesNetworkError: false
      };
    case RULE_CONST.GET_RULES_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        instanceRulesData: action.payload.response.data,
        instanceRulesNetworkError: false
      };
    case RULE_CONST.GET_RULES_FOR_INSTANCE_FAILURE:
      return {
        ...state,
        isRequesting: false,
        instanceRulesData: null,
        instanceRulesNetworkError: action.payload.error ? true : false
      };
    case RULE_CONST.CREATE_RULE_REQUEST:
      return {
        ...state,
        createRuleSuccess: null,
        createRuleFailure: false,
        createRuleNetworkFailure: false
      };
    case RULE_CONST.CREATE_RULE_SUCCESS:
      return {
        ...state,
        createRuleSuccess: action.payload.response
          ? action.payload.response.data.ruleId
          : null,
        createRuleFailure: false,
        createRuleNetworkFailure: false
      };
    case RULE_CONST.CREATE_RULE_FAILURE:
      return {
        ...state,
        createRuleSuccess: null,
        createRuleFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        createRuleNetworkFailure: action.payload.error ? true : false
      };
    case RULE_CONST.GET_TEAM_BY_RULE_REQUEST:
      return {
        ...state,
        teamsDataByRule: null,
        isRequesting: true,
        teamsDataByRuleError: false
      };
    case RULE_CONST.GET_TEAM_BY_RULE_SUCCESS:
      return {
        ...state,
        teamsDataByRule: action.payload.response.data,
        isRequesting: false,
        teamsDataByRuleError: false
      };
    case RULE_CONST.GET_TEAM_BY_RULE_FAILURE:
      return {
        ...state,
        teamsDataByRule: null,
        isRequesting: false,
        teamsDataByRuleError: action.payload ? true : false
      };
    case RULE_CONST.UPDATE_RULE_REQUEST:
      return {
        ...state,
        updateRuleSuccess: false,
        updateRuleFailure: false,
        updateRuleNetworkFailure: false
      };
    case RULE_CONST.UPDATE_RULE_SUCCESS:
      return {
        ...state,
        updateRuleSuccess: action.payload ? true : false,
        updateRuleFailure: false,
        updateRuleNetworkFailure: false
      };
    case RULE_CONST.UPDATE_RULE_FAILURE:
      return {
        ...state,
        updateRuleSuccess: false,
        updateRuleFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateRuleNetworkFailure: action.payload.error ? true : false
      };
    case RULE_CONST.DELETE_RULE_REQUEST:
      return {
        ...state,
        deleteRuleSuccess: false,
        deleteRuleFailure: false
      };
    case RULE_CONST.DELETE_RULE_SUCCESS:
      return {
        ...state,
        deleteRuleSuccess: action.payload ? true : false,
        deleteRuleFailure: false
      };
    case RULE_CONST.DELETE_RULE_FAILURE:
      return {
        ...state,
        deleteRuleSuccess: false,
        deleteRuleFailure: action.payload.error ? true : false
      };
    case RULE_CONST.GET_RULE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationsData: null,
        isRequestingTwo: true
      };
    case RULE_CONST.GET_RULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationsData: action.payload.response
          ? action.payload.response.data
          : null,
        isRequestingTwo: false
      };
    case RULE_CONST.GET_RULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationsData: null,
        isRequestingTwo: false
      };
    case RULE_CONST.CREATE_RULE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationCreatedSuccess: false,
        notificationNetworkError: false,
        notificationError: false
      };
    case RULE_CONST.CREATE_RULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationCreatedSuccess: action.payload.response ? true : false,
        notificationNetworkError: false,
        notificationError: false
      };
    case RULE_CONST.CREATE_RULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationCreatedSuccess: false,
        notificationNetworkError: action.payload.error ? true : false,
        notificationError: action.payload.response ? true : false
      };
    case RULE_CONST.UPDATE_RULE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationUpdateSuccess: null
      };
    case RULE_CONST.UPDATE_RULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...state,
        notificationUpdateSuccess: action.payload.response
          ? action.payload.response.data.Message
          : null
      };
    case RULE_CONST.UPDATE_RULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationUpdateSuccess: null
      };
    case RULE_CONST.DELETE_RULE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationDeleteSuccess: null
      };
    case RULE_CONST.DELETE_RULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationDeleteSuccess: action.payload.response
          ? action.payload.response.data.Message
          : null
      };
    case RULE_CONST.DELETE_RULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationDeleteSuccess: null
      };
  }
  return state;
}
