import React, { PureComponent } from "react";
import Reporting from "./Containers/ReportContainer";

class ReportPage extends PureComponent {
  render() {
    return (
      <div>
        <Reporting
          customerId={this.props.match.params.id}
          searchParams={this.props.location.search}
        />
      </div>
    );
  }
}

export default ReportPage;
