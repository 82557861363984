import React, { PureComponent } from "react";
import Information from "./Containers/InformationContainer";

class InformationPage extends PureComponent {
  render() {
    return (
      <div>
        <Information />
      </div>
    );
  }
}

export default InformationPage;
