import { TIMEZONE_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    timezoneData: [],
    countryCodeData: [],
    isRequesting: false,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case TIMEZONE_CONST.GET_TIMEZONE_REQUEST:
      return {
        ...state,
        timezoneData: null,
        isRequesting: true,
        timezoneDataError: false,
        timezoneDataNetworkError: false
      };
    case TIMEZONE_CONST.GET_TIMEZONE_SUCCESS:
      // console.log(action.payload.response.data);
      return {
        ...state,
        isRequesting: false,
        timezoneData: action.payload.response.data,
        timezoneDataError: false,
        timezoneDataNetworkError: false
      };
    case TIMEZONE_CONST.GET_TIMEZONE_FAILURE:
      return {
        ...state,
        timezoneData: null,
        isRequesting: false,
        timezoneDataError: action.payload.response ? true : false,
        timezoneDataNetworkError: action.payload.error ? true : false
      };
    case TIMEZONE_CONST.GET_COUNTRY_CODE_REQUEST:
      return {
        ...state,
        countryCodeData: null,
        isRequesting: true,
        countryCodeDataError: false,
        countryCodeDataNetworkError: false
      };
    case TIMEZONE_CONST.GET_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        countryCodeData: action.payload.response.data,
        countryCodeDataError: false,
        countryCodeDataNetworkError: false
      };
    case TIMEZONE_CONST.GET_COUNTRY_CODE_FAILURE:
      return {
        ...state,
        countryCodeData: null,
        isRequesting: false,
        countryCodeDataError: action.payload.response ? true : false,
        countryCodeDataNetworkError: action.payload.error ? true : false
      };
  }
  return state;
}
