import RegistrationPage from "views/Components/RegistrationComponent/Index.js";
import LoginPage from "../views/Components/LoginComponent/Index";
import ForgotPasswordPage from "../views/Components/ForgotPasswordComponent/Index";
import UpdatePasswordPage from "../views/Components/UpdatePasswordComponent";
import PrivacyPolicy from "../views/Components/PrivacyPolicyComponent";

const pagesRoutes = [
  {
    path: "/pages/invite/:id",
    name: "Registration Page",
    short: "Registration",
    mini: "RP",
    icon: "users_circle-08",
    component: RegistrationPage
  },
  {
    path: "/pages/login-page",
    name: "login Page",
    short: "login",
    mini: "LP",
    icon: "users_circle-08",
    component: LoginPage
  },
  {
    path: "/pages/forgot-password",
    name: "forgot password Page",
    short: "forgot",
    mini: "FP",
    icon: "users_circle-08",
    component: ForgotPasswordPage
  },
  {
    path: "/pages/update-password",
    name: "update password Page",
    short: "update",
    mini: "UP",
    icon: "users_circle-08",
    component: UpdatePasswordPage
  },
  {
    path: "/pages/privacy-policy",
    name: "Privacy Policy",
    short: "Privacy Policy",
    mini: "PP",
    icon: "users_circle-08",
    component: PrivacyPolicy
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/pages/login-page",
    name: "login Page"
  }
];

export default pagesRoutes;
