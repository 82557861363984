import React, { PureComponent } from "react";
import Dashboard from "./Containers/OrganisationDashboardContainer";

class OrganisationDashboard extends PureComponent {
  render() {
    return (
      <div>
        <Dashboard customer_id={this.props.match.params.id} />
      </div>
    );
  }
}

export default OrganisationDashboard;
