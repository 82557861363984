import React from "react";
import {
  Container,
  Col,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input
} from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import Loader from "../../Common/PageLoader";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import BackComponent from "../../Common/BackComponent";
import { Button } from "components";
import {
  showIssuesStatus,
  dateFormat,
  dateFormatWithTime
} from "../../../../helpers/Utility";
class IssueDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: ""
    };
  }
  renderComments = data => {
    if (data.length) {
      return data.map(alert => {
        return (
          <div key={alert.UID_Alert} className="text-left">
            <span className="text-muted font-weight-bold">
              <span className="badge badge-primary name-badge-styles mr-2">
                {alert.User.First_Name.charAt(0).toUpperCase()}
                {alert.User.Last_Name.charAt(0).toUpperCase()}
              </span>
              {alert.User.First_Name} {alert.User.Last_Name}
              <small className="ml-2">
                {dateFormatWithTime(alert.Created_Date)}
              </small>
            </span>
            <p>{alert.Comment_Data}</p>
          </div>
        );
      });
    }
  };
  createCommentText = e => {
    this.setState({
      commentText: e.target.value
    });
  };
  render() {
    const issueData = this.props.issueData ? this.props.issueData : null;
    return (
      <div>
        <Loader loading={issueData ? false : true} />
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                  <SideBarToggle />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={4} md={6} xs={12}>
                  <div>
                    <Breadcrumb
                      tag="nav"
                      listTag="div"
                      className="breadcrumb-style"
                    >
                      <BreadcrumbItem tag="a" href="javascript:void(0);">
                        <BackComponent name="Dashboard" morePrevious={true} />
                      </BreadcrumbItem>
                      <BreadcrumbItem tag="a" href="javascript:void(0);">
                        <BackComponent name="Issues" />
                      </BreadcrumbItem>
                      <BreadcrumbItem
                        active
                        tag="span"
                        className="font-weight-bold"
                      >
                        Details
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Card>
                    <CardHeader className="text-center font-weight-bold create-team-title">
                      {issueData ? issueData.Rule.Name_Rule : ""}
                    </CardHeader>
                    <CardBody className="text-center">
                      <Row className="mt-2">
                        <Col lg={6} md={6} xs={6} className="mx-auto">
                          <span className="text-muted font-weight-bold pull-right">
                            {" "}
                            Created Date:{" "}
                          </span>
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                          <span className="text-muted pull-left">
                            {issueData
                              ? dateFormat(issueData.Rule.Created_Date)
                              : ""}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={6} md={6} xs={6} className="mx-auto">
                          <span className="text-muted font-weight-bold pull-right">
                            Assigned To:
                          </span>
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                          <span className="text-muted pull-left">
                            {issueData
                              ? issueData.Assigned_To
                                ? issueData.User.First_Name +
                                  " " +
                                  issueData.User.Last_Name
                                : "N/A"
                              : ""}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={6} md={6} xs={6} className="mx-auto">
                          <span className="text-muted font-weight-bold pull-right">
                            Priority:
                          </span>
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                          <span className="text-muted pull-left">
                            {issueData
                              ? issueData.Rule.Priorities[0].Name_Priority
                              : ""}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={6} md={6} xs={6} className="mx-auto">
                          <span className="text-muted font-weight-bold pull-right">
                            Status:
                          </span>
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                          <span className="text-muted pull-left">
                            {issueData
                              ? showIssuesStatus(issueData.Status)
                              : ""}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={6} md={6} xs={6} className="mx-auto">
                          <span className="text-muted font-weight-bold pull-right">
                            {" "}
                            Comments:{" "}
                          </span>
                        </Col>
                        <Col lg={6} md={6} xs={6} className="text-left">
                          {issueData && issueData.AlertComments
                            ? this.renderComments(issueData.AlertComments)
                            : ""}
                          <Input
                            name="description"
                            type="text"
                            className="team-input-style w-75"
                            placeholder="Add comments"
                            value={this.state.commentText}
                            onChange={e => this.createCommentText(e)}
                          />
                          <div className="float-left">
                            <Button
                              color="primary"
                              onClick={e =>
                                this.props.createComment(
                                  issueData ? issueData.UID_Alert : "",
                                  this.state.commentText
                                )
                              }
                            >
                              Save
                            </Button>
                            <Button>Cancel</Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#fff" }}
        />
      </div>
    );
  }
}
IssueDetailsPage.propTypes = {
  searchIssue: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  issuesData: PropTypes.array,
  showIssueDetailsModal: PropTypes.bool,
  handleModal: PropTypes.func,
  issueDetails: PropTypes.object
};
export default compose(
  translate,
  connect()
)(IssueDetailsPage);
