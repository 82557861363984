import { AGENT_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    isRequesting: true,
    agentsData: null
  },
  action
) {
  switch (action.type) {
    case AGENT_CONST.GET_AGENT_REQUEST:
      return {
        ...state,
        isRequesting: true,
        agentsData: null,
        agentsDataError: false,
        agentsDataNetworkError: false
      };
    case AGENT_CONST.GET_AGENT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        agentsData: action.payload.response,
        agentsDataError: false,
        agentsDataNetworkError: false
      };
    case AGENT_CONST.GET_AGENT_FAILURE:
      return {
        ...state,
        isRequesting: false,
        agentsData: null,
        agentsDataError: action.payload.response ? true : false,
        agentsDataNetworkError: action.payload.error ? true : false
      };
    case AGENT_CONST.CREATE_AGENT_REQUEST:
      return {
        ...state,
        createAgentSuccess: false,
        createAgentFailure: false,
        createAgentNetworkFailure: false
      };
    case AGENT_CONST.CREATE_AGENT_SUCCESS:
      return {
        ...state,
        createAgentSuccess: action.payload.response ? true : false,
        createAgentFailure: false,
        createAgentNetworkFailure: false
      };
    case AGENT_CONST.CREATE_AGENT_FAILURE:
      return {
        ...state,
        createAgentSuccess: false,
        createAgentFailure: action.payload.response
          ? action.payload.response.data.error.errorDescription
          : false,
        createAgentNetworkFailure: action.payload.error ? true : false
      };
    case AGENT_CONST.DELETE_AGENT_REQUEST:
      return {
        ...state,
        deleteAgentSuccess: false,
        deleteAgentFailure: false,
        deleteAgentNetworkFailure: false
      };
    case AGENT_CONST.DELETE_AGENT_SUCCESS:
      return {
        ...state,
        deleteAgentSuccess: action.payload.response ? true : false,
        deleteAgentFailure: false,
        deleteAgentNetworkFailure: false
      };
    case AGENT_CONST.DELETE_AGENT_FAILURE:
      return {
        ...state,
        deleteAgentSuccess: false,
        deleteAgentFailure: action.payload.response
          ? action.payload.response.data.error.errorDescription
          : false,
        deleteAgentNetworkFailure: action.payload.error ? true : false
      };
    case AGENT_CONST.GET_CERTIFICATE_REQUEST:
      return {
        ...state,
        isRequestingTwo: true,
        agentCertificateSuccess: null,
        agentCertificateFailure: false
      };
    case AGENT_CONST.GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isRequestingTwo: false,
        agentCertificateSuccess: action.payload
          ? action.payload.response.data
          : null,
        agentCertificateFailure: false
      };
    case AGENT_CONST.GET_CERTIFICATE_FAILURE:
      return {
        ...state,
        isRequestingTwo: false,
        agentCertificateSuccess: null,
        agentCertificateFailure: action.payload.error ? true : false
      };
    case AGENT_CONST.UPDATE_AGENT_STATUS_REQUEST:
      return {
        ...state,
        agentStatusData: null,
        agentStatusError: false
      };
    case AGENT_CONST.UPDATE_AGENT_STATUS_SUCCESS:
      return {
        ...state,
        agentStatusData: action.payload.response.data,
        agentStatusError: false
      };
    case AGENT_CONST.UPDATE_AGENT_STATUS_FAILURE:
      return {
        ...state,
        agentStatusData: null,
        agentStatusError: action.payload ? true : false
      };
    case AGENT_CONST.UPDATE_AGENT_NAME_REQUEST:
      return {
        ...state,
        updateAgentNameSuccess: false,
        updateAgentNameFailure: false,
        updateAgentNameNetworkFailure: false
      };
    case AGENT_CONST.UPDATE_AGENT_NAME_SUCCESS:
      return {
        ...state,
        updateAgentNameSuccess: action.payload.response ? true : false,
        updateAgentNameFailure: false,
        updateAgentNameNetworkFailure: false
      };
    case AGENT_CONST.UPDATE_AGENT_NAME_FAILURE:
      return {
        ...state,
        updateAgentNameSuccess: false,
        updateAgentNameFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateAgentNameNetworkFailure: action.payload.error ? true : false
      };
  }
  return state;
}
