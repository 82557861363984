import React, { Fragment } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import {
  renderDate,
  renderTimePicker,
  renderTextField
} from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  createRuleNotification,
  updateRuleNotification,
  getRuleNotification
} from "../../../actions/ruleAction";
import {
  showError,
  showSuccess,
  daysArray,
  createGMTOffset,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import _ from "lodash";
import "rc-time-picker/assets/index.css";
class NotificationSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      modalClassic: false,
      loading: false,
      isEnableEveryDay: this.props.initialValues
        ? this.props.initialValues.isEveryDay
        : true,
      isEnableIsAllDay: this.props.initialValues
        ? this.props.initialValues.isAllDay
        : true,
      isEnableIsEmail: this.props.initialValues
        ? this.props.initialValues.isEmail
        : false,
      isEnablePush: this.props.initialValues
        ? this.props.initialValues.isPushNotification
        : false,
      isEnableWhatsapp: this.props.initialValues
        ? this.props.initialValues.isWhatsApp
        : false,
      isEnableTeam: this.props.initialValues
        ? this.props.initialValues.isTeam
        : false,
      multipleSelect: this.props.initialValues
        ? this.props.initialValues.daysList
        : [],
      timeZoneValue: this.props.initialTimeZoneValue
        ? this.props.initialTimeZoneValue
        : this.props.initialValues.timeZoneValue
        ? this.props.initialValues.timeZoneValue
        : this.props.initialTimeZoneValue2
    };
  }
  componentDidMount = () => {
    // console.log(this.props.timezonesData);
    let timeZoneData = [];
    if (this.props.timezonesData && this.props.timezonesData.length) {
      this.props.timezonesData.map((timezone, key) => {
        timeZoneData.push({
          value: timezone.UID_TimeZone,
          label: timezone.Zone_Name + createGMTOffset(timezone)
        });
      });
    }
    // console.log(timeZoneData);
    this.setState({
      timezonesData: timeZoneData
    });
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.notificationCreatedSuccess &&
      nextProps.notificationCreatedSuccess !==
        this.props.notificationCreatedSuccess
    ) {
      this.setState({
        loading: false
      });
      showSuccess("Notification Created Successfully.");
      this.props.handleModal();
      let qString = `?teamRuleId=${this.props.teamRuleId}&pageSize=${this.props.meta.pageSize}&pageIndex=${this.props.meta.page}`;
      this.props.dispatch(getRuleNotification(qString));
    }
    if (
      nextProps.notificationUpdateSuccess &&
      nextProps.notificationUpdateSuccess !==
        this.props.notificationUpdateSuccess
    ) {
      this.setState({
        loading: false
      });
      showSuccess(nextProps.notificationUpdateSuccess);
      this.props.handleModal();
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      let qString = `?teamRuleId=${
        this.props.teamRuleId
      }&pageSize=${pageSize}&pageIndex=${this.props.meta.page - 1}`;
      this.props.dispatch(getRuleNotification(qString));
    }
    if (
      nextProps.notificationError &&
      nextProps.notificationError !== this.props.notificationError
    ) {
      this.setState({
        loading: false
      });
      showError("Error creating notification.");
    }
  };
  /*call this function to change rule enabled state */
  validateRuleEnabled = value => {
    if (value === 1) {
      if (this.state.isEnableIsAllDay) {
        this.setState({
          isEnableIsAllDay: false
        });
      } else {
        this.setState({
          isEnableIsAllDay: true
        });
      }
    } else if (value === 2) {
      if (this.state.isEnableIsEmail) {
        this.setState({
          isEnableIsEmail: false
        });
      } else {
        this.setState({
          isEnableIsEmail: true
        });
      }
    } else if (value === 3) {
      if (this.state.isEnablePush) {
        this.setState({
          isEnablePush: false
        });
      } else {
        this.setState({
          isEnablePush: true
        });
      }
    } else if (value === 4) {
      if (this.state.isEnableWhatsapp) {
        this.setState({
          isEnableWhatsapp: false
        });
      } else {
        this.setState({
          isEnableWhatsapp: true
        });
      }
    } else if (value === 5) {
      if (this.state.isEnableTeam) {
        this.setState({
          isEnableTeam: false
        });
      } else {
        this.setState({
          isEnableTeam: true
        });
      }
    } else {
      if (this.state.isEnableEveryDay) {
        this.setState({
          isEnableEveryDay: false
        });
      } else {
        this.setState({
          isEnableEveryDay: true
        });
      }
    }
  };
  /*-------------modify time--------*/
  modifyTime = (time, zoneName) => {
    let startTime = momentTimeZone.tz("1970-01-01T" + time, zoneName).format();
    if (startTime && startTime.includes("+")) {
      startTime = startTime.replace(/-([^+]*)$/, "-$1");
    } else {
      startTime = startTime.replace(/-([^-]*)$/, "+$1");
    }
    return new Date(startTime);
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    const selectedTimeZone = _.find(this.props.timezonesData, {
      UID_TimeZone: this.state.timeZoneValue.value
    });
    // console.log(selectedTimeZone);
    const daysList = [];
    if (this.state.multipleSelect) {
      this.state.multipleSelect.map(value => {
        daysList.push(value.value);
      });
    }
    /*here creating data which is passing to API*/
    const formData = {
      daysList: this.state.isEnableEveryDay ? [] : daysList,
      teamRuleId: this.props.teamRuleId,
      endTime: this.props.initialValues
        ? this.state.isEnableIsAllDay
          ? ""
          : formProps.endTime !== "" && typeof formProps.endTime === "string"
          ? this.modifyTime(formProps.endTime, selectedTimeZone.Zone_Name)
          : formProps.endTime
          ? this.modifyTime(formProps.endTime, selectedTimeZone.Zone_Name)
          : ""
        : formProps.endTime
        ? this.modifyTime(formProps.endTime, selectedTimeZone.Zone_Name)
        : "",
      startTime: this.props.initialValues
        ? this.state.isEnableIsAllDay
          ? ""
          : formProps.startTime !== "" &&
            typeof formProps.startTime === "string"
          ? this.modifyTime(formProps.startTime, selectedTimeZone.Zone_Name)
          : formProps.startTime
          ? this.modifyTime(formProps.startTime, selectedTimeZone.Zone_Name)
          : ""
        : formProps.startTime
        ? this.modifyTime(formProps.startTime, selectedTimeZone.Zone_Name)
        : "",
      isAllDay: this.state.isEnableIsAllDay,
      isEveryDay: this.state.isEnableEveryDay,
      isPushNotification: this.state.isEnablePush,
      isWhatsApp: this.state.isEnableWhatsapp,
      isEmail: this.state.isEnableIsEmail,
      isTeam: this.state.isEnableTeam,
      timeZoneId: this.state.timeZoneValue.value
    };
    if (
      formData.isAllDay ||
      (formData.endTime !== "" && formData.startTime !== "")
    ) {
      if (
        formData.isEmail ||
        formData.isPushNotification ||
        formData.isWhatsApp ||
        formData.isTeam
      ) {
        if (formData.isEveryDay || formData.daysList.length > 0) {
          if (this.props.initialValues) {
            formData.ruleNotificationId = this.props.initialValues.ruleNotificationId;
            this.setState({
              loading: true
            });
            this.props.dispatch(updateRuleNotification(formData)); //update rule notification end point
          } else {
            this.setState({
              loading: true
            });
            this.props.dispatch(createRuleNotification(formData)); //create rule notification end point
          }
        } else {
          showError("Minimum one day is mandatory.");
        }
      } else {
        showError("Any one notification type is mandatory.");
      }
    } else {
      showError("All day or Time is mandatory.");
    }
  };
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  setOpen = ({ open }) => {
    this.setState({ open });
  };
  render() {
    // console.log(this.props.timezonesData);
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues
              ? "Update Notification Settings"
              : "Create Notification Settings"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              {!this.state.isEnableIsAllDay && (
                <Fragment>
                  <Row>
                    <Col lg={4} md={4} xs={5} className="text-right">
                      <label className="text-muted">Timezone</label>
                    </Col>
                    <Col lg={8} md={8} xs={7}>
                      <FormGroup>
                        <Select
                          id="multiselect"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Select timezone"
                          name="timeZoneValue"
                          options={this.state.timezonesData}
                          value={this.state.timeZoneValue}
                          onChange={value =>
                            this.setState({ timeZoneValue: value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} xs={5} className="text-right">
                      <label className="text-muted">Start time*</label>
                    </Col>
                    <Col lg={8} md={8} xs={7}>
                      <FormGroup>
                        <Field
                          component={renderTextField}
                          name="startTime"
                          type="time"
                          className="cursor-pointer"
                        />
                        {/* <Field
                          component={renderDate}
                          name="startTime"
                          className="cursor-pointer"
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} xs={5} className="text-right">
                      <label className="text-muted">End time*</label>
                    </Col>
                    <Col lg={8} md={8} xs={7}>
                      <FormGroup>
                        {/* <Field
                          component={renderDate}
                          name="endTime"
                          className="cursor-pointer"
                        /> */}
                        {/* <Field
                          component={renderTimePicker}
                          name="endTime"
                          //className="cursor-pointer"
                          onChange={this.onChange}
                        /> */}
                        {/* <TimePicker
                          open={this.state.open}
                          onOpen={this.setOpen}
                          onClose={this.setOpen}
                          timeFormat={"HH:mm"} //by using HH we can get 24 hours time format
                          style={{ width: 100 }}
                          showSecond={false}
                          defaultValue={moment()}
                          placeholder="Please select time"
                        /> */}
                        <Field
                          component={renderTextField}
                          name="endTime"
                          type="time"
                          className="cursor-pointer"
                          // onChange={this.onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              )}
              <Row>
                <Col
                  lg={12}
                  md={12}
                  xs={12}
                  className="pt-2 position-style-class-three"
                >
                  {/* <label className="ccheck position-style"> */}
                  <Row>
                    <Col lg={4} md={4} xs={4} />
                    <Col lg={8} md={8} xs={8}>
                      <label className="ccheck position-style">
                        <Row>
                          <Col lg={1} md={1} xs={1}>
                            <Field
                              component="input"
                              name="isAllDay"
                              type="checkbox"
                              checked={this.state.isEnableIsAllDay}
                              onClick={e => this.validateRuleEnabled(1)}
                            />
                            <span className="checkmark checkmark-two" />
                          </Col>
                          <Col
                            lg={11}
                            md={11}
                            xs={11}
                            className="position-style-class-three"
                          >
                            All day
                          </Col>
                        </Row>
                      </label>
                    </Col>
                  </Row>
                  {/* </label> */}
                </Col>
              </Row>
              {/*Every day option is added here */}
              <Row>
                <Col
                  lg={12}
                  md={12}
                  xs={12}
                  className="pt-2 position-style-class-three"
                >
                  {/* <label className="ccheck position-style"> */}
                  <Row>
                    <Col lg={4} md={4} xs={4} />
                    <Col lg={8} md={8} xs={8}>
                      <label className="ccheck position-style">
                        <Row>
                          <Col lg={1} md={1} xs={1}>
                            <Field
                              component="input"
                              name="everyDay"
                              type="checkbox"
                              checked={this.state.isEnableEveryDay}
                              onClick={e => this.validateRuleEnabled()}
                            />
                            <span className="checkmark checkmark-two" />
                          </Col>
                          <Col
                            lg={11}
                            md={11}
                            xs={11}
                            className="position-style-class-three"
                          >
                            Every day
                          </Col>
                        </Row>
                      </label>
                    </Col>
                  </Row>
                  {/* </label> */}
                </Col>
              </Row>
              {!this.state.isEnableEveryDay && (
                <Row>
                  <Col lg={4} md={4} xs={4} />
                  <Col lg={8} md={8} xs={8}>
                    <Select
                      id="multiselect"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      isMulti
                      placeholder="Select particular day"
                      name="multiSelect"
                      options={daysArray}
                      value={this.state.multipleSelect}
                      onChange={value =>
                        this.setState({ multipleSelect: value })
                      }
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col
                  lg={12}
                  md={12}
                  xs={12}
                  className="pt-2 mx-auto position-style-class-three"
                >
                  {/* <label className="ccheck position-style"> */}
                  <Row>
                    <Col lg={4} md={4} xs={4} />
                    <Col lg={8} md={8} xs={8}>
                      <label className="ccheck position-style">
                        <Row>
                          <Col lg={1} md={1} xs={1}>
                            <Field
                              component="input"
                              name="isEmail"
                              type="checkbox"
                              checked={this.state.isEnableIsEmail}
                              onClick={e => this.validateRuleEnabled(2)}
                            />
                            <span className="checkmark checkmark-two" />
                          </Col>
                          <Col
                            lg={11}
                            md={11}
                            xs={11}
                            className="position-style-class-three"
                          >
                            Send email notification
                          </Col>
                        </Row>
                      </label>
                    </Col>
                  </Row>
                  {/* </label> */}
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  xs={12}
                  className="pt-2 mx-auto position-style-class-three"
                >
                  {/* <label className="ccheck position-style"> */}
                  <Row>
                    <Col lg={4} md={4} xs={4} />
                    <Col lg={8} md={8} xs={8}>
                      <label className="ccheck position-style">
                        <Row>
                          <Col lg={1} md={1} xs={1}>
                            <Field
                              component="input"
                              name="isPushNotification"
                              type="checkbox"
                              checked={this.state.isEnablePush}
                              onClick={e => this.validateRuleEnabled(3)}
                            />
                            <span className="checkmark checkmark-two" />
                          </Col>
                          <Col
                            lg={11}
                            md={11}
                            xs={11}
                            className="position-style-class-three"
                          >
                            Send push notification
                          </Col>
                        </Row>
                      </label>
                    </Col>
                  </Row>
                  {/* </label> */}
                </Col>
              </Row>
              {/* <Row>
                <Col
                  lg={12}
                  md={12}
                  xs={12}
                  className="pt-2 mx-auto position-style-class-three"
                >
                  <Row>
                    <Col lg={4} md={4} xs={4} />
                    <Col lg={8} md={8} xs={8}>
                      <label className="ccheck position-style">
                        <Row>
                          <Col lg={1} md={1} xs={1}>
                            <Field
                              component="input"
                              name="isPushNotification"
                              type="checkbox"
                              checked={this.state.isEnableWhatsapp}
                              onClick={e => this.validateRuleEnabled(4)}
                            />
                            <span className="checkmark checkmark-two" />
                          </Col>
                          <Col
                            lg={11}
                            md={11}
                            xs={11}
                            className="position-style-class-three"
                          >
                            Send whatsapp notification
                          </Col>
                        </Row>
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row> */}

              <Row>
                <Col
                  lg={12}
                  md={12}
                  xs={12}
                  className="pt-2 mx-auto position-style-class-three"
                >
                  {/* <label className="ccheck position-style"> */}
                  <Row>
                    <Col lg={4} md={4} xs={4} />
                    <Col lg={8} md={8} xs={8}>
                      <label className="ccheck position-style">
                        <Row>
                          <Col lg={1} md={1} xs={1}>
                            <Field
                              component="input"
                              name="isTeam"
                              type="checkbox"
                              checked={this.state.isEnableTeam}
                              onClick={e => this.validateRuleEnabled(5)}
                            />
                            <span className="checkmark checkmark-two" />
                          </Col>
                          <Col
                            lg={11}
                            md={11}
                            xs={11}
                            className="position-style-class-three"
                          >
                            Send Team notification
                          </Col>
                        </Row>
                      </label>
                    </Col>
                  </Row>
                  {/* </label> */}
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} xs={12} className="text-right pr-5">
                  <Button
                    color="primary"
                    loading={this.state.loading}
                    type="submit"
                    className="ml-5"
                  >
                    <span className="font-weight-bold">
                      {this.props.initialValues ? "Update" : "Create"}
                    </span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
NotificationSettingsModal.propTypes = {
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
  initialValues: PropTypes.object,
  notificationUpdateSuccess: PropTypes.string,
  notificationCreatedSuccess: PropTypes.string,
  notificationError: PropTypes.bool,
  teamRuleId: PropTypes.string,
  timezonesData: PropTypes.array
};
function mapStateToProps(state) {
  return {
    notificationCreatedSuccess: state.rule.notificationCreatedSuccess,
    notificationError: state.rule.notificationError
      ? state.rule.notificationError
      : state.rule.notificationNetworkError,
    notificationUpdateSuccess: state.rule.notificationUpdateSuccess
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createNotification-form"
  }),
  connect(mapStateToProps)
)(NotificationSettingsModal);
