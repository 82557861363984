import React from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CardCategory } from "components";
import PropTypes from "prop-types";
class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderData() {
    if (this.props.cardData) {
      return this.props.cardData.map((row, key) => {
        return (
          <tr key={key}>
            <td>{row.info}</td>
            <td>
              <span className="badge badge-primary">{row.value}</span>
            </td>
          </tr>
        );
      });
    }
  }
  navigatePages = () => {
    if (this.props.path) {
      if (this.props.roleValue) {
        this.props.navigatePage(this.props.path, this.props.roleValue); //not working need to remove
      } else {
        this.props.navigatePage(
          this.props.path,
          this.props.teamName,
          this.props.customerName,
          this.props.customerId
        );
      }
    } else {
      return false;
    }
  };
  render() {
    return (
      <div className="card-parent">
        <Card
          className={
            this.props.isShowPointer
              ? "card-shadow margin-bottom-style"
              : "card-shadow2 margin-bottom-style"
          }
          onClick={
            e => this.navigatePages()
            // this.props.path && this.props.roleValue
            //   ? this.props.navigatePage(this.props.path, this.props.roleValue)
            //   : this.props.navigatePage(this.props.path)
          }
        >
          {!this.props.remove && (
            <CardHeader className={"card-header-background"}>
              <CardCategory>
                <i className={"fa " + this.props.icon} aria-hidden="true" />
                {"  "}
                {this.props.title}
              </CardCategory>
            </CardHeader>
          )}
          <CardBody className={this.props.showClasses ? "card-data-body" : ""}>
            {/* className={!this.props.remove ? "card-body-scroll" : "" } */}
            {!this.props.remove && (
              <Table
                responsive
                className={
                  this.props.showClasses
                    ? "table-data issues-table"
                    : "table-data"
                }
              >
                <tbody>{this.renderData()}</tbody>
              </Table>
            )}
            {this.props.remove && (
              <div className="text-center pb-5 pt-5">
                <i
                  className={"fa " + this.props.icon + " fa-5x pb-2 icon-color"}
                  aria-hidden="true"
                />
                <br />
                <span className="font-weight-bold">{this.props.title}</span>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
CardComponent.propTypes = {
  title: PropTypes.string,
  info1: PropTypes.string,
  info2: PropTypes.string,
  value1: PropTypes.number,
  value2: PropTypes.number,
  icon: PropTypes.string,
  path: PropTypes.string,
  navigatePage: PropTypes.func,
  navigateToMembers: PropTypes.func,
  remove: PropTypes.bool,
  roleValue: PropTypes.number
};
export default CardComponent;
