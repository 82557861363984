import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  Form,
  Button
} from "reactstrap";
import { reduxForm, Field } from "redux-form";

class OrgDashboardSettingsModal extends React.Component {
  handleSettingsModal = () => {
    this.props.handleSettingsModal();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleSettingsModal}
          className="text-center top-space"
        >
          <ModalHeader
            toggle={this.handleSettingsModal}
            className="justify-content-left title create-team-title"
            tag="p"
          >
            Azure Active Directory Settings
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col lg={9} md={9} className="mx-auto">
                  <Row>
                    <Col className="pt-2 remove-select-styles">
                      <FormGroup className="wrapper-style">
                        <Label className="pull-left">Domain Name</Label>
                        <Field
                          name="domainName"
                          component="input"
                          type="text"
                          className="pull-right"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pt-2">
                      <FormGroup>
                        <Label className="pull-left">Certificate</Label>
                        <Field
                          name="certificates"
                          component="input"
                          type="text"
                          className="pull-right"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="ml-auto pl-5">
                      <Button
                        color="primary"
                        type="submit"
                        className="ml-5 font-weight-bold"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default OrgDashboardSettingsModal;

/*
<div>
    <button onClick={this.handleSettingsModal}>
        Organisation Dashboard Setings
    </button>
</div>
*/
