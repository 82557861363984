/**
 * @action        : alertAction
 * @description   : for get the alert data
 * @Created by    : INNOMICK
 */

import { ALERT_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [alert description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */

export function getAlertDetails(alertId) {
  return dispatch => {
    dispatch(base.getRequest(ALERT_CONST.GET_ALERT_DETAILS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/AlertDetails/${alertId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(ALERT_CONST.GET_ALERT_DETAILS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ALERT_CONST.GET_ALERT_DETAILS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ALERT_CONST.GET_ALERT_DETAILS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
/*action for create alert comment */
export function createAlertComment(formData) {
  return dispatch => {
    dispatch(base.getRequest(ALERT_CONST.CREATE_ALERT_COMMENT_REQUEST));
    AXIOS_INSTANCE.post(`${BASE_URL}/Comment`, formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(ALERT_CONST.CREATE_ALERT_COMMENT_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ALERT_CONST.CREATE_ALERT_COMMENT_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ALERT_CONST.CREATE_ALERT_COMMENT_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

//action for get alerts
export function getAlerts(formData) {
  return dispatch => {
    dispatch(base.getRequest(ALERT_CONST.GET_ALERTS_REQUEST));
    AXIOS_INSTANCE.get(
      `${BASE_URL}/Alerts?type=${formData.type}&id=${formData.id}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(ALERT_CONST.GET_ALERTS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ALERT_CONST.GET_ALERTS_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ALERT_CONST.GET_ALERTS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

/*action for assign alert to user */
export function assignAlertToUser(formData) {
  return dispatch => {
    dispatch(base.getRequest(ALERT_CONST.ASSIGN_ALERT_TO_USER_REQUEST));
    AXIOS_INSTANCE.post(`${BASE_URL}/assignAlertToUser`, formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(ALERT_CONST.ASSIGN_ALERT_TO_USER_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ALERT_CONST.ASSIGN_ALERT_TO_USER_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ALERT_CONST.ASSIGN_ALERT_TO_USER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

/*action for un assign alert*/
export function unAssignAlert(formData) {
  return dispatch => {
    dispatch(base.getRequest(ALERT_CONST.UNASSIGN_ALERT_TO_USER_REQUEST));
    AXIOS_INSTANCE.put(`${BASE_URL}/unassignAlert`, formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(ALERT_CONST.UNASSIGN_ALERT_TO_USER_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ALERT_CONST.UNASSIGN_ALERT_TO_USER_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ALERT_CONST.UNASSIGN_ALERT_TO_USER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

/*action for get user list*/
export function getUserList(instanceId) {
  return dispatch => {
    dispatch(base.getRequest(ALERT_CONST.GET_USER_LIST_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/UserList/${instanceId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(ALERT_CONST.GET_USER_LIST_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ALERT_CONST.GET_USER_LIST_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ALERT_CONST.GET_USER_LIST_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
