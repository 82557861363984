import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Form
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderSelectField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { getRulesByCriteria } from "../../../actions/ruleAction";
import { assignRuleToTeam } from "../../../actions/teamAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";
import SelectSearch from "react-select-search";
class AssignRuleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesData: [
        // {
        //   name: "Search rule",
        //   value: 0
        // }
      ],
      loading: false
    };
    this.ruleId = "";
  }
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  /**here we are hitting api based on instance id */
  handleInstanceChange = e => {
    let formData = {};
    document.getElementById("exampleSelect").value = "instances";
    formData = {
      name: "instance",
      id: e.target.value
    };
    this.props.dispatch(getRulesByCriteria(formData, true));
  };
  handleSelectChange = (value, state, props) => {
    this.ruleId = value.value;
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.rulesData) {
      let rules = [];
      nextProps.rulesData.modifiedRule.map((rule, key) => {
        rules.push({
          name: rule.Name_Rule,
          value: rule.UID_Rule
        });
      });
      this.setState({
        rulesData: rules
      });
    }
    if (
      nextProps.assignRuleToTeamSuccess &&
      nextProps.assignRuleToTeamSuccess !== this.props.assignRuleToTeamSuccess
    ) {
      showSuccess("Rule has been successfully assigned to team");
      this.setState({
        loading: false
      });
      this.props.handleModal();
      let formData = {};
      formData = {
        name: "team",
        id: this.props.id
      };
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${0}`;
      this.props.dispatch(getRulesByCriteria(formData, false, qString));
    }
    if (
      nextProps.assignRuleToTeamFailure &&
      nextProps.assignRuleToTeamFailure !== this.props.assignRuleToTeamFailure
    ) {
      showError(nextProps.assignRuleToTeamFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.assignRuleToTeamNetworkFailure &&
      nextProps.assignRuleToTeamNetworkFailure !==
        this.props.assignRuleToTeamNetworkFailure
    ) {
      showError("Something went wrong.");
      this.setState({
        loading: false
      });
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    if (formProps.instance) {
      if (formProps) {
        let formData = {};
        formData.teamId = this.props.id ? this.props.id : "";
        formData.ruleId = this.ruleId;
        if (formData.ruleId !== "") {
          this.props.dispatch(assignRuleToTeam(formData));
          this.setState({
            loading: true
          });
        } else {
          showError("Please select rule.");
        }
      }
    } else {
      showError("Please select instance.");
    }
  };
  /**render function */
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            Assign Rule
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row>
                <Col lg={9} md={9} className="mx-auto">
                  <Row>
                    <Col className="pt-2 remove-select-styles">
                      <FormGroup>
                        <Label className="pull-left">Select Instance</Label>
                        <Field
                          name="instance"
                          // className="select-instance"
                          type="select"
                          component={renderSelectField}
                          options={this.props.instanceData}
                          onChange={e => this.handleInstanceChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pt-2 spinner-parent">
                      <SelectSearch
                        options={this.state.rulesData}
                        isSearchable={false}
                        name="rule"
                        placeholder="Select Rule"
                        height={200}
                        onChange={this.handleSelectChange}
                      />
                      {this.state.isRequesting ? (
                        <span className="spinner-child">
                          <i className="fa fa-2x fa-spinner fa-pulse" />
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="ml-auto pl-5">
                      <Button
                        color="primary"
                        loading={this.state.loading}
                        type="submit"
                        className="ml-5"
                      >
                        Assign
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
AssignRuleModal.propTypes = {
  handleModal: PropTypes.func,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  assignRuleToTeamSuccess: PropTypes.string,
  assignRuleToTeamFailure: PropTypes.string,
  assignRuleToTeamNetworkFailure: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    rulesData: state.rule.instanceRulesData,
    isRequesting: state.rule.isRequesting,
    assignRuleToTeamSuccess: state.team.ruleAssignedToTeamSuccess,
    assignRuleToTeamFailure: state.team.ruleAssignedToTeamFailure,
    assignRuleToTeamNetworkFailure: state.team.ruleAssignedToTeamNetworkFailure
  };
}
export default compose(
  translate,
  reduxForm({
    form: "assignRule-form"
  }),
  connect(mapStateToProps)
)(AssignRuleModal);
