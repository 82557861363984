import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
  FormGroup
} from "reactstrap";
import { Field } from "redux-form";
import { renderTextField } from "../../Common/InputRenderer";
import nowLogo from "assets/img/png/Argos.png";
import footerLogo from "assets/img/png/argos_footer.png";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "reactstrap-button-loader";
import compose from "compose-function";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import { AzureAD, AuthenticationState } from "react-aad-msal";
// Import the provider created in a different file
import { authProvider } from "../../../Components/authProvider";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <div>
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="logo-container logo-container">
                      <img src={nowLogo} alt="now-logo" />
                    </div>
                    <Card className="card-login custom-card">
                      <CardBody>
                        <p className="text-center text-white py-3">
                          {/* {"Login into your account"} */}
                          {this.props.t("login.TITLE")}
                        </p>
                        <Row>
                          <Col md="10" className="mx-auto">
                            <FormGroup
                              className={
                                this.props.login.emailState
                                  ? "has-label " + this.props.login.emailState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="text"
                                name="email"
                                placeholder={this.props.t(
                                  "login.EMAIL_PLACEHOLDER"
                                )}
                                onFocus={e =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={e => this.props.validateEmail(e)}
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.login.passwordState
                                  ? "has-label " +
                                    this.props.login.passwordState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="password"
                                name="password"
                                placeholder={this.props.t(
                                  "login.PASSWORD_PLACEHOLDER"
                                )}
                                onFocus={e =>
                                  this.setState({ lastnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ lastnameFocus: false })
                                }
                                onChange={e => this.props.validatePassword(e)}
                              />
                            </FormGroup>
                            <Button
                              block
                              color="primary"
                              type="submit"
                              className="mb-3"
                              loading={this.props.loading}
                            >
                              {this.props.t("login.LOGIN_BUTTON")}
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <div className="text-center">
                          <h5>
                            <a
                              onClick={e => this.props.forgotPassword()}
                              className="link footer-link text-white cursor-pointer font-weight-bold font-size-css"
                            >
                              {this.props.t("login.FORGOT_PASSWORD")}
                            </a>
                          </h5>
                        </div>
                      </CardFooter>
                    </Card>
                  </Form>
                  {/* <div className="logo-container mt-4">
                    <img src={footerLogo} alt="now-logo" />
                  </div> */}
                  <div className="footer-text">
                    <Link to="/pages/privacy-policy">
                      {this.props.t("login.PRIVACY_TERMS")}
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#40535D" }}
        />
      </div>
    );
  }
}
LoginPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  forgotPassword: PropTypes.func,
  validatePassword: PropTypes.func,
  validateEmail: PropTypes.func,
  login: PropTypes.object,
  userScope: PropTypes.object,
  language: PropTypes.object
};
export default compose(translate, connect())(LoginPage);
