/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import {
  getPartners,
  deletePartner,
  updatePartnerStatus,
} from "../../../../actions/partnerAction";
import Partner from "../Components/PartnerPage.jsx";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../../../../helpers/Utility";
import PropTypes from "prop-types";
class PartnerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: {
        showSelectUserRoleModal: false,
      },
      partnerData: [],
      partnerTableData: null,
      partnersDataError: false,
      showCreatePartnerModal: false,
      showUpdatePartnerModal: false,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      isRequesting: nextProps.isRequesting,
    });
    if (nextProps.partnersData) {
      let partnerTableData = [];
      nextProps.partnersData.data.map((partnerData, key) => {
        partnerTableData.push({
          Name_Partner: partnerData.Name_Partner,
          role: partnerData.roles,
          Created_Date: partnerData.Created_Date,
          actions: {
            id: partnerData.UID_Partner,
            name: partnerData.Name_Partner,
            status: partnerData.Status,
          },
        });
      });
      this.partnerTableData = partnerTableData;
      let meta = this.state.meta;
      if (nextProps.partnersData.metaData) {
        meta = {
          page: nextProps.partnersData.metaData.pageNumber,
          pageSize: nextProps.partnersData.metaData.isLastPage
            ? this.state.meta.pageSize
            : nextProps.partnersData.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.partnersData.metaData.isLastPage
            ? this.state.meta.pageTotal
            : nextProps.partnersData.metaData.pageCount,
          total: nextProps.partnersData.metaData.totalItemCount,
        };
      }
      this.setState({
        wholeData: nextProps.partnersData,
        partnerTableData: partnerTableData,
        partnerTableSearchData: partnerTableData,
        meta: meta,
      });
    }
    if (
      nextProps.partnersDataError &&
      nextProps.partnersDataError !== this.props.partnersDataError
    ) {
      showError(this.props.t("error.PARTNERS_GETTING_ERROR"));
      this.setState({
        partnersDataError: true,
      });
    }
    if (
      nextProps.deletePartnerSuccess &&
      nextProps.deletePartnerSuccess !== this.props.deletePartnerSuccess
    ) {
      showSuccess("Partner deleted successfully.");
      const qString = `?pageSize=${this.state.meta.pageSize}&pageIndex=${this
        .state.meta.page - 1}`;
      this.props.dispatch(getPartners(qString));
    }
    if (
      nextProps.deletePartnerFailure &&
      nextProps.deletePartnerFailure !== this.props.deletePartnerFailure
    ) {
      showError("Error in deleting partner.");
    }
    if (
      nextProps.partnerStatusData &&
      nextProps.partnerStatusData !== this.props.partnerStatusData
    ) {
      showSuccess("Partner status has been changed successfully.");
      const qString = `?pageSize=${this.state.meta.pageSize}&pageIndex=${this
        .state.meta.page - 1}`;
      this.props.dispatch(getPartners(qString));
    }
    if (
      nextProps.partnerStatusError &&
      nextProps.partnerStatusError !== this.props.partnerStatusError
    ) {
      showError("Error in changing partner status.");
    }
  };
  /*-------------here we are navigating members page-----------------------*/
  showMembers = (partnerId, partnerName) => {
    this.props.history.push(
      "/dashboard/partnerUsers/" +
        partnerId +
        "?name=" +
        partnerName.split(" ").join("_")
    );
  };
  /*------------this function we are using to search partners------------- */
  searchPartner = (event) => {
    if (event.target.value) {
      const result = this.state.partnerTableSearchData.filter(
        function(partner) {
          return (
            true ===
            partner.Name_Partner.toLowerCase().includes(
              event.target.value.toLowerCase()
            )
          );
        }.bind(this)
      );
      this.setState({
        partnerTableData: result,
      });
    } else {
      this.setState({
        partnerTableData: this.partnerTableData,
      });
    }
  };
  /*--------opening the create partner modal----------------- */
  showModal = () => {
    this.setState({
      showCreatePartnerModal: true,
    });
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showCreatePartnerModal: !this.state.showCreatePartnerModal,
    });
  };
  handleUpdateModal = () => {
    this.setState({
      showUpdatePartnerModal: !this.state.showUpdatePartnerModal,
    });
  };
  /*----------call this function to delete partner----*/
  deletePartner = (partnerId) => {
    const confirm = window.confirm("Are you sure you want delete partner ?");
    if (confirm) {
      this.props.dispatch(deletePartner(partnerId));
    } else {
      return false;
    }
  };
  /*----------call this function to update partner----*/
  updatePartner = (partnerId, partnerName) => {
    let initialValues = {
      name: partnerName,
      partnerId: partnerId,
    };
    this.setState({
      initialValues: initialValues,
      showUpdatePartnerModal: true,
    });
  };
  /*----------for update partner status---------------- */
  updatePartnerStatus = (partnerId, status) => {
    if (status) {
      const confirm = window.confirm(
        "Are you sure you want to disable partner ?"
      );
      if (confirm) {
        const statusData = {
          partnerId: partnerId,
          status: status ? false : true,
        };
        this.props.dispatch(updatePartnerStatus(statusData));
      }
    } else {
      const statusData = {
        partnerId: partnerId,
        status: status ? false : true,
      };
      this.props.dispatch(updatePartnerStatus(statusData));
    }
  };
  /*---------render method--------------------*/
  render() {
    return (
      <div>
        <Partner
          stateValues={this.state}
          showMembers={this.showMembers}
          wholeData={this.state.wholeData}
          searchPartner={this.searchPartner}
          onClickSearchButton={this.onClickSearchButton}
          showModal={this.showModal}
          handleModal={this.handleModal}
          deletePartner={this.deletePartner}
          updatePartner={this.updatePartner}
          updatePartnerStatus={this.updatePartnerStatus}
          handleUpdateModal={this.handleUpdateModal}
          t={this.props.t}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  // console.log(state.partner.isRequesting);
  return {
    partnersData: state.partner.partnerData,
    partnersDataError: state.partner.partnersDataError,
    deletePartnerSuccess: state.partner.deletePartnerSuccess,
    deletePartnerFailure: state.partner.deletePartnerFailure,
    partnerStatusData: state.partner.partnerStatusData,
    partnerStatusError: state.partner.partnerStatusError,
    isRequesting: state.partner.isRequesting,
  };
}
PartnerContainer.propTypes = {
  partnersData: PropTypes.object,
  partnersDataError: PropTypes.bool,
  deletePartnerSuccess: PropTypes.bool,
  deletePartnerFailure: PropTypes.bool,
  partnerStatusData: PropTypes.bool,
  partnerStatusError: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  isRequesting: PropTypes.bool,
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "home-form",
    //validate,
    // asyncValidate,
  }),
  connect(mapStateToProps)
)(PartnerContainer);
