import React from "react";
import { Container, Col, Row, Input } from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import moment from "moment";
// import nowLogo from "assets/img/svg/Argos_logo.svg";
import PartnerDashboardAlerts from "./PartnerDashboardAlerts";
import PartnerDashboardTable from "./PartnerDashboardTable";
import TeamsDashboardRole from "./TeamsDashboardRole";
import TeamsDashboardTable from "./TeamsDashboardTable";
import OrganizationAlertsActivity from "./OrganizationAlertsActivity";
import DashboardPieChart from "./DashboardPieChart";
import DashboardbarChart from "./DashboardBarChart";
import OrganizationDashboardAlerts from "./OrganizationDashboardAlerts";
import OrganizationDashboardTable from "./OrganizationDashboardTable";
import OrganizationNotifications from "./OrganizationNotifications";
import TeamDashboardActivity from "./TeamDashboardActivity";
import TeamDashboardLineChart from "./TeamDashboardLineChart";
import LoaderIcon from "assets/img/Loader.gif";
import {
  isPartnerVisible,
  isCustomerVisible,
  isCustomerDashboard,
  isPartnerDashboard,
  isTeamDashboard,
  isTeamVisible,
  isIssueVisible,
  isGlobalUserVisible,
} from "helpers/Utility";
 
function DashboardPage({
    permissions,
    inputData,
    pieChartData, 
    teamOverviewData,
    teamOverviewFunction,
    instanceData,
    graphData,
    partnerAlertData,
    partnerTableData,
    partnerRecentData,
    assigned,
    closed,
    unassigned,
    teamOverview,
    teamAlerts,
    teamActivityData,
    redirectViewAll,
    loader,
    dashboard,
    instanceOverViewSelectedIndex,
    customerIndex,
    getTeamOverViewSelectedIndex,
    teamIndex,
    getAlertsActivitySelectedIndex,
    alertsActivityIndex,
    teamOverviewPieIndex
    }) {
  // console.log(" componnents ",   partnerRecentData ," table data", partnerTableData )
  const date = new Date();
  function getMonday(date) {
    date = new Date(date);
    var day = date.getDay(),
      diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return moment(new Date(date.setDate(diff)))
      .local()
      .format("YYYY-MM-DD");
  }

  function getFIrstDateOfMonth(date) {
    return moment(new Date(date.getFullYear(), date.getMonth(), 1))
      .local()
      .format("YYYY-MM-DD");
  }

  function getFirstDateOfYear(date) {
    return moment(new Date(date.getFullYear(), 0, 1))
      .local()
      .format("YYYY-MM-DD");
  }

  const CheckDashboard = () => {
    if (isPartnerDashboard(dashboard)) {
     
      return (
        <>
          <PartnerDashboardAlerts alert={partnerAlertData} />
          <PartnerDashboardTable
            table={partnerTableData}
            recentData={partnerRecentData && 
              partnerRecentData.recentAlerts &&
              partnerRecentData.recentAlerts}
          />

          <DashboardPieChart
            table={partnerTableData}
            chartFunction={pieChartData}
            teamOverviewFunction={teamOverviewFunction} 
            assigned={assigned}
            closed={closed}
            unassigned={unassigned}
            teamOverview={teamOverview}
            teamOverViewPieData={teamOverviewData}
            teamOverviewPieIndex={teamOverviewPieIndex}
          />

          <DashboardbarChart
            barData={partnerRecentData && 
              partnerRecentData.getDayWiseAlertCount && 
              partnerRecentData.getDayWiseAlertCount}
            
            alertsActivityIndex={alertsActivityIndex}
            getAlertsActivitySelectedIndex={getAlertsActivitySelectedIndex}
          />

          {/* Team dashbaord Componnents */}
          {/* <TeamsDashboardRole
            alertData={
              teamAlerts.data &&
              teamAlerts.data.teamAlertCount.alertCount[0]
            }
          />
          <TeamsDashboardTable
            recentAlerts={
              teamAlerts.data && teamAlerts.data.teamAlertsDetails
            }
            instanceAlerts={
              teamAlerts.data && teamAlerts.data.alertsForInstance
            }
          />
          <TeamDashboardLineChart
            lineChartData={
              teamAlerts.data &&
              teamAlerts.data.teamAlertCount.alertData
            }
          />
          <TeamDashboardActivity
            myActivity={teamAlerts.data && teamAlerts.data.userAlerts}
            teamActivity={teamActivityData}
          /> */}
        </>
      );
    } else if (isCustomerDashboard(dashboard)) {
      return (
        <>
          <OrganizationDashboardAlerts
            roleData={instanceData.task && 
            instanceData.task.length > 0  &&
            instanceData.task}
            customerIndex={customerIndex} 

          />
          <OrganizationDashboardTable
            instanceData={instanceData.task && instanceData.task.length > 0  && instanceData.task}
            redirectViewAll={redirectViewAll}
            instanceOverViewSelectedIndex={instanceOverViewSelectedIndex }
            customerIndex={customerIndex} 
            getTeamOverViewSelectedIndex={getTeamOverViewSelectedIndex}
            teamIndex={teamIndex}
          />

          <OrganizationAlertsActivity
            alertsActivityData={graphData} 
            customerIndex={customerIndex} 
          />

          <OrganizationNotifications 
            notificationsData={graphData}
            customerIndex={customerIndex} 
          />
        </>
      );
    } else if(isTeamDashboard(dashboard)){
      return (
        <>
          <TeamsDashboardRole />
          <TeamsDashboardTable />
          {/* <DashboardLineChart /> */}
          <TeamDashboardActivity />
        </>
      );
    }
  };

  const headerForm = (event) => {
    inputData(event.target.value);
  };

  const getInstanceOverviewSelectedOption=(event)=>{
    event.preventDefault();
    console.log("evnet ", event.target.value)
    instanceOverViewSelectedIndex(event.target.value);
  }

  const renderInstanceOverviewSelectedOption=()=>{
      return instanceData.task && instanceData.task.length > 0 &&
      instanceData.task.map((data, index)=>{
      return(
        <option value={ index } >{ data.customerName }</option> 
          )
      })
  }

  return (
    <div>
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row>
              
              <Col lg={12}>
                <div className="dadhboard-header">
                  <div className="ml-2 dadhboard-header-div">
                    <p className="dadhboard-header-div-text">Dashboard</p>
                  </div>
                  {isCustomerDashboard(dashboard) ? 
                    <div className="ml-auto dadhboard-header-input-div d-flex">
                      <div><p style={{ marginTop: '5px', marginRight: '5px'}}>Choose company</p></div>
                      <div>
                        <Input type="select" className="float-right dadhboard-header-input"
                          onChange={(event) => getInstanceOverviewSelectedOption(event)}
                        >
                        {renderInstanceOverviewSelectedOption()}
                        </Input>
                      </div>
                    </div> : ""
                  }
                  <div className="ml-auto mr-1 dadhboard-header-div">
                    
                    <Input
                      type="select"
                      className="float-right dadhboard-header-input "
                      onChange={(event) => headerForm(event)}
                    >
                      <option value={getFIrstDateOfMonth(date)}>
                        This Month
                      </option>
                      <option value={getMonday(date)}>This Week</option>
                      <option value={getFirstDateOfYear(date)}>
                        This Year
                      </option>
                    </Input>
                  </div>
                </div>
              </Col>
            </Row>
            { loader ? 
                <div className="loader-center">
                  <img src={LoaderIcon} alt="loder icon"   ></img> 
                </div>
              : 
            <Row>
              <Col sm="12">
                {CheckDashboard()}

                {/* <TeamsDashboardRole />
                <TeamsDashboardTable />
                <DashboardPieChart />
                <DashboardbarChart />)
                <OrganizationDashboardRole /> 
                <OrganizationDashboardTable />
                <DashboardLineChart />
                <DashboardMixedGraph /> 
                <DashboardLineChart />
                 <TeamsDashboardRole />
                <TeamsDashboardTable />
                <DashboardLineChart />
                <TeamDashboardActivity  />  */}
              </Col>
              {/* <Col className="text-center font-weight-bold">
                Welcome to Argos Dashboard
              </Col> */}
            </Row>
            }
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}

DashboardPage.propTypes = {
  handleModal: PropTypes.func,
  showMembers: PropTypes.func,
  showSelectUserRoleModal: PropTypes.bool,
  userScope: PropTypes.array,
  changeRoles: PropTypes.func,
  roles_count: PropTypes.number,
  getMyRole: PropTypes.func,
};

export default DashboardPage;
