/**
 * @action        : teamAction
 * @description   : for get the team data
 * @Created by    : INNOMICK
 */

import { TEAM_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [userInvitation description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for teams
export function getTeams(customerId, qString) {
  let urlPromise;
  if (customerId) {
    urlPromise = AXIOS_INSTANCE.get(
      `${BASE_URL}/CustomerTeams/${customerId}${qString}`
    );
  } else {
    urlPromise = AXIOS_INSTANCE.get(BASE_URL + "/Teams");
  }
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.GET_ALL_TEAM_REQUEST));
    urlPromise
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.GET_ALL_TEAM_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.GET_ALL_TEAM_FAILURE, {
              reponse: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.GET_ALL_TEAM_FAILURE, {
            error: {
              statusText: error,
              netWorkError: true,
            },
          })
        );
      });
  };
}

// action for create teams
export function createTeams(formData) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.CREATE_TEAM_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/CreateTeam", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.CREATE_TEAM_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.CREATE_TEAM_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.CREATE_TEAM_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for team statistics
export function teamStatistics(teamId) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.GET_ALL_TEAM_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getTeamStatistics/${teamId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.GET_TEAM_STATISTICS_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.GET_TEAM_STATISTICS_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.GET_TEAM_STATISTICS_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for delete teams
export function deleteTeam(teamId) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.DELETE_TEAM_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/Team/${teamId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.DELETE_TEAM_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.DELETE_TEAM_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.DELETE_TEAM_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for update teams
export function updateTeam(formData) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.UPDATE_TEAM_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/UpdateTeam", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.UPDATE_TEAM_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.UPDATE_TEAM_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.UPDATE_TEAM_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for update team status
export function updateTeamStatus(formData) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.UPDATE_TEAM_STATUS_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateTeamStatus", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.UPDATE_TEAM_STATUS_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.UPDATE_TEAM_STATUS_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.UPDATE_TEAM_STATUS_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for get team alerts data
export function getTeamAlerts(formData) {
  let day = formData.day ? `&day=${formData.day}` : ``;
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.GET_TEAM_ALERTS_REQUEST));
    AXIOS_INSTANCE.get(
      `${BASE_URL}/Alerts?type=${formData.type}&id=${formData.id}${day}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.GET_TEAM_ALERTS_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.GET_TEAM_ALERTS_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.GET_TEAM_ALERTS_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for add team user
export function addTeamUser(formData) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.ADD_TEAM_USER_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/AddUserToTeam", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.ADD_TEAM_USER_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.ADD_TEAM_USER_FAILURE, {
              response: {
                data: response,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.ADD_TEAM_USER_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}
// action for get team user
export function getTeamUsers(teamId, qString) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.GET_TEAM_USERS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/teamMembers?teamId=${teamId}${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.GET_TEAM_USERS_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.GET_TEAM_USERS_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.GET_TEAM_USERS_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for get team user
export function deleteTeamUser(teamUserId) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.DELETE_TEAM_USER_REQUEST));
    AXIOS_INSTANCE.delete(
      `${BASE_URL}/removeUserFromTeam?teamUserId=${teamUserId}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.DELETE_TEAM_USER_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.DELETE_TEAM_USER_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.DELETE_TEAM_USER_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for assign rule to team
export function assignRuleToTeam(formData) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.ASSIGN_RULE_TO_TEAM_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/assignRuleToTeam", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.ASSIGN_RULE_TO_TEAM_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.ASSIGN_RULE_TO_TEAM_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.ASSIGN_RULE_TO_TEAM_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}

// action for un asssign  rule to team
export function unAssignRuleToTeam(teamRuleId) {
  return (dispatch) => {
    dispatch(base.getRequest(TEAM_CONST.UNASSIGN_RULE_TO_TEAM_REQUEST));
    AXIOS_INSTANCE.delete(
      `${BASE_URL}/removeRuleFromTeam?teamRuleId=${teamRuleId}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TEAM_CONST.UNASSIGN_RULE_TO_TEAM_SUCCESS, {
              response: {
                data: response.data,
              },
            })
          );
        } else {
          dispatch(
            base.getFailure(TEAM_CONST.UNASSIGN_RULE_TO_TEAM_FAILURE, {
              response: {
                data: response.error,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          base.getFailure(TEAM_CONST.UNASSIGN_RULE_TO_TEAM_FAILURE, {
            error: {
              data: error,
            },
          })
        );
      });
  };
}
