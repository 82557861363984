import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import compose from "compose-function";
import { translate } from "react-multi-lang";
import BackComponent from "./BackComponent";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { browserHistory } from "react-router";

class BreadCrumbComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Breadcrumb tag="nav" listTag="div" className="breadcrumb-style">
        {this.props.nonActive2 && (
          <BreadcrumbItem tag={Link} to={this.props.nonActive2Href}>
            {this.props.nonActive2}
          </BreadcrumbItem>
        )}
        {this.props.nonActive1 && (
          <BreadcrumbItem tag={Link} to={this.props.nonActive1Href}>
            {this.props.nonActive1}
          </BreadcrumbItem>
        )}
        <BreadcrumbItem active tag="span" className="font-weight-bold">
          {this.props.active}
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }
}

BreadCrumbComponent.propTypes = {
  active: PropTypes.string,
  nonActive1: PropTypes.string,
  nonActive1Href: PropTypes.string,
  nonActive2: PropTypes.string,
  nonActive2Href: PropTypes.string
};
export default compose(
  translate,
  withRouter,
  connect()
)(BreadCrumbComponent);

{
  /* <Breadcrumb
                      tag="nav"
                      listTag="div"
                      className="breadcrumb-style"
                    >
                      <BreadcrumbItem tag="span">
                        <BackComponent name="Organisations" />
                      </BreadcrumbItem>
                      <BreadcrumbItem
                        active
                        tag="span"
                        className="font-weight-bold"
                      >
                        {this.props.t("common.DASHBOARD")}
                      </BreadcrumbItem>
                    </Breadcrumb> */
}
