/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import UserProfilePage from "../Components/UserProfilePage";
import {
  showError,
  showSuccess,
  emailRex,
  mobileNumRex,
  getBase64,
} from "../../../../helpers/Utility";
import {
  updateProfilePicture,
  getProfilePicture,
  storeUserData,
  updateProfile,
} from "../../../../actions/loginAction";
import PropTypes from "prop-types";
class UserProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        // firstNameState: "has-success",
        // lastNameState: "has-success",
        // contactNumberState: "has-success"
      },
      loading: false,
      isEditable: false,
      phone: "",
    };
  }
  componentDidMount = () => {
    this.props.dispatch(storeUserData());
    // this.props.dispatch(getProfilePicture());
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.getProfileImage) {
      this.setState({
        userProfileImage: nextProps.getProfileImage,
      });
    }
    if (nextProps.initialValues) {
      this.setState({
        defaultImage: nextProps.initialValues.profileImage,
        phone: nextProps.initialValues.mobileNumber
          ? nextProps.initialValues.mobileNumber
          : "",
      });
    }
    if (
      nextProps.updateProfileSuccess &&
      nextProps.updateProfileSuccess !== this.props.updateProfileSuccess
    ) {
      this.setState({
        loading: false,
        isEditable: false,
      });
      this.props.history.push("/dashboard/home");
      showSuccess("User profile updated successfully.");
    }
    if (
      nextProps.updateProfileNetworkFailure &&
      nextProps.updateProfileNetworkFailure !==
        this.props.updateProfileNetworkFailure
    ) {
      this.setState({
        loading: false,
        isEditable: false,
      });
      showError("Something went wrong please check.");
    }
    if (
      nextProps.updateProfileFailure &&
      nextProps.updateProfileFailure !== this.props.updateProfileFailure
    ) {
      this.setState({
        loading: false,
        isEditable: false,
      });
      showError(nextProps.updateProfileFailure);
    }
  };
  /**
   * call this function to hit api.
   */
  onSubmit = (formProps, profileData) => {
    let profile = profileData;
    let isValid = true;
    // let fields = ["firstNameState", "lastNameState", "contactNumberState"];
    Object.values(profile).map((fieldName) => {
      if (fieldName !== "has-success") {
        isValid = false;
        // profile[fieldName] = "has-danger";
      }
    });
    if (!isValid) {
      this.setState({
        loading: false,
      });
      this.setState({ profile });
      return false;
    } else {
      let formData;
      if (this.state.currentPath) {
        formData = {
          firstName: formProps.firstName,
          lastName: formProps.lastName,
          mobileNumber: this.state.phone !== "" ? this.state.phone : "",
          profileImage: this.state.currentPath,
        };
      } else {
        formData = {
          firstName: formProps.firstName,
          lastName: formProps.lastName,
          mobileNumber: this.state.phone !== "" ? this.state.phone : "",
        };
      }
      // if (this.state.phone.includes("+")) {
      // } else {
      //   showError("Please select country code");
      // }
      this.setState({
        loading: true,
      });
      this.props.dispatch(updateProfile(formData));
    }
  };
  /**fucntion for upload new profile image */
  // uploadImage = e => {
  //   getBase64(e.target.files[0], result => {
  //     this.setState({ currentPath: result });
  //   });
  // };
  uploadImage = (e) => {
    this.currentFile = e.target.files[0].name;
    const extensions = ["jpg", "jpeg", "png"];
    const fileExtension = e.target.files[0].name.split(".").pop();
    if (extensions.includes(fileExtension.toLowerCase())) {
      getBase64(e.target.files[0], (result) => {
        this.setState({ currentPath: result });
      });
    } else {
      showError("Please select only valid format files");
    }
  };
  handleOnChange = (value) => {
    this.setState({
      phone: value,
    });
  };

  render() {
    return (
      <UserProfilePage
        onSubmit={this.onSubmit}
        handleSubmit={this.props.handleSubmit}
        validateFirstName={this.validateFirstName}
        validateLastName={this.validateLastName}
        validateContactNumber={this.validateContactNumber}
        userEmail={this.state.userEmail}
        profile={this.state.profile}
        filePath={this.state.currentPath}
        defaultImage={this.state.defaultImage}
        uploadImage={this.uploadImage}
        loading={this.state.loading}
        phone={this.state.phone}
        handleOnChange={this.handleOnChange}
      />
    );
  }
}
UserProfileContainer = reduxForm({
  form: "userProfile",
  //validate,
  // asyncValidate,
  enableReinitialize: true,
})(UserProfileContainer);
function mapStateToProps(state) {
  let initialValues;
  if (state.login.userProfileData) {
    initialValues = {
      firstName: state.login.userProfileData.First_Name,
      lastName: state.login.userProfileData.Last_Name,
      email: state.login.userProfileData.Email_User,
      mobileNumber: state.login.userProfileData.Contact_Number,
      profileImage: state.login.userProfileData.Profile_Image,
    };
  }
  return {
    initialValues: initialValues,
    updateProfileSuccess: state.login.updateProfileSuccess,
    updateProfileFailure: state.login.updateProfileFailure,
    updateProfileNetworkFailure: state.login.updateProfileNetworkFailure,
  };
}
UserProfileContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  userData: PropTypes.object,
  getProfileImage: PropTypes.string,
  initialValues: PropTypes.object,
  updateProfileFailure: PropTypes.string,
};
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(UserProfileContainer);
