import React, { PureComponent } from "react";
import RoleMembers from "./Containers/RoleMembersContainer";

class HomePage extends PureComponent {
  render() {
    return (
      <div>
        <RoleMembers
          search={this.props.location.search}
          role_number={parseInt(this.props.match.params.role, 10)}
          role_id={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default HomePage;
