import React, { PureComponent } from "react";
import Dashboard from "./Containers/RuleDashboardContainer";

class RuleDashboard extends PureComponent {
  render() {
    return (
      <div>
        <Dashboard
          rule_id={this.props.match.params.id}
          searchParams={this.props.location.search}
        />
      </div>
    );
  }
}

export default RuleDashboard;
