/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Button
} from "reactstrap";
import SideBarToggle from "../../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import { confirmPermissions } from "../../../../../helpers/Utility";
import CreateRuleModal from "../../../Modals/CreateRuleModal";
import CreateNotificationModal from "../../../Modals/NotificationSettingsModal";
import AssignTeamsModal from "../../../Modals/AssignTeamsModal";
import { getRuleTeam, deleteRule } from "../../../../../actions/ruleAction";
import queryString from "query-string";
import LoaderIcon from "../../../../../assets/img/Loader.gif";
import BackComponent from "../../../Common/BackComponent";
import ReactTable from "react-table";
/*-----icons---- */
// import WhatsAppIcon from "../../../../../assets/img/svg/whatsapp.svg";
import EmailIcon from "../../../../../assets/img/svg/envelope.svg";
import PhoneIcon from "../../../../../assets/img/svg/phone_ring.svg";
import ClockIcon from "../../../../../assets/img/svg/clock.svg";
import FullHrsIcon from "../../../../../assets/img/svg/24_hours.svg";
import TeamsIcon from "../../../../../assets/img/svg/teamsicon.svg";

function ruleDashboard(props) {
  /**const for permissions array */
  const permissions = props.stateValues.wholeData
    ? props.stateValues.wholeData.permissions
    : [];
  /*const for individual permissions */
  const isDeletable = confirmPermissions(permissions, "isDeletable");
  const isEditable = confirmPermissions(permissions, "isEditable");
  const isAnyOneEditDelete = isDeletable || isEditable;
  const {
    Name_Rule,
    Description_Rule,
    UID_Rule,
    UID_Instance,
    Customer
  } = props.stateValues.alertsData.rule
    ? props.stateValues.alertsData.rule
    : {};
  // const Customer_Name = Customer ? Customer.Name_Customer : "";
  // const CustomerId = Customer ? Customer.UID_Customer : "";
  // const showTeams = () => {
  //   if (
  //     props.stateValues.teamsDataByRule &&
  //     props.stateValues.teamsDataByRule.length
  //   ) {
  //     return props.stateValues.teamsDataByRule.map((rule, key) => {
  //       return (
  //         <tr key={key} className="table-data-rows-two">
  //           <td>{rule.Team.Name_Team}</td>
  //           <td>{rule.Team.Description_Team}</td>
  //           <td className="text-center">
  //             <span
  //               className="badge badge-primary cursor-pointer"
  //               onClick={e =>
  //                 props.navigateNotification(
  //                   rule.UID_TeamRule,
  //                   rule.Team.Name_Team,
  //                   Name_Rule
  //                 )
  //               }
  //             >
  //               Notification settings
  //             </span>
  //           </td>
  //         </tr>
  //       );
  //     });
  //   } else {
  //     if (props.stateValues.isRequesting) {
  //       return (
  //         <tr className="table-tr-loader">
  //           <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
  //         </tr>
  //       );
  //     } else {
  //       return (
  //         <tr className="table-tr-loader">
  //           <td className="no-data-found-style">No Data Found</td>
  //         </tr>
  //       );
  //     }
  //   }
  // };
  /*calling function to copy ruleKey */
  const copyRuleKey = () => {
    let copyText = document.getElementById("key");
    let textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };
  /*---------------react-table header----------------------*/
  const headers = {
    Name_Team: "Team Name",
    description: "Description",
    actions: "Actions",
    types: "Created notifications"
  };
  /*---------------react-data------------------- */
  const data = props.stateValues.teamsDataByRule
    ? props.stateValues.teamsDataByRule
    : [];
  return (
    <div>
      {props.stateValues.showAssignTeamsModal && (
        <AssignTeamsModal
          isOpen={props.stateValues.showAssignTeamsModal}
          handleAssignTeamsModal={props.handleAssignTeamsModal}
          teamsData={props.stateValues.teamsData}
          currentRuleId={props.rule_id}
          queryParams={queryString.parse(props.searchParams)}
          meta={props.stateValues.meta}
        />
      )}
      {props.stateValues.showUpdateRuleModal && (
        <CreateRuleModal
          isOpen={props.stateValues.showUpdateRuleModal}
          handleModal={props.handleModal}
          initialValues={props.stateValues.initialValues}
          ruleId={UID_Rule}
          id={UID_Instance}
        />
      )}
      {props.stateValues.showCreateNotificationModal && (
        <CreateNotificationModal
          isOpen={props.stateValues.showCreateNotificationModal}
          handleModal={props.handleNotificationModal}
          teamRuleId={props.stateValues.teamRuleId}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={10} md={10} xs={10} className="">
                <div>
                  <Breadcrumb
                    tag="nav"
                    listTag="div"
                    className="breadcrumb-style"
                  >
                    <BreadcrumbItem tag="a" href="javascript:void(0);">
                      <BackComponent name={props.name} morePrevious={true} />
                    </BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0);">
                      <BackComponent
                        name={Name_Rule}
                        selectedPage={
                          props.stateValues.previousPath
                            ? props.stateValues.previousPath
                            : false
                        }
                      />
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      active
                      tag="span"
                      className="font-weight-bold"
                    >
                      {props.t("common.DASHBOARD")}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
              <Col lg={2} md={2}>
                {isEditable && (
                  <Button
                    color="primary"
                    onClick={() => props.showAssignTeamsModal()}
                  >
                    <span className="font-weight-bold">Assign Teams</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Card className="mt-3">
              <CardBody>
                <Row className="">
                  <Col lg={11} md={11} xs={12}>
                    <span className="text-muted font-weight-bold">
                      Name of Rule:{" "}
                    </span>
                    <span className="text-muted">{Name_Rule}</span>
                    <br />
                    <span className="line-height-style">
                      <span className="text-muted font-weight-bold">
                        Rule key:{" "}
                      </span>
                      <span id="key" className="badge badge-primary">
                        {UID_Rule}
                      </span>{" "}
                      <span
                        id="idForCopySymbol"
                        className="cursor-pointer"
                        onClick={copyRuleKey}
                      >
                        <i className="fa fa-copy" />
                      </span>
                    </span>
                    <br />
                    <span className="text-muted font-weight-bold mt-5">
                      {props.t("common.DESCRIPTION")}:{" "}
                    </span>
                    <span className="text-muted">{Description_Rule}</span>
                    <Tooltip
                      placement="top"
                      isOpen={props.stateValues.tooltipOpen}
                      target="idForCopySymbol"
                      toggle={props.toggle}
                    >
                      Copy Rule key
                    </Tooltip>
                  </Col>
                  {isAnyOneEditDelete && (
                    <Col
                      lg={1}
                      md={1}
                      xs={12}
                      className="list-style position-style-class-one"
                    >
                      <div className="pull-right dummy-class position-style-class-two">
                        <Dropdown
                          nav
                          isOpen={props.stateValues.dropdownOpen}
                          toggle={e => props.dropdownToggle(e)}
                          className="mobile-style-css"
                        >
                          <DropdownToggle caret nav>
                            <i className="now-ui-icons design_bullet-list-67 font-weight-bold actions-icon-style" />
                            <p />
                          </DropdownToggle>
                          <DropdownMenu right>
                            {isEditable && (
                              <DropdownItem
                                tag="a"
                                className="text-muted font-weight-bold cursor-pointer"
                                onClick={e =>
                                  props.editRule(
                                    props.stateValues.alertsData.rule
                                      ? props.stateValues.alertsData.rule
                                      : {}
                                  )
                                }
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                />
                                Update Rule
                              </DropdownItem>
                            )}
                            {isDeletable && (
                              <DropdownItem
                                tag="a"
                                className="text-danger font-weight-bold cursor-pointer"
                                onClick={e => props.deleteRule()}
                              >
                                <i className="fa fa-trash" aria-hidden="true" />{" "}
                                Delete Rule
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
            <Card className="mt-4">
              <CardBody>
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  className="-striped-highlight"
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.Name_Team,
                          accessor: "Name_Team",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.description,
                          accessor: "description",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.actions,
                          accessor: "actions",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {isDeletable && (
                                    <span
                                      className="badge badge-danger cursor-pointer"
                                      onClick={() =>
                                        props.removeTeamFromRule(
                                          row.value.teamRuleId
                                        )
                                      }
                                    >
                                      delete
                                    </span>
                                  )}{" "}
                                  <span
                                    className="badge badge-primary cursor-pointer"
                                    onClick={() =>
                                      props.navigateNotification(
                                        row.value.teamRuleId,
                                        row.value.teamName,
                                        row.value.ruleName
                                      )
                                    }
                                  >
                                    Notification settings
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.types,
                          accessor: "types",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <span>
                                    {Object.entries(row.value.isAllDay)
                                      .length ? (
                                      <img
                                        src={FullHrsIcon}
                                        alt="24hrsIcon"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {row.value.isAllDay.isEmail ? (
                                      <img
                                        src={EmailIcon}
                                        alt="Email"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {row.value.isAllDay.isPushNotification ? (
                                      <img
                                        src={PhoneIcon}
                                        alt="Phone"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {/* {row.value.isAllDay.isWhatsApp ? (
                                      <img
                                        src={WhatsAppIcon}
                                        alt="WhatsApp"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "} */}
                                    {row.value.isAllDay.isTeam ? (
                                      <img
                                        src={TeamsIcon}
                                        alt="Teams"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {Object.entries(row.value.isAllDay)
                                      .length &&
                                    Object.entries(row.value.isNotAllDay).length
                                      ? "|"
                                      : ""}{" "}
                                    {Object.entries(row.value.isNotAllDay)
                                      .length ? (
                                      <img
                                        src={ClockIcon}
                                        alt="Clock"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {row.value.isAllDay.isEmail ? (
                                      ""
                                    ) : row.value.isNotAllDay.isEmail ? (
                                      <img
                                        src={EmailIcon}
                                        alt="Email"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {row.value.isAllDay.isPushNotification ? (
                                      ""
                                    ) : row.value.isNotAllDay
                                        .isPushNotification ? (
                                      <img
                                        src={PhoneIcon}
                                        alt="Phone"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                    {/* {row.value.isAllDay.isWhatsApp ? (
                                      ""
                                    ) : row.value.isNotAllDay.isWhatsApp ? (
                                      <img
                                        src={WhatsAppIcon}
                                        alt="WhatsApp"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )} */}
                                    {row.value.isAllDay.isTeam ? (
                                      ""
                                    ) : row.value.isNotAllDay.isTeam ? (
                                      <img
                                        src={TeamsIcon}
                                        alt="Teams"
                                        className="icon-style"
                                        // style={{
                                        //   width: "25px",
                                        //   height: "25px"
                                        // }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  manual
                  pages={props.stateValues.meta.pageTotal}
                  defaultPageSize={props.stateValues.meta.pageSize}
                  pageSizeOptions={props.stateValues.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                    }
                    if (props.rule_id) {
                      if (queryString.parse(props.searchParams).teamName) {
                        props.dispatch(
                          getRuleTeam(
                            // "teamRuleId",
                            // queryString.parse(props.searchParams).teamRuleId,
                            "ruleId",
                            props.rule_id,
                            qString
                          )
                        );
                      } else {
                        props.dispatch(
                          getRuleTeam("ruleId", props.rule_id, qString)
                        );
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
ruleDashboard.propTypes = {
  stateValues: PropTypes.object,
  showMembers: PropTypes.func,
  navigate: PropTypes.func,
  navigatePage: PropTypes.func,
  deleteRule: PropTypes.func,
  editRule: PropTypes.func,
  dropdownToggle: PropTypes.func,
  handleModal: PropTypes.func,
  handleNotificationModal: PropTypes.func,
  navigateNotification: PropTypes.func,
  team_id: PropTypes.string,
  dispatch: PropTypes.func,
  rule_id: PropTypes.string,
  searchParams: PropTypes.string,
  handleAssignTeamsModal: PropTypes.func,
  removeTeamFromRule: PropTypes.func
};
export default compose(translate, connect())(ruleDashboard);
