import { INSTANCE_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    isRequesting: false,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_REQUEST:
      return {
        ...state,
        instancesData: null,
        isRequesting: true,
        instancesDataError: false,
        instancesDataNetworkError: false
      };
    case INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        instancesData: action.payload.response.data.data,
        instancesDataError: false,
        instancesDataNetworkError: false
      };
    case INSTANCE_CONST.GET_INSTANCE_BY_CRITERIA_FAILURE:
      return {
        ...state,
        instancesData: null,
        isRequesting: false,
        instancesDataError: action.payload.response ? true : false,
        instancesDataNetworkError: action.payload.error ? true : false
      };
    case INSTANCE_CONST.CREATE_INSTANCE_REQUEST:
      return {
        ...state,
        createInstanceSuccess: false,
        createInstanceFailure: false,
        createInstanceNetworkFailure: false
      };
    case INSTANCE_CONST.CREATE_INSTANCE_SUCCESS:
      return {
        ...state,
        createInstanceSuccess: action.payload.response ? true : false,
        createInstanceFailure: false,
        createInstanceNetworkFailure: false
      };
    case INSTANCE_CONST.CREATE_INSTANCE_FAILURE:
      return {
        ...state,
        createInstanceSuccess: false,
        createInstanceFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        createInstanceNetworkFailure: action.payload.error ? true : false
      };
    case INSTANCE_CONST.GET_INSTANCE_STATISTICS_REQUEST:
      return {
        ...state,
        instanceStatisticsData: null,
        isRequesting: true,
        instanceStatisticsError: false
      };
    case INSTANCE_CONST.GET_INSTANCE_STATISTICS_SUCCESS:
      return {
        ...state,
        instanceStatisticsData: action.payload.response.data,
        isRequesting: false,
        instanceStatisticsError: false
      };
    case INSTANCE_CONST.GET_INSTANCE_STATISTICS_FAILURE:
      return {
        ...state,
        instanceStatisticsData: null,
        isRequesting: false,
        instanceStatisticsError: action.payload ? true : false
      };
      case INSTANCE_CONST.GET_INSTANCE_LOG_REQUEST:
      return {
        ...state,
        instanceLogsData: null,
        isRequesting: true,
        instanceLogsError: false
      };
    case INSTANCE_CONST.GET_INSTANCE_LOG_SUCCESS:
      return {
        ...state,
        instanceLogsData: action.payload.response.data,
        isRequesting: false,
        instanceLogsError: false
      };
    case INSTANCE_CONST.GET_INSTANCE_LOG_FAILURE:
      return {
        ...state,
        instanceLogsData: null,
        isRequesting: false,
        instanceLogsError: action.payload ? true : false
      };
    case INSTANCE_CONST.UPDATE_INSTANCE_REQUEST:
      return {
        ...state,
        updateInstanceSuccess: false,
        updateInstanceFailure: false,
        updateInstanceNetworkFailure: false
      };
    case INSTANCE_CONST.UPDATE_INSTANCE_SUCCESS:
      return {
        ...state,
        updateInstanceSuccess: action.payload ? true : false,
        updateInstanceFailure: false,
        updateInstanceNetworkFailure: false
      };
    case INSTANCE_CONST.UPDATE_INSTANCE_FAILURE:
      return {
        ...state,
        updateInstanceSuccess: false,
        updateInstanceFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateInstanceNetworkFailure: action.payload.error ? true : false
      };
    case INSTANCE_CONST.DELETE_INSTANCE_REQUEST:
      return {
        ...state,
        deleteInstanceSuccess: false,
        deleteInstanceFailure: false
      };
    case INSTANCE_CONST.DELETE_INSTANCE_SUCCESS:
      return {
        ...state,
        deleteInstanceSuccess: action.payload ? true : false,
        deleteInstanceFailure: false
      };
    case INSTANCE_CONST.DELETE_INSTANCE_FAILURE:
      return {
        ...state,
        deleteInstanceSuccess: false,
        deleteInstanceFailure: action.payload.error ? true : false
      };
    case INSTANCE_CONST.GET_CERTIFICATE_REQUEST:
      return {
        ...state,
        isRequestingTwo: true,
        instanceCertificateSuccess: null,
        instanceCertificateFailure: false
      };
    case INSTANCE_CONST.GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isRequestingTwo: false,
        instanceCertificateSuccess: action.payload
          ? action.payload.response.data
          : null,
        instanceCertificateFailure: false
      };
    case INSTANCE_CONST.GET_CERTIFICATE_FAILURE:
      return {
        ...state,
        isRequestingTwo: false,
        instanceCertificateSuccess: null,
        instanceCertificateFailure: action.payload.error ? true : false
      };
  }
  return state;
}
