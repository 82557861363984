/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";
import InstancePage from "../Components/InstancePage.jsx";
import PropTypes from "prop-types";
import {
  showError,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../../helpers/Utility";
import queryString from "query-string";
class InstanceContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {},
      password: "",
      loading: false,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0
      }
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.instancesData) {
      this.instancesData = nextProps.instancesData
        ? nextProps.instancesData.modifiedInstance
        : [];
      let instancesData = [];
      nextProps.instancesData.modifiedInstance.map((instance, key) => {
        instancesData.push({
          Name_Instance: {
            name: instance.Name_Instance,
            id: instance.UID_Instance
          },
          description: instance.Description_Instance
        });
      });
      this.instancesData = instancesData;
      let meta = this.state.meta;
      if (nextProps.instancesData.metaData) {
        meta = {
          page: nextProps.instancesData.metaData.pageNumber,
          pageSize: nextProps.instancesData.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.instancesData.metaData.pageCount,
          total: nextProps.instancesData.metaData.totalItemCount
        };
      }
      this.setState({
        instancesData: instancesData,
        instancesSearchData: instancesData,
        wholeTeamsData: nextProps.instancesData,
        meta: meta
      });
    }
    if (
      nextProps.instancesDataError &&
      nextProps.instancesDataError !== this.props.instancesDataError
    ) {
      showError("Error in getting Instances");
    }
    if (
      nextProps.instancesDataNetworkError &&
      nextProps.instancesDataNetworkError !==
        this.props.instancesDataNetworkError
    ) {
      showError("Something went wrong!");
    }
    if (nextProps.isRequesting) {
      this.setState({
        isRequesting: nextProps.isRequesting
      });
    } else {
      this.setState({
        isRequesting: nextProps.isRequesting
      });
    }
  };
  //here we are navigating instance dashboard page
  showDashboard = (instanceId, instanceName) => {
    const customerName = queryString.parse(this.props.customerName)
      .customerName;
    this.props.history.push(
      "/dashboard/instanceDashboard/" +
        instanceId +
        "?customer=" +
        customerName +
        "&instance=" +
        instanceName
    );
  };
  /*--------opening the create instance modal----------------- */
  showModal = () => {
    this.setState({
      showCreateInstanceModal: true
    });
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showCreateInstanceModal: !this.state.showCreateInstanceModal
    });
  };
  //this function we calling to search teams
  searchInstance = event => {
    if (this.state.instancesSearchData && event.target.value) {
      const result = this.state.instancesSearchData.filter(
        function(instance) {
          return (
            true ===
            instance.Name_Instance.name
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          );
        }.bind(this)
      );
      this.setState({
        instancesData: result
      });
    } else {
      this.setState({
        instancesData: this.instancesData
      });
    }
  };
  render() {
    const customerName = queryString
      .parse(this.props.customerName)
      .customerName.split("_")
      .join(" ");
    return (
      <div>
        <InstancePage
          wholeTeamsData={this.state.wholeTeamsData}
          instancesData={this.state.instancesData}
          searchInstance={this.searchInstance}
          customerId={this.props.customerId}
          customerName={customerName}
          showCreateInstanceModal={this.state.showCreateInstanceModal}
          showModal={this.showModal}
          handleModal={this.handleModal}
          initialValues={this.state.initialValues}
          showDashboard={this.showDashboard}
          t={this.props.t}
          stateValues={this.state}
        />
      </div>
    );
  }
}
InstanceContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  instancesData: PropTypes.object,
  instancesDataError: PropTypes.bool,
  instancesDataNetworkError: PropTypes.bool,
  customerId: PropTypes.string,
  t: PropTypes.func,
  deleteTeamSuccess: PropTypes.bool,
  isRequesting: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    instancesData: state.instance.instancesData,
    instancesDataError: state.instance.instancesDataError,
    instancesDataNetworkError: state.instance.instancesDataNetworkError,
    isRequesting: state.instance.isRequesting
  };
}
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(InstanceContainer);
