// eslint-disable-next-line no-console
// console.log(BASE_URL);
// import Swagger from 'swagger-client'
//import { LANG_CODES } from "../utils";
import axios from "axios";

// Function to handle token refreshing
const refreshToken = async () => {
  try {
    // Get the refreshToken from local storage
    const refreshToken = localStorage.getItem('refreshToken');
    // Make a request to your refreshToken API endpoint
    const response = await axios.post(`${BASE_URL}/token`, {
      token: refreshToken
    });

     // Check if the token is valid in the response
     if (response.data.isSuccess) {
      // Update the localStorage with the new accessToken and refreshToken
      localStorage.setItem('authToken', response.data.data.token);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);

      // Return the new accessToken
      return response.data.data.token;
    } else {
      // Handle the case where the token is invalid
      throw new Error(`Invalid Token: ${response.data.error.errorDescription}`);
    }
  } catch (error) {
    // Handle the error, e.g., redirect to login page
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const refreshTokenAndRetry = async (config) => {
  try {
    const newToken = await refreshToken();
    config.headers.Authorization = `Bearer ${newToken}`;
    return axios.request(config);
  } catch (refreshError) {
    // Handle the error, e.g., redirect to login page
    console.error('Error refreshing token:', refreshError);
    localStorage.clear();
    window.location.href = "/pages/login-page";
    throw refreshError;
  }
};

// creating global instance for the axios to call apis
export const AXIOS_INSTANCE = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

AXIOS_INSTANCE.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("authToken");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    // Return the response if successful
    return response;
  },
  async (error) => {
    // Check if the error is due to token expiration
    if (error.response && error.response.status === 401) {
      try {
        // Refresh the token and update the request with the new accessToken
        const newToken = await refreshTokenAndRetry(error.config);
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return axios.request(error.config);
      } catch (refreshError) {
        // Handle the error, e.g., redirect to login page
        console.error('Error refreshing token:', refreshError);
        localStorage.clear();
        window.location.href = "/pages/login-page";
        throw refreshError;
      }
    }

    // Return the error if not a token expiration error
    return Promise.reject(error);
  }
);
/*if (
  localStorage.getItem("lang") !== null &&
  localStorage.getItem("lang") !== undefined
) {
  //console.log(lang);
  const lang = localStorage.getItem("lang");
  AXIOS_INSTANCE.defaults.headers.common["Accept-Language"] = `${lang}`;
}
*/
export const LOGIN_CONFIG = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export const CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};
// base url
export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;
//export const BASE_URL = `https://argos-prod.azurewebsites.net/api/v1`;
