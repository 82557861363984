import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "compose-function";
import { translate } from "react-multi-lang";
import PropTypes from "prop-types";
class RoleBasedBadges extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const role = this.props.roleName;
    return role === "Admin" ? (
      <span className="badge-pill badge-danger">{role}</span>
    ) : (
      <span className="badge-pill badge-secondary">{role}</span>
    );
  }
}
RoleBasedBadges.propTypes = {
  history: PropTypes.object,
  roleName: PropTypes.string
};
export default compose(
  translate,
  withRouter,
  connect()
)(RoleBasedBadges);