/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Notification from "../Components/NotificationPage";
import {
  showError,
  showSuccess,
  getDaysArray,
  getLocalTime,
  leftPad,
  createGMTOffset,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../../../../helpers/Utility";
import PropTypes from "prop-types";
import queryString from "query-string";
import {
  getRuleNotification,
  deleteRuleNotification,
} from "../../../../actions/ruleAction";
import { getTimezone } from "../../../../actions/timezoneAction";
import moment from "moment";
import momentTimezone from "moment-timezone";
import _ from "lodash";
class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // isRequesting: false,
      isEnableIsAllDay: true,
      isEnableIsEmail: true,
      isEnablePush: true,
      notificationsData: null,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }
  componentDidMount = () => {
    this.props.dispatch(getTimezone());
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      isRequesting: nextProps.isRequesting,
    });
    if (
      nextProps.notificationsData &&
      nextProps.notificationsData !== this.props.notificationsData
    ) {
      let notificationsData = [];
      nextProps.notificationsData.ruleNotificationData.map(
        (notificationData, key) => {
          notificationsData.push({
            startTime: notificationData.Start_Time,
            endTime: notificationData.End_Time,
            timeZoneData: notificationData.TimeZone,
            days: notificationData.Days_List,
            notification: {
              isPushNotification: notificationData.isPushNotification,
              isWhatsApp: notificationData.isWhatsApp,
              isEmail: notificationData.isEmail,
              isSMS: notificationData.isSMS,
              isPhoneCall: notificationData.isPhoneCall,
              isAllDay: notificationData.isAllDay,
              isEveryDay: notificationData.isEveryDay,
              isTeam: notificationData.isTeam,
            },
            actions: {
              ruleNotificationId: notificationData.UID_RuleNotification,
              notificationData: notificationData,
            },
          });
        }
      );
      this.notificationData = notificationsData;
      let meta = this.state.meta;
      if (nextProps.notificationsData.metaData) {
        meta = {
          page: nextProps.notificationsData.metaData.pageNumber,
          pageSize: nextProps.notificationsData.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.notificationsData.metaData.pageCount,
          total: nextProps.notificationsData.metaData.totalItemCount,
        };
      }
      this.setState({
        notificationsData: notificationsData,
        wholeData: nextProps.notificationsData,
        meta: meta,
      });
    }
    if (
      nextProps.notificationDeleteSuccess &&
      nextProps.notificationDeleteSuccess !==
        this.props.notificationDeleteSuccess
    ) {
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.state.meta.pageSize
      )
        ? this.state.meta.pageSize
        : 10;
      let qString = `?teamRuleId=${
        this.props.ruleTeamId
      }&pageSize=${pageSize}&pageIndex=${this.state.meta.page - 1}`;
      this.props.dispatch(getRuleNotification(qString));
      showSuccess("Notification Deleted Successfully.");
    }
    if (
      nextProps.timezoneData &&
      nextProps.timezoneData !== this.props.timezoneData
    ) {
      let timezoneApiData = nextProps.timezoneData;
      let timezoneData = _.orderBy(timezoneApiData, ["Zone_Name"], ["asc"]);
      this.setState({
        timezoneData: timezoneData,
      });
    }
  };
  /*--------------function for handle notification modal----------- */
  handleNotificationModal = (teamRuleId) => {
    this.setState({
      showCreateNotificationModal: !this.state.showCreateNotificationModal,
      teamRuleId: teamRuleId,
    });
  };
  handleNotificationModalTwo = () => {
    this.setState({
      showUpdateNotificationModal: !this.state.showUpdateNotificationModal,
    });
  };
  /*--------------function for delete notification--------------------*/
  deleteNotification = (notificationId) => {
    const confirm = window.confirm(
      "Are you sure you want delete notification ?"
    );
    if (confirm) {
      this.props.dispatch(deleteRuleNotification(notificationId));
    } else {
      return false;
    }
  };
  /*--------------function for update notification--------------------*/
  updateNotification = (data) => {
    let initialValues = {
      ruleNotificationId: data.UID_RuleNotification,
      daysList: getDaysArray(JSON.parse(data.Days_List)),
      startTime: data.Start_Time
        ? momentTimezone
            .tz(data.Start_Time, data.TimeZone.Zone_Name)
            .format("HH:mm")
        : "",
      endTime: data.End_Time
        ? momentTimezone
            .tz(data.End_Time, data.TimeZone.Zone_Name)
            .format("HH:mm")
        : "",
      // startTime: data.Start_Time
      //   ? getLocalTime(
      //       data.Start_Time,
      //       data.TimeZone ? data.TimeZone.UID_TimeZone : null
      //     )
      //   : "",
      // endTime: data.End_Time
      //   ? getLocalTime(
      //       data.End_Time,
      //       data.TimeZone ? data.TimeZone.UID_TimeZone : null
      //     )
      //   : "",
      isAllDay: data.isAllDay,
      isEveryDay: data.isEveryDay,
      isEmail: data.isEmail,
      isPushNotification: data.isPushNotification,
      isWhatsApp: data.isWhatsApp,
      isTeam: data.isTeam,
      timeZoneValue: data.TimeZone
        ? {
            value: data.TimeZone.UID_TimeZone,
            label: data.TimeZone.Zone_Name + createGMTOffset(data.TimeZone),
          }
        : null,
    };
    this.setState({
      initialValues: initialValues,
      showUpdateNotificationModal: true,
    });
  };
  /*---------render method--------------------*/
  render() {
    /*--------------for user time zone data start-------------------*/
    let currentZoneName = momentTimezone.tz.guess();
    currentZoneName =
      currentZoneName === "Asia/Calcutta" ? "Asia/Kolkata" : currentZoneName;
    const currentZoneValue = _.find(this.state.timezoneData, [
      "Zone_Name",
      currentZoneName,
    ]);
    let initialTimeZoneValue;
    if (currentZoneValue) {
      initialTimeZoneValue = {
        value: currentZoneValue.UID_TimeZone,
        label: currentZoneValue.Zone_Name + createGMTOffset(currentZoneValue),
      };
    }
    /*--------------for user time zone data end----------------------*/
    const searchParamsObject = queryString.parse(this.props.searchParams);
    const { rule, teamName } = searchParamsObject;
    const ruleName = rule.split("_").join(" ");
    const team = teamName.split("_").join(" ");
    return (
      <div>
        <Notification
          stateValues={this.state}
          t={this.props.t}
          handleNotificationModal={this.handleNotificationModal}
          handleNotificationModalTwo={this.handleNotificationModalTwo}
          ruleName={ruleName}
          teamName={team}
          ruleTeamId={this.props.ruleTeamId}
          deleteNotification={this.deleteNotification}
          updateNotification={this.updateNotification}
          initialTimeZoneValue={initialTimeZoneValue}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    notificationsData: state.rule.notificationsData,
    notificationCreatedSuccess: state.rule.notificationCreatedSuccess,
    notificationError: state.rule.notificationError
      ? state.rule.notificationError
      : state.rule.notificationNetworkError,
    notificationDeleteSuccess: state.rule.notificationDeleteSuccess,
    notificationUpdateSuccess: state.rule.notificationUpdateSuccess,
    isRequesting: state.timezone.isRequesting,
    timezoneData: state.timezone.timezoneData,
  };
}
NotificationContainer.propTypes = {
  t: PropTypes.func,
  isRequesting: PropTypes.bool,
  searchParams: PropTypes.string,
  notificationsData: PropTypes.object,
  notificationCreatedSuccess: PropTypes.bool,
  notificationError: PropTypes.bool,
  notificationDeleteSuccess: PropTypes.string,
  timezoneData: PropTypes.array,
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "notification-form",
    //validate,
    // asyncValidate,
  }),
  connect(mapStateToProps)
)(NotificationContainer);
