import { PERSPECTIVE_CONST } from "../actions/actionTypes";

export default function reducer(
  state = {
    perpectiveData: [],
    isRequesting: false,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case PERSPECTIVE_CONST.GET_PERSPECTIVES_REQUEST:
      return {
        ...state,
        perspectiveData: null,
        isRequesting: true,
        perspectiveDataError: false,
        perspectiveDataNetworkError: false
      };
    case PERSPECTIVE_CONST.GET_PERSPECTIVES_SUCCESS:
      return {
        ...state,
        perspectiveData: action.payload.response.data,
        isRequesting: false,
        perspectiveDataError: false,
        perspectiveDataNetworkError: false
      };
    case PERSPECTIVE_CONST.GET_PERSPECTIVES_FAILURE:
      return {
        ...state,
        perspectiveData: null,
        isRequesting: false,
        perspectiveDataError: action.payload.response ? true : false,
        perspectiveDataNetworkError: action.payload.error ? true : false
      };
    case PERSPECTIVE_CONST.CREATE_PERSPECTIVE_REQUEST:
      return {
        ...state,
        createPerspectiveSuccess: false,
        createPerspectiveFailure: false,
        createPerspectiveNetworkFailure: false
      };
    case PERSPECTIVE_CONST.CREATE_PERSPECTIVE_SUCCESS:
      return {
        ...state,
        createPerspectiveSuccess: action.payload.response ? true : false,
        createPerspectiveFailure: false,
        createPerspectiveNetworkFailure: false
      };
    case PERSPECTIVE_CONST.CREATE_PERSPECTIVE_FAILURE:
      return {
        ...state,
        createPerspectiveSuccess: false,
        createPerspectiveFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        createPerspectiveNetworkFailure: action.payload.error ? true : false
      };
    case PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_REQUEST:
      return {
        ...state,
        updatePerspectiveSuccess: false,
        updatePerspectiveFailure: false,
        updatePerspectiveNetworkFailure: false
      };
    case PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_SUCCESS:
      return {
        ...state,
        updatePerspectiveSuccess: action.payload ? true : false,
        updatePerspectiveFailure: false,
        updatePerspectiveNetworkFailure: false
      };
    case PERSPECTIVE_CONST.UPDATE_PERSPECTIVE_FAILURE:
      return {
        ...state,
        updatePerspectiveSuccess: false,
        updatePerspectiveFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updatePerspectiveNetworkFailure: action.payload.error ? true : false
      };
    case PERSPECTIVE_CONST.DELETE_PERSPECTIVE_REQUEST:
      return {
        ...state,
        deleteInstanceSuccess: false,
        deleteInstanceFailure: false
      };
    case PERSPECTIVE_CONST.DELETE_PERSPECTIVE_SUCCESS:
      return {
        ...state,
        deleteInstanceSuccess: action.payload ? true : false,
        deleteInstanceFailure: false
      };
    case PERSPECTIVE_CONST.DELETE_PERSPECTIVE_FAILURE:
      return {
        ...state,
        deleteInstanceSuccess: false,
        deleteInstanceFailure: action.payload.error ? true : false
      };
  }
  return state;
}
