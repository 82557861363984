/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import RoleMembers from "../Components/RoleMembersPage.jsx";
import { UsersByRole, DeleteRole } from "../../../../actions/roleAction";
import { invitation } from "../../../../actions/invitationAction";
import {
  userRoles,
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../../helpers/Utility";
import PropTypes from "prop-types";
class RoleMembersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {},
      showInvitationModal: false,
      stopLoader: false,
      loading: false,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0
      }
    };
  }
  callApi = qString => {
    if (this.props.role_number) {
      let formData = {};
      if (userRoles.partner_roles.includes(this.props.role_number)) {
        formData = {
          role: this.props.role_number,
          partnerId: this.props.role_id,
          idName: "partnerId"
        };
        this.props.dispatch(UsersByRole(formData, qString));
      } else if (userRoles.customer_roles.includes(this.props.role_number)) {
        formData = {
          role: this.props.role_number,
          customerId: this.props.role_id,
          idName: "customerId"
        };
        this.props.dispatch(UsersByRole(formData, qString));
      }
    } else {
      let formData = {};
      formData = {
        role: 6
      };
      this.props.dispatch(UsersByRole(formData, qString));
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.rolesData) {
      this.setState({
        userRolesData: nextProps.rolesData
      });
    }
    if (nextProps.userDataByRole) {
      let userDataByRole = [];
      nextProps.userDataByRole.userData.map((userData, key) => {
        userDataByRole.push({
          First_Name: userData.User.Status_User
            ? userData.User.First_Name + " " + userData.User.Last_Name
            : "N/A",
          Email_User: userData.User.Email_User,
          status: userData.User.Status_User,
          actions: {
            id: userData.UID_UserRole,
            email: userData.User.Email_User,
            key: key,
            status: userData.User.Status_User
          }
        });
      });
      this.userDataByRole = userDataByRole;
      let meta = this.state.meta;
      if (nextProps.userDataByRole.metaData) {
        meta = {
          page: nextProps.userDataByRole.metaData.pageNumber,
          pageSize: nextProps.userDataByRole.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.userDataByRole.metaData.pageCount,
          total: nextProps.userDataByRole.metaData.totalItemCount
        };
      }
      this.setState({
        userDataByRole: userDataByRole,
        userSearchDataByRole: userDataByRole,
        userDataPermission: nextProps.userDataByRole,
        meta: meta
      });
    }
    if (
      nextProps.usersByRoleError &&
      nextProps.usersByRoleError !== this.props.usersByRoleError
    ) {
      showError("Something went wrong");
      this.setState({
        error: nextProps.usersByRoleError
      });
    }
    if (
      nextProps.removeUserRole &&
      nextProps.removeUserRole !== this.props.removeUserRole
    ) {
      showSuccess(nextProps.removeUserRole);
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.state.meta.pageSize
      )
        ? this.state.meta.pageSize
        : 10;
      const qString = `&pageSize=${pageSize}&pageIndex=${this.state.meta.page -
        1}`;
      this.callApi(qString);
    }
    if (
      nextProps.isInviteSuccess &&
      nextProps.isInviteSuccess !== this.props.isInviteSuccess
    ) {
      showSuccess(this.props.t("success.INVITATION_SUCCESS"));
      this.setState({
        loading: false
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.state.meta.pageSize
      )
        ? this.state.meta.pageSize
        : 10;
      const qString = `&pageSize=${pageSize}&pageIndex=${this.state.meta.page -
        1}`;
      this.callApi(qString);
    }
    if (
      nextProps.removeUserRoleError &&
      nextProps.removeUserRoleError !== this.props.removeUserRoleError
    ) {
      showError("Something went wrong");
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
  };
  //this function we are calling to search members
  searchName = event => {
    if (this.state.userSearchDataByRole && event.target.value) {
      const result = this.state.userSearchDataByRole.filter(
        function(data) {
          return (
            true ===
            data.name.toLowerCase().includes(event.target.value.toLowerCase())
          );
        }.bind(this)
      );
      this.setState({
        userDataByRole: result
      });
    } else {
      this.setState({
        userDataByRole: this.userDataByRole
      });
    }
  };
  showChangePassword = () => {
    this.props.history.push("/pages/update-password");
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({ showInvitationModal: !this.state.showInvitationModal });
  };
  //change state to open modal
  showModal = () => {
    this.setState({
      showInvitationModal: !this.state.showInvitationModal
    });
  };
  /*------------call this function to remove role --------*/
  deleteRole = userRoleId => {
    const confirm = window.confirm("Are your sure!! Delete Role");
    if (confirm) {
      this.props.dispatch(DeleteRole(userRoleId));
    } else {
      return false;
    }
  };
  /*---------call this function to re invite-------------- */
  reInvite = (email, key) => {
    let formData = {
      email: email,
      role: 7
    };
    this.setState({
      loading: true,
      key: key
    });
    this.props.dispatch(invitation(formData));
  };
  /*---------render method----------------------------- */
  render() {
    // const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(this.state.meta.pageSize)
    //   ? this.state.meta.pageSize
    //   : 10;
    return (
      <div>
        <RoleMembers
          showChangePassword={this.showChangePassword}
          handleModal={this.handleModal}
          showInvitationModal={this.state.showInvitationModal}
          showModal={this.showModal}
          userDataByRole={this.state.userDataByRole}
          userDataPermission={this.state.userDataPermission}
          role_number={this.props.role_number}
          role_id={this.props.role_id}
          searchName={this.searchName}
          onClickSearchButton={this.onClickSearchButton}
          keyDown={this.keyDown}
          stopLoader={this.state.stopLoader}
          error={this.state.error}
          deleteRole={this.deleteRole}
          reInvite={this.reInvite}
          isRequesting={this.state.isRequesting}
          search={this.props.search}
          loading={this.state.loading}
          rowKey={this.state.key}
          meta={this.state.meta}
          callApi={this.callApi}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    rolesData: state.invite.rolesData,
    userRolesData: state.invite.userRolesData,
    userDataByRole: state.roles.usersByRole,
    usersByRoleError: state.roles.usersByRoleError,
    removeUserRole: state.roles.removeUserRole,
    removeUserRoleError: state.roles.removeUserRoleError,
    isInviteSuccess: state.invite.isInviteSuccess,
    isRequesting: state.roles.isRequesting
  };
}
RoleMembersContainer.propTypes = {
  role_number: PropTypes.number,
  role_id: PropTypes.string,
  userDataByRole: PropTypes.object,
  usersByRoleError: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  rolesData: PropTypes.array,
  removeUserRole: PropTypes.string,
  removeUserRoleError: PropTypes.bool,
  isInviteSuccess: PropTypes.bool,
  isRequesting: PropTypes.bool
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "members-form"
  }),
  connect(mapStateToProps)
)(RoleMembersContainer);
