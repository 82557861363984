import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "compose-function";
import { translate } from "react-multi-lang";
import PropTypes from "prop-types";
class BackComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <a
        href="javascript:void(0)"
        onClick={(e) =>
          // this.props.morePrevious
          //   ? this.props.history.go(
          //       this.props.goValue ? this.props.goValue : -2
          //     )
          //   : this.props.history.goBack()

          this.props.morePrevious
            ? this.props.history.go(
                this.props.goValue ? this.props.goValue : -2
              )
            : this.props.selectedPage
            ? this.props.history.push(this.props.selectedPage)
            : this.props.history.goBack()
        }
      >
        {this.props.name}
      </a>
    );
  }
}

BackComponent.propTypes = {
  history: PropTypes.object,
  name: PropTypes.string,
  morePrevious: PropTypes.bool,
};
export default compose(
  translate,
  withRouter,
  connect()
)(BackComponent);
