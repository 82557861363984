/**
 * @reducer       : role reducer
 * @description   :
 * @Created by    : innomick
 */

import { ROLES_CONST } from "../actions/actionTypes";
export default function reducer(state = {}, action) {
  switch (action.type) {
    case ROLES_CONST.USERS_BY_ROLE_REQUEST:
      return {
        ...state,
        usersByRole: null,
        usersByRoleError: false,
        isRequesting: true
      };
    case ROLES_CONST.USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        usersByRole: action.payload.response.data.data,
        usersByRoleError: false,
        isRequesting: false
      };
    case ROLES_CONST.USERS_BY_ROLE_FAILURE:
      return {
        ...state,
        usersByRole: null,
        usersByRoleError: action.payload.resposne ? true : false,
        isRequesting: false
      };
    case ROLES_CONST.DELETE_USERS_ROLE_REQUEST:
      return {
        ...state,
        removeUserRole: null,
        removeUserRoleError: false
      };
    case ROLES_CONST.DELETE_USERS_ROLE_SUCCESS:
      return {
        ...state,
        removeUserRole: action.payload.response
          ? action.payload.response.data.data.Message
          : null,
        removeUserRoleError: false
      };
    case ROLES_CONST.DELETE_USERS_ROLE_FAILURE:
      return {
        ...state,
        removeUserRole: null,
        removeUserRoleError: action.payload.error ? true : false
      };
    case ROLES_CONST.MEMBERS_COUNT_REQUEST:
      return {
        ...state,
        membersCount: null,
        isRequesting: true
      };
    case ROLES_CONST.MEMBERS_COUNT_SUCCESS:
      return {
        ...state,
        membersCount: action.payload.response
          ? action.payload.response.data.data
          : null,
        isRequesting: false
      };
    case ROLES_CONST.MEMBERS_COUNT_FAILURE:
      return {
        ...state,
        membersCount: null,
        isRequesting: false
      };
  }
  return state;
}
