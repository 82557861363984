/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from "react";
import compose from "compose-function";
// import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { translate } from "react-multi-lang";
class PrivacyPolicyComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  onSubmit() {}
  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col md="12" className="text-left">
              <h1 className="text-primary">Privacy Policy</h1>
              <p>
                The application Argos - Official is provided free of charge by
                is intended to be used as it is. This page is used for
                information about the subject of use of data and personal
                information. If you wish to use our service, you agree to the
                collection and use of this information. The personal information
                we collect is used to provide and improve the service. Terms
                used in this Privacy Policy, which are available from Argos -
                Official, unless otherwise stated in this Privacy Policy.
              </p>
            </Col>
            <Col md="12">
              <h6 className="my-3 text-secondary">Collection and use of information</h6>
              <p>
                For a better experience, we use certain information, including
                but not limited to CAMERA. The information we request will be
                accepted and used in this privacy policy. The app makes a
                third-party service that can collect information. Link to the
                privacy policy of the third-party service providers used by the
                application Google Play Services
              </p>
            </Col>
            <Col md="12">
              <h6 className="my-3 text-secondary">Service providers</h6>
              <p>We can use it for the following reasons:</p>
              <p>To facilitate our service; </p>
              <p>Provide the service on our behalf; </p>
              <p>To perform service-related operations </p>
              <p>To help us analyze how our service is used.</p>
              <p>
                We want to inform users of this service that these third parties
                have access to your personal information. The reason is to
                perform the tasks assigned to them on our behalf.
              </p>
            </Col>
            <Col md="12">
              <h6 className="my-3 text-secondary">Security</h6>
              <p>
                We appreciate your confidence in providing your personal
                information, so we strive to use commercially acceptable means
                to protect it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% safe and
                reliable, and we can not guarantee its absolute security.
              </p>
            </Col>
            <Col md="12">
              <h6 className="my-3 text-secondary">Links to other sites</h6>
              <p>
                This service may contain links to other sites. If you click on a
                third-party link, you will be redirected to this site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to consult the privacy policy of these
                websites. We have no control and no responsibility for the
                content, privacy policies or practices of third party sites or
                services.
              </p>
            </Col>
            <Col md="12">
              <h6 className="my-3 text-secondary">Changes to this privacy policy</h6>
              <p>
                We may update our privacy policy from time to time. Thus, it is
                advisable to consult this page periodically for any change.
                These changes take effect immediately after they are posted on
                this page.
              </p>
            </Col>
            <Col md="12">
              <h6 className="my-3 text-secondary">Contact Us</h6>
              <p>
                If you have any questions or suggestions regarding our privacy
                policy, please contact us. This privacy policy was created by
                <a href="https://aspisid.com/"> https://aspisid.com/ </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
// RegistrationPage = reduxForm({
//   form: "RegistrationPage"
//   //validate,
//   // asyncValidate,
// })(RegistrationPage);

function mapStateToProps(state) {
  return {};
}
// export default RegistrationPage;
export default compose(
  translate,
  connect(mapStateToProps)
)(PrivacyPolicyComponent);
