/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
//import { getUserRoles } from "../../../../actions/invitationAction";
import { MembersCount } from "../../../../actions/roleAction";
import UserRoles from "../Components/UserRolePage.jsx";
import { getCurrentRole, showError } from "../../../../helpers/Utility";
import PropTypes from "prop-types";
import queryString from "query-string";
import { roles_number } from "../../../../helpers/Utility";
class UserRoleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: {
        showSelectUserRoleModal: false,
        membersCount: null
      }
    };
  }
  componentDidMount = () => {
    switch (this.props.roleNumber) {
      case 2:
        this.props.dispatch(MembersCount("customerId", this.props.customerId));
        break;
      default:
        this.props.dispatch(MembersCount("partnerId", this.props.customerId));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.userRolesData &&
      nextProps.userRolesData !== this.state.userRolesData
    ) {
      this.setState({
        userRoles: nextProps.userRolesData
      });
    }
    if (
      nextProps.userRolesDataError &&
      nextProps.userRolesDataError !== this.props.userRolesDataError
    ) {
      showError(this.props.t("error.ROLES_GETTING_ERROR"));
    }
    if (nextProps.membersCount) {
      let membersCount = [
        {
          role: {
            name: "Admin Role",
            id: roles_number.customerAdmin
          },
          members: nextProps.membersCount
            ? nextProps.membersCount.memberCount.customerAdmin.length
            : 0,
          description: "N/A"
        },
        {
          role: { name: "Support Role", id: roles_number.customerUser },
          members: nextProps.membersCount
            ? nextProps.membersCount.memberCount.customerSupport.length
            : 0,
          description: "N/A"
        }
      ];
      this.setState({
        membersCount: membersCount
      });
    }
    if (nextProps.isRequesting) {
      this.setState({
        isRequesting: nextProps.isRequesting
      });
    } else {
      this.setState({
        isRequesting: nextProps.isRequesting
      });
    }
  };
  showMembers = type => {
    // if (!id) {
    //   this.props.history.push("/dashboard/members/" + type);
    // } else {
    //   this.props.history.push("/dashboard/members/" + type + "/" + id);
    // }
    this.props.history.push(
      "/dashboard/members/" +
        type +
        "/" +
        this.props.customerId +
        "?name=" +
        queryString.parse(this.props.name).customerName
    );
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showSelectUserRoleModal: !this.state.showSelectUserRoleModal
    });
  };
  render() {
    const customerName = queryString
      .parse(this.props.name)
      .customerName.split("_")
      .join(" ");
    return (
      <div>
        <UserRoles
          userRolesData={this.state.userRoles}
          showMembers={this.showMembers}
          handleModal={this.handleModal}
          userScope={this.state.userScope}
          membersCount={this.state.membersCount}
          customerId={this.props.customerId}
          Name_Customer={customerName}
          isRequesting={this.state.isRequesting}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    userRolesData: state.invite.userRolesData,
    userRolesDataError: state.invite.userRolesDataError,
    isUserScope: state.invite.isUserScopeSuccess,
    isUserScopeFail: state.invite.isUserScopeFailure,
    membersCount: state.roles.membersCount,
    isRequesting: state.roles.isRequesting
  };
}
UserRoleContainer.propTypes = {
  userRolesData: PropTypes.array,
  userRolesDataError: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  customerId: PropTypes.string,
  isRequesting: PropTypes.bool,
  membersCount: PropTypes.object
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "home-form"
    //validate,
    // asyncValidate,
  }),
  connect(mapStateToProps)
)(UserRoleContainer);
