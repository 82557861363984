/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Organisation from "../Components/OrganisationPage.jsx";
import PropTypes from "prop-types";
import { getPartners } from "../../../../actions/partnerAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../../../../helpers/Utility";
import {
  getCustomers,
  deleteCustomer,
  updateCustomerStatus,
} from "../../../../actions/customerAction";
class OrganisationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerData: null,
      customerDataError: false,
      partnerId: null,
      isRequesting: true,
      showCreateOrganisationModal: false,
      showUpdateOrganisationModal: false,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }
  /*----------component did mount-------------------*/
  componentDidMount = () => {
    this.props.dispatch(getPartners());
  };
  /*---------component will Receive Props----------------------------------*/
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      isRequesting: nextProps.isRequesting,
    });
    if (nextProps.partnersData) {
      this.setState({
        partnerData: nextProps.partnersData.data,
      });
    }
    if (
      nextProps.customerData &&
      nextProps.customerData !== this.props.customerData
    ) {
      let customerData = [];
      nextProps.customerData.data.Customer.map((customer, key) => {
        customerData.push({
          Name_Customer: customer.Name_Customer,
          Name_Partner: customer.Partner.Name_Partner,
          role: customer.roles,
          Created_Date: customer.Created_Date,
          actions: {
            id: customer.UID_Customer,
            partnerId: customer.Partner.UID_Partner,
            name: customer.Name_Customer,
            status: customer.Status,
            permission: customer.permission,
          },
        });
      });
      this.customerData = customerData;
      let meta = this.state.meta;
      if (nextProps.customerData.metaData) {
        meta = {
          page: nextProps.customerData.metaData.pageNumber,
          pageSize: nextProps.customerData.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.customerData.metaData.pageCount,
          total: nextProps.customerData.metaData.totalItemCount,
        };
      }
      this.setState({
        customerData: customerData,
        customerSearchData: customerData,
        wholeData: nextProps.customerData,
        meta: meta,
      });
    }
    if (nextProps.deleteCustomerFailure) {
      showError(nextProps.deleteCustomerFailure);
    }
    if (
      nextProps.deleteCustomerSuccess &&
      nextProps.deleteCustomerSuccess !== this.props.deleteCustomerSuccess
    ) {
      showSuccess("Organisation deleted successfully.");
      const qString = `?pageSize=${this.state.meta.pageSize}&pageIndex=${
        this.state.meta.page - 1
      }`;
      if (this.props.partner_id) {
        this.props.dispatch(getCustomers(qString, this.props.partner_id));
      } else if (this.state.partnerId) {
        this.props.dispatch(getCustomers(qString, this.state.partnerId));
      } else {
        this.props.dispatch(getCustomers(qString));
      }
    }
    if (
      nextProps.customerDataError &&
      nextProps.customerDataError !== this.props.customerDataError
    ) {
      this.setState({ customerDataError: nextProps.customerDataError });
      showError(this.props.t("error.ORGANISATIONS_GETTING_ERROR"));
    }
    if (
      nextProps.deleteCustomerNetworkFailure &&
      nextProps.deleteCustomerNetworkFailure !==
        this.props.deleteCustomerNetworkFailure
    ) {
      showError("Error in deleting organisation.");
    }
    if (
      nextProps.customerStatusData &&
      nextProps.customerStatusData !== this.props.customerStatusData
    ) {
      showSuccess("Customer status has been changed successfully.");
      const qString = `?pageSize=${this.state.meta.pageSize}&pageIndex=${
        this.state.meta.page - 1
      }`;
      if (this.props.partner_id) {
        this.props.dispatch(getCustomers(qString, this.props.partner_id));
      } else if (this.state.partnerId) {
        this.props.dispatch(getCustomers(qString, this.state.partnerId));
      } else {
        this.props.dispatch(getCustomers(qString));
      }
    }
    if (
      nextProps.customerStatusError &&
      nextProps.customerStatusError !== this.props.customerStatusError
    ) {
      showError("Error in changing customer status.");
    }
  };
  //here we are navigating organisation dashboard page
  showDashboard = (customer_id) => {
    this.props.history.push("/dashboard/organisationDashboard/" + customer_id);
  };
  //this function we are using to search customers
  searchCustomer = (event) => {
    if (this.state.customerSearchData && event.target.value) {
      const result = this.state.customerSearchData.filter(
        function (customer) {
          return (
            true ===
            customer.Name_Customer.toLowerCase().includes(
              event.target.value.toLowerCase()
            )
          );
        }.bind(this)
      );
      this.setState({
        customerData: result,
      });
    } else {
      this.setState({
        customerData: this.customerData,
      });
    }
  };
  /*---------------------call this function to call api based on filter --------------- */
  filterOrganisations = (event) => {
    this.setState({ partnerId: event.target.value });
    if (event.target.value === "Organisation") {
      const qString = `?pageSize=${10}&pageIndex=${this.state.meta.page - 1}`;
      this.props.dispatch(getCustomers(qString));
    } else {
      const qString = `?pageSize=${10}&pageIndex=${this.state.meta.page - 1}`;
      this.props.dispatch(getCustomers(qString, event.target.value));
    }
  };
  /*--------opening the create organisations modal----------------- */
  showModal = () => {
    this.setState({
      showCreateOrganisationModal: true,
    });
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showCreateOrganisationModal: !this.state.showCreateOrganisationModal,
    });
  };
  //call this function to handle(means close or open) modal
  handleModalTwo = () => {
    this.setState({
      showUpdateOrganisationModal: !this.state.showUpdateOrganisationModal,
    });
  };
  /*----------call this function to delete customer----*/
  deleteCustomer = (customerId) => {
    const confirm = window.confirm(
      "Are you sure you want delete organisation ?"
    );
    if (confirm) {
      this.props.dispatch(deleteCustomer(customerId));
    } else {
      return false;
    }
  };
  /*----------call this function to update partner----*/
  updateCustomer = (customerId, customerName, partnerID) => {
    let initialValues = {
      name: customerName,
      customerId: customerId,
      partnerId: partnerID,
    };
    this.setState({
      initialValues: initialValues,
      showUpdateOrganisationModal: true,
    });
  };
  /*----------for update customer status---------------- */
  updateCustomerStatus = (customerId, status) => {
    if (status) {
      const confirm = window.confirm(
        "Are you sure you want to disable organisation ?"
      );
      if (confirm) {
        const statusData = {
          customerId: customerId,
          status: status ? false : true,
        };
        this.props.dispatch(updateCustomerStatus(statusData));
      }
    } else {
      const statusData = {
        customerId: customerId,
        status: status ? false : true,
      };
      this.props.dispatch(updateCustomerStatus(statusData));
    }
  };
  /*---------------------render method ------------------*/
  render() {
    return (
      <div>
        <Organisation
          stateValues={this.state}
          showDashboard={this.showDashboard}
          searchCustomer={this.searchCustomer}
          onClickSearchButton={this.onClickSearchButton}
          filterOrganisations={this.filterOrganisations}
          partner_id={
            this.state.partnerId ? this.state.partnerId : this.props.partner_id
          }
          showModal={this.showModal}
          handleModal={this.handleModal}
          handleModalTwo={this.handleModalTwo}
          wholeData={this.state.wholeData}
          deleteCustomer={this.deleteCustomer}
          updateCustomer={this.updateCustomer}
          updateCustomerStatus={this.updateCustomerStatus}
          t={this.props.t}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    customerData: state.customer.customerData,
    customerDataError: state.customer.customerDataError,
    deleteCustomerSuccess: state.customer.deleteCustomerSuccess,
    deleteCustomerFailure: state.customer.deleteCustomerFailure,
    deleteCustomerNetworkFailure: state.customer.deleteCustomerNetworkFailure,
    customerStatusData: state.customer.customerStatusData,
    customerStatusError: state.customer.customerStatusError,
    isRequesting: state.customer.isRequesting,
    partnersData: state.partner.partnerData,
  };
}
OrganisationContainer.propTypes = {
  customerData: PropTypes.array,
  customerDataError: PropTypes.bool,
  partner_id: PropTypes.string,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  isRequesting: PropTypes.bool,
  deleteCustomerSuccess: PropTypes.bool,
  deleteCustomerFailure: PropTypes.string,
  deleteCustomerNetworkFailure: PropTypes.bool,
  customerStatusError: PropTypes.bool,
  customerStatusData: PropTypes.bool,
};
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(OrganisationContainer);
