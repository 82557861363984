/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Rules from "../Components/RulesPage.jsx";
import { getRulesByCriteria } from "../../../../actions/ruleAction";
import { getInstanceByCriteria } from "../../../../actions/instanceAction";
import { unAssignRuleToTeam } from "../../../../actions/teamAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../../helpers/Utility";
import PropTypes from "prop-types";
import queryString from "query-string";
import _ from "lodash";
class RulesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      showCreateRuleModal: false,
      showAssignRuleModal: false,
      loading: false,
      meta: {
        page: 0,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0
      }
    };
  }

  // Here we are extracting and showing selected react-table page from url
  UNSAFE_componentWillMount = () => {
    let searchString = this.props.history.location.search;
    let pageNumber = searchString
      ? queryString.parse(searchString).selectedPage
      : false;
    if (pageNumber) {
      this.setState({ meta: { ...this.state.meta, page: pageNumber } });
      this.setState({ pageNumber });
    }
  };

  componentDidMount = () => {
    // if (this.props.id) {
    //   let formData = {};
    //   formData = {
    //     name: queryString.parse(this.props.names).teamName
    //       ? "team"
    //       : "instance",
    //     id: this.props.id
    //   };
    //   this.props.dispatch(getRulesByCriteria(formData, false));
    // }
    let data = {};
    data = {
      name: "customer",
      id: queryString.parse(this.props.names).customerId
    };
    this.props.dispatch(getInstanceByCriteria(data));

    // Here we are comparing and storing default current url path
    let urlsearch = this.props.history.location.search;
    let splitted = _.split(urlsearch, "&selectedPage=", 1);
    let currentUrl = this.props.history.location.pathname + splitted;
    let currentPath = _.replace(currentUrl, /[&]/g, "~");
    this.setState({ currentPath });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.rulesData) {
      let rulesData = [];
      nextProps.rulesData.modifiedRule.map((ruleData, key) => {
        rulesData.push({
          Name_Rule: ruleData.Name_Rule,
          description: ruleData.Description_Rule,
          Name_Instance: ruleData.Instance.Name_Instance,
          status: ruleData.Rule_Enabled,
          priority: ruleData.Priority_Rule,
          unAssign: {
            teamRuleId: ruleData.TeamRule
              ? ruleData.TeamRule.UID_TeamRule
              : null,
            ruleId: ruleData.UID_Rule
          },
          UID_Instance: ruleData.UID_Instance
        });
      });
      this.rulesData = rulesData;
      let meta = this.state.meta;
      if (nextProps.rulesData.metaData) {
        meta = {
          // page: nextProps.rulesData.metaData.pageNumber, // here its need this one, otherwise page is not updating properly
          pageSize: nextProps.rulesData.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.rulesData.metaData.pageCount,
          total: nextProps.rulesData.metaData.totalItemCount
        };
      }
      this.setState({
        rulesData: rulesData,
        rulesSearchData: rulesData,
        wholeData: nextProps.rulesData,
        rulesDataForInstance: nextProps.rulesDataForInstance,
        meta: meta
      });
    }
    if (
      nextProps.ruleNetworkError &&
      nextProps.ruleNetworkError !== this.props.ruleNetworkError
    ) {
      showError("Something went wrong");
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
    if (nextProps.instancesData) {
      this.setState({
        instancesData: nextProps.instancesData
          ? nextProps.instancesData.modifiedInstance
          : null
      });
    }
    if (
      nextProps.instancesDataError &&
      nextProps.instancesDataError !== this.props.instancesDataError
    ) {
      showError("Error in getting Instances");
    }
    if (
      nextProps.ruleUnAssignedToTeamSuccess &&
      nextProps.ruleUnAssignedToTeamSuccess !==
        this.props.ruleUnAssignedToTeamSuccess
    ) {
      showSuccess(nextProps.ruleUnAssignedToTeamSuccess);
      let formData = {};
      formData = {
        name: "team",
        id: this.props.id
      };
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.state.meta.pageSize
      )
        ? this.state.meta.pageSize
        : 10;
      const qString = `&pageSize=${pageSize}&pageIndex=${0}`;
      this.props.dispatch(getRulesByCriteria(formData, false, qString));
    }
    if (
      nextProps.ruleUnAssignedToTeamFailure &&
      nextProps.ruleUnAssignedToTeamFailure !==
        this.props.ruleUnAssignedToTeamFailure
    ) {
      showError(nextProps.ruleUnAssignedToTeamFailure);
    }
    if (
      nextProps.ruleUnAssignedToTeamNetworkFailure &&
      nextProps.ruleUnAssignedToTeamNetworkFailure !==
        this.props.ruleUnAssignedToTeamNetworkFailure
    ) {
      showError("Something went wrong.");
    }
  };
  //this function we are calling to search members
  searchName = event => {
    if (this.state.rulesSearchData && event.target.value) {
      const result = this.state.rulesSearchData.filter(
        function(data) {
          return (
            true ===
            data.Name_Rule.toLowerCase().includes(
              event.target.value.toLowerCase()
            )
          );
        }.bind(this)
      );
      this.setState({
        rulesData: result
      });
    } else {
      this.setState({
        rulesData: this.rulesData
      });
    }
  };

  // onPageChange React Table Property Function
  pageChange = page => {
    let meta = { ...this.state.meta };
    meta.page = page;
    this.setState({ meta });
    this.setState({ pageNumber: page });
  };

  //here we are navigating rule dashboard page
  showDashboard = (ruleId, teamRuleId) => {
    let namesParse = this.props.names;
    let names = _.split(namesParse, "&selectedPage=", 1);
    let currentPath = this.state.currentPath ? this.state.currentPath : false;
    let pageNumber = this.state.pageNumber;
    this.props.history.push(
      "/dashboard/ruleDashboard/" +
        ruleId +
        names +
        "&teamRuleId=" +
        teamRuleId +
        `&currentPath=${currentPath}` +
        `&selectedPage=${pageNumber}`
    );
  };

  /*--------opening the create instance modal----------------- */
  showModal = () => {
    this.setState({
      showCreateRuleModal: true
    });
  };
  showAssignModal = () => {
    this.setState({
      showAssignRuleModal: true
    });
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showCreateRuleModal: !this.state.showCreateRuleModal
    });
  };
  //call this function to handle(means close or open) assign modal
  handleAssignModal = () => {
    this.setState({
      showAssignRuleModal: !this.state.showAssignRuleModal
    });
  };
  /*---------------------call this function to call api based on filter --------------- */
  filterRules = event => {
    this.setState({ filterInstanceId: event.target.value });
    if (event.target.value == "instances") {
      this.setState({
        rulesData: this.rulesData
      });
    } else {
      // let formData = {};
      // formData = {
      //   name: "instance",
      //   id: event.target.value
      // };
      // this.props.dispatch(getRulesByCriteria(formData, false));
      const result = this.rulesData.filter(
        function(data) {
          return true === data.UID_Instance.includes(event.target.value);
        }.bind(this)
      );
      this.setState({
        rulesData: result
      });
    }
  };
  /*------------------unAssign rule-----------*/
  unAssignRule = ruleId => {
    const confirm = window.confirm("Are you sure you want to un assign rule ?");
    if (confirm) {
      this.props.dispatch(unAssignRuleToTeam(ruleId));
    } else {
      return false;
    }
  };
  /*------------render method-------------------- */
  render() {
    const name = this.props.names
      ? queryString.parse(this.props.names).teamName
        ? queryString
            .parse(this.props.names)
            .teamName.split("_")
            .join(" ")
        : queryString.parse(this.props.names).instanceName
        ? queryString
            .parse(this.props.names)
            .instanceName.split("_")
            .join(" ")
        : ""
      : "";
    const customerName = this.props.names
      ? queryString
          .parse(this.props.names)
          .customerName.split("_")
          .join(" ")
      : "";
    const customerId = this.props.names
      ? queryString.parse(this.props.names).customerId
      : "";
    return (
      <div>
        <Rules
          stateValues={this.state}
          id={this.props.id}
          filterInstanceId={this.state.filterInstanceId}
          searchName={this.searchName}
          customerName={customerName}
          teamName={name}
          customerId={customerId}
          showDashboard={this.showDashboard}
          // rulesData={this.state.rulesData}
          rulesDataForInstance={this.state.rulesDataForInstance}
          wholeData={this.state.wholeData}
          names={this.props.names}
          showModal={this.showModal}
          showAssignModal={this.showAssignModal}
          handleModal={this.handleModal}
          filterRules={this.filterRules}
          handleAssignModal={this.handleAssignModal}
          // showAssignRuleModal={this.state.showAssignRuleModal}
          // showCreateRuleModal={this.state.showCreateRuleModal}
          instancesData={this.state.instancesData}
          unAssignRule={this.unAssignRule}
          pageChange={this.pageChange}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log(state);
  return {
    rulesData: state.rule.rulesData,
    rulesDataForInstance: state.rule.rulesDataOne,
    ruleNetworkError: state.rule.ruleNetworkError,
    isRequesting: state.rule.isRequesting,
    instancesData: state.instance.instancesData,
    instancesDataError: state.instance.instancesDataError,
    ruleUnAssignedToTeamSuccess: state.team.ruleUnAssignedToTeamSuccess,
    ruleUnAssignedToTeamFailure: state.team.ruleUnAssignedToTeamFailure,
    ruleUnAssignedToTeamNetworkFailure:
      state.team.ruleUnAssignedToTeamNetworkFailure
  };
}
RulesContainer.propTypes = {
  id: PropTypes.string,
  teamUsersList: PropTypes.object,
  teamUsersListError: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  rulesData: PropTypes.array,
  ruleNetworkError: PropTypes.bool,
  isRequesting: PropTypes.bool,
  instancesData: PropTypes.array,
  instancesDataError: PropTypes.bool,
  ruleUnAssignedToTeamFailure: PropTypes.string,
  ruleUnAssignedToTeamSuccess: PropTypes.string,
  ruleUnAssignedToTeamNetworkFailure: PropTypes.bool
};
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(RulesContainer);
