import React, { PureComponent } from "react";
import Login from "./Containers/LoginContainer";

class LoginPage extends PureComponent {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}

export default LoginPage;
