import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  CardHeader,
  Input,
} from "reactstrap";

import { Field } from "redux-form";
import { renderTextField } from "../../Common/InputRenderer";
import nowLogo from "assets/img/svg/Argos.svg";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import Button from "reactstrap-button-loader";
import compose from "compose-function";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";
import SideBarToggle from "../../Common/SideBarToggle";
import UserImage from "../../../../assets/img/png/DefaultUser.png";
import { emailRex, mobileNumRex } from "../../../../helpers/Utility";
import ReactPhoneInput from "react-phone-input-2";

class UserProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      isEditable: false,
    };
  }
  handleFileChange = () => {
    this.refs.fileInput.click();
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profile) {
      this.setState({
        profile: nextProps.profile,
      });
    }
  };
  /**
   * call this function to validate register user first name
   */
  validateFirstName = (e) => {
    let profile = this.state.profile;
    if (e.target.value.length <= 20 && e.target.value.length >= 3) {
      profile["firstNameState"] = "has-success";
    } else {
      profile["firstNameState"] = "has-danger";
    }
    this.setState({ profile });
  };
  /**
   * call this function to validate register user last name
   */
  validateLastName = (e) => {
    let profile = this.state.profile;
    if (e.target.value.length <= 20 && e.target.value.length >= 3) {
      profile["lastNameState"] = "has-success";
    } else {
      profile["lastNameState"] = "has-danger";
    }
    this.setState({ profile });
  };
  /**
   * call this function to validate register email
   */
  registerEmail = (e) => {
    let profile = this.state.profile;
    profile["email"] = e.target.value;
    if (emailRex.test(e.target.value)) {
      profile["emailState"] = "has-success";
    } else {
      profile["emailState"] = "has-danger";
    }
    this.setState({ profile });
  };
  /**
   * call this function to validate contact number
   */
  validateContactNumber = (e) => {
    let profile = this.state.profile;
    if (mobileNumRex.test(e.target.value)) {
      profile["contactNumberState"] = "has-success";
    } else {
      profile["contactNumberState"] = "has-danger";
    }
    this.setState({ profile });
  };
  onSubmit = (formProps) => {
    if (!this.state.isEditable) {
      this.setState({
        isEditable: true,
      });
    } else {
      this.props.onSubmit(formProps, this.state.profile);
    }
  };
  /*----------render function-------------------*/
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <div className="full-page-content for-user-profile">
          <div className="login-page">
            <Container>
              <Row className="mt-4 d-lg-none sidebar-btn-landscape">
                <Col>
                  <SideBarToggle />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={8}
                  lg={5}
                  className="ml-auto mr-auto margin-top-style"
                >
                  <Form
                    onSubmit={handleSubmit(this.onSubmit)}
                    className="user-profile-card-style"
                  >
                    {/* <div className="logo-container logo-container change-password-logo">
                      <img src={nowLogo} alt="now-logo" />
                    </div> */}
                    <Card className="card-login custom-card">
                      <CardHeader className="text-center position-relative">
                        <img
                          id="profile_img"
                          src={
                            this.props.filePath
                              ? this.props.filePath
                              : this.props.defaultImage
                              ? this.props.defaultImage
                              : UserImage
                          }
                          alt="user"
                          style={
                            this.state.isEditable
                              ? { filter: "opacity(35%)" }
                              : null
                          }
                          className={
                            this.state.isEditable
                              ? "cursor-pointer img-shadow"
                              : "img-shadow"
                          }
                          onClick={
                            this.state.isEditable
                              ? () => this.handleFileChange()
                              : ""
                          }
                        />
                        {this.state.isEditable && (
                          <div
                            className="position-absolute"
                            style={{
                              color: "#596164",
                              right: 0,
                              left: 0,
                              top: -10,
                            }}
                          >
                            <i
                              className="fa fa-2x fa fa-upload cursor-pointer"
                              aria-hidden="true"
                              onClick={
                                this.state.isEditable
                                  ? () => this.handleFileChange()
                                  : ""
                              }
                            />
                          </div>
                        )}
                        <input
                          type="file"
                          name="file"
                          id="file"
                          ref="fileInput"
                          multiple
                          className="CiList-inputs form-control-file"
                          onChange={(e) => this.props.uploadImage(e)}
                          style={{ display: "none" }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="10" lg="10" className="mx-auto my-auto">
                            <FormGroup
                              row
                              className={
                                this.state.profile.firstNameState
                                  ? "has-label mx-auto mb-3 " +
                                    this.state.profile.firstNameState
                                  : "has-label mx-auto mb-3"
                              }
                            >
                              <Label className="font-weight-bold">
                                First Name
                              </Label>
                              <Field
                                className="form-control mb-2"
                                name="firstName"
                                component={renderTextField}
                                onChange={(e) => this.validateFirstName(e)}
                                readOnly={this.state.isEditable ? false : true}
                              />
                            </FormGroup>
                            <FormGroup
                              row
                              className={
                                this.state.profile.lastNameState
                                  ? "has-label mx-auto mb-3 " +
                                    this.state.profile.lastNameState
                                  : "has-label mx-auto mb-3 "
                              }
                            >
                              <Label className="font-weight-bold">
                                Last Name
                              </Label>
                              <Field
                                className="form-control mb-2"
                                name="lastName"
                                component={renderTextField}
                                onChange={(e) => this.validateLastName(e)}
                                readOnly={this.state.isEditable ? false : true}
                              />
                            </FormGroup>
                            <FormGroup row className={"mx-auto mb-3 "}>
                              <Label className="font-weight-bold">Email</Label>
                              <Field
                                className="form-control mb-2"
                                name="email"
                                component={renderTextField}
                                readOnly={true}
                              />
                            </FormGroup>
                            <FormGroup
                              row
                              className={
                                this.state.profile.contactNumberState
                                  ? "has-label mx-auto mb-3 " +
                                    this.state.profile.contactNumberState
                                  : "has-label mx-auto mb-3 "
                              }
                            >
                              <Label className="font-weight-bold">
                                Contact Number
                              </Label>
                              <FormGroup
                              // className={
                              //   this.props.register.contactNumberState
                              //     ? 'has-label' +
                              //         this.props.register.contactNumberState
                              //     : 'has-label'
                              // }
                              >
                                <ReactPhoneInput
                                  inputExtraProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true,
                                    readOnly: this.state.isEditable
                                      ? false
                                      : true,
                                  }}
                                  regions={["europe", "asia"]}
                                  enableSearch={true}
                                  country={"gr"}
                                  countryCodeEditable={false}
                                  disabled={
                                    this.state.isEditable ? false : true
                                  }
                                  value={this.props.phone}
                                  onChange={this.props.handleOnChange}
                                  readOnly={
                                    this.state.isEditable ? false : true
                                  }
                                />
                              </FormGroup>

                              {/* <Field
                                className="form-control mb-2"
                                name="mobileNumber"
                                component={renderTextField}
                                onChange={e => this.validateContactNumber(e)}
                                readOnly={this.state.isEditable ? false : true}
                              /> */}
                              {/* */}
                              {/* <Input
                                className="form-input-styles"
                                type="text"
                                placeholder="Enter contact number"
                                name="email"
                                onChange={e => this.props.registerEmail(e)}
                                defaultValue={this.props.userData.contactNumber}
                                readOnly={true}
                              /> */}
                            </FormGroup>
                            <Button
                              block
                              color="primary"
                              type="submit"
                              className="mb-3"
                              loading={this.props.loading}
                            >
                              <span className="font-weight-bold">
                                {this.state.isEditable
                                  ? "Save"
                                  : "Click here to edit profile"}
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center text-muted">
                        <span>
                          <small>Note: valid image format:jpg,jpeg,png</small>
                        </span>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#40535D" }}
        />
      </div>
    );
  }
}
UserProfilePage.propTypes = {
  propValues: PropTypes.object,
  userData: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  forgotPassword: PropTypes.object,
  validateConfirmPassword: PropTypes.func,
  validatePassword: PropTypes.func,
  loading: PropTypes.bool,
  updatePassword: PropTypes.object,
  changePasswordScreen: PropTypes.string,
  initialValues: PropTypes.object,
  profile: PropTypes.object,
};
// UserProfilePage = reduxForm({
//   form: "Engagement",
//   //validate,
//   // asyncValidate,
//   enableReinitialize: true
// })(UserProfilePage);
// function mapStateToProps(state) {
//   let initialValues;
//   if (state.login.userProfileData) {
//     initialValues = {
//       firstName: state.login.userProfileData.First_Name,
//       lastName: state.login.userProfileData.Last_Name,
//       email: state.login.userProfileData.Email_User,
//       contactNumber: state.login.userProfileData.Contact_Number,
//       profileImage: state.login.userProfileData.Profile_Image
//     };
//   }
//   return {
//     initialValues: initialValues
//   };
// }
export default compose(
  translate,
  withRouter,
  connect()
)(UserProfilePage);
