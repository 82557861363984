import React, { PureComponent } from "react";
import PerspectivesPageContainer from "./Containers/PerspectivesPageContainer";

class HomePage extends PureComponent {
  render() {
    return (
      <div>
        <PerspectivesPageContainer />
      </div>
    );
  }
}

export default HomePage;
