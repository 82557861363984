import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "reactstrap";

import  unassignedIcon from "assets/img/png/unassigned-insances.png";
import orgainizationIcon from "assets/img/png/partener4.png";
import instancesIcon from "assets/img/png/partener2.png";
import  supportTeamIcon from "assets/img/png/partener3.png";
import  alertIcon from "assets/img/png/partener1.png";
import openAlert from "assets/img/png/open-alert.png";
import userUnassigned from "assets/img/png/user-unassigned.png";
import userAssigned from "assets/img/png/user-assigned.png";

function TeamsDashboardRole(props) {
  return (
    <div className="team-dashboard-role">
      <Container>
        <Row>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image" >
                    <img  width="33" src={ openAlert} alt="Card image cap"  />
                </div>
                <div >
                <CardTitle className="team-dashboard-role-card-body-title">Open Alerts</CardTitle>
                <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                   {props.alertData && props.alertData.Opened ||  '0' }
                </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
           
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image" >
                    <img  width="33" src={ openAlert} alt="Card image cap"  />
                </div>
                <div >
                <CardTitle className="team-dashboard-role-card-body-title">Closed Alerts</CardTitle>
                <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                {props.alertData && props.alertData.Closed ||  '0' }
                </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image" >
                    <img  width="33" src={userUnassigned} alt="Card image cap"  />
                </div>
                <div >
                <CardTitle className="team-dashboard-role-card-body-title">Unassigned</CardTitle>
                <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                {props.alertData && props.alertData.Unassigned ||  '0' }
                </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image" >
                    <img  width="33" src={ userAssigned} alt="Card image cap"  />
                </div>
                <div >
                <CardTitle className="team-dashboard-role-card-body-title">Assigned</CardTitle>
                <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                {props.alertData && props.alertData.Assigned ||  '0' }
                </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default TeamsDashboardRole;
