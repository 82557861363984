import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse, NavItem, Row, Col } from "reactstrap";
// react plugin for creating notifications over the dashboard
//import NotificationAlert from "react-notification-alert";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import { Button } from "components";

import nowLogo from "assets/img/png/Argos.png";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import { logout } from "../../actions/loginAction";
import {
  isPartnerVisible,
  isCustomerVisible,
  isTeamVisible,
  isIssueVisible,
  isGlobalUserVisible,
  getStartDateSevenDaysAgo
} from "../../helpers/Utility";
import { getNotifications } from "../../actions/notificationAction";

var ps;
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components") !== "" ? true : false,
      openForms: this.activeRoute("/forms") !== "" ? true : false,
      openTables: this.activeRoute("/tables") !== "" ? true : false,
      openMaps: this.activeRoute("/maps") !== "" ? true : false,
      openPages: this.activeRoute("/pages") !== "" ? true : false,
      notifications: [],
      startDate: getStartDateSevenDaysAgo()
    };
    this.activeRoute.bind(this);
    this.minimizeSidebar = this.minimizeSidebar.bind(this);
    this.messageListener = null;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  minimizeSidebar() {
    // var message = "Sidebar mini ";
    // if (document.body.classList.contains("sidebar-mini")) {
    //   message += "deactivated...";
    // } else {
    //   message += "activated...";
    // }
    document.body.classList.toggle("sidebar-mini");
  }
  componentDidMount() {

    const userToken = localStorage.getItem("authToken");
    if (window.ReactNativeWebView && userToken) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "login", userToken: userToken })
      );
    }

    this.getNotificationsData();
    this.notificationInterval = setInterval(this.getNotificationsData, 30000); // Call getNotificationsData every 30 seconds
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    if (document.body.classList.value !== "sidebar-mini") {
      return false;
    } else {
      this.minimizeSidebar();
    }
  }

  getNotificationsData = () => {
    const query = `?date=${this.state.startDate}`;
    this.props.dispatch(getNotifications(query));
  };

  componentWillUnmount() {
    clearInterval(this.notificationInterval);

    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    // to stop the warning of calling setState of unmounted component
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.notificationsData &&
      nextProps.notificationsData !== this.props.notificationsData
    ) {
      this.setState({
        notifications: nextProps.notificationsData.notificationList
      });
    }
  };
  /*------------function for navigate user profile ------------*/
  showUserProfile = () => {
    this.props.history.push("/dashboard/user-profile");
  };
  /*-----------function for logout --------------*/
  logout = async () => {
    const userToken = localStorage.getItem("authToken");
    if (window.ReactNativeWebView && userToken) {
      await window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "logout", userToken: userToken })
      );
    }
    this.props.dispatch(logout());
    localStorage.clear();
    this.props.history.push("/pages/login-page");
  };
  render() {
    const { notifications } = this.state;

    // Count the number of notifications with status "Unread"
    const unReadNotificationsCount =
      notifications.length &&
      notifications.filter(notification => notification.Status === "Unread")
        .length;

    return (
      <div>
        <div className="sidebar sidebar-color" data-color="#40535D">
          <div className="logo">
              <div className="logo-img">
                <img src={nowLogo} alt="argos-logo" className="logo-width" />
              </div>
              <div className="navbar-minimize">
              <Button
                simple
                neutral
                icon
                round
                id="minimizeSidebar"
                onClick={this.minimizeSidebar}
              >
                <i className="now-ui-icons arrows-1_minimal-left visible-on-sidebar-regular" />
                <i className="fa fa-thumb-tack visible-on-sidebar-mini" />
                {/* <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" /> */}
              </Button>
            </div>
          </div>
          <div className="sidebar-wrapper mt-4" ref="sidebar">
            <Nav>
              {this.props.routes.map((prop, key) => {
                /*----------based on the user module response we shows side bar-----------*/
                if (!isPartnerVisible() && prop.name === "Partners")
                  return null;
                if (!isCustomerVisible() && prop.name === "Organisations")
                  return null;
                if (!isTeamVisible() && prop.name === "Teams") return null;
                if (!isIssueVisible() && prop.name === "Issues") return null;
                if (!isGlobalUserVisible() && prop.name === "Global Users")
                  return null;
                if (prop.redirect) return null;
                if (prop.collapse) {
                  var st = {};
                  st[prop["state"]] = !this.state[prop.state];
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      <a
                        data-toggle="collapse"
                        aria-expanded={this.state[prop.state]}
                        onClick={() => this.setState(st)}
                      >
                        <i className={"now-ui-icons " + "business_bank"} />
                        <p>
                          {prop.name}
                          <b className="caret" />
                        </p>
                      </a>
                      <Collapse isOpen={this.state[prop.state]}>
                        <ul className="nav">
                          {prop.views.map((prop, key) => {
                            if (prop.redirect) return null;
                            return (
                              <li
                                className={this.activeRoute(prop.path)}
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  activeClassName="active"
                                  className="sideBar-routes"
                                >
                                  <span className="sidebar-mini-icon">
                                    {prop.mini}
                                  </span>
                                  <span className="sidebar-normal">
                                    {prop.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                }
                return (
                  <li
                    className={`${this.activeRoute(prop.path)} ${
                      prop.name === "Organisations" || prop.name === "Partners"
                        ? "mobile-hidden"
                        : ""
                    }`}
                    key={key}
                  >
                    {prop.name && (
                      <NavLink
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <img src={prop.icon} className="prop-icons mr-2" />
                        <span className="fade-in">{prop.name}</span>
                        {prop.count && unReadNotificationsCount > 0 && (
                          <span className="notification-count">
                            {unReadNotificationsCount}
                          </span>
                        )}
                      </NavLink>
                    )}
                  </li>
                );
              })}
              <NavItem>
                <a className="logout-style" onClick={e => this.logout()}>
                  <i className="fa fa-sign-out margin-right-css" />
                  <span className="fade-in">
                    {this.props.t("login.LOGOUT")}
                  </span>
                </a>
              </NavItem>
            </Nav>
            <div className="sidebar-footer">
              <Row>
                <Col className="text-center">
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.showUserProfile()}
                  >
                    <i className="fa fa-user-circle fa-2x text-white" />
                  </a>
                </Col>
                {/* <Col>
                  <a href="javascript:void(0)">
                    <i className="fa fa-comments fa-2x text-white" />
                  </a>
                </Col> */}
                {/* <Col>
                  <a href="javascript:void(0)"> */}
                {/* <img src={footerIcons} alt="footer" /> */}
                {/* <i className="fa fa-bell fa-2x text-white" />
                  </a>
                </Col> */}
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    notificationsData: state.notifications.notificationsData
  };
}

export default compose(
  withRouter,
  translate,
  connect(mapStateToProps)
)(Sidebar);
