import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
  FormGroup
} from "reactstrap";

import { Field } from "redux-form";
import { renderTextField } from "../../Common/InputRenderer";
import nowLogo from "assets/img/png/Argos.png";
import PropTypes from "prop-types";
import Button from "reactstrap-button-loader";
import compose from "compose-function";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-multi-lang";
import SideBarToggle from "../../Common/SideBarToggle";

class UpdatePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  /*----------render function-------------------*/
  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <div>
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row className="mt-4 d-lg-none sidebar-btn-landscape">
                <Col>
                  <SideBarToggle />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={8}
                  lg={5}
                  className="ml-auto mr-auto margin-top-style"
                >
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="logo-container logo-container change-password-logo">
                      <img src={nowLogo} alt="now-logo" />
                    </div>
                    <Card className="card-login custom-card card-color">
                      <CardBody>
                        <p className="text-center text-white py-3">
                          {this.props.changePasswordScreen === "2"
                            ? this.props.t("login.UPDATE_PASSWORD_TITLE1")
                            : this.props.t("login.UPDATE_PASSWORD_TITLE2")}
                        </p>
                        <Row>
                          <Col md="10" className="mx-auto">
                            <FormGroup
                              className={
                                this.props.updatePassword.oldPasswordState
                                  ? "has-label " +
                                    this.props.updatePassword.oldPasswordState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="password"
                                placeholder={this.props.t("login.OLD_PASSWORD")}
                                name="currentPassword"
                                onFocus={e =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={e =>
                                  this.props.validatePassword(e, true)
                                }
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.updatePassword.passwordState
                                  ? "has-label " +
                                    this.props.updatePassword.passwordState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="password"
                                placeholder={this.props.t("login.NEW_PASSWORD")}
                                name="password"
                                onFocus={e =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={e =>
                                  this.props.validatePassword(e, false)
                                }
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.updatePassword.confirmpasswordState
                                  ? "has-label " +
                                    this.props.updatePassword
                                      .confirmpasswordState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="password"
                                placeholder={this.props.t(
                                  "login.CONFIRM_PASSWORD"
                                )}
                                name="newPassword"
                                onFocus={e =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={e =>
                                  this.props.validateConfirmPassword(e)
                                }
                              />
                            </FormGroup>
                            <Button
                              block
                              color="primary"
                              type="submit"
                              className="mb-3"
                              loading={this.props.loading}
                            >
                              {this.props.t("login.SUBMIT")}
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter />
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#40535D" }}
        />
      </div>
    );
  }
}
UpdatePasswordPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  forgotPassword: PropTypes.object,
  validateConfirmPassword: PropTypes.func,
  validatePassword: PropTypes.func,
  loading: PropTypes.bool,
  updatePassword: PropTypes.object,
  changePasswordScreen: PropTypes.string
};
export default compose(translate, withRouter, connect())(UpdatePasswordPage);
