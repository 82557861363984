import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";

import { Line } from "react-chartjs-2";
import Assigned from "assets/img/png/assigned.png";
import Unassigned from "assets/img/png/unassigned.png";

const options = {
  legend: {
    display: false,
    position: "bottom",
    labels: {
      boxWidth: 10,
    },
  },
};
 
function OrganizationAlertsActivity({ alertsActivityData , customerIndex }) {
  // console.log("test alerts",alertsActivityData[customerIndex] )
  let alertActivityGraphData = alertsActivityData[customerIndex]
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels: [],
    datasets: [
      {
        label: "Resolved",
        data: [],
        fill: false,
        borderColor: "#FA7A50",
        backgroundColor: "#FA7A50",
      },
      {
        label: "Opened",
        data: [],
        fill: false,
        borderColor: "#1E7FF1",
        backgroundColor: "#1E7FF1",
      },
    ],
  };

  if (alertActivityGraphData && alertActivityGraphData.customerAlerts != null) {
    alertActivityGraphData.customerAlerts.map((alert) => {
      if (
        monthNames.some((res) => res.includes(alert.Created_Date.slice(0, 3)))
      ) {
        data.labels.push(alert.Created_Date.slice(0, 3));
      } else {
        const dayData = new Date(alert.Created_Date).toLocaleString(undefined, {
          day: "2-digit",
        });
        data.labels.push(
          dayData + " " + monthNames[new Date(alert.Created_Date).getMonth()]
        );
      }
      data.datasets[0].data.push(alert.closeAlertCount);
      data.datasets[1].data.push(alert.openAlertCount);
    });
  }

  return (
    <div className="team-dashboard-line-graph">
      <Container>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <CardHeader className="card-items-inline">
                <p>Alerts Activity</p>
              </CardHeader>
              <hr />
              <CardBody>
                <Line
                  data={data}
                  height="100"
                  responsive="true"
                  options={options}
                />
              </CardBody>
              <CardFooter>
                <div className="status-right text-center">
                  <span className="dashboard-unasigned dashboar-low">
                    <i className="dahboard-alert-low">
                      <img src={Unassigned} width="10" />
                    </i>
                    Opened
                  </span>
                  <span className="dashboard-assigned dashboar-high">
                    <i className="dahboard-alert-high">
                      <img src={Assigned} width="10" />
                    </i>
                    Resolved
                  </span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default OrganizationAlertsActivity;
