/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Information from "../Components/InformationPage";
import PropTypes from "prop-types";
class InformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  UNSAFE_componentWillReceiveProps = nextProps => {};
  render() {
    return (
      <div>
        <Information />
      </div>
    );
  }
}
InformationContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
};
function mapStateToProps(state) {
  return {};
}
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "info-form"
  }),
  connect(mapStateToProps)
)(InformationContainer);
