/* eslint-disable default-case */
import { AUTH_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    token: null,
    isAuthenticated: false,
    isAuthenticating: false,
  },
  action
) {
  switch (action.type) {
    case AUTH_CONST.LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        statusText: null,
        errorText: null,
        token: null,
        loginError: false,
        loginErrorDescription: false,
        loginNetworkError: null,
        isTempPassword: null,
      };
    case AUTH_CONST.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        loginData: action.payload.response.data,
        loginSuccess: action.payload.response.data.isSuccess,
        token: action.payload.response
          ? action.payload.response.data.data.auth_token
          : null,
        loginError: false,
        loginErrorDescription: false,
        isTempPassword: action.payload.response.data.data.user
          ? action.payload.response.data.data.user.isTempPassword
          : false,
      };
    case AUTH_CONST.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        loginError: !action.payload.error.isSuccess ? true : false,
        loginErrorDescription: action.payload
          ? action.payload.error.statusText
          : "",
        loginNetworkError: action.payload.error.netWorkError
          ? "Network Error:Please check your Network"
          : "",
        statusCode: action.payload.error.statusCode,
        token: null,
        isTempPassword: null,
      };
    case AUTH_CONST.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        updatePasswordFailed: false,
        updatePasswordSuccess: false,
        updatePasswordNetworkFailed: false,
      };
    case AUTH_CONST.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordFailed: false,
        updatePasswordSuccess: action.payload.response.data.isSuccess,
        updatePasswordNetworkFailed: false,
      };
    case AUTH_CONST.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePasswordFailed: action.payload.response ? true : false,
        updatePasswordNetworkFailed: action.payload.error ? true : false,
        updatePasswordSuccess: false,
      };
    case AUTH_CONST.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordFailure: false,
      };
    case AUTH_CONST.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: action.payload.response.data.isSuccess,
        forgotPasswordFailure: false,
      };
    case AUTH_CONST.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordFailure: !action.payload.error.isSuccess,
      };
    case AUTH_CONST.USER_MODULE_REQUEST:
      return {
        ...state,
        userModuleData: null,
        userModuleDataError: false,
        userModuleDataNetworkError: false,
      };
    case AUTH_CONST.USER_MODULE_SUCCESS:
      return {
        ...state,
        userModuleData: action.payload
          ? action.payload.response.data.data
          : null,
        userModuleDataError: false,
        userModuleDataNetworkError: false,
      };
    case AUTH_CONST.USER_MODULE_FAILURE:
      return {
        ...state,
        userModuleData: null,
        userModuleDataError: action.payload.response ? true : false,
        userModuleDataNetworkError: action.payload.error ? true : false,
      };
    case AUTH_CONST.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileSuccess: false,
        updateProfileFailure: false,
        updateProfileNetworkFailure: false,
      };
    case AUTH_CONST.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileSuccess: action.payload ? true : false,
        updateProfileFailure: false,
        updateProfileNetworkFailure: false,
      };
    case AUTH_CONST.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileSuccess: false,
        updateProfileFailure: action.payload.response
          ? action.payload.response.data.error.errorDescription
          : false,
        updateProfileNetworkFailure: action.payload.error ? true : false,
      };
    case AUTH_CONST.GET_USER_STATISTICS_REQUEST:
      return {
        ...state,
        getUserStatisticsSuccess: false,
        getUserStatisticsFailure: false,
        getUserStatisticsNetworkFailure: false,
      };
    case AUTH_CONST.GET_USER_STATISTICS_SUCCESS:
      return {
        ...state,
        getUserStatisticsSuccess: action.payload ? true : false,
        getUserStatisticsFailure: false,
        getUserStatisticsNetworkFailure: false,
      };
    case AUTH_CONST.GET_USER_STATISTICS_FAILURE:
      return {
        ...state,
        getUserStatisticsSuccess: false,
        getUserStatisticsFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        getUserStatisticsNetworkFailure: action.payload.error ? true : false,
      };
    case AUTH_CONST.UPDATE_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        updateProfileImageSuccess: false,
        updateProfileImageFailure: false,
        updateProfileImageNetworkFailure: false,
      };
    case AUTH_CONST.UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        updateProfileImageSuccess: action.payload ? true : false,
        updateProfileImageFailure: false,
        updateProfileImageNetworkFailure: false,
      };
    case AUTH_CONST.UPDATE_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        updateProfileImageSuccess: false,
        updateProfileImageFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateProfileImageNetworkFailure: action.payload.error ? true : false,
      };
    case AUTH_CONST.GET_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        getProfileImageSuccess: false,
        getProfileImageFailure: false,
        getProfileImageNetworkFailure: false,
      };
    case AUTH_CONST.GET_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        getProfileImageSuccess: action.payload
          ? action.payload.response.data.data.Path
          : false,
        getProfileImageFailure: false,
        getProfileImageNetworkFailure: false,
      };
    case AUTH_CONST.GET_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        getProfileImageSuccess: false,
        getProfileImageFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        getProfileImageNetworkFailure: action.payload.error ? true : false,
      };
    //**--------for storing user data ------------*/
    case AUTH_CONST.STORE_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfileData: false,
      };
    case AUTH_CONST.STORE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileData: action.payload.response.data,
      };
  }
  return state;
}
