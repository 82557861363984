/**
 * @action        : partnerAction
 * @description   :
 * @Created by    : INNOMICK
 */

import { PARTNER_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [userInvitation description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for get partners
export function getPartners(qString) {
  let url;
  if (qString) {
    url = `${BASE_URL}/Partners${qString}`;
  } else {
    url = `${BASE_URL}/Partners`;
  }
  return dispatch => {
    dispatch(base.getRequest(PARTNER_CONST.GET_ALL_PARTNER_REQUEST));
    AXIOS_INSTANCE.get(url)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PARTNER_CONST.GET_ALL_PARTNER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PARTNER_CONST.GET_ALL_PARTNER_FAILURE, {
              error: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PARTNER_CONST.GET_ALL_PARTNER_FAILURE, {
            error: {
              statusText: error,
              netWorkError: true
            }
          })
        );
      });
  };
}

// action for create partner
export function createPartner(formData) {
  return dispatch => {
    dispatch(base.getRequest(PARTNER_CONST.CREATE_PARTNER_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/CreatePartner", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PARTNER_CONST.CREATE_PARTNER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PARTNER_CONST.CREATE_PARTNER_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PARTNER_CONST.CREATE_PARTNER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update partner
export function updatePartner(formData) {
  return dispatch => {
    dispatch(base.getRequest(PARTNER_CONST.UPDATE_PARTNER_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/UpdatePartner", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PARTNER_CONST.UPDATE_PARTNER_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PARTNER_CONST.UPDATE_PARTNER_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PARTNER_CONST.UPDATE_PARTNER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete parter
export function deletePartner(partnerId) {
  return dispatch => {
    dispatch(base.getRequest(PARTNER_CONST.DELETE_PARTNER_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/Partner?partnerId=${partnerId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PARTNER_CONST.DELETE_PARTNER_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PARTNER_CONST.DELETE_PARTNER_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PARTNER_CONST.DELETE_PARTNER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update partner status
export function updatePartnerStatus(formData) {
  return dispatch => {
    dispatch(base.getRequest(PARTNER_CONST.UPDATE_PARTNER_STATUS_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updatePartnerStatus", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(PARTNER_CONST.UPDATE_PARTNER_STATUS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(PARTNER_CONST.UPDATE_PARTNER_STATUS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(PARTNER_CONST.UPDATE_PARTNER_STATUS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
