/* eslint-disable default-case */
import { DASHBOARD_CONST } from "../actions/actionTypes";

export default function reducer(
  state = {
    isRequesting: true,
    getUserStatisticsSuccess: {}
  },
  action
) {
  switch (action.type) {
    case DASHBOARD_CONST.GET_USER_STATISTICS_REQUEST:
      return {
        ...state,
        getUserStatisticsSuccess: false,
        getUserStatisticsFailure: false,
        getUserStatisticsNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_USER_STATISTICS_SUCCESS:
      return {
        ...state,
        getUserStatisticsSuccess: action.payload
          ? action.payload.response.data
          : false,
        getUserStatisticsFailure: false,
        getUserStatisticsNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_USER_STATISTICS_FAILURE:
      return {
        ...state,
        getUserStatisticsSuccess: false,
        getUserStatisticsFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        getUserStatisticsNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_REQUEST:
      return {
        ...state,
        orgDashboardGraphDataSuccess: false,
        orgDashboardGraphDataFailure: false,
        orgDashboardGraphDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        orgDashboardGraphDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        orgDashboardGraphDataFailure: false,
        orgDashboardGraphDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_ALERT_AND_NOTIFICATION_FAILURE:
      return {
        ...state,
        orgDashboardGraphDataSuccess: false,
        orgDashboardGraphDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        orgDashboardGraphDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_REQUEST:
      return {
        ...state,
        orgDashboardSuccess: false,
        orgDashboardFailure: false,
        orgDashboardNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_SUCCESS:
      return {
        ...state,
        orgDashboardSuccess: action.payload
          ? action.payload.response.data
          : false,
        orgDashboardFailure: false,
        orgDashboardNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_ORGANIZATION_ALERTS_FAILURE:
      return {
        ...state,
        orgDashboardSuccess: false,
        orgDashboardFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        orgDashboardNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_PARTNER_DASHBOARD_REQUEST:
      return {
        ...state,
        partnerRecentAlertsDataSuccess: false,
        partnerRecentAlertsDataFailure: false,
        partnerRecentAlertsDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_PARTNER_DASHBOARD_SUCCESS:
      return {
        ...state,
        partnerRecentAlertsDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        partnerRecentAlertsDataFailure: false,
        partnerRecentAlertsDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_PARTNER_DASHBOARD_FAILURE:
      return {
        ...state,
        partnerRecentAlertsDataSuccess: false,
        partnerRecentAlertsDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        partnerRecentAlertsDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };


    case DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_REQUEST:
      return {
        ...state,
        partnerDashboardTableDataSuccess: false,
        partnerDashboardTableDataFailure: false,
        partnerDashboardTableDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_SUCCESS:
      return {
        ...state,
        partnerDashboardTableDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        partnerDashboardTableDataFailure: false,
        partnerDashboardTableDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_CUSTOMER_WISE_DATA_FAILURE:
      return {
        ...state,
        partnerDashboardTableDataSuccess: false,
        partnerDashboardTableDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        partnerDashboardTableDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_DASHBOARD_INFO_REQUEST:
      return {
        ...state,
        partnerDashboardAlertDataSuccess: false,
        partnerDashboardAlertDataFailure: false,
        partnerDashboardAlertDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        partnerDashboardAlertDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        partnerDashboardAlertDataFailure: false,
        partnerDashboardAlertDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_DASHBOARD_INFO_FAILURE:
      return {
        ...state,
        partnerDashboardAlertDataSuccess: false,
        partnerDashboardAlertDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        partnerDashboardAlertDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_TEAM_USERS_REQUEST:
      return {
        ...state,
        partnerTeamOverviewDataSuccess: false,
        partnerTeamOverviewDataFailure: false,
        partnerTeamOverviewDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_TEAM_USERS_SUCCESS:
      return {
        ...state,
        partnerTeamOverviewDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        partnerTeamOverviewDataFailure: false,
        partnerTeamOverviewDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_TEAM_USERS_FAILURE:
      return {
        ...state,
        partnerTeamOverviewDataSuccess: false,
        partnerTeamOverviewDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        partnerTeamOverviewDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_TEAM_ALERTS_REQUEST:
      return {
        ...state,
        teamDashboardDataSuccess: false,
        teamDashboardDataFailure: false,
        teamDashboardDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_TEAM_ALERTS_SUCCESS:
      return {
        ...state,
        teamDashboardDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        teamDashboardDataFailure: false,
        teamDashboardDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_TEAM_ALERTS_FAILURE:
      return {
        ...state,
        teamDashboardDataSuccess: false,
        teamDashboardDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        teamDashboardDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };

    case DASHBOARD_CONST.GET_TEAM_USER_ALERTS_REQUEST:
      return {
        ...state,
        teamDashboardActivityDataSuccess: false,
        teamDashboardActivityDataFailure: false,
        teamDashboardActivityDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_TEAM_USER_ALERTS_SUCCESS:
      return {
        ...state,
        teamDashboardActivityDataSuccess: action.payload
          ? action.payload.response.data
          : false,
        teamDashboardActivityDataFailure: false,
        teamDashboardActivityDataNetworkFailure: false,
        isRequesting: false
      };
    case DASHBOARD_CONST.GET_TEAM_USER_ALERTS_FAILURE:
      return {
        ...state,
        teamDashboardActivityDataSuccess: false,
        teamDashboardActivityDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        teamDashboardActivityDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };
      
    case DASHBOARD_CONST.GET_USER_INFO_REQUEST:
      return {
        ...state,
        dashboardDataSuccess: false,
        dashbaordDataFailure: false,
        dashbaordDataNetworkFailure: false,
        isRequesting: true
      };
    case DASHBOARD_CONST.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        dashboardDataSuccess: action.payload
          ? action.payload.response.data
          : false,
          dashbaordDataFailure: false,
          dashbaordDataNetworkFailure: false,
        isRequesting: false
      };  
    case DASHBOARD_CONST.GET_USER_INFO_FAILURE:
      return {
        ...state,
        dashboardDataSuccess: false,
        dashbaordDataFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
          dashbaordDataNetworkFailure: action.payload.error ? true : false,
        isRequesting: false
      };  
  }
  return state;
}
