import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from "reactstrap";
import SideBarToggle from "../../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import CardComponent from "../../../Common/CardComponent";
import {
  getInstanceIssuesTypeCount,
  showIssuesStatus,
  dateFormat,
  issueDetails,
  organisationDashboardDetails,
  confirmPermissions
} from "../../../../../helpers/Utility";
import BreadcrumbComponent from "../../../Common/BreadCrumbComponent";
import CreateInstanceModal from "../../../Modals/CreateInstanceModal";
import queryString from "query-string";
import LoaderIcon from "../../../../../assets/img/Loader.gif";

function instanceDashboard(props) {
  const instanceLogs = props.stateValues ? 
                          props.stateValues.instanceLogsData ? 
                            props.stateValues.instanceLogsData : "" 
                          : "";
  /**const for permissions array */
  const permissions = props.stateValues.instanceStatisticsData
    ? props.stateValues.instanceStatisticsData.permissions
    : [];
  /*const for individual permissions */
  const isDeletable = confirmPermissions(permissions, "isDeletable");
  const isEditable = confirmPermissions(permissions, "isEditable");
  const isAnyOneEditDelete = isDeletable || isEditable;
  const { Name_Instance, Description_Instance, UID_Instance, Customer } = props
    .stateValues.instanceStatisticsData
    ? props.stateValues.instanceStatisticsData
    : {};
  const Customer_Name = Customer ? Customer.Name_Customer : "";
  const CustomerId = Customer ? Customer.UID_Customer : "";
  /*const for ruleDetails */
  const ruleDetails = [
    {
      info: organisationDashboardDetails.total_Rules,
      value: props.stateValues.instanceStatisticsData
        ? props.stateValues.instanceStatisticsData.Rules.length
        : 0
    }
  ];
  /*const for ruleDetails */
  const agentDetails = [
    {
      info: organisationDashboardDetails.total_Agents,
      value: props.stateValues.instanceStatisticsData
        ? props.stateValues.instanceStatisticsData.Agents.length
        : 0
    }
  ];
  /*const for issueDetails */
  const issuesDetails = [
    {
      info: issueDetails.unAssigned_Name,
      value: props.stateValues.instanceStatisticsData
        ? getInstanceIssuesTypeCount(
            props.stateValues.instanceStatisticsData.Alerts,
            issueDetails.unAssigned_Number
          )
        : 0
    },
    {
      info: issueDetails.assigned_Name,
      value: props.stateValues.instanceStatisticsData
        ? getInstanceIssuesTypeCount(
            props.stateValues.instanceStatisticsData.Alerts,
            issueDetails.assigned_Number
          )
        : 0
    },
    {
      info: issueDetails.closed_Name,
      value: props.stateValues.instanceStatisticsData
        ? getInstanceIssuesTypeCount(
            props.stateValues.instanceStatisticsData.Alerts,
            issueDetails.closed_Number
          )
        : 0
    }
  ];
  /*calling function to copy instanceKey */
  const copyInstanceKey = () => {
    let copyText = document.getElementById("key");
    let textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };
  return (
    <div>
      {props.stateValues.showUpdateInstanceModal && (
        <CreateInstanceModal
          isOpen={props.stateValues.showUpdateInstanceModal}
          handleModal={props.handleModal}
          initialValues={props.stateValues.initialValues}
          instanceId={UID_Instance}
          customerId={CustomerId}
        />
      )}
      {props.stateValues.instanceCertificatePath && (
        <a
          id="linkId"
          href={
            props.stateValues.instanceCertificatePath
              ? props.stateValues.instanceCertificatePath
              : "#"
          }
          target="_blank"
        />
      )}

      <div className="full-page-content">
        <div className="login-page">
          <Container>
            {props.stateValues.isRequesting && (
              <div className="table-tr-loader">
                <img
                  className="loader-inside-dashboards"
                  src={LoaderIcon}
                  alt="loader"
                />
              </div>
            )}
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={6} md={6} xs={10} className="">
                <div>
                  <BreadcrumbComponent
                    active={props.t("common.DASHBOARD")}
                    nonActive1={props.instanceName}
                    nonActive1Href={
                      "/dashboard/instances/" +
                      CustomerId +
                      "?customerName=" +
                      queryString.parse(props.searchParams).customer
                    }
                    nonActive2={props.customerName}
                    nonActive2Href={
                      "/dashboard/organisationDashboard/" + CustomerId
                    }
                  />
                </div>
              </Col>
            </Row>
            <Card className="mt-3">
              <CardBody>
                <Row>
                  <Col lg={11} md={11} xs={12}>
                    <span className="line-height-style">
                      <span className="text-muted font-weight-bold">
                        Name of Instance:{" "}
                      </span>
                      <span className="text-muted">{Name_Instance}</span>
                    </span>
                    <br />
                    <span className="line-height-style">
                      <span className="text-muted font-weight-bold">
                        Instance key:{" "}
                      </span>
                      <span id="key" className="badge badge-primary">
                        {UID_Instance}
                      </span>{" "}
                      <span
                        id="idForCopySymbol"
                        className="cursor-pointer"
                        onClick={e => copyInstanceKey()}
                      >
                        <i className="fa fa-copy" />
                      </span>
                      <Tooltip
                        placement="top"
                        isOpen={props.stateValues.tooltipOpen}
                        target="idForCopySymbol"
                        toggle={props.toggle}
                      >
                        Copy Instance key
                      </Tooltip>
                    </span>
                    <br />
                    <span className="line-height-style">
                      <span className="text-muted font-weight-bold mt-5">
                        {props.t("common.DESCRIPTION")}:{" "}
                      </span>
                      <span className="text-muted">{Description_Instance}</span>
                    </span>
                  </Col>
                  {isAnyOneEditDelete && (
                    <Col
                      lg={1}
                      md={1}
                      xs={12}
                      className="list-style position-style-class-one"
                    >
                      <div className="pull-right dummy-class position-style-class-two">
                        <Dropdown
                          nav
                          isOpen={props.stateValues.dropdownOpen}
                          toggle={e => props.dropdownToggle(e)}
                          className="mobile-style-css"
                        >
                          <DropdownToggle caret nav>
                            <i className="now-ui-icons design_bullet-list-67 font-weight-bold actions-icon-style" />
                            <p />
                          </DropdownToggle>
                          <DropdownMenu right>
                            {isEditable && (
                              <DropdownItem
                                tag="a"
                                className="text-muted font-weight-bold cursor-pointer"
                                onClick={e =>
                                  props.editInstance(
                                    props.stateValues.instanceStatisticsData
                                      ? props.stateValues.instanceStatisticsData
                                      : {}
                                  )
                                }
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                />
                                Update Instance
                              </DropdownItem>
                            )}
                            {isDeletable && (
                              <DropdownItem
                                tag="a"
                                className="text-danger font-weight-bold cursor-pointer"
                                onClick={e => props.deleteInstance()}
                              >
                                <i className="fa fa-trash" aria-hidden="true" />{" "}
                                Delete Instance
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
            <div className="margin-top-max-two" />
            <Row className="">
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Rules"
                  cardData={ruleDetails}
                  icon="fa-gavel"
                  navigatePage={props.navigatePage}
                  teamName={Name_Instance}
                  customerName={Customer_Name}
                  customerId={CustomerId}
                  path={
                    props.stateValues.isRequesting ? "" : "/dashboard/rules"
                  }
                  isShowPointer={props.stateValues.isRequesting ? false : true}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Agents"
                  cardData={agentDetails}
                  icon="fa-users"
                  showClasses={true}
                  navigatePage={props.navigatePage}
                  teamName={Name_Instance}
                  customerName={Customer_Name}
                  customerId={CustomerId}
                  path={
                    props.stateValues.isRequesting ? "" : "/dashboard/agents"
                  }
                  isShowPointer={props.stateValues.isRequesting ? false : true}
                />
              </Col>
              <Col lg={3} xs={12} md={4} className="mx-auto">
                <CardComponent
                  title="Issues"
                  cardData={issuesDetails}
                  icon="fa-exclamation-circle"
                  showClasses={true}
                  navigatePage={props.navigatePage}
                  teamName={Name_Instance}
                  customerName={Customer_Name}
                  customerId={CustomerId}
                  path={
                    props.stateValues.isRequesting ? "" : "/dashboard/issues"
                  }
                  isShowPointer={props.stateValues.isRequesting ? false : true}
                />
              </Col>
            </Row>
            {instanceLogs && instanceLogs.length ? (<>
              <div className="p-5">
                <Row> 
                  <Col>
                    <div className="text-center font-weight-bold text-primary">
                      <span>Logs</span>
                      </div>
                    <table className="m-1 table table-bordered table-responsive-md" >
          
                      <tr>
                        <th>Created Date</th>
                        <th>Rule Name</th>
                        <th>Notification Team</th>
                        <th>Notification Type</th>
                        <th>Email</th>
                        <th>Alert Status</th>
                        <th>Notification Send</th>
                      </tr>
                    {instanceLogs && instanceLogs.length ? instanceLogs.map((data) => (<>
                      <tr>
                        <td>{data.Created_Date ? dateFormat(data.Created_Date): ""}</td>
                        <td>{data.Rule.Name_Rule ? data.Rule.Name_Rule: ""}</td>
                        <td>{data.Team.Name_Team? data.Team.Name_Team: ""}</td>
                        <td>{data.Notification_Type ? data.Notification_Type: ""}</td>
                        <td>{data.User.Email_User? data.User.Email_User: ""}</td>
                        <td>{showIssuesStatus(data.Alert.Status)}</td>
                        <td>{data.isNotificationSent ? "Yes": "No"}</td>
                      </tr>
                    </>)) : ""}
                    </table>
                    </Col>
                  </Row>
                </div>
              </>) : ""}
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
instanceDashboard.propTypes = {
  stateValues: PropTypes.object,
  showMembers: PropTypes.func,
  navigate: PropTypes.func,
  navigatePage: PropTypes.func,
  deleteInstance: PropTypes.func,
  editInstance: PropTypes.func,
  dropdownToggle: PropTypes.func,
  handleModal: PropTypes.func
};
export default compose(translate, connect())(instanceDashboard);
