/**
 * @action        : agentAction
 * @description   : for get the agent data
 * @Created by    : INNOMICK
 */

import { AGENT_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [AGENT description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */

export function getAgents(instanceId, qString) {
  return dispatch => {
    dispatch(base.getRequest(AGENT_CONST.GET_AGENT_REQUEST));
    AXIOS_INSTANCE.get(
      `${BASE_URL}/getAgent?instanceId=${instanceId}${qString}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AGENT_CONST.GET_AGENT_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AGENT_CONST.GET_AGENT_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AGENT_CONST.GET_AGENT_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for create agent action
export function createAgent(formData) {
  return dispatch => {
    dispatch(base.getRequest(AGENT_CONST.CREATE_AGENT_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/CreateAgent", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AGENT_CONST.CREATE_AGENT_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AGENT_CONST.CREATE_AGENT_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AGENT_CONST.CREATE_AGENT_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete agent action
export function deleteAgent(agentId) {
  return dispatch => {
    dispatch(base.getRequest(AGENT_CONST.DELETE_AGENT_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/deleteAgent?agentId=${agentId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AGENT_CONST.DELETE_AGENT_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AGENT_CONST.DELETE_AGENT_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AGENT_CONST.DELETE_AGENT_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get certificate
export function getAgentCertificate(agentId) {
  return dispatch => {
    dispatch(base.getRequest(AGENT_CONST.GET_CERTIFICATE_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getAgentCertificate?agentId=${agentId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AGENT_CONST.GET_CERTIFICATE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AGENT_CONST.GET_CERTIFICATE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AGENT_CONST.GET_CERTIFICATE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update agent status
export function updateAgentStatus(formData) {
  return dispatch => {
    dispatch(base.getRequest(AGENT_CONST.UPDATE_AGENT_STATUS_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateAgentStatus", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AGENT_CONST.UPDATE_AGENT_STATUS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AGENT_CONST.UPDATE_AGENT_STATUS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AGENT_CONST.UPDATE_AGENT_STATUS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update agent name
export function updateAgentName(formData) {
  return dispatch => {
    dispatch(base.getRequest(AGENT_CONST.UPDATE_AGENT_NAME_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateAgentName", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AGENT_CONST.UPDATE_AGENT_NAME_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AGENT_CONST.UPDATE_AGENT_NAME_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AGENT_CONST.UPDATE_AGENT_NAME_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
