import React from "react";

import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import { Bar } from "react-chartjs-2";
import Assigned from "assets/img/png/assigned.png";
import Unassigned from "assets/img/png/unassigned.png";

function OrganizationNotifications({ notificationsData , customerIndex }) {
  let notificationsGraphData = notificationsData[customerIndex]
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels: [],
    datasets: [
      {
        type: "line",
        label: "Email",
        data: [],
        fill: false,
        borderColor: "#FA7A50",
        backgroundColor: "#FA7A50",
      },
      {
        type: "bar",
        label: "Push",
        data: [],
        fill: false,
        borderColor: "#1E7FF1",
        backgroundColor: "#1E7FF1",
      },
    ],
  };

  const options = {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        boxWidth: 10,
      },
    },
  };

  if (notificationsGraphData && notificationsGraphData.customerAlerts != null) {
    notificationsGraphData.customerAlerts.map((index, key) => {
      if (
        monthNames.some((res) => res.includes(index.Created_Date.slice(0, 3)))
      ) {
        data.labels.push(index.Created_Date.slice(0, 3));
      } else {
        const dayData = new Date(index.Created_Date).toLocaleString(undefined, {
          day: "2-digit",
        });
        data.labels.push(
          dayData + " " + monthNames[new Date(index.Created_Date).getMonth()]
        );
      }
      data.datasets[0].data.push(index.closeAlertCount);
      data.datasets[1].data.push(index.openAlertCount);
    });
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <CardHeader className="card-items-inline">
                <p>Notifications</p>
              </CardHeader>
              <hr style={{ marginTop: "5px" }} />
              <CardBody>
                <Bar
                  data={data}
                  height="100"
                  responsive="true"
                  options={options}
                />
              </CardBody>
              <CardFooter>
                <div className="status-right text-center">
                  <span className="dashboard-unasigned dashboar-low">
                    <i className="dahboard-alert-low">
                      <img src={Unassigned} width="10" />
                    </i>
                    Push
                  </span>
                  <span className="dashboard-assigned dashboar-high">
                    <i className="dahboard-alert-high">
                      <img src={Assigned} width="10" />
                    </i>
                    Email
                  </span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OrganizationNotifications;
