import React, { PureComponent } from "react";
import NotificationsContainer from "./Containers/NotificationsContainer";

class NotificationPage extends PureComponent {
  render() {
    return (
      <div>
        <NotificationsContainer />
        {/* <span className="notification-count">1</span> */}
      </div>
    );
  }
}

export default NotificationPage;
