import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Form
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  createPartner,
  getPartners,
  updatePartner
} from "../../../actions/partnerAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";
class createPartnerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      loading: false,
      search_loading: false,
      createPartner: {
        nameState: this.props.initialValues ? "has-success" : null
      }
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.createPartnerSuccess &&
      nextProps.createPartnerSuccess !== this.props.createPartnerSuccess
    ) {
      showSuccess("Partner created successfully.");
      this.setState({
        loading: false
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      this.props.dispatch(getPartners(qString));
      this.props.handleModal();
    }
    if (
      nextProps.createPartnerFailure &&
      nextProps.createPartnerFailure !== this.props.createPartnerFailure
    ) {
      showError(nextProps.createPartnerFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.createPartnerNetworkFailure &&
      nextProps.createPartnerNetworkFailure !==
        this.props.createPartnerNetworkFailure
    ) {
      showError("Error in creating partner.");
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updatePartnerSuccess &&
      nextProps.updatePartnerSuccess !== this.props.updatePartnerSuccess
    ) {
      showSuccess("Partner updated successfully.");
      this.setState({
        loading: false
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      this.props.dispatch(getPartners(qString));
      this.props.handleModal();
    }
    if (
      nextProps.updatePartnerFailure &&
      nextProps.updatePartnerFailure !== this.props.updatePartnerFailure
    ) {
      showError(nextProps.updatePartnerFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updatePartnerNetworkFailure &&
      nextProps.updatePartnerNetworkFailure !==
        this.props.updatePartnerNetworkFailure
    ) {
      showError("Error in updating partner.");
      this.setState({
        loading: false
      });
    }
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    if (formProps) {
      let team = this.state.createPartner;
      let isValid = true;
      if (team["nameState"] !== "has-success") {
        team["nameState"] = "has-danger";
        isValid = false; //not valid
      }
      if (!isValid) {
        showError("Partner name is mandatory.");
        return false;
      } else {
        if (this.props.initialValues) {
          this.props.dispatch(updatePartner(formProps));
        } else {
          this.props.dispatch(createPartner(formProps));
        }
      }
      this.setState({
        loading: true
      });
    } else {
      return false;
    }
  };
  /**
   * call this function to validate name of the partner
   */
  validateName = e => {
    let team = this.state.createPartner;
    if (e.target.value.length > 4 && e.target.value.length < 50) {
      team["nameState"] = "has-success";
    } else {
      team["nameState"] = "has-danger";
    }
    this.setState({ team });
  };
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  render() {
    console.log(this.props.meta);
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues ? "Update Partner" : "Create Partner"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup
                    className={
                      this.state.createPartner.nameState
                        ? "has-label " + this.state.createPartner.nameState
                        : "has-label"
                    }
                  >
                    <Field
                      component={renderTextField}
                      name="name"
                      type="text"
                      placeholder="Enter partner name*"
                      className="team-input-style"
                      onChange={e => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={7} className="ml-auto pl-5">
                  <Button
                    color="primary"
                    loading={this.state.loading}
                    type="submit"
                  >
                    {this.props.initialValues
                      ? this.props.t("teams.UPDATE")
                      : this.props.t("teams.CREATE")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
createPartnerModal.propTypes = {
  createPartnerSuccess: PropTypes.bool,
  createPartnerFailure: PropTypes.string,
  createPartnerNetworkFailure: PropTypes.bool,
  updatePartnerSuccess: PropTypes.bool,
  updatePartnerFailure: PropTypes.string,
  updatePartnerNetworkFailure: PropTypes.bool,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  meta: PropTypes.object
};
function mapStateToProps(state) {
  return {
    createPartnerSuccess: state.partner.createPartnerSuccess,
    createPartnerFailure: state.partner.createPartnerFailure,
    createPartnerNetworkFailure: state.partner.createPartnerNetworkFailure,
    updatePartnerSuccess: state.partner.updatePartnerSuccess,
    updatePartnerFailure: state.partner.updatePartnerFailure,
    updatePartnerNetworkFailure: state.partner.updatePartnerNetworkFailure
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createPartner-form"
  }),
  connect(mapStateToProps)
)(createPartnerModal);
