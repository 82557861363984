/**
 * @action        : customerAction
 * @description   : for customers data
 * @Created by    : INNOMICK
 */

import { CUSTOMER_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [userInvitation description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for user invitation
export function getCustomers(qString, partnerId) {
  let urlPromise;
  if (partnerId) {
    urlPromise = AXIOS_INSTANCE.get(
      `${BASE_URL}/partnerCustomers${qString}&partnerId=${partnerId}`
    );
  } else {
    urlPromise = AXIOS_INSTANCE.get(`${BASE_URL}/Customers${qString}`);
  }
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.GET_ALL_CUSTOMER_REQUEST));
    urlPromise
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.GET_ALL_CUSTOMER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.GET_ALL_CUSTOMER_FAILURE, {
              error: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.GET_ALL_CUSTOMER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for user customer statistics
export function customerStatistics(customerId) {
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/CustomerDetails?customerId=${customerId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_FAILURE, {
              error: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for user customer roles //till now didn't use
export function customerRoles(customerId) {
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.GET_CUSTOMER_ROLES_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/CustomerRoles/${customerId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.GET_CUSTOMER_ROLES_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.GET_CUSTOMER_ROLES_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.GET_CUSTOMER_ROLES_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for create customer
export function createCustomer(formData) {
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.CREATE_CUSTOMER_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/CreateCustomer", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.CREATE_CUSTOMER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.CREATE_CUSTOMER_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.CREATE_CUSTOMER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update customer
export function updateCustomer(formData) {
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.UPDATE_CUSTOMER_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/UpdateCustomer", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.UPDATE_CUSTOMER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.UPDATE_CUSTOMER_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.UPDATE_CUSTOMER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete customer
export function deleteCustomer(customerId) {
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.DELETE_CUSTOMER_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/Customer?customerId=${customerId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.DELETE_CUSTOMER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.DELETE_CUSTOMER_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.DELETE_CUSTOMER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update customer status
export function updateCustomerStatus(formData) {
  return dispatch => {
    dispatch(base.getRequest(CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateCustomerStatus", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
