import React, { PureComponent } from "react";
import UpdatePassword from "./Containers/UpdatePasswordContainer";

class UpdatePasswordPage extends PureComponent {
  render() {
    return (
      <div>
        <UpdatePassword />
      </div>
    );
  }
}

export default UpdatePasswordPage;
