/* eslint-disable default-case */
import { CUSTOMER_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    customerData: [],
    isRequesting: true,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case CUSTOMER_CONST.GET_ALL_CUSTOMER_REQUEST:
      return {
        ...state,
        customerData: null,
        isRequesting: true,
        customerDataError: false
      };
    case CUSTOMER_CONST.GET_ALL_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerData: action.payload.response.data,
        isRequesting: false,
        customerDataError: false
      };
    case CUSTOMER_CONST.GET_ALL_CUSTOMER_FAILURE:
      return {
        ...state,
        customerDataError: action.payload ? true : false,
        isRequesting: false,
        customerData: null
      };
    case CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_REQUEST:
      return {
        ...state,
        isRequesting: true,
        customerStatisticsData: null,
        customerStatisticsError: false
      };
    case CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        customerStatisticsData: action.payload.response.data,
        customerStatisticsError: false
      };
    case CUSTOMER_CONST.GET_CUSTOMER_STATISTICS_FAILURE:
      return {
        ...state,
        isRequesting: false,
        customerStatisticsData: null,
        customerStatisticsError: action.payload ? true : false
      };
    case CUSTOMER_CONST.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        createCustomerSuccess: false,
        createCustomerFailure: false,
        createCustomerNetworkFailure: false
      };
    case CUSTOMER_CONST.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createCustomerSuccess: action.payload ? true : false,
        createCustomerFailure: false,
        createCustomerNetworkFailure: false
      };
    case CUSTOMER_CONST.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        createCustomerSuccess: false,
        createCustomerFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        createCustomerNetworkFailure: action.payload.error ? true : false
      };
    case CUSTOMER_CONST.UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        updateCustomerSuccess: false,
        updateCustomerFailure: false,
        updateCustomerNetworkFailure: false
      };
    case CUSTOMER_CONST.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateCustomerSuccess: action.payload.response ? true : false,
        updateCustomerFailure: false,
        updateCustomerNetworkFailure: false
      };
    case CUSTOMER_CONST.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        updateCustomerSuccess: false,
        updateCustomerFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updateCustomerNetworkFailure: action.payload.error ? true : false
      };
    case CUSTOMER_CONST.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        deleteCustomerSuccess: false,
        deleteCustomerFailure: false,
        deleteCustomerNetworkFailure: false
      };
    case CUSTOMER_CONST.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteCustomerSuccess: action.payload ? true : false,
        deleteCustomerFailure: false,
        deleteCustomerNetworkFailure: false
      };
    case CUSTOMER_CONST.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        deleteCustomerSuccess: false,
        deleteCustomerFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        deleteCustomerNetworkFailure: action.payload.error ? true : false
      };
    case CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_REQUEST:
      return {
        ...state,
        customerStatusData: null,
        customerStatusError: false
      };
    case CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_SUCCESS:
      return {
        ...state,
        customerStatusData: action.payload.response.data,
        customerStatusError: false
      };
    case CUSTOMER_CONST.UPDATE_CUSTOMER_STATUS_FAILURE:
      return {
        ...state,
        customerStatusData: null,
        customerStatusError: action.payload ? true : false
      };
  }
  return state;
}
