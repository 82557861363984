import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  Form
} from "reactstrap";
import Button from "reactstrap-button-loader";
import { connect } from "react-redux";
import compose from "compose-function";
import { renderTeamsDataSelectField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { assignRuleToTeam } from "../../../actions/teamAction";
import { getRuleTeam } from "../../../actions/ruleAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";

class AssignTeamsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.ruleId = "";
  }

  handleAssignTeamsModal = () => {
    this.props.handleAssignTeamsModal();
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.assignRuleToTeamSuccess &&
      nextProps.assignRuleToTeamSuccess !== this.props.assignRuleToTeamSuccess
    ) {
      showSuccess("Rule has been successfully assigned to team");
      this.setState({
        loading: false
      });
      this.props.handleAssignTeamsModal();
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      this.props.dispatch(
        getRuleTeam("ruleId", this.props.currentRuleId, qString)
      );
    }
    if (
      nextProps.assignRuleToTeamFailure &&
      nextProps.assignRuleToTeamFailure !== this.props.assignRuleToTeamFailure
    ) {
      showError(nextProps.assignRuleToTeamFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.assignRuleToTeamNetworkFailure &&
      nextProps.assignRuleToTeamNetworkFailure !==
        this.props.assignRuleToTeamNetworkFailure
    ) {
      showError("Something went wrong.");
      this.setState({
        loading: false
      });
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
  };

  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    formProps.ruleId = this.props.currentRuleId;
    if (formProps.teamId !== "0" && formProps.teamId) {
      this.props.dispatch(assignRuleToTeam(formProps));
      this.setState({
        loading: true
      });
    } else {
      showError("Please select Team.");
    }
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleAssignTeamsModal}
          className="text-center top-space"
        >
          <ModalHeader
            toggle={this.handleAssignTeamsModal}
            className="justify-content-left title create-team-title"
            tag="p"
          >
            Assign Teams
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row>
                <Col lg={9} md={9} className="mx-auto">
                  <Row>
                    <Col className="pt-2 remove-select-styles">
                      <FormGroup className="wrapper-style">
                        <Label className="pull-left">Teams</Label>
                        <Field
                          name="teamId"
                          type="select"
                          options={this.props.teamsData}
                          component={renderTeamsDataSelectField}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="ml-auto pl-5">
                      <Button
                        color="primary"
                        type="submit"
                        loading={this.state.loading}
                        className="ml-5 font-weight-bold"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    rulesData: state.rule.instanceRulesData,
    isRequesting: state.rule.isRequesting,
    assignRuleToTeamSuccess: state.team.ruleAssignedToTeamSuccess,
    assignRuleToTeamFailure: state.team.ruleAssignedToTeamFailure,
    assignRuleToTeamNetworkFailure: state.team.ruleAssignedToTeamNetworkFailure
  };
}
export default compose(
  reduxForm({
    form: "assignTeams-form"
  }),
  connect(mapStateToProps)
)(AssignTeamsModal);
