import React from "react";
import { Container, Col, Row, Card, Table, Button, Input } from "reactstrap";
import Previous from "assets/img/png/previous.png";
import Next from "assets/img/png/next.png";

function OrganizationDashboardTable({ 
  instanceData,
  redirectViewAll, 
  instanceOverViewSelectedIndex,
  customerIndex,
  getTeamOverViewSelectedIndex,
  teamIndex, }) {
  // console.log("instance data", instanceOverViewSelectedIndex );
  let instanceOverviewData = instanceData[customerIndex].customerAlerts;
  let teamOverviewData = instanceData[customerIndex].customerAlerts;
   
  const renderEmptyRow = (data) => {
    if (0 < data && 10 > data) {
      let i = 0;
      let row = [];
      for (i; i < data; i++) {
        row.push(
          <>
            <tr className="empty-row-width">
              <td> </td>
              <td> </td>
              <td> </td>
              <td />
            </tr>
          </>
        );
      }
      return row;
    }
  };

  const renderInstanceOVerviewTable = (data) => {
    if (data.length > 0) {
      return data.map((data, index) => {
        return (
          <>
            <tr key={index}>
              <th>{data.name}</th>
              <td className="table-heading-center">{data.rules}</td>
              <td className="table-heading-center">{data.assigned}</td>
              <td className="table-heading-center">{data.unassigned}</td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <>
          <tr>
            <td> </td>
            <td> </td>
            <td>No record found </td>
            <td />
          </tr>
        </>
      );
    }
  };

  const renderOrganizationTeamOverviewTable = (data) => {
    let count;
    if (data.length > 0) {
      return data.map((data, index) => {
        return (
          <>
            <tr key={index}>
              <th> {data.name}</th>
              <td className="table-heading-center">{data.rules}</td>
              <td className="table-heading-center">{data.members}</td>
              <td className="column-size-padding">
                <Button
                  className={
                    data.status
                      ? "organization-dashboard-table-header-button-style"
                      : "organization-dashboard-table-header-button-style-inactive"
                  }
                >
                 {data.status ? "Active" : "Inactive"} 
                </Button>
              </td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <>
          <tr>
            <td> </td>
            <td> </td>
            <td>No record found </td>
            <td />
          </tr>
        </>
      );
    }
  };

  const redirectToOraganizationDashbaord = (event) => {
    event.preventDefault();
    redirectViewAll();
  };

  const getTeamOverviewSelectedOption=(event)=>{
    event.preventDefault();
    console.log("evnet ", event.target.value)
    getTeamOverViewSelectedIndex(event.target.value);
  }

  const getInstanceOverviewSelectedOption=(event)=>{
    event.preventDefault();
    console.log("evnet ", event.target.value)
    instanceOverViewSelectedIndex(event.target.value);
  }

  const renderInstanceOverviewSelectedOption=()=>{
      return instanceData.map((data, index)=>{
      return(
        <option value={ index } >{ data.customerName }</option> 
          )
      })
  }

  return (
    <div className="team-dashboard-Table">
      <Container>
        <Row>
          <Col sm="12" md="6" className="dashboard-instance">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    Instances Overview
                  </p>
                </div>
                {/* <div className="ml-auto mr-2 mt-2 card-items-inline-form d-flex">
                <div><p style={{ marginTop: '5px'}}>Choose company</p></div>
                <div>
                  <Input type="select" className="float-right dadhboard-header-input"
                    onChange={(event) => getInstanceOverviewSelectedOption(event)}
                  >
                  {renderInstanceOverviewSelectedOption()}
                  </Input>
                  </div>
                </div> */}
                <div className="team-dashboard-table-header-button">
                  <Button
                    className="team-dashboard-table-header-button-style"
                    onClick={(event) => redirectToOraganizationDashbaord(event)}
                  >
                    View All
                  </Button>
                </div>
              </div>
            </Card>
            <Table className="team-dashboard-Table-border fixed_header">
              <thead>
                <tr className="team-dashboard-table-heading-border">
                  <th className="team-dashboard-table-heading-border-first">
                    Instances
                  </th>
                  <th className="table-heading-center">Rules</th>
                  <th className="table-heading-center">Assigned</th>
                  <th className="table-heading-center">Unassigned</th>
                </tr>
              </thead>
              <tbody className="team-dashboard-table-body">
                {instanceOverviewData &&
                  instanceOverviewData.instanceWiseData &&
                  renderInstanceOVerviewTable(instanceOverviewData.instanceWiseData)}

                {instanceOverviewData &&
                  instanceOverviewData.instanceWiseData &&
                  renderEmptyRow(10 - instanceOverviewData.instanceWiseData.length)}
              </tbody>
              <thead />
            </Table>
            {/* <Card className="team-dashboard-table-footer-card">
              <div className="team-dashboard-table-header-prev">
                <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                </Button>
                <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                </Button>
              </div>
            </Card> */}
          </Col>

          <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    Teams Overview
                  </p>
                </div>
                {/* <div className="ml-auto mr-2 mt-2 card-items-inline-form d-flex">
                <div><p style={{ marginTop: '5px'}}>Choose company</p></div>
                <div>
                  <Input type="select" className="float-right dadhboard-header-input"
                    onChange={(event) => getTeamOverviewSelectedOption(event)}
                  >
                  {renderInstanceOverviewSelectedOption()}
                  </Input>
                  </div>
                </div> */}
                <div className="team-dashboard-table-header-button">
                  <Button
                    className="team-dashboard-table-header-button-style"
                    onClick={(event) => redirectToOraganizationDashbaord(event)}
                  >
                    View All
                  </Button>
                </div>
              </div>
            </Card>
            <Table className="team-dashboard-Table-border fixed_header">
              <thead>
                <tr className="team-dashboard-table-heading-border">
                  <th className="team-dashboard-table-heading-border-first">
                    Teams
                  </th>
                  <th className="table-heading-center">Rules</th>
                  <th className="table-heading-center">Members</th>
                  <th className="table-heading-center">Status</th>
                </tr>
              </thead>
              <tbody className="team-dashboard-table-body">
                {teamOverviewData &&
                  teamOverviewData.getTeamWiseData &&
                  renderOrganizationTeamOverviewTable(
                    teamOverviewData.getTeamWiseData
                  )}
                {teamOverviewData &&
                  teamOverviewData.getTeamWiseData &&
                  renderEmptyRow(10 - teamOverviewData.getTeamWiseData.length)}
              </tbody>
              <thead />
            </Table>
            {/* <Card className="team-dashboard-table-footer-card">
              <div className="team-dashboard-table-header-prev">
                <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                </Button>
                <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                </Button>
              </div>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default OrganizationDashboardTable;
