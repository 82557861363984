import { INVITE_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    isInviteSuccess: false,
    isInviteFailure: false,
    isInviteNetworkFailure: false,
  },
  action
) {
  switch (action.type) {
    case INVITE_CONST.INVITE_REQUEST:
      return {
        ...state,
        isInviteSuccess: false,
        isInviteFailure: false,
        isInviteNetworkFailure: false,
      };
    case INVITE_CONST.INVITE_SUCCESS:
      return {
        ...state,
        isInviteSuccess: action.payload.response.data.isSuccess,
        isInviteFailure: false,
        isInviteNetworkFailure: false,
      };
    case INVITE_CONST.INVITE_FAILURE:
      return {
        ...state,
        isInviteSuccess: false,
        isInviteFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        isInviteNetworkFailure: action.payload.error ? true : false,
      };
    case INVITE_CONST.GET_EMAIL_REQUEST:
      return {
        ...state,
        userData: null,
        userDataError: false,
        userDataNetworkError: false,
      };
    case INVITE_CONST.GET_EMAIL_SUCCESS:
      return {
        ...state,
        userData: action.payload.response
          ? action.payload.response.data.data.userData
          : null,
        userDataError: false,
        userDataNetworkError: false,
      };
    case INVITE_CONST.GET_EMAIL_FAILURE:
      return {
        ...state,
        userData: null,
        userDataError: action.payload.response ? true : false,
        userDataNetworkError: action.payload.error ? true : false,
      };
    case INVITE_CONST.SEARCH_USER_REQUEST:
      return {
        ...state,
        suggestedData: [],
      };
    case INVITE_CONST.SEARCH_USER_SUCCESS:
      return {
        ...state,
        suggestedData: action.payload.response
          ? action.payload.response.data.data.result
          : [],
      };
    case INVITE_CONST.SEARCH_USER_FAILURE:
      return {
        ...state,
        suggestedData: [],
      };
    case INVITE_CONST.UPDATE_USER_REQUEST:
      return {
        ...state,
        isUserDataUpdated: false,
        isUserDataUpdatedError: false,
      };
    case INVITE_CONST.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUserDataUpdated: action.payload.response.data.isSuccess,
        isUserDataUpdatedError: false,
      };
    case INVITE_CONST.UPDATE_USER_FAILURE:
      return {
        ...state,
        isUserDataUpdated: false,
        isUserDataUpdatedError: action.payload
          ? action.payload.error.data.error.errorDescription
          : false,
      };
    case INVITE_CONST.GET_USER_ROLE_REQUEST:
      return {
        ...state,
        userRolesData: null,
        userRolesDataError: false,
      };
    case INVITE_CONST.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRolesData: action.payload.response.data.data,
        userRolesDataError: false,
      };
    case INVITE_CONST.GET_USER_ROLE_FAILURE:
      return {
        ...state,
        userRolesData: null,
        userRolesDataError: action.payload ? true : false,
      };
    case INVITE_CONST.GET_USERSCOPE_REQUEST:
      return {
        ...state,
        isUserScopeSuccess: null,
        isUserScopeFailure: false,
      };
    case INVITE_CONST.GET_USERSCOPE_SUCCESS:
      return {
        ...state,
        isUserScopeSuccess: action.payload.response.data.data.scope,
        isUserScopeFailure: false,
      };
    case INVITE_CONST.GET_USERSCOPE_FAILURE:
      return {
        ...state,
        isUserScopeSuccess: null,
        isUserScopeFailure: action.payload ? true : false,
      };
    case INVITE_CONST.SEND_ROLE_DATA_REQUEST:
      return {
        ...state,
        rolesData: undefined,
      };
    case INVITE_CONST.SEND_ROLE_DATA_SUCCESS:
      return {
        ...state,
        rolesData: action.payload.response,
      };
    case INVITE_CONST.SEND_ROLE_DATA_FAILURE:
      return {
        ...state,
        rolesData: undefined,
      };
  }
  return state;
}
