import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Table,
  Button,
} from "reactstrap";
import Previous from "assets/img/png/previous.png";
import Next from "assets/img/png/next.png";
import Circle from "assets/img/png/circle.png";
import DefaultUser from "assets/img/png/DefaultUser.png";
import moment from "moment";
function TeamDashboardActivity(props) {
  // console.log("props activity", props.teamActivity && 
  // props.teamActivity.getUserStatisticsSuccess  &&
  // props.teamActivity.getUserStatisticsSuccess.data.task[0].teamAlerts
  // );
  
  // if(props.teamActivity && 
  //   props.teamActivity.getUserStatisticsSuccess){
  //   props.teamActivity.getUserStatisticsSuccess.data.task[0].teamAlerts.map((index, key)=>{
  //     console.log(index.DisplayName)
  //   })
  // }


  const getTodayDate=()=>{
    return moment(new Date()).format("Do MMM YYYY"); 
  }

  const getActivityDate=(date)=>{
    return moment(date).format("Do MMM YYYY"); 
  }

  const getCloseActivityDate=(date)=>{
    return moment(date).format("Do MMM YYYY | h:mm a"); 
  }

  return (
    <div className="team-dashboard-Table mb-5">
      <Container>
        <Row>
          <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    My Activity
                  </p>
                </div>
              </div>
            </Card>
            <div className="table-wrapper-scroll-y my-custom-scrollbar">
              <Table className="team-dashboard-Table-border">              
                <tbody className="team-dashboard-table-recent-alerts">
                  {props.myActivity && props.myActivity.length > 0 &&
                    props.myActivity.map((index)=>{
                    return(
                      <tr>
                      <td>
                        <div className={ 1 == index.Status ? "team-dashboard-table-recent-alerts-low" :
                          2 == index.Status  ? "team-dashboard-table-recent-alerts-closed" : ""
                        }>
                          <p className="team-dashboard-table-recent-alerts-activity-title">
                            { index.RuleName && index.RuleName || '' }
                          </p>
                          <p className="team-dashboard-table-recent-alerts-activity-instance">
                            Instance: {index.InstanceName && index.InstanceName || '' }{" "}
                          </p>
                          <p className="team-dashboard-table-recent-alerts-activity-time">
                          { index.Created_Date && index.Status && 
                             1 == index.Status ? getActivityDate(index.Created_Date) || '' : 
                             2 == index.Status ? getCloseActivityDate(index.Created_Date) || '' : ""
                          }
                          </p>
                        </div>
                      </td> 
                      <td className="team-dashbaord-activity">
                        <Button className={index.Status && 1 == index.Status ? "team-dashbaord-activity-button " :
                          2 == index.Status ? "team-dashbaord-activity-button-closed" : "" } >
                          { index.Status && 1 == index.Status ? " In Progress" : 
                            2 == index.Status ? "Closed" : "" }
                        </Button>
                      </td>
                    </tr>
                    )
                  })}
                  {/* <tr>
                    <td>
                      <div className="team-dashboard-table-recent-alerts-low">
                        <p className="team-dashboard-table-recent-alerts-activity-title">
                          TATA Service is down
                        </p>
                        <p className="team-dashboard-table-recent-alerts-activity-instance">
                          Instance: Tally ERP 9.0{" "}
                        </p>
                        <p className="team-dashboard-table-recent-alerts-activity-time">
                          08th Jan, 2021 | 9:30 am{" "}
                        </p>
                      </div>
                    </td>
                    <td className="team-dashbaord-activity">
                      <Button className="team-dashbaord-activity-button mr-4">
                        In Progress
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="team-dashboard-table-recent-alerts-closed">
                        <p className="team-dashboard-table-recent-alerts-activity-title">
                          TATA Service is down
                        </p>
                        <p className="team-dashboard-table-recent-alerts-activity-instance">
                          Instance: Tally ERP 9.0{" "}
                        </p>
                        <p className="team-dashboard-table-recent-alerts-activity-time">
                          08th Jan, 2021 | 9:30 am{" "}
                        </p>
                      </div>
                    </td>
                    <td className="team-dashbaord-activity">
                      <Button className="team-dashbaord-activity-button-closed mr-4">
                        Closed
                      </Button>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    Team Activity
                  </p>
                </div>
              </div>
            </Card>
            <div className="table-wrapper-scroll-y my-custom-scrollbar">
              <Table className="team-dashboard-Table-border">
                <tbody className="team-dashboard-table-recent-alerts">
                  { props.teamActivity && 
                    props.teamActivity.getUserStatisticsSuccess &&
                    props.teamActivity.getUserStatisticsSuccess.data.task[0].teamAlerts.map((index, key)=>{
                      return (
                        <tr>
                          <td>
                            <div className="detail align-items-center d-inline-flex">
                              <img src={index.ProfilePicture ? index.ProfilePicture : DefaultUser} height="44" />
                              <div>
                                <p className="team-dashboard-table-recent-alerts-activity-title">
                                  { index.DisplayName }
                                </p>
                                <p className="team-dashboard-table-recent-alerts-activity-instance">
                                  { index.TotalAlertCount } issue assign
                                </p>
                                <p className="team-dashboard-table-recent-alerts-activity-time">
                                  {getTodayDate()}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="td-actions text-right">
                            <div className="team-dashbaord-activity">
                              <Button className={index.ClosedAlertCount ? "team-dashbaord-activity-button-closed" : "team-dashbaord-activity-button " }>
                              {index.ClosedAlertCount ? index.ClosedAlertCount + " " +"Closed": index.InProgressAlertCount + " " + "In Progress" }
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                  
                {/*                    
                  <tr>
                    <td>
                      <div className="detail align-items-center d-inline-flex">
                        <img src={DefaultUser} height="44" />
                        <div>
                          <p className="team-dashboard-table-recent-alerts-activity-title">
                            Cameron Williamson
                          </p>
                          <p className="team-dashboard-table-recent-alerts-activity-instance">
                            12 issue assign
                          </p>
                          <p className="team-dashboard-table-recent-alerts-activity-time">
                            29th March, 2021
                          </p>
                        </div>
                      </div>
                    </td>
                    
                    <td className="td-actions text-right">
                      <div className="team-dashbaord-activity">
                        <Button className="team-dashbaord-activity-button-closed mr-4">
                          8 closed
                        </Button>
                      </div>
                    </td>
                  </tr> */}
                   
                  
                </tbody>
              </Table>
            </div>
            {/* <Card className="team-dashboard-table-footer-card">  
            <div>
            <ul className="d-flex mt-2 ">
                        <li  style={{color: '#CC2323', fontSize: '14px', marginLeft: '30px'}}>High</li>
                            <li style={{color: '#FF8A00', fontSize: '14px', marginLeft: '30px'}} >Medium </li>
                                <li style={{color: '#1E7FF1', fontSize: '14px', marginLeft: '30px'}} > Low</li>
                    </ul>  
                </div>
                <div className="team-dashboard-table-header-prev">
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                  </Button>
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                  </Button>
                </div>
                
            </Card> */}
            {/* <Card className="team-dashboard-table-footer-card-alerts">  
                 
                <ul className="team-dashboard-table-header-footer-alerts">
                        <li  style={{color: '#CC2323', fontSize: '14px', marginLeft: '30px'}}>High</li>
                            <li style={{color: '#FF8A00', fontSize: '14px', marginLeft: '30px'}} >Medium </li>
                                <li style={{color: '#1E7FF1', fontSize: '14px', marginLeft: '30px'}} > Low</li>
                    </ul>  
            </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default TeamDashboardActivity;
