import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Form,
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  createRule,
  getRulesByCriteria,
  updateRule,
} from "../../../actions/ruleAction";
import { getAlerts } from "../../../actions/alertAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../../../helpers/Utility";
class CreateRuleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      loading: false,
      search_loading: false,
      enableRule: this.props.initialValues
        ? this.props.initialValues.ruleEnabled
        : true,
      priority: this.props.initialValues
        ? this.props.initialValues.priority
        : null,
      createRule: {
        nameState: this.props.initialValues ? "has-success" : null,
        priorityState: this.props.initialValues ? "has-success" : null,
      },
    };
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.createRuleSuccess &&
      nextProps.createRuleSuccess !== this.props.createRuleSuccess
    ) {
      showSuccess("Rule created successfully.");
      this.setState({
        loading: false,
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const pageIndex = this.props.meta.page ? this.props.meta.page - 1 : 0;
      const qString = `&pageSize=${pageSize}&pageIndex=${pageIndex}`;
      let formData = {
        name: this.props.name,
        id: this.props.id,
      };
      this.props.dispatch(getRulesByCriteria(formData, null, qString));
      this.props.handleModal();
    }
    if (
      nextProps.updateRuleSuccess &&
      nextProps.updateRuleSuccess !== this.props.updateRuleSuccess
    ) {
      showSuccess("Rule updated successfully.");
      this.setState({
        loading: false,
      });
      let formData = {
        type: "rule",
        id: this.props.ruleId,
      };
      this.props.dispatch(getAlerts(formData));
      this.props.handleModal();
    }
    if (
      nextProps.createRuleFailure &&
      nextProps.createRuleFailure !== this.props.createRuleFailure
    ) {
      showError(nextProps.createRuleFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.createRuleNetworkFailure &&
      nextProps.createRuleNetworkFailure !== this.props.createRuleNetworkFailure
    ) {
      showError("Error in creating rule.");
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.updateRuleFailure &&
      nextProps.updateRuleFailure !== this.props.updateRuleFailure
    ) {
      showError(nextProps.updateRuleFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.updateRuleNetworkFailure &&
      nextProps.updateRuleNetworkFailure !== this.props.updateRuleNetworkFailure
    ) {
      showError("Error in updating rule.");
      this.setState({
        loading: false,
      });
    }
  };
  /**
   * call this function to validate name of the team
   */
  validateName = (e) => {
    let rule = this.state.createRule;
    if (e.target.value.length > 4 && e.target.value.length < 50) {
      rule["nameState"] = "has-success";
    } else {
      rule["nameState"] = "has-danger";
    }
    this.setState({ rule });
  };
  /*call this function to change rule enabled state */
  validateRuleEnabled = (e) => {
    if (this.state.enableRule) {
      this.setState({
        enableRule: false,
      });
    } else {
      this.setState({
        enableRule: true,
      });
    }
  };
  /*call this function to change priority state */
  validatePriority = (e) => {
    let rule = this.state.createRule;
    if (e.target.value) {
      rule["priorityState"] = "has-success";
      this.setState({
        priority: e.target.value,
      });
    } else {
      rule["priorityState"] = "has-danger";
    }
    this.setState({ rule });
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = (formProps) => {
    if (formProps) {
      formProps.instanceId = this.props.id;
      formProps.priority = Number(this.state.priority);
      formProps.ruleEnabled = this.state.enableRule;
      let rule = this.state.createRule;
      let isValid = true;
      let fields = ["nameState", "priorityState"];
      fields.map((fieldName) => {
        if (rule[fieldName] !== "has-success") {
          rule[fieldName] = "has-danger";
          isValid = false; //not valid
        }
      });
      if (!isValid) {
        showError("Please fill mandatory fields.");
        return false;
      } else {
        if (this.props.initialValues) {
          this.props.dispatch(updateRule(formProps));
        } else {
          this.props.dispatch(createRule(formProps));
        }
        this.setState({
          loading: true,
        });
      }
    } else {
      return false;
    }
  };
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues ? "Update Rule" : "Create Rule"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3 mb-3">
                <Col lg={9} md={9} className="mx-auto">
                  <FormGroup
                    className={
                      this.state.createRule.nameState
                        ? "has-label " + this.state.createRule.nameState
                        : "has-label"
                    }
                  >
                    <Field
                      component={renderTextField}
                      name="name"
                      type="text"
                      placeholder="Enter Rule Name*"
                      className="team-input-style"
                      onChange={(e) => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={9} md={9} className="mx-auto">
                  <FormGroup>
                    <Field
                      component={renderTextField}
                      name="description"
                      type="textarea"
                      className="textarea-styles"
                      placeholder="Enter Rule Description"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={9} md={9} className="mx-auto">
                  <Row>
                    <Col lg={12} md={12}>
                      <FormGroup check className="form-check-radio">
                        <Label className="float-left text-muted">
                          Priority*
                        </Label>
                        <Label check>
                          <Field
                            name="priority"
                            component="input"
                            type="radio"
                            className="pl-2"
                            value="0"
                            onChange={(e) => this.validatePriority(e)}
                          />
                          <span className="form-check-sign" />
                          <span className="mr-2 text-muted">Low</span>
                        </Label>
                        <Label check>
                          <Field
                            name="priority"
                            component="input"
                            type="radio"
                            className="pl-2"
                            value="1"
                            onChange={(e) => this.validatePriority(e)}
                          />
                          <span className="form-check-sign" />
                          <span className="mr-2 text-muted">Medium</span>
                        </Label>
                        <Label check>
                          <Field
                            name="priority"
                            component="input"
                            type="radio"
                            value="2"
                            onChange={(e) => this.validatePriority(e)}
                          />
                          <span className="form-check-sign" />
                          <span className="mr-2 text-muted">High</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={9} md={9} className="mx-auto">
                  <Row>
                    <Col lg={5} md={5} className="pt-2">
                      <label className="ccheck float-left">
                        Enable Rule
                        <Input
                          component="input"
                          type="checkbox"
                          checked={this.state.enableRule}
                          onClick={(e) => this.validateRuleEnabled(e)}
                        />
                        <span className="checkmark ml-4" />
                      </label>
                    </Col>
                    <Col lg={7} md={7} className="pl-5">
                      <Button
                        color="primary"
                        onClick={(e) => this.onSubmit(this.state.value)}
                        loading={this.state.loading}
                        type="submit"
                        className="ml-5"
                      >
                        {this.props.initialValues
                          ? "Update"
                          : this.props.t("teams.CREATE")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CreateRuleModal.propTypes = {
  createRuleSuccess: PropTypes.string,
  createRuleFailure: PropTypes.string,
  createRuleNetworkFailure: PropTypes.bool,
  updateRuleSuccess: PropTypes.bool,
  updateRuleFailure: PropTypes.string,
  updateRuleNetworkFailure: PropTypes.bool,
  handleModal: PropTypes.func,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  meta: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    createRuleSuccess: state.rule.createRuleSuccess,
    createRuleFailure: state.rule.createRuleFailure,
    createRuleNetworkFailure: state.rule.createRuleNetworkFailure,
    updateRuleSuccess: state.rule.updateRuleSuccess,
    updateRuleFailure: state.rule.updateRuleFailure,
    updateRuleNetworkFailure: state.rule.updateRuleNetworkFailure,
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createRule-form",
  }),
  connect(mapStateToProps)
)(CreateRuleModal);
