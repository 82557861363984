import { ALERT_CONST } from "../actions/actionTypes";
export default function reducer(state = {}, action) {
  switch (action.type) {
    case ALERT_CONST.GET_ALERT_DETAILS_REQUEST:
      return {
        ...state,
        isRequestingTwo: true,
        issueDetailsData: null
      };
    case ALERT_CONST.GET_ALERT_DETAILS_SUCCESS:
      return {
        ...state,
        isRequestingTwo: false,
        issueDetailsData: action.payload
          ? action.payload.response.data.task
          : null
      };
    case ALERT_CONST.GET_ALERT_DETAILS_FAILURE:
      return {
        ...state,
        isRequestingTwo: false,
        issueDetailsData: null
      };
    case ALERT_CONST.CREATE_ALERT_COMMENT_REQUEST:
      return {
        ...state,
        commentDataSuccess: false,
        commentDataNetworkError: false,
        commentDataError: false
      };
    case ALERT_CONST.CREATE_ALERT_COMMENT_SUCCESS:
      return {
        ...state,
        commentDataSuccess: action.payload ? true : false,
        commentDataNetworkError: false,
        commentDataError: false
      };
    case ALERT_CONST.CREATE_ALERT_COMMENT_FAILURE:
      return {
        ...state,
        commentDataSuccess: false,
        commentDataError: action.payload.response ? true : false,
        commentDataNetworkError: action.payload.error ? true : false
      };
    case ALERT_CONST.GET_ALERTS_REQUEST:
      return {
        ...state,
        alertsData: {},
        alertsDataError: false,
        alertsDataNetworkError: false
      };
    case ALERT_CONST.GET_ALERTS_SUCCESS:
      return {
        ...state,
        alertsData: action.payload.response
          ? action.payload.response.data.task
          : {},
        alertsDataError: false,
        alertsDataNetworkError: false
      };
    case ALERT_CONST.GET_ALERTS_FAILURE:
      return {
        ...state,
        alertsData: {},
        alertsDataError: action.payload.response ? true : false,
        alertsDataNetworkError: action.payload.error ? true : false
      };
    case ALERT_CONST.ASSIGN_ALERT_TO_USER_REQUEST:
      return {
        ...state,
        assignAlertSuccess: null,
        assignAlertError: false,
        assignAlertNetworkError: false
      };
    case ALERT_CONST.ASSIGN_ALERT_TO_USER_SUCCESS:
      return {
        ...state,
        assignAlertSuccess: action.payload.response
          ? action.payload.response.data.Message
          : null,
        assignAlertError: false,
        assignAlertNetworkError: false
      };
    case ALERT_CONST.ASSIGN_ALERT_TO_USER_FAILURE:
      return {
        ...state,
        assignAlertSuccess: null,
        assignAlertError: action.payload.response ? true : false,
        assignAlertNetworkError: action.payload.error ? true : false
      };
    case ALERT_CONST.UNASSIGN_ALERT_TO_USER_REQUEST:
      return {
        ...state,
        unassignAlertSuccess: null,
        unassignAlertError: null,
        unassignAlertNetworkError: false
      };
    case ALERT_CONST.UNASSIGN_ALERT_TO_USER_SUCCESS:
      return {
        ...state,
        unassignAlertSuccess: action.payload.response
          ? action.payload.response.data.Message
          : null,
        unassignAlertError: null,
        unassignAlertNetworkError: false
      };
    case ALERT_CONST.UNASSIGN_ALERT_TO_USER_FAILURE:
      return {
        ...state,
        unassignAlertSuccess: null,
        unassignAlertError: action.payload.response
          ? action.payload.response.data.error.errorDescription
          : null,
        unassignAlertNetworkError: action.payload.error ? true : false
      };
    /*for user list */
    case ALERT_CONST.GET_USER_LIST_REQUEST:
      return {
        ...state,
        getUserListSuccess: [],
        getUserListError: null,
        getUserListNetworkError: false
      };
    case ALERT_CONST.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        getUserListSuccess: action.payload.response
          ? action.payload.response.data.userList
          : null,
        getUserListError: null,
        getUserListNetworkError: false
      };
    case ALERT_CONST.GET_USER_LIST_FAILURE:
      return {
        ...state,
        getUserListSuccess: [],
        getUserListError: action.payload.response
          ? action.payload.response.data.error.errorDescription
          : null,
        getUserListNetworkError: action.payload.error ? true : false
      };
  }
  return state;
}
