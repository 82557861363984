/* eslint-disable default-case */
import { PARTNER_CONST } from "../actions/actionTypes";
export default function reducer(
  state = {
    isRequesting: true,
    errorData: {}
  },
  action
) {
  switch (action.type) {
    case PARTNER_CONST.GET_ALL_PARTNER_REQUEST:
      return {
        ...state,
        partnerData: null,
        isRequesting: true,
        partnersDataError: false
      };
    case PARTNER_CONST.GET_ALL_PARTNER_SUCCESS:
      return {
        ...state,
        partnerData: action.payload.response
          ? action.payload.response.data
          : [],
        isRequesting: false,
        partnersDataError: false
      };
    case PARTNER_CONST.GET_ALL_PARTNER_FAILURE:
      return {
        ...state,
        partnersDataError: action.payload ? true : false,
        isRequesting: false,
        partnerData: null
      };
    case PARTNER_CONST.CREATE_PARTNER_REQUEST:
      return {
        ...state,
        createPartnerSuccess: false,
        createPartnerFailure: false,
        createPartnerNetworkFailure: false
      };
    case PARTNER_CONST.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        createPartnerSuccess: action.payload ? true : false,
        createPartnerFailure: false,
        createPartnerNetworkFailure: false
      };
    case PARTNER_CONST.CREATE_PARTNER_FAILURE:
      return {
        ...state,
        createPartnerSuccess: false,
        createPartnerFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        createPartnerNetworkFailure: action.payload.error ? true : false
      };
    case PARTNER_CONST.UPDATE_PARTNER_REQUEST:
      return {
        ...state,
        updatePartnerSuccess: false,
        updatePartnerFailure: false,
        updatePartnerNetworkFailure: false
      };
    case PARTNER_CONST.UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        updatePartnerSuccess: action.payload ? true : false,
        updatePartnerFailure: false,
        updatePartnerNetworkFailure: false
      };
    case PARTNER_CONST.UPDATE_PARTNER_FAILURE:
      return {
        ...state,
        updatePartnerSuccess: false,
        updatePartnerFailure: action.payload.response
          ? action.payload.response.data.errorDescription
          : false,
        updatePartnerNetworkFailure: action.payload.error ? true : false
      };
    case PARTNER_CONST.DELETE_PARTNER_REQUEST:
      return {
        ...state,
        deletePartnerSuccess: false,
        deletePartnerFailure: false
      };
    case PARTNER_CONST.DELETE_PARTNER_SUCCESS:
      return {
        ...state,
        deletePartnerSuccess: action.payload ? true : false,
        deletePartnerFailure: false
      };
    case PARTNER_CONST.DELETE_PARTNER_FAILURE:
      return {
        ...state,
        deletePartnerSuccess: false,
        deletePartnerFailure: action.payload.error ? true : false
      };
    case PARTNER_CONST.UPDATE_PARTNER_STATUS_REQUEST:
      return {
        ...state,
        partnerStatusData: null,
        partnerStatusError: false
      };
    case PARTNER_CONST.UPDATE_PARTNER_STATUS_SUCCESS:
      return {
        ...state,
        partnerStatusData: action.payload.response.data,
        partnerStatusError: false
      };
    case PARTNER_CONST.UPDATE_PARTNER_STATUS_FAILURE:
      return {
        ...state,
        partnerStatusData: null,
        partnerStatusError: action.payload ? true : false
      };
  }
  return state;
}
