/**
 * @action        : ruleAction
 * @description   : for get the rule data
 * @Created by    : INNOMICK
 */

import { RULE_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [rule description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for get rules by criteria
export function getRulesByCriteria(formData, onlyForInstance, qString) {
  if (onlyForInstance) {
    //getting rules for instance only(modal purpose)
    return dispatch => {
      dispatch(base.getRequest(RULE_CONST.GET_RULES_FOR_INSTANCE_REQUEST));
      AXIOS_INSTANCE.get(
        `${BASE_URL}/getRuleByCriteria?name=${formData.name}&id=${formData.id}${qString}`
      )
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          if (response.isSuccess) {
            response.data.isTeam = formData.name == "team" ? true : false;
            dispatch(
              base.getSuccess(RULE_CONST.GET_RULES_FOR_INSTANCE_SUCCESS, {
                response: {
                  data: response.data
                }
              })
            );
          } else {
            dispatch(
              base.getFailure(RULE_CONST.GET_RULES_FOR_INSTANCE_FAILURE, {
                response: {
                  data: response
                }
              })
            );
          }
        })
        .catch(error => {
          dispatch(
            base.getFailure(RULE_CONST.GET_RULES_FOR_INSTANCE_FAILURE, {
              error: {
                data: error
              }
            })
          );
        });
    };
  } else {
    //here getting rules teams/instance both
    return dispatch => {
      dispatch(base.getRequest(RULE_CONST.GET_RULES_BY_CRITERIA_REQUEST));
      AXIOS_INSTANCE.get(
        `${BASE_URL}/getRuleByCriteria?name=${formData.name}&id=${formData.id}${qString}`
      )
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          if (response.isSuccess) {
            response.data.isTeam = formData.name == "team" ? true : false;
            dispatch(
              base.getSuccess(RULE_CONST.GET_RULES_BY_CRITERIA_SUCCESS, {
                response: {
                  data: response.data
                }
              })
            );
          } else {
            dispatch(
              base.getFailure(RULE_CONST.GET_RULES_BY_CRITERIA_FAILURE, {
                response: {
                  data: response
                }
              })
            );
          }
        })
        .catch(error => {
          dispatch(
            base.getFailure(RULE_CONST.GET_RULES_BY_CRITERIA_FAILURE, {
              error: {
                data: error
              }
            })
          );
        });
    };
  }
}

// action for create rules
export function createRule(formData) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.CREATE_RULE_REQUEST));
    AXIOS_INSTANCE.post(`${BASE_URL}/CreateRule`, formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.CREATE_RULE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.CREATE_RULE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.CREATE_RULE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get teams by rule
export function getRuleTeam(key, valueId, qString) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.GET_TEAM_BY_RULE_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getRuleTeam?${key}=${valueId}${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.GET_TEAM_BY_RULE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.GET_TEAM_BY_RULE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.GET_TEAM_BY_RULE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update rule
export function updateRule(formData) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.UPDATE_RULE_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/UpdateRule", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.UPDATE_RULE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.UPDATE_RULE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.UPDATE_RULE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete rule
export function deleteRule(ruleId) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.DELETE_RULE_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/DeleteRule?ruleId=${ruleId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.DELETE_RULE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.DELETE_RULE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.DELETE_RULE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get rule notification
export function getRuleNotification(qString) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.GET_RULE_NOTIFICATION_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getRuleNotification${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.GET_RULE_NOTIFICATION_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.GET_RULE_NOTIFICATION_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.GET_RULE_NOTIFICATION_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for create rule notification
export function createRuleNotification(formData) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.CREATE_RULE_NOTIFICATION_REQUEST));
    AXIOS_INSTANCE.post(`${BASE_URL}/createRuleNotification`, formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.CREATE_RULE_NOTIFICATION_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.CREATE_RULE_NOTIFICATION_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.CREATE_RULE_NOTIFICATION_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update rule notification
export function updateRuleNotification(formData) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.UPDATE_RULE_NOTIFICATION_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateRuleNotification", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.UPDATE_RULE_NOTIFICATION_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.UPDATE_RULE_NOTIFICATION_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.UPDATE_RULE_NOTIFICATION_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete rule notification
export function deleteRuleNotification(ruleId) {
  return dispatch => {
    dispatch(base.getRequest(RULE_CONST.DELETE_RULE_NOTIFICATION_REQUEST));
    AXIOS_INSTANCE.delete(
      `${BASE_URL}/deleteRuleNotification?ruleNotificationId=${ruleId}`
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(RULE_CONST.DELETE_RULE_NOTIFICATION_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(RULE_CONST.DELETE_RULE_NOTIFICATION_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(RULE_CONST.DELETE_RULE_NOTIFICATION_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
