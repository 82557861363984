import React from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { Button } from "components";
import addUser from "assets/img/svg/add-user.svg";
import SideBarToggle from "../../Common/SideBarToggle";
import InvitationModal from "../../Modals/InvitationModal";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import { confirmPermissions, userRoles } from "../../../../helpers/Utility";
import BreadcrumbComponent from "../../Common/BreadCrumbComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import queryString from "query-string";
import ReactTable from "react-table";
import { UsersByRole } from "../../../../actions/roleAction";
class RoleMembersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInvitationModal: false,
      dropdownOpen: false,
    };
  }
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({ showInvitationModal: !this.state.showInvitationModal });
  };
  // //this function we are using to show members details
  // showMembersDetails = (isRoleRemovable, isInvite, showAction) => {
  //   if (this.props.isRequesting) {
  //     return (
  //       <tr className="table-tr-loader">
  //         <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
  //       </tr>
  //     );
  //   } else {
  //     if (this.props.userDataByRole && this.props.userDataByRole.length) {
  //       return this.props.userDataByRole.map((data, key) => {
  //         return (
  //           <tr key={key} className="table-data-rows-two">
  //             <td className="pl-3 table-data-width">
  //               {data.User.Status_User
  //                 ? data.User.First_Name + " " + data.User.Last_Name
  //                 : "N/A"}
  //             </td>
  //             <td className="pl-3">{data.User.Email_User}</td>
  //             <td className="pl-3">
  //               {data.User.Status_User ? "Active" : "Pending"}
  //             </td>

  //             {showAction && (
  //               <td>
  //                 {isRoleRemovable && (
  //                   <Button
  //                     className="btn-sm"
  //                     color="danger"
  //                     round
  //                     onClick={e => this.props.deleteRole(data.UID_UserRole)}
  //                   >
  //                     <i className="fa fa-trash" aria-hidden="true" />
  //                   </Button>
  //                 )}{" "}
  //                 {isInvite &&
  //                   (data.User.Status_User ? (
  //                     ""
  //                   ) : (
  //                     <Button
  //                       className="btn-sm"
  //                       color="primary"
  //                       round
  //                       onClick={e =>
  //                         this.props.reInvite(data.User.Email_User, key)
  //                       }
  //                     >
  //                       {this.props.loading && this.props.rowKey === key ? (
  //                         <i className="fa fa-spinner fa-pulse" />
  //                       ) : (
  //                         <i
  //                           className="fa fa-share fa-1x cursor-pointer"
  //                           aria-hidden="true"
  //                           text="white"
  //                         />
  //                       )}
  //                     </Button>
  //                   ))}
  //               </td>
  //             )}
  //           </tr>
  //         );
  //       });
  //     } else {
  //       return (
  //         <tr className="table-tr-loader">
  //           <td className="no-data-found-style">No Data Found</td>
  //         </tr>
  //       );
  //     }
  //   }
  // };
  showRoles = () => {
    if (this.props.role_number == 2) {
      return "PartnerAdmin (Role)";
    } else if (this.props.role_number == 3) {
      return "PartnerSupport (Role)";
    } else if (this.props.role_number == 4) {
      return "CustomerAdmin (Role)";
    } else if (this.props.role_number == 5) {
      return "CustomerSupport (Role)";
    }
  };
  render() {
    const showBreadcrumb = this.props.role_number ? true : false;
    const permissions = this.props.userDataPermission
      ? this.props.userDataPermission.permissions
      : [];
    const isInvite = confirmPermissions(permissions, "isInvitable");
    const isRoleRemovable = confirmPermissions(permissions, "isRoleRemovable");
    const showAction = isInvite && isRoleRemovable;
    const customerName = this.props.userDataPermission
      ? this.props.userDataPermission.userData.length
        ? this.props.userDataPermission.userData[0].Customer
          ? this.props.userDataPermission.userData[0].Customer.Name_Customer
          : ""
        : ""
      : "";
    const customer = customerName.split(" ").join("_");
    const param = this.props.search
      ? queryString.parse(this.props.search).name.split("_").join(" ")
      : "";
    const confirmRole = userRoles.partner_roles.includes(
      this.props.role_number
    );
    /*-react-table data-*/
    const data = this.props.userDataByRole ? this.props.userDataByRole : [];
    /*-------react-table headers ---------*/
    const headers = {
      First_Name: "member name",
      Email_User: "Email Id",
      status: "Status",
      actions: "Actions",
    };
    return (
      <div>
        {this.state.showInvitationModal && (
          <InvitationModal
            isOpen={this.state.showInvitationModal}
            handleModal={this.handleModal}
            role_id={this.props.role_id}
            role_number={this.props.role_number}
            meta={this.props.meta}
          />
        )}
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                  <SideBarToggle />
                </Col>
                <Col className="d-lg-none d-md-none">
                  {isInvite && (
                    <Button
                      color="primary"
                      onClick={(e) =>
                        this.setState({
                          showInvitationModal: !this.state.showInvitationModal,
                        })
                      }
                      className="mobile-button"
                    >
                      <img
                        src={addUser}
                        alt="add_user"
                        className="mr-2 add-member-icon"
                      />
                      <span className="font-weight-bold">
                        {this.props.t("members.ADD_MEMBER")}
                      </span>
                    </Button>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                  <p className="font-weight-bold">
                    {this.props.t("members.TITLE")}
                  </p>
                </Col>
                <Col
                  lg={5}
                  md={5}
                  xs={6}
                  sm={6}
                  className="mr-auto padding-two"
                >
                  <InputGroup className="no-border">
                    <Input
                      id="myInput"
                      placeholder={this.props.t("members.SEARCH")}
                      className="search-bar-css shadow-css"
                      onKeyUp={(e) => this.props.searchName(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        className="search-bar-css icon-css shadow-css cursor-pointer"
                        // onClick={e => this.props.onClickSearchButton()}
                      >
                        <i className="now-ui-icons ui-1_zoom-bold" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col
                  lg={2}
                  md={2}
                  xs={12}
                  sm={12}
                  className="offset-sm-2 offset-xs-2 order-first order-lg-last order-md-last"
                >
                  {isInvite && (
                    <Button
                      color="primary"
                      onClick={(e) =>
                        this.setState({
                          showInvitationModal: !this.state.showInvitationModal,
                        })
                      }
                      className="create-team-button"
                    >
                      <img
                        src={addUser}
                        alt="add_user"
                        className="mr-2 add-member-icon"
                      />
                      <span className="font-weight-bold">
                        {this.props.t("members.ADD_MEMBER")}
                      </span>
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} xs={12} className="">
                  <div>
                    {showBreadcrumb && (
                      <BreadcrumbComponent
                        active={this.props.t("members.MEMBERS")}
                        nonActive1={this.showRoles()}
                        nonActive1Href={
                          confirmRole
                            ? "/dashboard/partnerUsers/" +
                              this.props.role_id +
                              "?name=" +
                              param
                            : "/dashboard/roles/2/" +
                              this.props.role_id +
                              "?customerName=" +
                              param //here previously "customer" is there
                        }
                        nonActive2={param}
                        nonActive2Href={
                          confirmRole
                            ? "/dashboard/partner"
                            : "/dashboard/organisationDashboard/" +
                              this.props.role_id
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Card className="mt-4">
                <CardBody>
                  <ReactTable
                    loading={!this.props.isRequesting ? false : true}
                    loadingText={"Argos..."}
                    className="cursor-pointer"
                    data={data}
                    columns={[
                      {
                        columns: [
                          {
                            Header: headers.First_Name,
                            accessor: "First_Name",
                            Cell: (row) => (
                              <div>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    {row.value}
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            Header: headers.Email_User,
                            accessor: "Email_User",
                            Cell: (row) => (
                              <div>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    {row.value}
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            sortable: false,
                            Header: headers.status,
                            accessor: "status",
                            Cell: (row) => (
                              <div>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    <div className="">
                                      {row.value ? (
                                        <span className="badge badge-primary">
                                          active
                                        </span>
                                      ) : (
                                        <span className="badge badge-secondary background-style">
                                          inactive
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            sortable: false,
                            Header: headers.actions,
                            accessor: "actions",
                            Cell: (row) => (
                              <div>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    <div className="ml-5">
                                      {showAction && (
                                        <div>
                                          {isRoleRemovable && (
                                            <Button
                                              className="btn-sm"
                                              color="danger"
                                              round
                                              onClick={(e) =>
                                                this.props.deleteRole(
                                                  row.value.id
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              />
                                            </Button>
                                          )}{" "}
                                          {isInvite &&
                                            (row.value.status ? (
                                              ""
                                            ) : (
                                              <Button
                                                className="btn-sm"
                                                color="primary"
                                                round
                                                onClick={(e) =>
                                                  this.props.reInvite(
                                                    row.value.email,
                                                    row.value.key
                                                  )
                                                }
                                              >
                                                {this.props.loading &&
                                                this.props.rowKey ===
                                                  row.value.key ? (
                                                  <i className="fa fa-spinner fa-pulse" />
                                                ) : (
                                                  <i
                                                    className="fa fa-share fa-1x cursor-pointer"
                                                    aria-hidden="true"
                                                    text="white"
                                                  />
                                                )}
                                              </Button>
                                            ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                        ],
                      },
                    ]}
                    manual
                    pages={this.props.meta.pageTotal}
                    defaultPageSize={this.props.meta.pageSize}
                    pageSizeOptions={this.props.meta.pageSizeOptions}
                    onFetchData={(state, instance) => {
                      let qString;
                      if (state.sorted.length) {
                        qString = `&pageSize=${state.pageSize}&pageIndex=${
                          state.page
                        }&field=${state.sorted[0].id}&order=${
                          state.sorted[0].desc ? "desc" : "asc"
                        }`;
                      } else {
                        qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                      }
                      if (this.props.role_number || qString) {
                        this.props.callApi(qString);
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#fff" }}
        />
      </div>
    );
  }
}
RoleMembersPage.propTypes = {
  searchName: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  userDataByRole: PropTypes.array,
  role_id: PropTypes.number,
  role_number: PropTypes.string,
  userDataPermission: PropTypes.object,
  error: PropTypes.bool,
  meta: PropTypes.object,
};
export default compose(translate, connect())(RoleMembersPage);
