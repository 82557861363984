/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Login from "../Components/LoginPage.jsx";
import { accountLogin, userModule } from "../../../../actions/loginAction";
import { logout } from "../../../../actions/loginAction";
import { emailRex } from "../../../../helpers/Utility";
import PropTypes from "prop-types";
import { showError, showSuccess } from "../../../../helpers/Utility";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {},
      loading: false,
    };

    let userLoggedIn = false;
    userLoggedIn = localStorage.getItem("authToken") ? true : false;
    if (!userLoggedIn || !this.readCookie("authToken")) {
      this.eraseCookie("authToken");
    }

    if (this.readCookie("authToken")) {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView) {
        this.props.history.push("/dashboard/issues");
      } else {
        this.props.history.push("/dashboard/home");
      }
    } else {
      this.logout();
    }
  }

  /*-----------function for logout --------------*/
  logout = () => {
    // this.props.dispatch(logout());
    localStorage.clear();
    this.props.history.push("/pages/login-page");
  };

  // Below three Default cookie functions are not related to project code so dont change any code in this functions
  // Default function for creating the Cookie
  createCookie = (name, value, days) => {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  };
  // Default function for reading the cookie
  readCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  // Defautlt function for erasing the cookie
  eraseCookie = (name) => {
    this.createCookie(name, "", -1);
  };

  componentDidMount = () => {
    localStorage.removeItem("user_role");
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    /*---------------------login response-------------------*/
    const isMobileView = window.innerWidth <= 768; // Adjust the threshold as needed
    if (nextProps.loginSuccess) {
      let userLoggedIn = false;
      userLoggedIn = localStorage.getItem("authToken") ? true : false;
      if (userLoggedIn) {
        this.createCookie("authToken", userLoggedIn, 7); // creating new cookie ("authToken":false) with 7 days validity
      }
      this.props.dispatch(userModule());
    }
    if (
      nextProps.loginError &&
      nextProps.loginError !== this.props.loginError
    ) {
      showError(nextProps.loginErrorDescription);
      this.setState({ loading: !this.state.loading });
    }
    if (
      nextProps.isTempPassword &&
      nextProps.isTempPassword !== this.props.isTempPassword
    ) {
      if (isMobileView) {
        this.props.history.push("/dashboard/issues");
      } else {
        this.props.history.push("/dashboard/home");
      }
    }
    /*---------------user module response----------------------*/
    if (
      nextProps.userModuleDataError &&
      nextProps.userModuleDataError !== this.props.userModuleDataError
    ) {
      showError(this.props.t("error.USER_MODULES_ERROR"));
    }
    if (
      nextProps.userModuleDataNetworkError &&
      nextProps.userModuleDataNetworkError !==
        this.props.userModuleDataNetworkError
    ) {
      showError(this.props.t("error.NETWORK_ERROR"));
    }
    if (nextProps.userModuleData) {
      localStorage.setItem(
        "userModule",
        JSON.stringify(nextProps.userModuleData)
      );
      

      if (isMobileView) {
        this.props.history.push("/dashboard/issues");
      } else {
        this.props.history.push("/dashboard/home");
      }
    }
    /*-------------------user roles response-----------------------*/
    this.roles_count = nextProps.isUserScope ? nextProps.isUserScope.length : 0;
    if (
      nextProps.isUserScope &&
      nextProps.isUserScope.length > 1 &&
      nextProps.isUserScope !== this.props.isUserScope
    ) {
      this.setState({
        userScope: nextProps.isUserScope,
      });
    } else if (nextProps.isUserScope) {
      localStorage.setItem(
        "user_role",
        JSON.stringify(nextProps.isUserScope[0])
      );
      setTimeout(
        function() {
          if (isMobileView) {
            this.props.history.push("/dashboard/issues");
          } else {
            this.props.history.push("/dashboard/home");
          }
        }.bind(this),
        1000
      );
    }
    if (
      nextProps.isUserScopeFail &&
      nextProps.isUserScopeFail !== this.props.isUserScopeFail
    ) {
      showError("Error in roles");
    }
  };

  /**
   * call this function to navigate forgot password page
   */
  forgotPassword = () => {
    this.props.history.push("/pages/forgot-password");
  };
  /**
   * call this function to call login api.
   */
  onSubmit = (formProps) => {
    let login = this.state.login;
    let isValid = true;
    if (login["emailState"] !== "has-success") {
      login["emailState"] = "has-danger";
      isValid = false; //not valid
    }
    if (login["passwordState"] !== "has-success") {
      login["passwordState"] = "has-danger";
      isValid = false; //not valid
    }
    if (!isValid) {
      return false;
    } else {
      this.setState({ showLoader: true });
      this.setState({ loading: !this.state.loading });
      this.props.dispatch(accountLogin(formProps));
    }
  };
  /**
   * call this function to validate email
   */
  validateEmail = (e) => {
    let login = this.state.login;
    login["email"] = e.target.value;
    if (emailRex.test(e.target.value)) {
      login["emailState"] = "has-success";
    } else {
      login["emailState"] = "has-danger";
    }
    this.setState({ login });
  };
  /**
   * call this function to validate password
   */
  validatePassword = (e) => {
    let login = this.state.login;
    login["password"] = e.target.value;
    if (e.target.value.length >= 5) {
      login["passwordState"] = "has-success";
    } else {
      login["passwordState"] = "has-danger";
    }
    this.setState({ login });
  };
  render() {
    return (
      <div>
        <Login
          content={this.state.content}
          changeText={this.changeText}
          onSubmit={this.onSubmit}
          goCreatePage={this.goCreatePage}
          handleSubmit={this.props.handleSubmit}
          login={this.state.login}
          validateEmail={this.validateEmail}
          validatePassword={this.validatePassword}
          loading={this.state.loading}
          forgotPassword={this.forgotPassword}
          userScope={this.state.userScope}
        />
      </div>
    );
  }
}
LoginContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  loginData: PropTypes.object,
  loginSuccess: PropTypes.bool,
  loginError: PropTypes.bool,
  isTempPassword: PropTypes.bool,
  isUserScopeFail: PropTypes.bool,
  isUserScope: PropTypes.array,
  t: PropTypes.func,
  userModuleData: PropTypes.object,
  userModuleDataError: PropTypes.bool,
  userModuleDataNetworkError: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    loginErrorDescription: state.login
      ? state.login.loginErrorDescription
      : null,
    loginSuccess: state.login.loginSuccess,
    isTempPassword: state.login.isTempPassword,
    loginData:
      state.login.loginData && state.login.loginData.data.user.isTempPassword,
    token: state.login.loginData ? state.login.loginData.data.token : null,
    loginError: state.login.loginError,
    statusCode: state.login.statusCode,
    isUserScope: state.invite.isUserScopeSuccess,
    isUserScopeFail: state.invite.isUserScopeFailure,
    userModuleData: state.login.userModuleData,
    userModuleDataError: state.login.userModuleDataError,
    userModuleDataNetworkError: state.login.userModuleDataNetworkError,
    //loginNetworkError: state.login.loginNetworkError
  };
}
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "login-form",
    //validate,
    // asyncValidate,
  }),
  connect(mapStateToProps)
)(LoginContainer);
