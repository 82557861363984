/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import InstanceDashboard from "../../Dashboard/Components/InstanceDashboard";
import PropTypes from "prop-types";
import {
  instanceStatistics,
  instanceLogs,
  deleteInstance,
  getInstanceCertificate
} from "../../../../../actions/instanceAction";
import queryString from "query-string";
import { showError, showSuccess } from "../../../../../helpers/Utility";

class InstanceDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      showUpdateInstanceModal: false,
      tooltipOpen: false,
      instanceCertificatePath: null,
      isRequesting: false,
      isRequestingTwo: false,
      initialValues: {}
    };
  }
  componentDidMount = () => {
    if (this.props.instance_id) {
      this.props.dispatch(instanceStatistics(this.props.instance_id));
      this.props.dispatch(instanceLogs(this.props.instance_id));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.instanceStatisticsData) {
      this.setState({
        instanceStatisticsData: nextProps.instanceStatisticsData,
        customerId: nextProps.instanceStatisticsData
          ? nextProps.instanceStatisticsData.Customer.UID_Customer
          : ""
      });
    }
    if (
      nextProps.instanceStatisticsError &&
      nextProps.instanceStatisticsError !== this.props.instanceStatisticsError
    ) {
      this.setState({
        instanceStatisticsError: nextProps.instanceStatisticsError
      });
      showError(this.props.t("error.DASHBOARD_ERROR"));
    }
    if (nextProps.instanceLogsData &&
        nextProps.instanceLogsData!== this.props.instanceLogsData
        ) {
        this.setState({
        instanceLogsData: nextProps.instanceLogsData
      });
    }
    if (
      nextProps.deleteInstanceSuccess &&
      nextProps.deleteInstanceSuccess !== this.props.deleteInstanceSuccess
    ) {
      showSuccess("Instance deleted successfully");
      this.props.history.push(
        "/dashboard/instances/" +
          this.state.customerId +
          "?customerName=" +
          queryString.parse(this.props.searchParams).customer
      );
    }
    if (
      nextProps.deleteInstanceFailure &&
      nextProps.deleteInstanceFailure !== this.props.deleteInstanceFailure
    ) {
      showError("Something went wrong");
    }
    if (
      nextProps.teamStatusData &&
      nextProps.teamStatusData !== this.props.teamStatusData
    ) {
      showSuccess(this.props.t("success.TEAM_STATUS_SUCCESS"));
      this.props.dispatch(instanceStatistics(this.props.instance_id));
    }
    if (
      nextProps.teamStatusError &&
      nextProps.teamStatusError !== this.props.teamStatusError
    ) {
      showError(this.props.t("error.TEAM_STATUS_ERROR"));
    }
    if (
      nextProps.instanceCertificateSuccess &&
      nextProps.instanceCertificateSuccess !==
        this.props.instanceCertificateSuccess
    ) {
      this.setState({
        instanceCertificatePath: nextProps.instanceCertificateSuccess
      });
      setTimeout(
        function() {
          document.getElementById("linkId") &&
            document.getElementById("linkId").click();
          this.setState({
            instanceCertificatePath: null
          });
        }.bind(this),
        1000
      );
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
    this.setState({
      isRequestingTwo: nextProps.isRequestingTwo
    });
  };
  /*--------------this navigate function we are using to navigate pages from dashboard -----------*/
  navigatePage = (path, InstanceName, customerName, customerId) => {
    const Instance = InstanceName.split(" ").join("_");
    const customer = customerName.split(" ").join("_");
    // const Instance = queryString.parse(this.props.searchParams).instance;
    // const customer = queryString.parse(this.props.searchParams).customer;
    this.props.history.push(
      path +
        "/" +
        this.props.instance_id +
        "?instanceName=" +
        Instance +
        "&customerName=" +
        customer +
        "&customerId=" +
        customerId
    );
  };
  navigateToMembers = (path, roleValue) => {
    this.props.history.push(path + "/" + roleValue + "/" + this.props.team_id);
  };
  //call this function to handle(means close or open) modal
  handleModal = () => {
    this.setState({
      showUpdateInstanceModal: !this.state.showUpdateInstanceModal
    });
  };
  dropdownToggle = e => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  /*---------for delete and update team ------------------*/
  deleteInstance = () => {
    const confirm = window.confirm(
      "Are you sure you want to delete instance ?"
    );
    if (confirm) {
      this.props.dispatch(deleteInstance(this.props.instance_id));
    } else {
      return false;
    }
  };
  editInstance = instanceData => {
    let initialValues = {
      name: instanceData.Name_Instance,
      description: instanceData.Description_Instance
        ? instanceData.Description_Instance
        : "",
      instanceId: instanceData.UID_Instance
    };
    this.setState({
      initialValues: initialValues,
      showUpdateInstanceModal: true
    });
  };
  /**calling this function for toggling tooltip */
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };
  /*calling this function to call api download instance key */
  downloadInstanceKey = () => {
    this.props.dispatch(getInstanceCertificate(this.props.instance_id));
  };
  /*---------render method---------------------- */
  render() {
    const InstanceName = queryString
      .parse(this.props.searchParams)
      .instance.split("_")
      .join(" ");
    const CustomerName = queryString
      .parse(this.props.searchParams)
      .customer.split("_")
      .join(" ");
    return (
      <div>
        <InstanceDashboard
          navigatePage={this.navigatePage}
          stateValues={this.state}
          instance_id={this.props.instance_id}
          dropdownToggle={this.dropdownToggle}
          deleteInstance={this.deleteInstance}
          editInstance={this.editInstance}
          handleModal={this.handleModal}
          downloadInstanceKey={this.downloadInstanceKey}
          searchParams={this.props.searchParams}
          t={this.props.t}
          toggle={this.toggle}
          instanceName={InstanceName}
          customerName={CustomerName}
          copyInstanceKey={this.copyInstanceKey}
        />
      </div>
    );
  }
}
/*---------Props validation---------------------- */
InstanceDashboardContainer.propTypes = {
  history: PropTypes.object,
  instance_id: PropTypes.string,
  goBack: PropTypes.func,
  deleteInstanceSuccess: PropTypes.bool,
  deleteInstanceFailure: PropTypes.bool,
  instanceStatisticsData: PropTypes.object,
  instanceStatisticsError: PropTypes.bool,
  teamStatusError: PropTypes.bool,
  teamStatusData: PropTypes.bool,
  dispatch: PropTypes.func,
  searchParams: PropTypes.string,
  instanceCertificateSuccess: PropTypes.string
};
function mapStateToProps(state) {
  return {
    deleteInstanceSuccess: state.instance.deleteInstanceSuccess,
    deleteInstanceFailure: state.instance.deleteInstanceFailure,
    instanceStatisticsData: state.instance.instanceStatisticsData,
    instanceStatisticsError: state.instance.instanceStatisticsError,
    instanceLogsData: state.instance.instanceLogsData,
    instanceCertificateSuccess: state.instance.instanceCertificateSuccess,
    isRequesting: state.instance.isRequesting,
    isRequestingTwo: state.instance.isRequestingTwo
  };
}
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(InstanceDashboardContainer);
