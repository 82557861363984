import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Form
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  createInstance,
  updateInstance,
  getInstanceByCriteria,
  instanceStatistics
} from "../../../actions/instanceAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";
class CreateInstanceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      loading: false,
      search_loading: false,
      createInstance: {
        nameState: this.props.initialValues ? "has-success" : null
      }
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.createInstanceSuccess &&
      nextProps.createInstanceSuccess !== this.props.createInstanceSuccess
    ) {
      showSuccess("Instance created successfully.");
      this.setState({
        loading: false
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `&pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      let formData = {
        name: "customer",
        id: this.props.customerId
      };
      this.props.dispatch(getInstanceByCriteria(formData, qString));
      this.props.handleModal();
    }
    if (
      nextProps.updateInstanceSuccess &&
      nextProps.updateInstanceSuccess !== this.props.updateInstanceSuccess
    ) {
      showSuccess("Instance updated successfully.");
      this.setState({
        loading: false
      });
      this.props.dispatch(instanceStatistics(this.props.instanceId));
      this.props.handleModal();
    }
    if (
      nextProps.createInstanceFailure &&
      nextProps.createInstanceFailure !== this.props.createInstanceFailure
    ) {
      showError(nextProps.createInstanceFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.createInstanceNetworkFailure &&
      nextProps.createInstanceNetworkFailure !==
        this.props.createInstanceNetworkFailure
    ) {
      showError("Error in creating instance.");
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updateInstanceFailure &&
      nextProps.updateInstanceFailure !== this.props.updateInstanceFailure
    ) {
      showError(nextProps.updateInstanceFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updateInstanceNetworkFailure &&
      nextProps.updateInstanceNetworkFailure !==
        this.props.updateInstanceNetworkFailure
    ) {
      showError("Error in updating instance.");
      this.setState({
        loading: false
      });
    }
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    if (formProps) {
      formProps.customerId = this.props.customerId;
      let instance = this.state.createInstance;
      let isValid = true;
      if (instance["nameState"] !== "has-success") {
        instance["nameState"] = "has-danger";
        isValid = false; //not valid
      }
      if (!isValid) {
        showError("Instance name is mandatory.");
        return false;
      } else {
        if (this.props.initialValues) {
          this.props.dispatch(updateInstance(formProps));
        } else {
          this.props.dispatch(createInstance(formProps));
        }
        this.setState({
          loading: true
        });
      }
    } else {
      return false;
    }
  };
  /**
   * call this function to validate name of the team
   */
  validateName = e => {
    let instance = this.state.createInstance;
    if (e.target.value.length > 4 && e.target.value.length < 50) {
      instance["nameState"] = "has-success";
    } else {
      instance["nameState"] = "has-danger";
    }
    this.setState({ instance });
  };
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues ? "Update Instance" : "Create Instance"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup
                    className={
                      this.state.createInstance.nameState
                        ? "has-label " + this.state.createInstance.nameState
                        : "has-label"
                    }
                  >
                    <Field
                      component={renderTextField}
                      name="name"
                      type="text"
                      placeholder="Enter Instance Name*"
                      className="team-input-style"
                      onChange={e => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup>
                    <Field
                      component={renderTextField}
                      name="description"
                      type="textarea"
                      className="textarea-styles"
                      placeholder="Enter Instance Description"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={7} className="ml-auto pl-5">
                  <Button
                    color="primary"
                    // onClick={e => this.onSubmit(this.state.value)}
                    loading={this.state.loading}
                    type="submit"
                  >
                    {this.props.initialValues
                      ? "Update"
                      : this.props.t("teams.CREATE")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CreateInstanceModal.propTypes = {
  createInstanceSuccess: PropTypes.bool,
  createInstanceFailure: PropTypes.string,
  createInstanceNetworkFailure: PropTypes.bool,
  updateInstanceSuccess: PropTypes.bool,
  updateInstanceFailure: PropTypes.string,
  updateInstanceNetworkFailure: PropTypes.bool,
  handleModal: PropTypes.func,
  customerId: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  meta: PropTypes.object
};
function mapStateToProps(state) {
  return {
    createInstanceSuccess: state.instance.createInstanceSuccess,
    createInstanceFailure: state.instance.createInstanceFailure,
    createInstanceNetworkFailure: state.instance.createInstanceNetworkFailure,
    updateInstanceSuccess: state.instance.updateInstanceSuccess,
    updateInstanceFailure: state.instance.updateInstanceFailure,
    updateInstanceNetworkFailure: state.instance.updateInstanceNetworkFailure
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createInstance-form"
  }),
  connect(mapStateToProps)
)(CreateInstanceModal);
