import React from "react";
import { Container, Col, Row, Card, Table } from "reactstrap";
import Previous from "assets/img/png/previous.png";
import Next from "assets/img/png/next.png";

 
function PartnerDashboardTable( {table ,recentData } ) {

  const renderEmptyRow = (data) => {
    if (0 < data && 10 > data) {
      let i = 0;
      let row = [];
      for (i; i < data; i++) {
        row.push(
          <>
            <tr className="empty-row-width">
              <td> </td>
              <td> </td>
              <td> </td>
              <td />
            </tr>
          </>
        );
      }
      return row;
    }
  };

  const renderOrganizationActivityTable=(data)=>{
    if(data.length > 0) {
      return data.map((data) => { 
        return (
          <tr>
            <th>
              {  data.customer &&
                data.customer.Name_Customer && 
                data.customer.Name_Customer
                ? data.customer.Name_Customer
                : "0"}
            </th>
            <td>
              {
                data.customer &&
                data.customer.count &&
                data.customer.count.InstanceCount &&
                data.customer.count.InstanceCount
                ? data.customer.count.InstanceCount
                : "0"}
            </td>
            <td>
              { data.customer &&
                data.customer.count &&
                data.customer.count.Opened &&
                data.customer.count.Opened
                ? data.customer.count.Opened
                : "0"}
            </td>
            <td>
              { data.customer &&
                data.customer.count &&
                data.customer.count.Closed &&
                data.customer.count.Closed
                ? data.customer.count.Closed
                : "0"}
            </td>
          </tr>
        );
      })
    }
    else {
      return (
        <>
          <tr>
            <td> </td>
            <td> </td>
            <td>No record found </td>
            <td />
          </tr>
        </>
      );
    }
  }
  const renderRecentAlertsTable=(data)=>{
    if(data.length > 0){
      return data.map((data) => {
        return (
          <tr className="d-block">
            <td className="w-auto">
              <div
                className={
                  data.Priority
                    ? data.Priority == "High"
                      ? "team-dashboard-table-recent-alerts-high"
                      : data.Priority == "Medium"
                      ? "team-dashboard-table-recent-alerts-medium"
                      : data.Priority == "Low"
                      ? "team-dashboard-table-recent-alerts-low"
                      : ""
                    : ""
                }
              >
                <p className="team-dashboard-table-recent-alerts-title">
                  {data.RuleName ? data.RuleName : ""}
                </p>
                <p className="team-dashboard-table-recent-alerts-instance">
                  Instance: <span className="instance-name">
                  {data.InstanceName ? data.InstanceName : ""} | {" "}
                  {data.CustomerName ? data.CustomerName : ""} </span>
                </p>
                <p className="team-dashboard-table-recent-alerts-time">
                  {data.Created_Date ? data.Created_Date : ""}
                </p>
              </div>
            </td>
          </tr>
        );
      })
    }
    else{
      return(
      <tr>
        <td className="text-center"> No record found
        </td>
      </tr>
      )
    }
  }

  return (
    <div className="team-dashboard-Table">
      <Container>
        <Row>
          <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    Organization Activity
                  </p>
                </div>
                {/* <div className="team-dashboard-table-header-button">
                  <Button className="team-dashboard-table-header-button-style">
                    View All
                  </Button>
                </div> */}
              </div>
            </Card>
            <Table className="fixed_header" >
              <thead className="">
                <tr className="team-dashboard-table-heading-border">
                  <th className="team-dashboard-table-heading-border-first">
                    Company Name
                  </th>
                  <th className="table-heading-center">Instances</th>
                  <th className="table-heading-center">Open Ticket</th>
                  <th className="table-heading-center">Close Tickets</th>
                </tr>
              </thead>
              <tbody className="team-dashboard-table-body  ">
                { table &&
                   table[0] &&
                   renderOrganizationActivityTable(table)
                   
                }
                {table &&
                   table[0] && renderEmptyRow(10-table.length)}
              {/* </div> */}
              </tbody>      
            </Table>
            
           
            
            {/* <Card className="team-dashboard-table-footer-card">  
                <div className="team-dashboard-table-header-prev">
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                  </Button>
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                  </Button>
                </div>
            </Card> */}
          </Col>
          <Col sm="12" md="6">
            <Card className="team-dashboard-table-heading-card">
              <div className="team-dashboard-table-header">
                <div>
                  <p className="team-dashboard-table-header-text">
                    {" "}
                    Recent Alerts
                  </p>
                </div>
                {/* <div className="team-dashboard-table-header-button">
                  <Button className="team-dashboard-table-header-button-style">
                    View All
                  </Button>
                </div> */}
              </div>
            </Card>
            <Table className="fixed_header">
              <tbody className="team-dashboard-table-body-custom">
                { recentData &&
                   
                  renderRecentAlertsTable( recentData)
                   
                }
              </tbody>
            </Table>
            <Card className="team-dashboard-table-footer-card">
              <div>
                <ul className="d-flex mt-2 ">
                  <li
                    style={{
                      color: "#CC2323",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    High
                  </li>
                  <li
                    style={{
                      color: "#FF8A00",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    Medium{" "}
                  </li>
                  <li
                    style={{
                      color: "#1E7FF1",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Low
                  </li>
                </ul>
              </div>
              {/* <div className="team-dashboard-table-header-prev">
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Previous} width="10" />
                  </Button>
                  <Button className="team-dashboard-table-header-button-style dashboard-view-all-previous">
                  <img src={Next} width="10" />
                  </Button>
                </div> */}
            </Card>
            {/* <Card className="team-dashboard-table-footer-card-alerts">
              <ul className="team-dashboard-table-header-footer-alerts">
                <li
                  style={{
                    color: "#CC2323",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  High
                </li>
                <li
                  style={{
                    color: "#FF8A00",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  Medium{" "}
                </li>
                <li
                  style={{
                    color: "#1E7FF1",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {" "}
                  Low
                </li>
              </ul>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default PartnerDashboardTable;
