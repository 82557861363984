import React, { PureComponent } from "react";
import Organisation from "./Containers/OrganisationContainer";

class OrganisationPage extends PureComponent {
  render() {
    return (
      <div>
        <Organisation partner_id={this.props.match.params.id} />
      </div>
    );
  }
}

export default OrganisationPage;
