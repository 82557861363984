import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Form
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import {
  showError,
  showSuccess
} from "../../../helpers/Utility";

import { createPerspective, getPerspectiveData, updatePerspective } from "../../../actions/perspectiveAction";

class CreatePerspectiveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      loading: false,
      search_loading: false,
      createPerspective: {
        nameState: this.props.initialValues ? "has-success" : null
      },
      openLabels: [],
      perspectiveData: this.props.initialValues ? this.props.initialValues.perspectiveData  : []
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {

    if (
      nextProps.createPerspectiveSuccess 
      &&
      nextProps.createPerspectiveSuccess !== this.props.createPerspectiveSuccess
    ) {
      showSuccess("Perspective created successfully.");
      this.setState({
        loading: false
      });
      this.props.dispatch(getPerspectiveData());
      this.props.handleModal();
    }
    if (
      nextProps.updatePerspectiveSuccess 
      &&
      nextProps.updatePerspectiveSuccess !== this.props.updatePerspectiveSuccess
    ) {
      showSuccess("Perspective updated successfully.");
      this.setState({
        loading: false
      });
      this.props.dispatch(getPerspectiveData());
      this.props.handleModal();
    }
    if (
      nextProps.createPerspectiveFailure &&
      nextProps.createPerspectiveFailure !== this.props.createPerspectiveFailure
    ) {
      showError(nextProps.createCustomerFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.updatePerspectiveFailure &&
      nextProps.updatePerspectiveFailure !== this.props.updatePerspectiveFailure
    ) {
      showError(nextProps.createCustomerFailure);
      this.setState({
        loading: false,
      });
    }

  };

  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    if (formProps) {
      let instance = this.state.createPerspective;
      let isValid = true;
      if (instance["nameState"] !== "has-success") {
        instance["nameState"] = "has-danger";
        isValid = false;
      }
      if (!isValid) {
        showError("Perspective name is required.");
        return false;
      } else if (this.state.perspectiveData.length === 0) {
        showError("Please select at least one instance");
        return false;
      } else {  
        if (this.props.initialValues) {
          const updatePerspectiveReq = {
            perspectiveId: this.props.initialValues.perspectiveId,
            customPerspective: {
              name: formProps.name,
              perspectiveData: this.state.perspectiveData
            }
          }
          this.props.dispatch(updatePerspective(updatePerspectiveReq));
          this.setState({
            loading: true
          });
        } else {
          const createPerspectiveReq = {
            customPerspective: {
              name: formProps.name,
              perspectiveData: this.state.perspectiveData
            }
          }
          this.props.dispatch(createPerspective(createPerspectiveReq));
          this.setState({
            loading: true
          });
        }
      }
    } else {
      return false;
    }
  };
  /**
   * call this function to validate name of the perspective
   */
  validateName = e => {
    let perspective = this.state.createPerspective;
    if (e.target.value.length >= 3 && e.target.value.length < 50) {
      perspective["nameState"] = "has-success";
    } else {
      perspective["nameState"] = "has-danger";
    }
    this.setState({ perspective });
  };

  handleInstances = (value) => {
    const { perspectiveData } = this.state;
    const customerIndex = perspectiveData.findIndex(item => item.id === value.UID_Customer);
  
    if (customerIndex !== -1) {
      // Customer exists
      const updatedPerspectiveData = perspectiveData.map((item, index) => {
        if (index === customerIndex) {
          const existingInstances = item.instanceId;
          const instanceIndex = existingInstances.indexOf(value.UID_Instance);
  
          if (instanceIndex !== -1) {
            // Instance already exists, remove it
            existingInstances.splice(instanceIndex, 1);
  
            // If instances array is empty, remove the object
            if (existingInstances.length === 0) {
              return null;
            }
  
            return {
              ...item,
              instanceId: existingInstances
            };
          }
  
          // Instance does not exist, add it
          return {
            ...item,
            instanceId: [...existingInstances, value.UID_Instance]
          };
        }
        return item;
      }).filter(Boolean); // Filter out null (removed) objects
  
      this.setState({ perspectiveData: updatedPerspectiveData });
    } else {
      // Customer does not exist, add a new object with instance
      const newInstance = {
        perspectiveType: "perspective",
        id: value.UID_Customer,
        instanceId: [value.UID_Instance]
      };
  
      this.setState(prevState => ({
        perspectiveData: [...prevState.perspectiveData, newInstance]
      }));
    }
  };
  
  
  
  handleModal = () => {
    this.props.handleModal();
  };

  componentDidMount() {
    // Open all labels by default on initial render
    const { customersData } = this.props;
    const allLabels = customersData.map((item) => item.UID_Customer);
    this.setState({ openLabels: allLabels });
  }

  toggleProduction = (label) => {
    this.setState((prevState) => {
      let updatedLabels = [...prevState.openLabels];
      if (updatedLabels.includes(label)) {
        updatedLabels = updatedLabels.filter((l) => l !== label);
      } else {
        updatedLabels.push(label);
      }
      return { openLabels: updatedLabels };
    });
  };

  render() {
    const { handleSubmit, customersData } = this.props;
    const {  openLabels, perspectiveData } = this.state;

    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space perspective-modal"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
            style={{ position: 'sticky', top: 0, zIndex: 1050 }}
          >
            {this.props.initialValues
              ? "Update Perspective"
              : "Create Perspective"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup
                    className={
                      this.state.createPerspective.nameState
                        ? "has-label " + this.state.createPerspective.nameState
                        : "has-label"
                    }
                  >
                    <Field
                      component={renderTextField}
                      name="name"
                      type="text"
                      placeholder="Perspective Name*"
                      className="team-input-style"
                      onChange={e => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {customersData.map((item, index) => (
              <div key={index}>
                <Row>
                <Col lg={9} md={9} xs={9} className="mx-auto">
                    <label className="ccheck position-style instance-lable" 
                    onClick={() => this.toggleProduction(item.UID_Customer)}
                    >
                        {item.Name_Customer}
                        <i className={`toggle-icon ${openLabels.includes(item.UID_Customer) ? 'open' : 'closed'}`} />
                    </label>
                </Col>
                </Row>

                {openLabels.includes(item.UID_Customer) && (
                  <Row>
                    <Col lg={3} md={2} xs={2} />
                    <Col lg={9} md={10} xs={10}>
                      {item.Instances.map((instance, idx) => {
                        // Check if this instance are default selected in edit mode
                        const isSelected = perspectiveData.some(data => {
                          return (
                            data.id === item.UID_Customer && data.instanceId.includes(instance.UID_Instance)
                          );
                        });

                        return (
                          <label key={idx} className="ccheck position-style">
                            <Row>
                              <Col lg={1} md={1} xs={2}>
                                <Field
                                  component="input"
                                  name={instance.Name_Instance}
                                  type="checkbox"
                                  checked={isSelected} // Set the checked state based on isSelected
                                  onClick={e => this.handleInstances(instance)}
                                />
                                <span className="checkmark checkmark-two" />
                              </Col>
                              <Col lg={11} md={11} xs={10} className="instance-options">
                                {instance.Name_Instance}
                              </Col>
                            </Row>
                          </label>
                        );
                      })}
                    </Col>
                  </Row>
                )}

              </div>
              ))}

              <Row className="perspective-create-button">
                <Col lg={7} className="ml-auto pl-5">
                  <Button
                    color="primary"
                    loading={this.state.loading}
                    type="submit"
                  >
                    {this.props.initialValues
                      ? this.props.t("teams.UPDATE")
                      : this.props.t("teams.CREATE")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CreatePerspectiveModal.propTypes = {
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object
};
function mapStateToProps(state) {
  return {
    createPerspectiveSuccess: state.perspective.createPerspectiveSuccess,
    createPerspectiveFailure: state.perspective.createPerspectiveFailure,
    // createPerspectiveNetworkFailure: state.perspective.createPerspectiveNetworkFailure,
    updatePerspectiveSuccess: state.perspective.updatePerspectiveSuccess,
    updatePerspectiveFailure: state.instance.updatePerspectiveFailure
    // updateInstanceNetworkFailure: state.instance.updateInstanceNetworkFailure
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createPerspective-form"
  }),
  connect(mapStateToProps)
)(CreatePerspectiveModal);
