/**
 * @action        : notificationAction
 * @description   : get the notifications
 * @Created by    : INNOMICK
 */

import { NOTIFICATIONS_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";

// action for get notifications
export function getNotifications(qString) {
  return dispatch => {
    dispatch(base.getRequest(NOTIFICATIONS_CONST.GET_NOTIFICATIONS_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/Notification${qString}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(NOTIFICATIONS_CONST.GET_NOTIFICATIONS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(NOTIFICATIONS_CONST.GET_NOTIFICATIONS_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(NOTIFICATIONS_CONST.GET_NOTIFICATIONS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for delete notifications
export function deleteNotification(formData) {
  return dispatch => {
    dispatch(base.getRequest(NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_REQUEST));
    AXIOS_INSTANCE.delete(BASE_URL + "/deleteNotification", { data: formData })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(NOTIFICATIONS_CONST.DELETE_NOTIFICATIONS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update Notificationstatus
export function updateNotificationStatus(formData) {
  return dispatch => {
    dispatch(base.getRequest(NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateNotification", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(NOTIFICATIONS_CONST.UPDATE_NOTIFICATIONS_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
