import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Form,
  Label,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  createTeams,
  getTeams,
  updateTeam,
  teamStatistics
} from "../../../actions/teamAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../helpers/Utility";
class CreateTeamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      loading: false,
      search_loading: false,
      createTeam: {
        nameState: this.props.initialValues ? "has-success" : null
      }
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.createTeamSuccess &&
      nextProps.createTeamSuccess !== this.props.createTeamSuccess
    ) {
      showSuccess(this.props.t("success.TEAM_CREATED_SUCCESS"));
      this.setState({
        loading: false
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      this.props.dispatch(getTeams(this.props.customerId, qString));
      this.props.handleModal();
    }
    if (
      nextProps.updateTeamSuccess &&
      nextProps.updateTeamSuccess !== this.props.updateTeamSuccess
    ) {
      showSuccess(this.props.t("success.TEAM_UPDATED_SUCCESS"));
      this.setState({
        loading: false
      });
      this.props.dispatch(teamStatistics(this.props.teamId));
      this.props.handleModal();
    }
    if (
      nextProps.createTeamFailure &&
      nextProps.createTeamFailure !== this.props.createTeamFailure
    ) {
      showError(nextProps.createTeamFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.createTeamNetworkFailure &&
      nextProps.createTeamNetworkFailure !== this.props.createTeamNetworkFailure
    ) {
      showError(this.props.t("error.CREATE_TEAM_ERROR"));
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updateTeamFailure &&
      nextProps.updateTeamFailure !== this.props.updateTeamFailure
    ) {
      showError(nextProps.updateTeamFailure);
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updateTeamNetworkFailure &&
      nextProps.updateTeamNetworkFailure !== this.props.updateTeamNetworkFailure
    ) {
      showError(this.props.t("error.UPDATE_TEAM_ERROR"));
      this.setState({
        loading: false
      });
    }
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = formProps => {
    if (formProps) {
      formProps.customerId = this.props.customerId;
      let team = this.state.createTeam;
      let isValid = true;
      if (team["nameState"] !== "has-success") {
        team["nameState"] = "has-danger";
        isValid = false; //not valid
      }
      if (!isValid) {
        showError("Team name is mandatory.");
        return false;
      } else {
        if (this.props.initialValues) {
          this.props.dispatch(updateTeam(formProps));
        } else {
          this.props.dispatch(createTeams(formProps));
        }
        this.setState({
          loading: true
        });
      }
    } else {
      return false;
    }
  };
  /**
   * call this function to validate name of the team
   */
  validateName = e => {
    let team = this.state.createTeam;
    if (e.target.value.length > 4 && e.target.value.length < 50) {
      team["nameState"] = "has-success";
    } else {
      team["nameState"] = "has-danger";
    }
    this.setState({ team });
  };
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues
              ? this.props.t("teams.UPDATE_TEAM")
              : this.props.t("teams.CREATE_TEAM")}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup
                    className={
                      this.state.createTeam.nameState
                        ? "has-label " + this.state.createTeam.nameState
                        : "has-label"
                    }
                  >
                    {/* <Label className="text-muted float-left">Enter Team Name*</Label> */}
                    <Field
                      component={renderTextField}
                      name="name"
                      type="text"
                      placeholder={this.props.t("teams.ENTER_TEAM_NAME")}
                      className="team-input-style"
                      onChange={e => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup>
                    {/* <Label className="text-muted float-left">
                      Enter Description
                    </Label> */}
                    <Field
                      component={renderTextField}
                      name="description"
                      type="textarea"
                      className="textarea-styles"
                      placeholder={this.props.t("teams.ENTER_DESCRIPTION")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup>
                    {/* <Label className="text-muted float-left">Enter Team Name*</Label> */}
                    <Field
                      component={renderTextField}
                      name="teamLink"
                      type="text"
                      // placeholder={this.props.t("teams.ENTER_TEAM_LINK")}
                      placeholder="Microsoft Team Link"
                      className="team-input-style"
                      // onChange={e => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={7} className="ml-auto pl-5">
                  <Button
                    color="primary"
                    loading={this.state.loading}
                    type="submit"
                  >
                    {this.props.initialValues
                      ? this.props.t("teams.UPDATE")
                      : this.props.t("teams.CREATE")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CreateTeamModal.propTypes = {
  createTeamSuccess: PropTypes.bool,
  createTeamFailure: PropTypes.string,
  createTeamNetworkFailure: PropTypes.bool,
  updateTeamSuccess: PropTypes.bool,
  updateTeamFailure: PropTypes.string,
  updateTeamNetworkFailure: PropTypes.bool,
  handleModal: PropTypes.func,
  customerId: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  meta: PropTypes.object
};
function mapStateToProps(state) {
  return {
    createTeamSuccess: state.team.createTeamSuccess,
    createTeamFailure: state.team.createTeamFailure,
    createTeamNetworkFailure: state.team.createTeamNetworkFailure,
    updateTeamSuccess: state.team.updateTeamSuccess,
    updateTeamFailure: state.team.updateTeamFailure,
    updateTeamNetworkFailure: state.team.updateTeamNetworkFailure
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createTeam-form"
  }),
  connect(mapStateToProps)
)(CreateTeamModal);
