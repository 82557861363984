import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import PerspectivesPage from "../Components/PerspectivesPage.jsx";
import { getPerspectiveData } from "../../../../actions/perspectiveAction.js";
import { getCustomers } from "../../../../actions/customerAction";
import {
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../../helpers/Utility.jsx";
import PropTypes from "prop-types";
class PerspectivesPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {},
      showInvitationModal: false,
      stopLoader: false,
      loading: false,
      meta: {
        pageNumber: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0
      },
      customerData: null,
    };
  }
  callApi = () => {
    this.props.dispatch(getPerspectiveData())
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
     // perspective details api response
     if (
      nextProps.perspectiveData &&
      nextProps.perspectiveData.data !== this.props.perspectiveData
    ) {
      this.setState({
        perspectiveData: nextProps.perspectiveData.data.Perspective, //perspective data
        meta: {...this.state.meta, ...nextProps.perspectiveData.metaData}
      });
    }

    if (
      nextProps.deleteInstanceSuccess &&
      nextProps.deleteInstanceSuccess !== this.props.deleteInstanceSuccess
    ) {
      showSuccess("Perspective deleted successfully.");
      
      this.props.dispatch(getPerspectiveData());
    }

    if (
      nextProps.customerData &&
      nextProps.customerData !== this.props.customerData
    ) {
      this.setState({
        customerData: nextProps.customerData.data.Customer,
      });
    }

    this.setState({
      isRequesting: nextProps.isRequesting
    });
  };

  navigatePage = (path, UID_Perspective) => {
    this.props.history.push(
      path +
        "/" +
        "perspectiveId=" + UID_Perspective  
    );
  };


  componentDidMount() {
   const  qString = `?pageSize=${10}&pageIndex=${
      0
    }`;
    // This method is called after the component is rendered
    this.props.dispatch(getCustomers(qString))
  }


  /*---------render method----------------------------- */
  render() {
    return (
      <div>
        <PerspectivesPage
          meta={this.state.meta}
          callApi={this.callApi}
          perspectiveData={this.state.perspectiveData}
          path="/dashboard/issues"
          navigatePage={this.navigatePage}
          customersData={this.state.customerData}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isRequesting: state.perspective.isRequesting,
    perspectiveData: state.perspective.perspectiveData,
    customerData: state.customer.customerData,
    customerDataError: state.customer.customerDataError,
    deleteInstanceSuccess: state.perspective.deleteInstanceSuccess
  };
}
PerspectivesPageContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  isRequesting: PropTypes.bool
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "members-form"
  }),
  connect(mapStateToProps)
)(PerspectivesPageContainer);
