import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "reactstrap";

import unassignedIcon from "assets/img/png/unassigned-insances.png";
import orgainizationIcon from "assets/img/png/partener4.png";
import instancesIcon from "assets/img/png/partener2.png";
import supportTeamIcon from "assets/img/png/partener3.png";
import alertIcon from "assets/img/png/partener1.png";
import assigned from "assets/img/png/user-assigned.png";
import unAssigned from "assets/img/png/unassigned-insances.png";

function OrganizationDashboardAlerts({ roleData , customerIndex }) {
  let roleALertsData = roleData[customerIndex]
  return (
    <div className="team-dashboard-role">
      <Container>
        <Row>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={supportTeamIcon} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Teams
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {roleALertsData.customerAlerts
                      ? roleALertsData.customerAlerts.teamCount[0].TeamsPerCustomer
                        ? roleALertsData.customerAlerts.teamCount[0].TeamsPerCustomer
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={instancesIcon} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Instances
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {roleALertsData.customerAlerts
                      ? roleALertsData.customerAlerts.instanceCount[0].InstancesPerCustomer
                        ? roleALertsData.customerAlerts.instanceCount[0].InstancesPerCustomer
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={assigned} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Assigned Alerts
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {roleALertsData.customerAlerts
                      ? roleALertsData.customerAlerts.alertCount[0].Assigned
                        ? roleALertsData.customerAlerts.alertCount[0].Assigned
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={unAssigned} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Unassigned Alerts
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {roleALertsData.customerAlerts
                      ? roleALertsData.customerAlerts.alertCount[0].Unassigned
                        ? roleALertsData.customerAlerts.alertCount[0].Unassigned
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default OrganizationDashboardAlerts;
