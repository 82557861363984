import React, { PureComponent } from "react";
import Rules from "./Containers/RulesContainer";

class RulesPage extends PureComponent {
  render() {
    return (
      <div>
        <Rules
          names={this.props.location.search}
          id={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default RulesPage;
