/**
 * @action        : invitationAction
 * @description   : invite the new users
 * @Created by    : INNOMICK
 */

import { ROLES_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
// action for get user scope
export function UsersByRole(formData, qString) {
  let url;
  if (formData.partnerId) {
    url = `${BASE_URL}/UsersByRole?role=${formData.role}&partnerId=${formData.partnerId}${qString}`;
  } else if (formData.customerId) {
    url = `${BASE_URL}/UsersByRole?role=${formData.role}&customerId=${formData.customerId}${qString}`;
  } else {
    url = `${BASE_URL}/UsersByRole?role=${formData.role}${qString}`;
  }
  return dispatch => {
    dispatch(base.getRequest(ROLES_CONST.USERS_BY_ROLE_REQUEST));
    AXIOS_INSTANCE.get(url)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response) {
          dispatch(
            base.getSuccess(ROLES_CONST.USERS_BY_ROLE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ROLES_CONST.USERS_BY_ROLE_FAILURE, {
              reponse: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ROLES_CONST.USERS_BY_ROLE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

//action for delete role
export function DeleteRole(userRoleId) {
  return dispatch => {
    dispatch(base.getRequest(ROLES_CONST.DELETE_USERS_ROLE_REQUEST));
    AXIOS_INSTANCE.delete(`${BASE_URL}/removeRole?userRoleId=${userRoleId}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response) {
          dispatch(
            base.getSuccess(ROLES_CONST.DELETE_USERS_ROLE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ROLES_CONST.DELETE_USERS_ROLE_FAILURE, {
              reponse: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ROLES_CONST.DELETE_USERS_ROLE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

//action for member count
export function MembersCount(idName, id) {
  return dispatch => {
    dispatch(base.getRequest(ROLES_CONST.MEMBERS_COUNT_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/memberCount?${idName}=${id}`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response) {
          dispatch(
            base.getSuccess(ROLES_CONST.MEMBERS_COUNT_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(ROLES_CONST.MEMBERS_COUNT_FAILURE, {
              reponse: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(ROLES_CONST.MEMBERS_COUNT_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
