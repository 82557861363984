/**
 * @action        : timezoneAction
 * @description   : for get the timezones data
 * @Created by    : INNOMICK
 */

import { TIMEZONE_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";

// action for get timezones
export function getTimezone() {
  return dispatch => {
    dispatch(base.getRequest(TIMEZONE_CONST.GET_TIMEZONE_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/getTimeZone`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TIMEZONE_CONST.GET_TIMEZONE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(TIMEZONE_CONST.GET_TIMEZONE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(TIMEZONE_CONST.GET_TIMEZONE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get timezones
export function getCountyCodes() {
  return dispatch => {
    dispatch(base.getRequest(TIMEZONE_CONST.GET_COUNTRY_CODE_REQUEST));
    AXIOS_INSTANCE.get(`${BASE_URL}/Country`)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(TIMEZONE_CONST.GET_COUNTRY_CODE_SUCCESS, {
              response: {
                data: response.data
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(TIMEZONE_CONST.GET_COUNTRY_CODE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(TIMEZONE_CONST.GET_COUNTRY_CODE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
