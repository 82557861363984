import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  Form,
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderSelectFieldTwo, renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  createCustomer,
  getCustomers,
  updateCustomer,
} from "../../../actions/customerAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../../../helpers/Utility";
class CreateOrganisationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createOrganisation: {
        nameState: this.props.initialValues ? "has-success" : null,
      },
    };
  }
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.createCustomerSuccess &&
      nextProps.createCustomerSuccess !== this.props.createCustomerSuccess
    ) {
      showSuccess("Organisation created successfully.");
      this.setState({
        loading: false,
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      if (this.props.partnerId) {
        this.props.dispatch(getCustomers(qString, this.props.partnerId));
      } else {
        this.props.dispatch(getCustomers(qString));
      }
      this.props.handleModal();
    }
    if (
      nextProps.createCustomerFailure &&
      nextProps.createCustomerFailure !== this.props.createCustomerFailure
    ) {
      showError(nextProps.createCustomerFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.createCustomerNetworkFailure &&
      nextProps.createCustomerNetworkFailure !==
        this.props.createCustomerNetworkFailure
    ) {
      showError("Error in creating Organisation.");
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.updateCustomerSuccess &&
      nextProps.updateCustomerSuccess !== this.props.updateCustomerSuccess
    ) {
      showSuccess("Organisation updated successfully.");
      this.setState({
        loading: false,
      });
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.props.meta.pageSize
      )
        ? this.props.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.props.meta.page -
        1}`;
      if (this.props.partnerId) {
        this.props.dispatch(getCustomers(qString, this.props.partnerId));
      } else {
        this.props.dispatch(getCustomers(qString));
      }
      this.props.handleModal();
    }
    if (
      nextProps.updateCustomerFailure &&
      nextProps.updateCustomerFailure !== this.props.updateCustomerFailure
    ) {
      showError(nextProps.updateCustomerFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.updateCustomerNetworkFailure &&
      nextProps.updateCustomerNetworkFailure !==
        this.props.updateCustomerNetworkFailure
    ) {
      showError("Error in updating organisation.");
      this.setState({
        loading: false,
      });
    }
  };
  /**
   * call this function to validate name of the customer
   */
  validateName = (e) => {
    let customer = this.state.createOrganisation;
    if (e.target.value.length > 4 && e.target.value.length < 50) {
      customer["nameState"] = "has-success";
    } else {
      customer["nameState"] = "has-danger";
    }
    this.setState({ customer });
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = (formProps) => {
    if (formProps.partnerId) {
      let customer = this.state.createOrganisation;
      let isValid = true;
      if (customer["nameState"] !== "has-success") {
        customer["nameState"] = "has-danger";
        isValid = false; //not valid
      }
      if (!isValid) {
        showError("Organisation name mandatory.");
      } else {
        if (this.props.initialValues) {
          if (formProps.partnerId && formProps.partnerId !== "0") {
            formProps.partnerId = formProps.partnerId
              ? formProps.partnerId
              : this.props.initialValues.partnerId;
            this.props.dispatch(updateCustomer(formProps));
            this.setState({
              loading: true,
            });
          } else {
            showError("Please select partner.");
            this.setState({
              loading: false,
            });
          }
        } else {
          this.props.dispatch(createCustomer(formProps));
          this.setState({
            loading: true,
          });
        }
      }
    } else {
      showError("Please select partner.");
    }
  };
  /**render function */
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues
              ? "Update Organisation"
              : "Create Organisation"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row>
                <Col lg={9} md={9} className="mx-auto">
                  <Row>
                    <Col className="pt-2 remove-select-styles">
                      <FormGroup className="wrapper-style">
                        <Label className="pull-left">Select Partner*</Label>
                        <Field
                          name="partnerId"
                          type="select"
                          className="select-field-class"
                          component={renderSelectFieldTwo}
                          options={this.props.partnersData}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pt-2">
                      <FormGroup
                        className={
                          this.state.createOrganisation.nameState
                            ? "has-label " +
                              this.state.createOrganisation.nameState
                            : "has-label"
                        }
                      >
                        <Field
                          component={renderTextField}
                          name="name"
                          type="text"
                          placeholder="Enter organisation name*"
                          className="team-input-style"
                          onChange={(e) => this.validateName(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="ml-auto pl-5">
                      <Button
                        color="primary"
                        loading={this.state.loading}
                        type="submit"
                        className="ml-5"
                      >
                        {this.props.initialValues
                          ? this.props.t("teams.UPDATE")
                          : this.props.t("teams.CREATE")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CreateOrganisationModal.propTypes = {
  createCustomerSuccess: PropTypes.bool,
  createCustomerFailure: PropTypes.string,
  createCustomerNetworkFailure: PropTypes.bool,
  handleModal: PropTypes.func,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  assignRuleToTeamSuccess: PropTypes.string,
  assignRuleToTeamFailure: PropTypes.bool,
  meta: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    createCustomerSuccess: state.customer.createCustomerSuccess,
    createCustomerFailure: state.customer.createCustomerFailure,
    createCustomerNetworkFailure: state.customer.createCustomerNetworkFailure,
    updateCustomerSuccess: state.customer.updateCustomerSuccess,
    updateCustomerFailure: state.customer.updateCustomerFailure,
    updateCustomerNetworkFailure: state.customer.updateCustomerNetworkFailure,
  };
}
export default compose(
  translate,
  reduxForm({
    form: "createCustomer-form",
  }),
  connect(mapStateToProps)
)(CreateOrganisationModal);
