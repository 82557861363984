/**
 * @reducer       : index reducer
 * @description   :
 * @Created by    : innomick
 */
import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form"; // SAYING use redux form reducer as reducer
import LoginReducer from "./loginReducer";
import InvitationReducer from "./invitationReducer";
import RolesReducer from "./roleReduces";
import PartnerReducer from "./partnerReducer";
import CustomerReducer from "./customerReducer";
import TeamReducer from "./teamReducer";
import AlertReducer from "./alertReducer";
import RuleReducer from "./ruleReducer";
import InstnaceReducer from "./instanceReducer";
import AgentReducer from "./agentReducer";
import TimeZoneReducer from "./timezoneReducer";
import Dashboard from "./dashboardReducer";
import perspectiveReducer from "./perspectiveReducer";
import notificationReducer from "./notificationReducer";

const appReducer = combineReducers({
  form: formReducer,
  login: LoginReducer,
  invite: InvitationReducer,
  roles: RolesReducer,
  partner: PartnerReducer,
  customer: CustomerReducer,
  team: TeamReducer,
  alert: AlertReducer,
  rule: RuleReducer,
  instance: InstnaceReducer,
  agent: AgentReducer,
  timezone: TimeZoneReducer,
  dashboard: Dashboard,
  perspective: perspectiveReducer,
  notifications: notificationReducer
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_REQUEST") {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
