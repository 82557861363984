import React from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  FormGroup
} from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import IssueDetailsModal from "../../Modals/IssueDetalisModal";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import UserImage from "../../../../assets/img/png/DefaultUser.png";
import {
  showRulesPriority,
  dateFormat,
  timeFormat
} from "../../../../helpers/Utility";
import BreadCrumbComponent from "../../Common/BreadCrumbComponent";
import queryString from "query-string";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import _ from "lodash";

class IssuesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInvitationModal: false,
      dropdownOpen: false,
      perspectiveOption: "Perspectives",
      unAssignedIssues: [],
      assignedIssues: [],
      closedIssues: [],
      userData: JSON.parse(localStorage.getItem("userDetails")), //here we are getting user data from localStrorage
      selectedPerspective: queryString.parse(this.props.id).perspectiveId // Set your default value here
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.issuesData) {
      let unAssignedIssues = [];
      let assignedIssues = [];
      let closedIssues = [];
      let allIssuesData = nextProps.issuesData;
      // Here we are showing all the issues sorted by updated date in descending order
      let allIssuesSortedByUpdatedDate = _.orderBy(
        allIssuesData,
        [
          issue => {
            const priority = issue.Rule.Priority_Rule;
            // Return the priority value directly
            return priority;
          },
          "Updated_Date"
        ],
        ["desc", "desc"]
      );
      const allIssues = allIssuesSortedByUpdatedDate;
      allIssues.map((issue, key) => {
        switch (issue.Status) {
          case 0:
            unAssignedIssues.push(issue);
            break;
          case 1:
            assignedIssues.push(issue);
            break;
          default:
            closedIssues.push(issue);
            break;
        }
      });
      this.setState({
        unAssignedIssues: unAssignedIssues,
        assignedIssues: assignedIssues,
        closedIssues: closedIssues
      });
    }
  };

  //this function we are using to show issues
  showIssuesDetails = issuesData => {
    if (issuesData && issuesData.length) {
      return issuesData.map((issue, key) => {
        let createdDate = issue.Created_Date
          ? dateFormat(issue.Created_Date)
          : "";
        let createdTime = issue.Created_Date
          ? timeFormat(issue.Created_Date)
          : "";
        let updatedDate = issue.Updated_Date
          ? dateFormat(issue.Updated_Date)
          : "";
        let updatedTime = issue.Updated_Date
          ? timeFormat(issue.Updated_Date)
          : "";
        return (
          <Container
            className="box-style py-3 pl-3 cursor-pointer"
            onClick={e => this.props.showIssueModal(issue.UID_Alert, key)}
            key={key}
          >
            {this.props.isRequestingTwo &&
            this.props.keyValue === key &&
            this.props.alertId === issue.UID_Alert ? (
              <i className="fa fa-spinner fa-pulse" />
            ) : (
              ""
            )}
            <Row className="mb-2">
              <Col lg={10} md={10} xs={10}>
                <span>{issue.Rule ? issue.Rule.Name_Rule : ""}</span>
              </Col>
              <Col lg={2} md={2} xs={2}>
                <span>
                  <img
                    src={
                      issue.User
                        ? issue.User.Profile_Image
                          ? issue.User.Profile_Image
                          : UserImage
                        : UserImage
                    }
                    // src={
                    //   this.state.userData.Profile_Image
                    //     ? this.state.userData.Profile_Image
                    //     : UserImage
                    // }
                    alt="user"
                    className="rounded-circle img-fluid user-css-styles"
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={5} md={5} xs={5}>
                <span className="font-size-style">
                  {issue.Instance.Customer.Name_Customer}
                </span>
              </Col>
              <Col lg={5} md={5} xs={5}>
                <span className="font-size-style">
                  {issue.Instance.Name_Instance}
                </span>
              </Col>
              <Col lg={2} md={2} xs={2}>
                <span className="font-size-style priority-icon-style">
                  {/* <i
                    className={showRulesPriority(
                      issue.Rule ? issue.Rule.Priority_Rule : ""
                    )}
                  /> */}
                  <img
                    src={showRulesPriority(
                      issue.Rule ? issue.Rule.Priority_Rule : ""
                    )}
                    alt="PriorityImage"
                    width="20"
                  />
                </span>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col lg={5} md={5} xs={5}>
                <div className="font-size-style text-muted">Created :</div>
              </Col>
              <Col lg={5} md={5} xs={5}>
                <div className="font-size-style text-muted">Updated :</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} md={5} xs={5}>
                <div className="font-size-style text-muted">{createdDate}</div>
              </Col>
              <Col lg={5} md={5} xs={5}>
                <div className="font-size-style text-muted">{updatedDate}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} md={5} xs={5}>
                <div className="font-size-style text-muted">{createdTime}</div>
              </Col>
              <Col lg={5} md={5} xs={5}>
                <div className="font-size-style text-muted">{updatedTime}</div>
              </Col>
            </Row>
          </Container>
        );
      });
    } else {
      return (
        <Container className="box-style py-3 pl-3">
          <Row>
            <Col className="text-center">
              <span className="text-center">No Issues Found</span>
            </Col>
          </Row>
        </Container>
      );
    }
  };
  // Extracting Perspectives Data
  showPerspectiveOptions = perspectiveDataProps => {
    if (perspectiveDataProps) {
      return perspectiveDataProps.Perspective.map((value, key) => {
        return (
          <option key={key} value={value.UID_Perspective}>
            {value.Name_Perspective}
          </option>
        );
      });
    }
  };

  // Function to update selectedPerspective in state
  handlePerspectiveChange = e => {
    this.setState({ selectedPerspective: e.target.value });
    this.props.filterPerspective(e);
  };
  render() {
    let alertsRoute = window.location.pathname;
    return (
      <div>
        {this.props.stateValues.showIssueDetailsModal && (
          <IssueDetailsModal
            isOpen={this.props.stateValues.showIssueDetailsModal}
            handleModal={this.props.handleModal}
            issueDetails={this.props.stateValues.issueDetails}
            formData={this.props.stateValues.formData}
            userData={this.props.stateValues.userData}
          />
        )}
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                  <SideBarToggle />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                  <p className="font-weight-bold">Issues</p>
                </Col>
                <Col
                  lg={5}
                  md={5}
                  xs={6}
                  sm={6}
                  className="mr-auto padding-two"
                >
                  <InputGroup className="no-border">
                    <Input
                      placeholder="Search for Issues"
                      className="search-bar-css shadow-css"
                      onKeyUp={e => this.props.searchIssue(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="search-bar-css icon-css shadow-css cursor-pointer">
                        <i className="now-ui-icons ui-1_zoom-bold" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col lg={3} md={3} className="p-3">
                  <FormGroup>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={this.handlePerspectiveChange}
                      value={this.state.selectedPerspective}
                    >
                      <option value="perspective">Perspectives</option>
                      {this.showPerspectiveOptions(
                        this.props.stateValues.perspectiveData
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} xs={12} className="">
                  <div>
                    {alertsRoute !== "/dashboard/issues" && (
                      <BreadCrumbComponent
                        active="Issues"
                        nonActive1={this.props.teamName}
                        nonActive1Href={
                          queryString.parse(this.props.names).teamName
                            ? "/dashboard/teamDashboard/" + this.props.id
                            : "/dashboard/instanceDashboard/" +
                              this.props.id +
                              "?customer=" +
                              queryString.parse(this.props.names).customerName +
                              "&instance=" +
                              queryString.parse(this.props.names).instanceName
                        }
                        nonActive2={this.props.customerName}
                        nonActive2Href={
                          "/dashboard/organisationDashboard/" +
                          this.props.customerId
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg={12} md={12}>
                  <Card>
                    <CardBody>
                      <span className="font-weight-bold mr-2">
                        Show closed alerts for last:
                      </span>{" "}
                      <ButtonGroup>
                        <Button
                          color={
                            this.props.stateValues.selectedDays === 7
                              ? "primary"
                              : "secondary"
                          }
                          onClick={() => this.props.getClosedAlertsData(7)}
                        >
                          <span className="font-weight-bold">7 days</span>
                        </Button>
                        <Button
                          color={
                            this.props.stateValues.selectedDays === 15
                              ? "primary"
                              : "secondary"
                          }
                          onClick={() => this.props.getClosedAlertsData(15)}
                        >
                          <span className="font-weight-bold">15 days</span>
                        </Button>
                        <Button
                          color={
                            this.props.stateValues.selectedDays === 30
                              ? "primary"
                              : "secondary"
                          }
                          onClick={() => this.props.getClosedAlertsData(30)}
                        >
                          <span className="font-weight-bold">30 days</span>
                        </Button>
                        <Button
                          color={
                            this.props.stateValues.selectedDays === "all"
                              ? "primary"
                              : "secondary"
                          }
                          onClick={() => this.props.getClosedAlertsData("all")}
                        >
                          <span className="font-weight-bold">All</span>
                        </Button>
                      </ButtonGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.props.stateValues.isRequesting ? (
                <Row className="mt-4">
                  <Col lg={12} md={12} xs={12}>
                    <img
                      className="loader-inside-tr"
                      src={LoaderIcon}
                      alt="loader"
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="mt-4">
                  <Col lg={4} md={4}>
                    <Card className="card-style">
                      <div className="text-center pd-3 mb-3 font-weight-bold">
                        Unassigned
                      </div>
                      {this.showIssuesDetails(this.state.unAssignedIssues)}{" "}
                      {/*0 means un assign issues */}
                    </Card>
                  </Col>
                  <Col lg={4} md={4}>
                    <Card className="card-style">
                      <div className="text-center pd-3 mb-3 font-weight-bold">
                        Assigned
                      </div>
                      {this.showIssuesDetails(this.state.assignedIssues)}{" "}
                      {/*1 means assign issues */}
                    </Card>
                  </Col>
                  <Col lg={4} md={4}>
                    <Card className="card-style">
                      <div className="text-center pd-3 mb-3 font-weight-bold">
                        Closed
                      </div>
                      {this.showIssuesDetails(this.state.closedIssues)}{" "}
                      {/*2 means closed issues */}
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#fff" }}
        />
      </div>
    );
  }
}
IssuesPage.propTypes = {
  searchIssue: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  issuesData: PropTypes.array,
  showIssueDetailsModal: PropTypes.bool,
  handleModal: PropTypes.func,
  issueDetails: PropTypes.object,
  key: PropTypes.number,
  isRequestingTwo: PropTypes.bool,
  stateValues: PropTypes.object
};
export default compose(translate, connect())(IssuesPage);
