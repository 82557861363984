import React, { Component, Fragment } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { translate } from "react-multi-lang";
import compose from "compose-function";
import { Card } from "reactstrap";
import PropTypes from "prop-types";

class BarChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: this.props.alertsData.label,
        datasets: [
          {
            data: this.props.alertsData.data,
            backgroundColor: "rgba(239,109,76)",
            hoverBackgroundColor: "rgba(239,109,60)",
            label: this.props.title,
            fill: false
          }
        ]
      }
    };
  }
  componentWillReceiveProps = nextProps => {
    this.setState({
      data: {
        labels: this.props.alertsData.label,
        datasets: [
          {
            data: this.props.alertsData.data,
            backgroundColor: "rgba(239,109,76)",
            hoverBackgroundColor: "rgba(239,109,60)",
            label: this.props.title,
            fill: false
          }
        ]
      }
    });
  };
  //   componentDidMount() {
  //     const { datasets } = this.refs.chart.chartInstance.data;
  //     console.log(datasets);
  //   }
  render() {
    return (
      <Fragment>
        <Card id="chart" className="p-2">
          <HorizontalBar
            data={this.state.data}
            height={this.state.data.labels.length > 100 ? "800" : "300"}
            options={{
              title: {
                display: true,
                text: `${this.props.subTitle} :${this.props.totalValue}`,
                fontSize: 15,
                position: "bottom"
              },
              legend: {
                display: true,
                position: "top"
              }
              // scales: {
              //   xAxes: [
              //     {
              //       stacked: true
              //     }
              //   ],
              //   yAxes: [
              //     {
              //       stacked: true
              //     }
              //   ]
              // }
            }}
          />
        </Card>
      </Fragment>
    );
  }
}

BarChartComponent.propTypes = {
  t: PropTypes.func
};

export default compose(translate)(BarChartComponent);
