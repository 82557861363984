/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Card,
  CardBody
} from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import compose from "compose-function";
import { connect } from "react-redux";
import { Button } from "components";
import CreateInstanceModal from "../../Modals/CreateInstanceModal";
import BreadCrumbComponent from "../../Common/BreadCrumbComponent";
import { confirmPermissions } from "../../../../helpers/Utility";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import ReactTable from "react-table";
import { getInstanceByCriteria } from "../../../../actions/instanceAction";

function instancePage(props) {
  const permissions = props.wholeTeamsData
    ? props.wholeTeamsData.permissions
    : [];
  const data = props.instancesData ? props.instancesData : [];
  /*-------react table header------------ */
  const headers = {
    Name_Instance: "Instance Name",
    description: props.t("partner.DESCRIPTION")
  };
  return (
    <div>
      {props.showCreateInstanceModal && (
        <CreateInstanceModal
          isOpen={props.showCreateInstanceModal}
          handleModal={props.handleModal}
          customerId={props.customerId}
          meta={props.stateValues.meta}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
              <Col className="d-md-none">
                {confirmPermissions(permissions, "isCreatable") && (
                  <Button
                    color="primary"
                    onClick={() => props.showModal()}
                    className="mobile-button"
                  >
                    Create Instance
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={6} className="padding-one">
                <p className="font-weight-bold text-muted">Instances</p>
              </Col>
              <Col lg={5} md={5} xs={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    id="myInput"
                    placeholder="Search for Instance"
                    className="search-bar-css shadow-css"
                    onKeyUp={e => props.searchInstance(e)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="search-bar-css icon-css shadow-css">
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={12}
                sm={12}
                className="offset-sm-2 offset-xs-2 order-first order-lg-last order-md-last"
              >
                {confirmPermissions(permissions, "isCreatable") && (
                  <Button
                    color="primary"
                    onClick={() => props.showModal()}
                    className="create-team-button"
                  >
                    <span className="font-weight-bold">Create Instance</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xs={12} className="">
                <div>
                  <BreadCrumbComponent
                    active="Instances"
                    nonActive1={props.customerName}
                    nonActive1Href={
                      "/dashboard/organisationDashboard/" + props.customerId
                    }
                    nonActive2="Organisations"
                    nonActive2Href={"/dashboard/organisations"}
                  />
                </div>
              </Col>
            </Row>
            <Card className="mt-4">
              <CardBody>
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  className="cursor-pointer -striped-highlight"
                  data={data}
                  getTrProps={(state, row) => ({
                    onClick: () =>
                      props.showDashboard(
                        row.original.Name_Instance.id,
                        row.original.Name_Instance.name
                      )
                  })}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.Name_Instance,
                          accessor: "Name_Instance",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value.name}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.description,
                          accessor: "description",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className=""> {row.value}</div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  manual
                  pages={props.stateValues.meta.pageTotal}
                  defaultPageSize={props.stateValues.meta.pageSize}
                  pageSizeOptions={props.stateValues.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                    }

                    if (props.customerId && qString) {
                      let formData = {
                        name: "customer",
                        id: props.customerId
                      };
                      props.dispatch(getInstanceByCriteria(formData, qString));
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
}
instancePage.propTypes = {
  searchInstance: PropTypes.func,
  instancesData: PropTypes.array,
  wholeTeamsData: PropTypes.object,
  language: PropTypes.object,
  customerId: PropTypes.string,
  customerName: PropTypes.string,
  deleteTeam: PropTypes.func,
  editTeam: PropTypes.func,
  showCreateInstanceModal: PropTypes.bool,
  handleModal: PropTypes.func,
  initialValues: PropTypes.object,
  showDashboard: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  stateValues: PropTypes.object.isRequired
};
export default compose(translate, connect())(instancePage);
