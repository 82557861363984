/**
 * @action        : invitationAction
 * @description   : invite the new users
 * @Created by    : INNOMICK
 */

import { INVITE_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON } from "../utils";

import * as base from "./baseAction";
/**
 * [userInvitation description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for user invitation
export function invitation(formData) {
  return dispatch => {
    dispatch(base.getRequest(INVITE_CONST.INVITE_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/Invitation", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INVITE_CONST.INVITE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INVITE_CONST.INVITE_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INVITE_CONST.INVITE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

//action for get user Email ID
export function getUserEmailById(invitationId) {
  return dispatch => {
    dispatch(base.getRequest(INVITE_CONST.GET_EMAIL_REQUEST));
    AXIOS_INSTANCE.get(BASE_URL + "/UserMail?userRoleId=" + invitationId)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INVITE_CONST.GET_EMAIL_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INVITE_CONST.GET_EMAIL_FAILURE, {
              response: {
                data: response.error
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INVITE_CONST.GET_EMAIL_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
//action for search user
export function searchUser(searchWord) {
  return dispatch => {
    dispatch(base.getRequest(INVITE_CONST.SEARCH_USER_REQUEST));
    AXIOS_INSTANCE.get(BASE_URL + "/SearchUser/" + searchWord)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INVITE_CONST.SEARCH_USER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INVITE_CONST.SEARCH_USER_FAILURE, {
              error: {
                statusCode: response.error.error,
                statusText: response.error.errorDescription,
                isSuccess: response.isSuccess
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INVITE_CONST.SEARCH_USER_FAILURE, {
            error: {
              statusText: error,
              netWorkError: true
            }
          })
        );
      });
  };
}
// action for update user
export function updateUser(formData) {
  return dispatch => {
    dispatch(base.getRequest(INVITE_CONST.UPDATE_USER_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/UpdateUser", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(INVITE_CONST.UPDATE_USER_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INVITE_CONST.UPDATE_USER_FAILURE, {
              error: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INVITE_CONST.UPDATE_USER_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get user roles
export function getUserRoles(role_id) {
  return dispatch => {
    dispatch(base.getRequest(INVITE_CONST.GET_USER_ROLE_REQUEST));
    AXIOS_INSTANCE.get(BASE_URL + "/UserRole?role=" + role_id)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response) {
          dispatch(
            base.getSuccess(INVITE_CONST.GET_USER_ROLE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INVITE_CONST.GET_USER_ROLE_FAILURE, {
              error: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INVITE_CONST.GET_USER_ROLE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get user scope
export function UserScope(token) {
  if (!AXIOS_INSTANCE.defaults.headers.common["Authorization"]) {
    //const token = localStorage.getItem("authToken");
    AXIOS_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return dispatch => {
    dispatch(base.getRequest(INVITE_CONST.GET_USERSCOPE_REQUEST));
    AXIOS_INSTANCE.get(BASE_URL + "/UserScope")
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response) {
          dispatch(
            base.getSuccess(INVITE_CONST.GET_USERSCOPE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(INVITE_CONST.GET_USERSCOPE_FAILURE, {
              error: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(INVITE_CONST.GET_USERSCOPE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
