import React, { Fragment } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import ButtonLoader from "reactstrap-button-loader";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import LineChartComponent from "../../Common/ChartComponent";
import BarChartComponent from "../../Common/BarChartComponent";
import BreadCrumbComponent from "../../Common/BreadCrumbComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import { confirmPermissions } from "../../../../helpers/Utility";
import Pdf from "react-to-pdf";

const ref = React.createRef();
const options = {
  orientation: "portrait",
  unit: "in",
  format: [4, 2],
};
function ReportPage(props) {
  let CustomerNameProps = props.customerName;
  let customerName = CustomerNameProps.replace(/_/g, " "); // replacing underscores with spaces

  const showInstancesOptions = (instancesData) => {
    if (instancesData && instancesData.length) {
      return instancesData.map((value, key) => {
        return (
          <option key={key} value={value.UID_Instance}>
            {value.Name_Instance}
          </option>
        );
      });
    }
  };

  return (
    <div>
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={6} md={6} xs={12} className="">
                <div>
                  <BreadCrumbComponent
                    active="Report Dashboard"
                    nonActive1={customerName}
                    nonActive1Href={
                      "/dashboard/organisationDashboard/" + props.customerId
                    }
                    nonActive2="Organisations"
                    nonActive2Href={"/dashboard/organisations"}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={12} md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={7} md={12} xs={12} className="text-left">
                        <span className="font-weight-bold">
                          Show data for last:
                        </span>{" "}
                        <ButtonGroup>
                          <Button
                            color={
                              props.selectedDays === 1 ? "primary" : "secondary"
                            }
                            onClick={() => props.getDashboardData(1)}
                          >
                            <span className="font-weight-bold">2 days</span>
                          </Button>
                          <Button
                            color={
                              props.selectedDays === 5 ? "primary" : "secondary"
                            }
                            onClick={() => props.getDashboardData(5)}
                          >
                            <span className="font-weight-bold">5 days</span>
                          </Button>
                          <Button
                            color={
                              props.selectedDays === 15
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() => props.getDashboardData(15)}
                          >
                            <span className="font-weight-bold">15 days</span>
                          </Button>
                          <Button
                            color={
                              props.selectedDays === 30
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() => props.getDashboardData(30)}
                          >
                            <span className="font-weight-bold">30 days</span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col lg={3} md={6} xs={12}>
                        <FormGroup>
                          <Input
                            type="select"
                            name="selectInstances"
                            id="selectInstances"
                            className="mt-2"
                            onChange={(e) => props.getDashboardDataByInstance(e)}
                          >
                          <option value="">Select Instance</option>
                          {showInstancesOptions(
                            props.instances
                          )}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg={2} md={6} xs={4}>
                        {!props.isRequesting && (
                          <ButtonLoader
                            color={"primary"}
                            onClick={() => props.printDocument()}
                            loading={props.loading}
                          >
                            <span className="font-weight-bold">
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              />{" "}
                              Download
                            </span>
                          </ButtonLoader>
                        )}
                        {/* <Pdf
                          targetRef={ref}
                          filename="div-blue.pdf"
                          // options={options}
                        >
                          {({ toPdf }) => (
                            <button onClick={toPdf}>Generate pdf</button>
                          )}
                        </Pdf> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {props.isRequesting ? (
              <div className="table-tr-loader">
                <img
                  className="loader-inside-dashboards"
                  src={LoaderIcon}
                  alt="loader"
                />
              </div>
            ) : (
              <Fragment>
                <div>
                  <Row>
                    <Col lg={12} md={12} xs={12} className="text-center">
                      <span className="font-weight-bold text-muted">
                        Data Analysis Of Last {props.selectedDays} Days
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={6} md={6} xs={12}>
                      {Object.keys(props.notificationsData).length ? (
                        <LineChartComponent
                          title="Push Notifications"
                          data={props.notificationsData.data}
                          labels={props.notificationsData.days}
                          subTitle={"Total push notifications"}
                          totalValue={props.notificationsData.totalCount}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      {Object.keys(props.emailData).length ? (
                        <LineChartComponent
                          title="Email Notifications"
                          data={props.emailData.data}
                          labels={props.emailData.days}
                          subTitle={"Total email notifications"}
                          totalValue={props.emailData.totalCount}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {Object.keys(props.alertsData).length ? (
                      <Col>
                        <BarChartComponent
                          title="Alerts"
                          // data={[5, 45, 15, 88, 28, 2, 48, 63]}
                          alertsData={props.alertsData}
                          subTitle={"Total alerts"}
                          totalValue={props.alertsData.totalCount}
                        />
                      </Col>
                    ) : (
                      <Col />
                    )}
                  </Row>
                  <Row>
                    <Col>
                      {Object.keys(props.openAlertsData).length &&
                      Object.keys(props.closedAlertsData).length ? (
                        <LineChartComponent
                          title="Push Notifications"
                          data1={props.openAlertsData.data}
                          data2={props.closedAlertsData.data}
                          isMulti={true}
                          subTitle={"Total tickets"}
                          totalValue={330}
                          labels={props.openAlertsData.days}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    position: "absolute",
                    opacity: "0",
                    cursor: "unset",
                  }}
                >
                  <div id="divToPrint">
                    <Row>
                      <Col lg={12} md={12} xs={12} className="text-center">
                        <span className="font-weight-bold text-muted">
                          Data Analysis Of Last {props.selectedDays} Days
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={6} md={6} xs={12}>
                        {Object.keys(props.notificationsData).length ? (
                          <LineChartComponent
                            title="Push Notifications"
                            data={props.notificationsData.data}
                            labels={props.notificationsData.days}
                            subTitle={"Total push notifications"}
                            totalValue={props.notificationsData.totalCount}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        {Object.keys(props.emailData).length ? (
                          <LineChartComponent
                            title="Email Notifications"
                            data={props.emailData.data}
                            labels={props.emailData.days}
                            subTitle={"Total email notifications"}
                            totalValue={props.emailData.totalCount}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row>
                      {Object.keys(props.alertsData).length ? (
                        <Col>
                          <BarChartComponent
                            title="Alerts"
                            // data={[5, 45, 15, 88, 28, 2, 48, 63]}
                            alertsData={props.alertsData}
                            subTitle={"Total alerts"}
                            totalValue={props.alertsData.totalCount}
                          />
                        </Col>
                      ) : (
                        <Col />
                      )}
                    </Row>
                    <Row>
                      <Col>
                        {Object.keys(props.openAlertsData).length &&
                        Object.keys(props.closedAlertsData).length ? (
                          <LineChartComponent
                            title="Push Notifications"
                            data1={props.openAlertsData.data}
                            data2={props.closedAlertsData.data}
                            isMulti={true}
                            subTitle={"Total tickets"}
                            totalValue={330}
                            labels={props.openAlertsData.days}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Fragment>
            )}
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
ReportPage.propTypes = {
  getDashboardData: PropTypes.func,
  alertsData: PropTypes.object,
  notificationsData: PropTypes.object,
  emailData: PropTypes.object,
  selectedDays: PropTypes.number,
  openAlertsData: PropTypes.object,
  closedAlertsData: PropTypes.object,
  printDocument: PropTypes.func,
};
export default ReportPage;
