import React from "react";
class SideBarToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  };
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      //e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      // this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }
  render() {
    return (
      <div className="navbar-toggle">
        <button
          type="button"
          ref="sidebarToggle"
          className="navbar-toggler"
          onClick={() => this.openSidebar()}
        >
          <span className="navbar-toggler-bar bar1" />
          <span className="navbar-toggler-bar bar2" />
          <span className="navbar-toggler-bar bar3" />
        </button>
      </div>
    );
  }
}
export default SideBarToggle;
