import React, { PureComponent } from "react";
import Instance from "./Containers/InstanceContainer";

class InstancePage extends PureComponent {
  render() {
    return (
      <div>
        <Instance
          customerId={this.props.match.params.id}
          customerName={this.props.location.search}
        />
      </div>
    );
  }
}

export default InstancePage;
