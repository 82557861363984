/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import TeamMembers from "../Components/TeamMembersPage.jsx";
import { getTeamUsers, deleteTeamUser } from "../../../../actions/teamAction";
import { invitation } from "../../../../actions/invitationAction";
import {
  showError,
  showSuccess,
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS
} from "../../../../helpers/Utility";
import PropTypes from "prop-types";
import queryString from "query-string";
class TeamMembersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stopLoader: false,
      loading: false,
      showAddTeamUserModal: false,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0
      }
    };
  }
  componentDidMount = () => {
    // if (this.props.teamId) {
    //   let formData = {};
    //   formData = {
    //     teamId: this.props.teamId
    //   };
    //   this.props.dispatch(getTeamUsers(formData));
    // }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    this.setState({
      isRequesting: nextProps.isRequesting
    });
    if (nextProps.teamUsersList) {
      let teamMembersData = [];
      nextProps.teamUsersList.userData.map((userData, key) => {
        teamMembersData.push({
          First_Name: userData.User.Status_User
            ? userData.User.First_Name + " " + userData.User.Last_Name
            : "N/A",
          Email_User: userData.User.Email_User,
          status: userData.User.Status_User,
          actions: {
            id: userData.UID_TeamUser,
            email: userData.User.Email_User,
            key: key,
            status: userData.User.Status_User
          }
        });
      });
      this.userData = teamMembersData;
      let meta = this.state.meta;
      if (nextProps.teamUsersList.metaData) {
        meta = {
          page: nextProps.teamUsersList.metaData.pageNumber,
          pageSize: nextProps.teamUsersList.metaData.pageSize,
          pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
          pageTotal: nextProps.teamUsersList.metaData.pageCount,
          total: nextProps.teamUsersList.metaData.totalItemCount
        };
      }
      this.setState({
        teamUsersList: teamMembersData,
        teamUsersSearchList: teamMembersData,
        userDataPermission: nextProps.teamUsersList,
        meta: meta
      });
    }
    if (
      nextProps.teamUsersListError &&
      nextProps.teamUsersListError !== this.props.teamUsersListError
    ) {
      showError("Something went wrong");
      this.setState({
        error: nextProps.teamUsersListError
      });
    }
    if (
      nextProps.removeTeamUser &&
      nextProps.removeTeamUser !== this.props.removeTeamUser
    ) {
      showSuccess(nextProps.removeTeamUser);
      const pageSize = INDEX_PAGE_SIZE_OPTIONS.includes(
        this.state.meta.pageSize
      )
        ? this.state.meta.pageSize
        : 10;
      const qString = `?pageSize=${pageSize}&pageIndex=${this.state.meta.page -
        1}`;
      this.props.dispatch(getTeamUsers(this.props.teamId, qString));
    }
    if (
      nextProps.removeTeamUserError &&
      nextProps.removeTeamUserError !== this.props.removeTeamUserError
    ) {
      showError("Something went wrong");
    }
    if (
      nextProps.isInviteSuccess &&
      nextProps.isInviteSuccess !== this.props.isInviteSuccess
    ) {
      showSuccess(this.props.t("success.INVITATION_SUCCESS"));
      this.setState({
        loading: false
      });
    }
  };
  //this function we are calling to search members
  searchName = event => {
    if (this.state.teamUsersSearchList && event.target.value) {
      const result = this.state.teamUsersSearchList.filter(
        function(data) {
          return (
            true ===
            data.First_Name.toLowerCase().includes(
              event.target.value.toLowerCase()
            )
          );
        }.bind(this)
      );
      this.setState({
        teamUsersList: result
      });
    } else {
      this.setState({
        teamUsersList: this.userData
      });
    }
  };
  //call this function to handle(means close or open) modal
  handleAddModal = () => {
    this.setState({ showAddTeamUserModal: !this.state.showAddTeamUserModal });
  };
  /*-------------function for to open modal for add user to team-----------*/
  addTeamUser = () => {
    this.setState({
      showAddTeamUserModal: true
    });
  };
  /*------call this function to delete users------------*/
  deleteUser = userId => {
    const confirm = window.confirm("Are you sure you want to delete User ?");
    if (confirm) {
      this.props.dispatch(deleteTeamUser(userId));
    } else {
      return false;
    }
  };
  /**------call this function to re-invite users ----------*/
  reInvite = (email, key) => {
    let formData = {
      email: email,
      role: 5,
      customerId: queryString.parse(this.props.names).customerId
    };
    this.setState({
      loading: true,
      key: key
    });
    this.props.dispatch(invitation(formData));
  };
  /*------------render method-------------------- */
  render() {
    const teamName = queryString
      .parse(this.props.names)
      .teamName.split("_")
      .join(" ");
    const customerName = queryString
      .parse(this.props.names)
      .customerName.split("_")
      .join(" ");
    const customerId = queryString.parse(this.props.names).customerId;
    return (
      <div>
        <TeamMembers
          t={this.props.t}
          reInvite={this.reInvite}
          deleteUser={this.deleteUser}
          handleAddModal={this.handleAddModal}
          showModal={this.showModal}
          teamUsersList={this.state.teamUsersList}
          userDataPermission={this.state.userDataPermission}
          teamId={this.props.teamId}
          searchName={this.searchName}
          onClickSearchButton={this.onClickSearchButton}
          isRequesting={this.state.isRequesting}
          customerName={customerName}
          teamName={teamName}
          customerId={customerId}
          loading={this.state.loading}
          rowKey={this.state.key}
          addTeamUser={this.addTeamUser}
          showAddTeamUserModal={this.state.showAddTeamUserModal}
          meta={this.state.meta}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    rolesData: state.invite.rolesData,
    userRolesData: state.invite.userRolesData,
    teamUsersList: state.team.teamUsersList,
    teamUsersListError: state.team.teamUsersListError,
    removeTeamUser: state.team.removeTeamUser,
    removeTeamUserError: state.team.removeTeamUserError,
    isRequesting: state.team.isRequesting,
    isInviteSuccess: state.invite.isInviteSuccess
  };
}
TeamMembersContainer.propTypes = {
  teamId: PropTypes.string,
  teamUsersList: PropTypes.object,
  teamUsersListError: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  rolesData: PropTypes.array,
  removeTeamUser: PropTypes.string,
  removeTeamUserError: PropTypes.bool,
  isInviteSuccess: PropTypes.bool
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "members-form"
  }),
  connect(mapStateToProps)
)(TeamMembersContainer);
