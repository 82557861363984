import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "reactstrap";

import unassignedIcon from "assets/img/png/unassigned-insances.png";
import orgainizationIcon from "assets/img/png/partener4.png";
import instancesIcon from "assets/img/png/partener2.png";
import supportTeamIcon from "assets/img/png/partener3.png";
import alertIcon from "assets/img/png/partener1.png";

function PartnerDashboardRole({ alert }) {
  return (
    <div className="team-dashboard-role">
      <Container>
        <Row>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img
                    width="33"
                    src={orgainizationIcon}
                    alt="Card image cap"
                  />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Organization
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {alert &&
                    alert.organisationCount &&
                    //  alert.organisationCount[0].OrganisationCount &&
                    alert.organisationCount
                      ? alert.organisationCount[0].OrganisationCount
                        ? alert.organisationCount[0].OrganisationCount
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={instancesIcon} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Instances
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {alert &&
                    alert.instanceCount &&
                    //  alert.instanceCount[0].InstanceCount &&
                    alert.instanceCount
                      ? alert.instanceCount[0].InstanceCount
                        ? alert.instanceCount[0].InstanceCount
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={supportTeamIcon} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Support Team
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {alert &&
                    alert.teamCount &&
                    //  alert.teamCount[0].TeamCount &&
                    alert.teamCount
                      ? alert.teamCount[0].TeamCount
                        ? alert.teamCount[0].TeamCount
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="team-dashboard-role-card-height">
              {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
              <CardBody className="team-dashboard-role-card-body d-flex">
                <div className=" team-dashboard-role-card-body-image">
                  <img width="33" src={alertIcon} alt="Card image cap" />
                </div>
                <div>
                  <CardTitle className="team-dashboard-role-card-body-title">
                    Alerts
                  </CardTitle>
                  <CardSubtitle className="mb-2  team-dashboard-role-card-body-sub-title">
                    {alert &&
                    alert.dayWiseAlertCount &&
                    //  alert.dayWiseAlertCount[0].TotalAlertCount &&
                    alert.dayWiseAlertCount
                      ? alert.dayWiseAlertCount[0].TotalAlertCount
                        ? alert.dayWiseAlertCount[0].TotalAlertCount
                        : "0"
                      : "0"}
                  </CardSubtitle>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default PartnerDashboardRole;
