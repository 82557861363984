import React from "react";
import { Container } from "reactstrap";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   //return { hasError: true };
  //   console.log(error);
  // }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    // console.log(error.toString());
    // console.log(info.componentStack);
    this.setState({
      hasError: true,
      error: error.toString()
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container id="container">
          <div>
            <div className="text-center">
              <i
                className="fa fa-5x fa-exclamation-triangle"
                aria-hidden="true"
              />
            </div>
            <div id="content">
              <h3 className="text-center">
                Contact to our support team with message below
              </h3>
            </div>
            <h2 className="text-center text-danger mt-2">{this.state.error}</h2>
          </div>
        </Container>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
