import React, { PureComponent } from "react";
import Registration from "./Containers/RegistrationContainer";

class RegistrationPage extends PureComponent {
  render() {
    return (
      <div>
        <Registration invitationId={this.props.match.params.id} />
      </div>
    );
  }
}

export default RegistrationPage;
