import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Form,
} from "reactstrap";
import { connect } from "react-redux";
import compose from "compose-function";
import Button from "reactstrap-button-loader";
import { translate } from "react-multi-lang";
import { renderTextField } from "../Common/InputRenderer";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  getAgents,
  createAgent,
  deleteAgent,
  updateAgentName,
} from "../../../actions/agentAction";
import { showError, showSuccess } from "../../../helpers/Utility";
class CreateAgentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClassic: false,
      loading: false,
      loadingDelete: false,
      search_loading: false,
      CreateAgent: {
        nameState: this.props.initialValues ? "has-success" : null,
      },
    };
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.CreateAgentSuccess &&
      nextProps.CreateAgentSuccess !== this.props.CreateAgentSuccess
    ) {
      showSuccess("Agent created successfully.");
      this.setState({
        loading: false,
      });
      this.props.dispatch(getAgents(this.props.instanceId));
      this.props.handleModal();
    }
    if (
      nextProps.deleteAgentSuccess &&
      nextProps.deleteAgentSuccess !== this.props.deleteAgentSuccess
    ) {
      showSuccess("Agent deleted successfully.");
      this.setState({
        loadingDelete: false,
      });
      this.props.dispatch(getAgents(this.props.instanceId));
      this.props.handleModal();
    }
    if (
      nextProps.updateAgentNameSuccess &&
      nextProps.updateAgentNameSuccess !== this.props.updateAgentNameSuccess
    ) {
      showSuccess("Agent Updated successfully.");
      this.setState({
        loading: false,
      });
      this.props.dispatch(getAgents(this.props.instanceId));
      this.props.handleModal();
    }
    if (
      nextProps.CreateAgentFailure &&
      nextProps.CreateAgentFailure !== this.props.CreateAgentFailure
    ) {
      showError(nextProps.CreateAgentFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.deleteAgentFailure &&
      nextProps.deleteAgentFailure !== this.props.deleteAgentFailure
    ) {
      showError(nextProps.deleteAgentFailure);
      this.setState({
        loadingDelete: false,
      });
    }
    if (
      nextProps.CreateAgentNetworkFailure &&
      nextProps.CreateAgentNetworkFailure !==
        this.props.CreateAgentNetworkFailure
    ) {
      showError("Error in creating instance.");
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.deleteAgentNetworkFailure &&
      nextProps.deleteAgentNetworkFailure !==
        this.props.deleteAgentNetworkFailure
    ) {
      showError("Error in deleting instance.");
      this.setState({
        loadingDelete: false,
      });
    }
    if (
      nextProps.updateAgentNameFailure &&
      nextProps.updateAgentNameFailure !== this.props.updateAgentNameFailure
    ) {
      showError(nextProps.updateAgentNameFailure);
      this.setState({
        loading: false,
      });
    }
    if (
      nextProps.updateAgentNameNetworkFailure &&
      nextProps.updateAgentNameNetworkFailure !==
        this.props.updateAgentNameNetworkFailure
    ) {
      showError("Error in updating agent.");
      this.setState({
        loading: false,
      });
    }
  };
  /*-----------------here we are calling creating and updating apis----------------- */
  onSubmit = (formProps) => {
    if (formProps) {
      formProps.instanceId = this.props.instanceId;
      let instance = this.state.CreateAgent;
      let isValid = true;
      if (instance["nameState"] !== "has-success") {
        instance["nameState"] = "has-danger";
        isValid = false; //not valid
      }
      if (!isValid) {
        showError("Agent name is mandatory.");
        return false;
      } else {
        if (this.props.initialValues) {
          delete formProps.instanceId;
          this.props.dispatch(updateAgentName(formProps));
        } else {
          this.props.dispatch(createAgent(formProps));
        }
        this.setState({
          loading: true,
        });
      }
    } else {
      return false;
    }
  };

  /* function for deleting agent */
  deleteAgent = () => {
    let currentAgentId = this.props.initialValues
      ? this.props.initialValues.agentId
      : "";
    const confirmDelete = window.confirm(
      "Are you sure you want to delete the Agent ?"
    );
    if (confirmDelete) {
      this.props.dispatch(deleteAgent(currentAgentId));
      this.setState({
        loadingDelete: true,
      });
    }
  };

  /**
   * call this function to validate name of the team
   */
  validateName = (e) => {
    let instance = this.state.CreateAgent;
    if (e.target.value.length > 4 && e.target.value.length < 50) {
      instance["nameState"] = "has-success";
    } else {
      instance["nameState"] = "has-danger";
    }
    this.setState({ instance });
  };
  //call this function to close create team modal
  handleModal = () => {
    this.props.handleModal();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleModal}
          className="text-center top-space"
        >
          <ModalHeader
            className="justify-content-left title create-team-title"
            toggle={this.handleModal}
            tag="p"
          >
            {this.props.initialValues ? "Update Agent" : "Create Agent"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Row className="mt-3">
                <Col lg={9} className="mx-auto">
                  <FormGroup
                    className={
                      this.state.CreateAgent.nameState
                        ? "has-label " + this.state.CreateAgent.nameState
                        : "has-label"
                    }
                  >
                    <Field
                      component={renderTextField}
                      name="name"
                      type="text"
                      placeholder="Enter Agent Name*"
                      className="team-input-style"
                      onChange={(e) => this.validateName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row>
                <Col lg={7} className="ml-auto pl-5">
                  <Button
                    color="primary"
                    // onClick={e => this.onSubmit(this.state.value)}
                    loading={this.state.loading}
                    type="submit"
                  >
                    {this.props.initialValues
                      ? "Update"
                      : this.props.t("teams.CREATE")}
                  </Button>
                </Col>
              </Row> */}
              <Row>
                {!this.props.initialValues && (
                  <Col lg={7} className="ml-auto pl-5">
                    <Button
                      color="primary"
                      // onClick={e => this.onSubmit(this.state.value)}
                      loading={this.state.loading}
                      type="submit"
                    >
                      {this.props.t("teams.CREATE")}
                    </Button>
                  </Col>
                )}
                {this.props.initialValues && (
                  <>
                    <Col>
                      <Button
                        color="primary"
                        // onClick={e => this.onSubmit(this.state.value)}
                        loading={this.state.loading}
                        type="submit"
                      >
                        Update
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        onClick={() => this.deleteAgent()}
                        loading={this.state.loadingDelete}
                      >
                        Delete
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
CreateAgentModal.propTypes = {
  CreateAgentSuccess: PropTypes.bool,
  CreateAgentFailure: PropTypes.string,
  CreateAgentNetworkFailure: PropTypes.bool,
  deleteAgentSuccess: PropTypes.bool,
  deleteAgentFailure: PropTypes.string,
  deleteAgentNetworkFailure: PropTypes.bool,
  updateAgentNameSuccess: PropTypes.bool,
  updateAgentNameFailure: PropTypes.string,
  updateAgentNameNetworkFailure: PropTypes.bool,
  handleModal: PropTypes.func,
  customerId: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    CreateAgentSuccess: state.agent.createAgentSuccess,
    CreateAgentFailure: state.agent.createAgentFailure,
    CreateAgentNetworkFailure: state.agent.createAgentNetworkFailure,
    deleteAgentSuccess: state.agent.deleteAgentSuccess,
    deleteAgentFailure: state.agent.deleteAgentFailure,
    deleteAgentNetworkFailure: state.agent.deleteAgentNetworkFailure,
    updateAgentNameSuccess: state.agent.updateAgentNameSuccess,
    updateAgentNameFailure: state.agent.updateAgentNameFailure,
    updateAgentNameNetworkFailure: state.agent.updateAgentNameNetworkFailure,
  };
}
export default compose(
  translate,
  reduxForm({
    form: "CreateAgent-form",
  }),
  connect(mapStateToProps)
)(CreateAgentModal);
