/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import Reporting from "../Components/ReportPage";
import PropTypes from "prop-types";
import { getDashboardData } from "../../../../actions/dashboardAction";
import { getInstanceByCriteria } from "../../../../actions/instanceAction";
import { getOrderedItems, dateFormat } from "../../../../helpers/Utility";
import queryString from "query-string";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
class ReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertsData: {},
      instancesData: null,
      selectedInstanceId: null,
      notificationsData: {},
      emailData: {},
      openAlertsData: {},
      closedAlertsData: {},
      selectedDays: 30,
      isRequesting: true,
      loading: false
    };
  }
  componentDidMount = () => {
    if (this.props.customerId) {
      const qString = `?days=${this.state.selectedDays}&customerId=${this.props.customerId}`;
      this.props.dispatch(getDashboardData(qString));
      let formData = {
        name: "customer",
        id: this.props.customerId
      };
      this.props.dispatch(getInstanceByCriteria(formData));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    this.setState({
      isRequesting: nextProps.isRequesting
    });
    if (
      nextProps.reportDashboardData &&
      nextProps.reportDashboardData !== this.props.reportDashboardData
    ) {
      let alertNames = [];
      let alertDatas = [];
      let pushNotification = [];
      let emailData = [];
      let openAlerts = [];
      let closedAlerts = [];
      let totalDays = [];
      let alertsCount = 0;
      let totalNotificationCount = 0;
      let totalEmailCount = 0;
      getOrderedItems(
        nextProps.reportDashboardData.data.alertData,
        "AlertCount"
      ).map(alert => {
        alertNames.push(alert.Rule.Name_Rule);
        alertDatas.push(alert.AlertCount);
        alertsCount = alertsCount + alert.AlertCount;
      });
      nextProps.reportDashboardData.data.dashBoardInfo.map(data => {
        pushNotification.push(data.pushNotificationCount);
        totalNotificationCount =
          totalNotificationCount + data.pushNotificationCount;
        emailData.push(data.emailCount);
        totalEmailCount = totalEmailCount + data.emailCount;
        openAlerts.push(data.openAlertCount);
        closedAlerts.push(data.closeAlertCount);
        totalDays.push(dateFormat(data.Created_Date));
      });
      this.setState({
        DashboardData: nextProps.reportDashboardData,
        alertsData: {
          label: alertNames,
          data: alertDatas,
          totalCount: alertsCount
        },
        notificationsData: {
          days: totalDays,
          data: pushNotification,
          totalCount: totalNotificationCount
        },
        emailData: {
          days: totalDays,
          data: emailData,
          totalCount: totalEmailCount
        },
        openAlertsData: {
          days: totalDays,
          data: openAlerts
        },
        closedAlertsData: {
          days: totalDays,
          data: closedAlerts
        }
      });
    }
    if (nextProps.instancesData) {
      this.setState({
        instancesData: nextProps.instancesData
          ? nextProps.instancesData.modifiedInstance
          : null,
      });
    }
  };
  /*----------function for get dashboard data based on days-------------- */
  getDashboardData = days => {
    this.setState({
      selectedDays: days
    });
    if (this.props.customerId) {
      const qString = `?days=${days}&customerId=${this.props.customerId}${this.state.selectedInstanceId ? `&instanceId=${this.state.selectedInstanceId}` : ''}`;
      this.props.dispatch(getDashboardData(qString));
    }
  };
  /*----------function for get dashboard data based on selected instance-------------- */
  getDashboardDataByInstance = e => {
    const instanceId = e.target.value;
    this.setState({
      selectedInstanceId: instanceId
    });
    if (this.props.customerId && instanceId) {
      const qString = `?days=${this.state.selectedDays}&customerId=${this.props.customerId}&instanceId=${instanceId}`;
      this.props.dispatch(getDashboardData(qString));
    } else {
      const qString = `?days=${this.state.selectedDays}&customerId=${this.props.customerId}`;
      this.props.dispatch(getDashboardData(qString));
    }
  };
  /*-------------funciton for print screen------------------------- */
  // printDocument = () => {
  //   const input = document.getElementById("divToPrint");
  //   input.style.display = "block";
  //   html2canvas(input).then(canvas => {
  //     var pdf = new jsPDF("p", "pt", "a4");
  //     for (var i = 0; i <= input.clientHeight / 980; i++) {
  //       //! This is all just html2canvas stuff
  //       var srcImg = canvas;
  //       var sX = 0;
  //       var sY = 980 * i; // start 980 pixels down for every new page
  //       var sWidth = 900;
  //       var sHeight = 980;
  //       var dX = 0;
  //       var dY = 0;
  //       var dWidth = 900;
  //       var dHeight = 980;
  //       let onePageCanvas = document.createElement("canvas");
  //       onePageCanvas.setAttribute("width", 900);
  //       onePageCanvas.setAttribute("height", 980);
  //       var ctx = onePageCanvas.getContext("2d");
  //       // details on this usage of this function:
  //       // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
  //       ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
  //       // document.body.appendChild(canvas);
  //       var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
  //       var width = onePageCanvas.width;
  //       var height = onePageCanvas.clientHeight;
  //       //! If we're on anything other than the first page,
  //       // add another page
  //       if (i > 0) {
  //         pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
  //       }
  //       //! now we declare that we're working on that page
  //       pdf.setPage(i + 1);
  //       //! now we add content to that page!
  //       pdf.addImage(canvasDataURL, "PNG", 20, 40, width * 0.62, height * 0.62);
  //     }
  //     //! after the for loop is finished running, we save the pdf.
  //     pdf.save("Test.pdf");
  //   });
  //   input.style.display = "none";
  // };
  printDocument = () => {
    const input = document.getElementById("divToPrint");
    input.style.display = "block";
    var HTML_Width = input.clientWidth;
    var HTML_Height = input.clientHeight;
    // const pdfHeight = 841.89;
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var opt = {
      margin: [30, 0, 50, 0],
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        unit: "pt",
        format: [PDF_Width, PDF_Height],
        orientation: "portrait",
        // pagebreak: { mode: ["avoid-all", "css", "legacy"] }
        pagebreak: { avoid: "tr" }
      }
    };
    // New Promise-based usage:
    html2pdf()
      .from(input)
      .set(opt)
      .save();
    // Old monolithic-style usage:
    // html2pdf(input, opt);
    // Define optional configuration
    // html2canvas(input, {
    //   scale: 2,
    //   pagesplit: true
    // }).then(canvas => {
    //   canvas.getContext("2d");
    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
    //   pdf.addImage(
    //     imgData,
    //     "JPG",
    //     top_left_margin,
    //     top_left_margin,
    //     canvas_image_width,
    //     canvas_image_height
    //   );
    //   for (var i = 1; i <= totalPDFPages; i++) {
    //     pdf.addPage(PDF_Width, PDF_Height);
    //     pdf.addImage(
    //       imgData,
    //       "JPG",
    //       top_left_margin,
    //       -(PDF_Height * i) + top_left_margin * 4,
    //       canvas_image_width,
    //       canvas_image_height
    //     );
    //   }
    //   pdf.save("HTML-Document.pdf");
    // });
    setTimeout(() => {
      input.style.display = "none";
    }, 0);
  };
  // printDocument = () => {
  //   const input = document.getElementById("divToPrint");
  //   input.style.display = "block";
  //   html2canvas(input, { scale: 2 }).then(canvas => {
  //     const imgData = canvas.toDataURL("image/png");
  //     var doc = new jsPDF("p", "mm");
  //     var position = 0;
  //     var margin = 2;
  //     var imgWidth = 210 - 2 * margin;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;
  //     doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       doc.addPage();
  //       doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }
  //     doc.save("file.pdf");
  //   });
  //   input.style.display = "none";
  // };
  render() {
    const searchParams = queryString.parse(this.props.searchParams);
    return (
      <div>
        <Reporting
          customerId={this.props.customerId}
          customerName={searchParams.customerName}
          alertsData={this.state.alertsData}
          notificationsData={this.state.notificationsData}
          emailData={this.state.emailData}
          openAlertsData={this.state.openAlertsData}
          closedAlertsData={this.state.closedAlertsData}
          getDashboardData={this.getDashboardData}
          getDashboardDataByInstance={this.getDashboardDataByInstance}
          selectedDays={this.state.selectedDays}
          isRequesting={this.state.isRequesting}
          printDocument={this.printDocument}
          loading={this.state.loading}
          instances={this.state.instancesData}
        />
      </div>
    );
  }
}
ReportContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  reportDashboardData: PropTypes.object,
  isRequesting: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    reportDashboardData: state.dashboard.getUserStatisticsSuccess,
    isRequesting: state.dashboard.isRequesting,
    instancesData: state.instance.instancesData
    // agentsData: state.agent.agentsData
  };
}
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "report-form"
  }),
  connect(mapStateToProps)
)(ReportContainer);
