/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody
} from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import Loader from "../../Common/PageLoader";
import { Button } from "components";
import { confirmPermissions } from "../../../../helpers/Utility";
import { dateFormat } from "../../../../helpers/Utility";
import { translate } from "react-multi-lang";
import compose from "compose-function";
import { connect } from "react-redux";
import CreatPartnerModal from "../../Modals/CreatePartnerModal";
import RoleBasedBadges from "../../Common/RoleBasedBadgesComponent";
import Switch from "react-bootstrap-switch";
import ReactTable from "react-table";
import { getPartners } from "../../../../actions/partnerAction";

function PartnerPage(props) {
  /* ---function for show members---------- */
  const showMembers = partnerData => {
    props.showMembers(partnerData.id, partnerData.name);
  };
  const permissions = props.wholeData ? props.wholeData.permissions : [];
  const isDeletable = confirmPermissions(permissions, "isDeletable");
  const isEditable = confirmPermissions(permissions, "isEditable");
  const isDisable = confirmPermissions(permissions, "isDisable");
  const isShowAction = isDeletable || isEditable;
  const data = props.stateValues.partnerTableData
    ? props.stateValues.partnerTableData
    : [];
  /*-------react table header------------ */
  const headers = {
    Name_Partner: props.t("partner.TABLE_TITLE"),
    role: "Role",
    Created_Date: "Created Date",
    actions: "Actions"
  };
  return (
    <div>
      {props.stateValues.showCreatePartnerModal && (
        <CreatPartnerModal
          isOpen={props.stateValues.showCreatePartnerModal}
          handleModal={props.handleModal}
          meta={props.stateValues.meta}
        />
      )}
      {props.stateValues.showUpdatePartnerModal && (
        <CreatPartnerModal
          isOpen={props.stateValues.showUpdatePartnerModal}
          handleModal={props.handleUpdateModal}
          initialValues={props.stateValues.initialValues}
          meta={props.stateValues.meta}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
              <Col className="d-md-none">
                {confirmPermissions(permissions, "isPartnerCreatable") && (
                  <Button
                    color="primary"
                    onClick={e => props.showModal()}
                    className="mobile-button"
                  >
                    <span className="font-weight-bold">Create Partner</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={3} md={3} xs={6} className="padding-one">
                <p className="font-weight-bold">{props.t("partner.TITLE")}</p>
              </Col>
              <Col lg={5} md={5} xs={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    id="myInput"
                    placeholder={props.t("partner.SEARCH")}
                    className="search-bar-css shadow-css"
                    onKeyUp={e => props.searchPartner(e)}
                    // onKeyUp={e => tableSearchFunction()}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="search-bar-css icon-css shadow-css">
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={12}
                sm={12}
                className="offset-sm-2 offset-xs-2 order-first order-lg-last order-md-last"
              >
                {confirmPermissions(permissions, "isPartnerCreatable") && (
                  <Button
                    color="primary"
                    className="create-team-button"
                    onClick={e => props.showModal()}
                  >
                    <span className="font-weight-bold">Create Partner</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Card>
              <CardBody>
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  // LoadingComponent={Loader}
                  className="cursor-pointer -striped-highlight"
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.Name_Partner,
                          accessor: "Name_Partner",
                          Cell: row => (
                            <div
                              onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.role,
                          accessor: "role",
                          Cell: row => (
                            <div
                              onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value[1] ? (
                                    <div>
                                      <RoleBasedBadges
                                        roleName={row.value[0]}
                                      />{" "}
                                      <RoleBasedBadges
                                        roleName={row.value[1]}
                                      />
                                    </div>
                                  ) : (
                                    <RoleBasedBadges roleName={row.value[0]} />
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        },

                        {
                          Header: headers.Created_Date,
                          accessor: "Created_Date",
                          Cell: row => (
                            <div
                              onClick={() => showMembers(row.original.actions)}
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    {" "}
                                    {dateFormat(row.value)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.actions,
                          accessor: "actions",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="ml-5">
                                    {" "}
                                    {isShowAction && (
                                      <td className="text-center switch-styles">
                                        {isDeletable && (
                                          <Button
                                            className="btn-sm"
                                            color="danger"
                                            round
                                            onClick={e =>
                                              props.deletePartner(row.value.id)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            />
                                          </Button>
                                        )}{" "}
                                        {isEditable && (
                                          <Button
                                            className="btn-sm"
                                            color="primary"
                                            round
                                            onClick={e =>
                                              props.updatePartner(
                                                row.value.id,
                                                row.value.name
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                            />
                                          </Button>
                                        )}{" "}
                                        {isDisable && (
                                          <Switch
                                            onText={
                                              <i className="now-ui-icons ui-1_check text-primary" />
                                            }
                                            offText={
                                              <i className="now-ui-icons ui-1_simple-remove" />
                                            }
                                            value={
                                              row.value.status ? true : false
                                            }
                                            onChange={e =>
                                              props.updatePartnerStatus(
                                                row.value.id,
                                                row.value.status
                                              )
                                            }
                                          />
                                        )}
                                      </td>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  manual
                  pages={props.stateValues.meta.pageTotal}
                  defaultPageSize={props.stateValues.meta.pageSize}
                  pageSizeOptions={props.stateValues.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `?pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `?pageSize=${state.pageSize}&pageIndex=${state.page}`;
                    }

                    if (qString) {
                      props.dispatch(getPartners(qString));
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
PartnerPage.propTypes = {
  stateValues: PropTypes.object,
  showSelectUserRoleModal: PropTypes.bool,
  userRolesData: PropTypes.object,
  handleModal: PropTypes.func,
  showMembers: PropTypes.func,
  searchPartner: PropTypes.func,
  language: PropTypes.object,
  partnersData: PropTypes.array,
  wholeData: PropTypes.object,
  isRequesting: PropTypes.bool
};
export default compose(translate, connect())(PartnerPage);
