/* eslint-disable prettier/prettier */
/**
 * @action        : loginAction
 * @description   : Handles signup, signin, forget and reset password actions
 * @Created by    : INNOMICK
 */

import { AUTH_CONST } from "./actionTypes";
import { AXIOS_INSTANCE, BASE_URL } from "./apiEndPoint";
import { checkHttpStatus, parseJSON, handleLoginRedirect } from "../utils";
import { UserScope } from "./invitationAction";

import * as base from "./baseAction";
/**
 * [userLogin description]
 * @param  {[type]} formData [description]
 * @return {[type]}          [description]
 */
// action for login
export function accountLogin(formData) {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.LOGIN_REQUEST));
    AXIOS_INSTANCE.post(BASE_URL + "/login", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          AXIOS_INSTANCE.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.token}`;
          // UserScope(response.data.token);
          dispatch(
            base.getSuccess(AUTH_CONST.LOGIN_SUCCESS, {
              response: {
                statusCode: 200,
                data: response
              }
            })
          );
          handleLoginRedirect(response.data);
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.LOGIN_FAILURE, {
              error: {
                statusCode: response.error.error,
                statusText: response.error.errorDescription,
                isSuccess: response.isSuccess
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.LOGIN_FAILURE, {
            error: {
              statusText: error,
              netWorkError: true
            }
          })
        );
      });
  };
}
// action for forgot password
export function forgotPassword(formData) {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.FORGOT_PASSWORD_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/forgotPassword", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AUTH_CONST.FORGOT_PASSWORD_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.FORGOT_PASSWORD_FAILURE, {
              error: {
                statusCode: response.error.error,
                statusText: response.error.errorDescription,
                isSuccess: response.isSuccess
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.FORGOT_PASSWORD_FAILURE, {
            error: {
              statusText: error,
              netWorkError: true
            }
          })
        );
      });
  };
}

// action for update password
export function updatePassword(formData) {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.UPDATE_PASSWORD_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updatePassword", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AUTH_CONST.UPDATE_PASSWORD_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.UPDATE_PASSWORD_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.UPDATE_PASSWORD_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for user module
export function userModule() {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.USER_MODULE_REQUEST));
    AXIOS_INSTANCE.get(BASE_URL + "/userModule")
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AUTH_CONST.USER_MODULE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.USER_MODULE_FAILURE, {
              reponse: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.USER_MODULE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
// action for logout data
export function logout() {
  AXIOS_INSTANCE.defaults.headers.common["Authorization"] = undefined;
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.LOGOUT_REQUEST));
  };
}

//action for store user data
export function storeUserData() {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.STORE_USER_PROFILE_REQUEST));
    dispatch(
      base.getSuccess(AUTH_CONST.STORE_USER_PROFILE_SUCCESS, {
        response: {
          data: JSON.parse(localStorage.getItem("userDetails"))
        }
      })
    );
  };
}

// action for update profile
export function updateProfile(formData) {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.UPDATE_PROFILE_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/updateProfile", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AUTH_CONST.UPDATE_PROFILE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
          localStorage.setItem("userDetails", JSON.stringify(response.data));
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.UPDATE_PROFILE_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.UPDATE_PROFILE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for update profile image
export function updateProfilePicture(formData) {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.UPDATE_PROFILE_IMAGE_REQUEST));
    AXIOS_INSTANCE.put(BASE_URL + "/postProfilePicture", formData)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AUTH_CONST.UPDATE_PROFILE_IMAGE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.UPDATE_PROFILE_IMAGE_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.UPDATE_PROFILE_IMAGE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}

// action for get profile image
export function getProfilePicture() {
  return dispatch => {
    dispatch(base.getRequest(AUTH_CONST.GET_PROFILE_IMAGE_REQUEST));
    AXIOS_INSTANCE.get(BASE_URL + "/getProfilePicture")
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.isSuccess) {
          dispatch(
            base.getSuccess(AUTH_CONST.GET_PROFILE_IMAGE_SUCCESS, {
              response: {
                data: response
              }
            })
          );
        } else {
          dispatch(
            base.getFailure(AUTH_CONST.GET_PROFILE_IMAGE_FAILURE, {
              response: {
                data: response
              }
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          base.getFailure(AUTH_CONST.GET_PROFILE_IMAGE_FAILURE, {
            error: {
              data: error
            }
          })
        );
      });
  };
}
