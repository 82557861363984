import React, { Fragment } from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import Loader from "../../Common/PageLoader";
import { roles_number } from "../../../../helpers/Utility";
import { translate } from "react-multi-lang";
import compose from "compose-function";
import { connect } from "react-redux";
import BreadcrumbComponent from "../../Common/BreadCrumbComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import ReactTable from "react-table";

class userRolePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // //function for render members data and count
  // renderMembersData = (adminCount, supportCount) => {
  //   if (this.props.isRequesting) {
  //     return (
  //       <tr className="table-tr-loader">
  //         <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
  //       </tr>
  //     );
  //   } else {
  //     return (
  //       <Fragment>
  //         <tr
  //           className={"table-data-rows"}
  //           onClick={e => this.props.showMembers(roles_number.customerAdmin)}
  //         >
  //           <td className="pl-3">Admin Role</td>
  //           <td className="text-center">{adminCount}</td>
  //           <td className="text-center">N/A</td>
  //         </tr>
  //         <tr
  //           className={"table-data-rows"}
  //           onClick={e => this.props.showMembers(roles_number.customerUser)}
  //         >
  //           <td className="pl-3">Support Role</td>
  //           <td className="text-center">{supportCount}</td>
  //           <td className="text-center">N/A</td>
  //         </tr>
  //       </Fragment>
  //     );
  //   }
  // };
  render() {
    // const Customer_Name = this.props.membersCount
    //   ? this.props.membersCount.Name_Customer
    //   : "";
    // const adminCount = this.props.membersCount
    //   ? this.props.membersCount.memberCount.customerAdmin.length
    //   : 0;
    // const supportCount = this.props.membersCount
    //   ? this.props.membersCount.memberCount.customerSupport.length
    //   : 0;
    const data = this.props.membersCount ? this.props.membersCount : [];
    /*-------react-table header----------*/
    const headers = {
      role: "Role name",
      members: "members",
      description: "description",
    };
    return (
      <div>
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row className="mt-4 d-lg-none sidebar-btn-landscape">
                <Col>
                  <SideBarToggle />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={3} xs={6} className="padding-one">
                  <p className="font-weight-bold text-muted">
                    {this.props.t("roles.TITLE")}
                  </p>
                </Col>
                <Col lg={5} xs={6} className="mr-auto padding-two">
                  <InputGroup className="no-border">
                    <Input
                      placeholder={this.props.t("roles.SEARCH")}
                      className="search-bar-css shadow-css"
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="search-bar-css icon-css shadow-css">
                        <i className="now-ui-icons ui-1_zoom-bold" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} xs={12} className="">
                  <div>
                    <BreadcrumbComponent
                      active="Roles"
                      nonActive1={this.props.Name_Customer}
                      nonActive1Href={
                        "/dashboard/organisationDashboard/" +
                        this.props.customerId
                      }
                      nonActive2="Organisations"
                      nonActive2Href={"/dashboard/organisations"}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} xs={12} className="text-left" />
              </Row>
              <Card className="mt-4">
                <CardBody>
                  <ReactTable
                    loading={this.props.membersCount ? false : true}
                    loadingText={"Argos..."}
                    showPagination={false}
                    className="cursor-pointer"
                    getTrProps={(state, row) => ({
                      onClick: () =>
                        this.props.showMembers(row.original.role.id),
                    })}
                    data={data}
                    columns={[
                      {
                        columns: [
                          {
                            sortable: false,
                            Header: headers.role,
                            accessor: "role",
                            Cell: (row) => (
                              <div
                              // onClick={() => showMembers(row.original.actions)}
                              >
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    {row.value.name}
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            sortable: false,
                            Header: headers.members,
                            accessor: "members",
                            Cell: (row) => (
                              <div
                              // onClick={() => showMembers(row.original.actions)}
                              >
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    {row.value}
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            sortable: false,
                            Header: headers.description,
                            accessor: "description",
                            Cell: (row) => (
                              <div
                              // onClick={() => showMembers(row.original.actions)}
                              >
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    {row.value}
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                        ],
                      },
                    ]}
                    defaultPageSize={2}
                  />
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#fff" }}
        />
      </div>
    );
  }
}
userRolePage.propTypes = {
  showSelectUserRoleModal: PropTypes.bool,
  userRolesData: PropTypes.object,
  handleModal: PropTypes.func,
  showMembers: PropTypes.func,
  language: PropTypes.object,
  membersCount: PropTypes.object,
  isRequesting: PropTypes.bool,
};
export default compose(translate, connect())(userRolePage);
