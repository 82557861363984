import React, { PureComponent } from "react";
import Agents from "./Containers/AgentContainer";

class RulesPage extends PureComponent {
  render() {
    return (
      <div>
        <Agents
          names={this.props.location.search}
          id={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default RulesPage;
