import React, { PureComponent } from "react";
import TeamMembers from "./Containers/TeamMembersContainer";

class TeamMemberPage extends PureComponent {
  render() {
    return (
      <div>
        <TeamMembers
          names={this.props.location.search}
          teamId={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default TeamMemberPage;
