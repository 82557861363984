// import "babel-polyfill";
// import "react-app-polyfill/ie11";
import "core-js";
import "regenerator-runtime/runtime";

// import "react-app-polyfill/stable";
// import "react-app-polyfill/ie9";
// import "core-js/stable";

import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import indexRoutes from "routes/index.jsx";
import { applyMiddleware, createStore } from "redux";
import promise from "redux-promise";
import { Provider } from "react-redux";
import reducers from "./reducers";
import thunkMiddleware from "redux-thunk";
import { setDefaultTranslations, setDefaultLanguage } from "react-multi-lang";
import nl from "./translations/nl.json";
import en from "./translations/en.json";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.css?v=1.2.0";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "assets/css/demo.css";
import "react-sticky-header/styles.css";
import "assets/css/custom.css";

const hist = createBrowserHistory();
setDefaultTranslations({ en, nl });
setDefaultLanguage(`${process.env.REACT_APP_LANG_NAME}`);
// eslint-disable-next-line no-console
const createStoreWithMiddleware = applyMiddleware(
  promise,
  thunkMiddleware
)(createStore);
ReactDOM.render(
  <Provider
    store={createStoreWithMiddleware(
      reducers,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )}
  >
    <Router history={hist}>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} key={key} component={prop.component} />
          );
        })}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);