/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import ForgotPasswordPage from "../../ForgotPasswordComponent/Components/ForgotPasswordPage";
import { emailRex } from "../../../../helpers/Utility";
import { forgotPassword } from "../../../../actions/loginAction";
import { showError, showSuccess } from "../../../../helpers/Utility";
import PropTypes from "prop-types";
class ForgotPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassword: {},
      loading: false
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.forgotPasswordSuccess &&
      nextProps.forgotPasswordSuccess !== this.props.forgotPasswordSuccess
    ) {
      showSuccess(this.props.t("success.FORGOT_PASSWORD_EMAIL_CHECK"));
      this.props.history.push("/pages/login-page");
    }
    if (
      nextProps.forgotPasswordFailure &&
      nextProps.forgotPasswordFailure !== this.props.forgotPasswordFailure
    ) {
      showError(this.props.t("error.EMAIL_NOT_EXISTS_ERROR"));
      this.setState({ loading: false });
    }
  };
  /**
   * call this function to validate email
   */
  validateEmail = e => {
    let forgotPassword = this.state.forgotPassword;
    forgotPassword["email"] = e.target.value;
    if (emailRex.test(e.target.value)) {
      forgotPassword["emailState"] = "has-success";
    } else {
      forgotPassword["emailState"] = "has-danger";
    }
    this.setState({ forgotPassword });
  };
  /*--------calling api--------------- */
  onSubmit = formData => {
    this.setState({
      loading: true
    });
    let forgot = this.state.forgotPassword;
    let isValid = true;
    if (forgot["emailState"] !== "has-success") {
      forgot["emailState"] = "has-danger";
      isValid = false; //not valid
    }
    if (!isValid) {
      return false;
    } else {
      this.props.dispatch(forgotPassword(formData));
    }
  };
  /* ---------------render function---------------------*/
  render() {
    return (
      <div>
        <ForgotPasswordPage
          forgotPassword={this.state.forgotPassword}
          validateEmail={this.validateEmail}
          onSubmit={this.onSubmit}
          handleSubmit={this.props.handleSubmit}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    forgotPasswordSuccess: state.login.forgotPasswordSuccess,
    forgotPasswordFailure: state.login.forgotPasswordFailure
  };
}
ForgotPasswordContainer.propTypes = {
  forgotPasswordSuccess: PropTypes.bool,
  forgotPasswordFailure: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "forgot-form"
  }),
  connect(mapStateToProps)
)(ForgotPasswordContainer);
