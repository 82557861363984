import React, { PureComponent } from "react";
import Team from "./Containers/TeamContainer";

class TeamPage extends PureComponent {
  render() {
    return (
      <div>
        <Team
          customerId={this.props.match.params.id}
          customerName={this.props.location.search}
        />
      </div>
    );
  }
}

export default TeamPage;
