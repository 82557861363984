/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import OrganisationDashboard from "../Components/OrganisationDashboardPage";
import PropTypes from "prop-types";
import { customerStatistics } from "../../../../../actions/customerAction";
import { showError } from "../../../../../helpers/Utility";
class OrganisationDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOrgDashboardSettingsModal: false,
      isRequesting: true
    };
  }
  componentDidMount = () => {
    if (this.props.customer_id) {
      this.props.dispatch(customerStatistics(this.props.customer_id));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.customerStatisticsData) {
      this.setState({
        customerStatisticsData: nextProps.customerStatisticsData
      });
    }
    if (
      nextProps.customerStatisticsError &&
      nextProps.customerStatisticsError !== this.props.customerStatisticsError
    ) {
      this.setState({
        customerStatisticsError: nextProps.customerStatisticsError
      });
      showError(this.props.t("error.DASHBOARD_ERROR"));
    }
    this.setState({
      isRequesting: nextProps.isRequesting
    });
  };
  /*--------opening the create organisations modal----------------- */
  showSettingsModal = () => {
    this.setState({
      showOrgDashboardSettingsModal: true
    });
  };
  //call this function to handle(means close or open) modal
  handleSettingsModal = () => {
    this.setState({
      showOrgDashboardSettingsModal: !this.state.showOrgDashboardSettingsModal
    });
  };
  /*--------------this navigate function we are using to navigate pages from dashboard -----------*/
  navigatePage = value => {
    const customerName = this.state.customerStatisticsData
      ? this.state.customerStatisticsData.Name_Customer.split(" ").join("_")
      : "";
    this.props.history.push(
      value + "/" + this.props.customer_id + "?customerName=" + customerName
    );
  };
  navigateToMembers = (value, roleValue) => {
    const customerName = this.state.customerStatisticsData
      ? this.state.customerStatisticsData.Name_Customer
      : "";
    const customer = customerName.split(" ").join("_");
    this.props.history.push(
      value +
        "/" +
        roleValue +
        "/" +
        this.props.customer_id +
        "?customerName=" +
        customer
    );
  };
  /*---------render method-------------------------*/
  render() {
    return (
      <div>
        <OrganisationDashboard
          stateValues={this.state}
          navigatePage={this.navigatePage}
          navigateToMembers={this.navigateToMembers}
          customerStatistics={this.state.customerStatisticsData}
          isRequesting={this.state.isRequesting}
          t={this.props.t}
          showSettingsModal={this.showSettingsModal}
          handleSettingsModal={this.handleSettingsModal}
        />
      </div>
    );
  }
}
OrganisationDashboardContainer.propTypes = {
  history: PropTypes.object,
  customer_id: PropTypes.string,
  customerStatisticsData: PropTypes.object,
  customerStatisticsError: PropTypes.bool,
  isRequesting: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    customerStatisticsData: state.customer.customerStatisticsData,
    customerStatisticsError: state.customer.customerStatisticsError,
    isRequesting: state.customer.isRequesting
  };
}
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "home-form"
    //validate,
    // asyncValidate,
  }),
  connect(mapStateToProps)
)(OrganisationDashboardContainer);
