/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
} from "reactstrap";
import { Button } from "components";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { dateFormat, isPartnerVisible } from "../../../../helpers/Utility";
import { translate } from "react-multi-lang";
import compose from "compose-function";
import { connect } from "react-redux";
import CreateOrganisationModal from "../../Modals/CreateOrganisationModal";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import RoleBasedBadges from "../../Common/RoleBasedBadgesComponent";
import { confirmPermissions } from "../../../../helpers/Utility";
import Switch from "react-bootstrap-switch";
import ReactTable from "react-table";
import { getCustomers } from "../../../../actions/customerAction";

function OrganisationPage(props) {
  const showOptions = (filteredValues) => {
    if (filteredValues) {
      return filteredValues.map((value, key) => {
        return (
          <option key={key} value={value.UID_Partner}>
            {value.Name_Partner}
          </option>
        );
      });
    }
  };
  const checkPartners = (partnersData) => {
    let partners = [];
    partnersData.map((partner, key) => {
      if (partner.roles.includes("Admin")) {
        partners.push(partner);
      }
    });
    return partners;
  };
  /*--------user permissions-------------*/
  const permissions = props.wholeData ? props.wholeData.permissions : [];
  const isDeletable = confirmPermissions(permissions, "isSuperAdmin");
  const isEditable = confirmPermissions(permissions, "isEditable");
  const isDisable = confirmPermissions(permissions, "isDisable");
  const isShowAction = isDeletable || isEditable;
  const partnerData = props.stateValues.partnerData
    ? props.stateValues.partnerData
    : [];
  const adminRolePartners = checkPartners(partnerData);
  const data = props.stateValues.customerData
    ? props.stateValues.customerData
    : [];
  /*---------react table header---------*/
  const headers = {
    Name_Customer: props.t("organisation.TABLE_TITLE"),
    Name_Partner: "Partner",
    role: "Role",
    Created_Date: "Created Date",
    actions: "Actions",
  };
  return (
    <div>
      {props.stateValues.showCreateOrganisationModal && (
        <CreateOrganisationModal
          isOpen={props.stateValues.showCreateOrganisationModal}
          handleModal={props.handleModal}
          partnersData={adminRolePartners}
          partnerId={props.partner_id}
          meta={props.stateValues.meta}
        />
      )}
      {props.stateValues.showUpdateOrganisationModal && (
        <CreateOrganisationModal
          isOpen={props.stateValues.showUpdateOrganisationModal}
          handleModal={props.handleModalTwo}
          partnersData={adminRolePartners}
          initialValues={props.stateValues.initialValues}
          partnerId={props.partner_id}
          meta={props.stateValues.meta}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
              <Col className="d-md-none">
                {confirmPermissions(permissions, "isCustomerCreatable") && (
                  <Button
                    color="primary"
                    onClick={(e) => props.showModal()}
                    className="mobile-button"
                  >
                    Create Organisation
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={3} md={3} xs={6} className="padding-one">
                <p className="font-weight-bold text-muted">
                  {props.t("organisation.TITLE")}
                </p>
              </Col>
              <Col lg={5} md={5} xs={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    id="myInput"
                    placeholder={props.t("organisation.SEARCH")}
                    className="search-bar-css shadow-css"
                    onKeyUp={(e) => props.searchCustomer(e)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="search-bar-css icon-css shadow-css">
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={12}
                sm={12}
                className="offset-sm-1 offset-xs-1 order-first order-lg-last order-md-last mr-4"
              >
                {confirmPermissions(permissions, "isCustomerCreatable") && (
                  <Button
                    color="primary"
                    className="create-team-button"
                    onClick={(e) => props.showModal()}
                  >
                    <span className="font-weight-bold">
                      Create Organisation
                    </span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                {/* <span className="text-muted">
                    {props.t("partner.DESCRIPTION")}: Here we are showing
                    organisations data.
                  </span> */}
              </Col>
              <Col lg={4}>
                {/* {props.stateValues.customerData && isPartnerVisible() ? ( */}
                {!props.stateValues.isRequesting && (
                  <Row>
                    <Label xs={6} md={6} className="text-right">
                      Filter
                    </Label>
                    <Col xs={6} md={6}>
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          onChange={(e) => props.filterOrganisations(e)}
                          id="exampleSelect"
                          value={props.partner_id}
                        >
                          {showOptions(partnerData)}
                          <option value="Organisation">All Partners</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {/* ) : (
                  ""
                )} */}
              </Col>
            </Row>
            <Card>
              <CardBody>
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  className="cursor-pointer -striped-highlight"
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.Name_Customer,
                          accessor: "Name_Customer",
                          Cell: (row) => (
                            <div
                              onClick={() =>
                                props.showDashboard(row.original.actions.id)
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          sortable: false,
                          Header: headers.Name_Partner,
                          accessor: "Name_Partner",
                          Cell: (row) => (
                            <div
                              onClick={() =>
                                props.showDashboard(row.original.actions.id)
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          sortable: false,
                          Header: headers.role,
                          accessor: "role",
                          Cell: (row) => (
                            <div
                              onClick={() =>
                                props.showDashboard(row.original.actions.id)
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value[1] ? (
                                    <div>
                                      <RoleBasedBadges
                                        roleName={row.value[0]}
                                      />{" "}
                                      <RoleBasedBadges
                                        roleName={row.value[1]}
                                      />
                                    </div>
                                  ) : (
                                    <RoleBasedBadges roleName={row.value[0]} />
                                  )}
                                </div>
                              </div>
                            </div>
                          ),
                        },

                        {
                          Header: headers.Created_Date,
                          accessor: "Created_Date",
                          Cell: (row) => (
                            <div
                              onClick={() =>
                                props.showDashboard(row.original.actions.id)
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    {" "}
                                    {dateFormat(row.value)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          sortable: false,
                          Header: headers.actions,
                          accessor: "actions",
                          Cell: (row) => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="ml-5">
                                    {" "}
                                    {isShowAction && (
                                      <td className="text-center switch-styles">
                                        {/* {isDeletable && (
                                          <Button
                                            className="btn-sm"
                                            color="danger"
                                            round
                                            onClick={e =>
                                              props.deleteCustomer(row.value.id)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            />
                                          </Button>
                                        )}{" "} */}
                                        {row.value.permission.includes(
                                          "isEditable"
                                        ) && (
                                          <Button
                                            className="btn-sm"
                                            color="primary"
                                            round
                                            onClick={(e) =>
                                              props.updateCustomer(
                                                row.value.id,
                                                row.value.name,
                                                row.value.partnerId
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                            />
                                          </Button>
                                        )}{" "}
                                        {row.value.permission.includes(
                                          "isDisable"
                                        ) && (
                                          <Switch
                                            onText={
                                              <i className="now-ui-icons ui-1_check text-primary" />
                                            }
                                            offText={
                                              <i className="now-ui-icons ui-1_simple-remove" />
                                            }
                                            value={
                                              row.value.status ? true : false
                                            }
                                            onChange={(e) =>
                                              props.updateCustomerStatus(
                                                row.value.id,
                                                row.value.status
                                              )
                                            }
                                          />
                                        )}
                                      </td>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                        },
                      ],
                    },
                  ]}
                  manual
                  pages={props.stateValues.meta.pageTotal}
                  defaultPageSize={props.stateValues.meta.pageSize}
                  pageSizeOptions={props.stateValues.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `?pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `?pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }`;
                    }
                    if (props.partner_id && qString) {
                      props.dispatch(getCustomers(qString, props.partner_id));
                    } else {
                      props.dispatch(getCustomers(qString));
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
OrganisationPage.propTypes = {
  stateValues: PropTypes.object,
  showCreateOrganisationModal: PropTypes.bool,
  userRolesData: PropTypes.object,
  handleModal: PropTypes.func,
  showDashboard: PropTypes.func,
  searchCustomer: PropTypes.func,
  language: PropTypes.object,
  dispatch: PropTypes.func,
};
export default compose(
  translate,
  connect()
)(OrganisationPage);
