import React, { Fragment } from "react";
import { Input, FormFeedback, FormText } from "reactstrap";
import { Button } from "components";
import Datetime from "react-datetime";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

export const renderTextField = ({
  input,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <Input {...(touched ? { valid: !error } : {})} {...input} {...custom} />
    {error && <FormFeedback>{error}</FormFeedback>}
    {!error && warning && <FormText>{warning}</FormText>}
  </Fragment>
);

export const renderSelectField = ({
  input,
  options,
  placeholder,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <select className="custom-select" placeholder={placeholder} {...input}>
      <option value={0}>Please select instance</option>
      {options.map(option => (
        <option key={option.UID_Instance} value={option.UID_Instance}>
          {option.Name_Instance}
        </option>
      ))}
    </select>
  </Fragment>
);
renderSelectField.defaultProps = {
  options: []
};

export const renderSelectFieldTwo = ({
  input,
  options,
  placeholder,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <select className="custom-select" placeholder={placeholder} {...input}>
      <option value={0}>Please select partner</option>
      {options.map(option => (
        <option key={option.UID_Partner} value={option.UID_Partner}>
          {option.Name_Partner}
        </option>
      ))}
    </select>
  </Fragment>
);

export const renderSelectFieldThree = ({
  input,
  options,
  placeholder,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <select className="custom-select" placeholder={placeholder} {...input}>
      <option value={0}>Select code</option>
      {options.map(option => (
        <option key={option.UID_Country} value={option.Calling_Code}>
          +{option.Calling_Code}
        </option>
      ))}
    </select>
  </Fragment>
);
renderSelectField.defaultProps = {
  options: []
};

export const renderDate = ({
  input,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <Datetime
      {...(touched ? { valid: error } : {})}
      {...input}
      {...custom}
      timeFormat={"HH:mm"} //by using HH we can get 24 hours time format
      dateFormat={false}
      inputProps={{
        placeholder: "Please select time",
        readOnly: true
      }}
    />
    {touched &&
      ((error && <span className="errorText">{error}</span>) ||
        (warning && <span>{warning}</span>))}
    {error && <FormFeedback>{error}</FormFeedback>}
    {!error && warning && <FormText>{warning}</FormText>}
  </Fragment>
);

export const renderTimePicker = ({
  input,
  meta: { touched, error, warning },
  ...custom
}) => {
  // console.log(input);
  return (
    <Fragment>
      <TimePicker
        {...(touched ? { valid: error } : {})}
        {...input}
        {...custom}
        timeFormat={"HH:mm"} //by using HH we can get 24 hours time format
        style={{ width: 100 }}
        showSecond={false}
        defaultValue={moment()}
        placeholder="Please select time"
      />
      {touched &&
        ((error && <span className="errorText">{error}</span>) ||
          (warning && <span>{warning}</span>))}
      {error && <FormFeedback>{error}</FormFeedback>}
      {!error && warning && <FormText>{warning}</FormText>}
    </Fragment>
  );
};

export const renderTeamsDataSelectField = ({
  input,
  options,
  placeholder,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <select className="custom-select" placeholder={placeholder} {...input}>
      <option value={0}>Please select Customer Team</option>
      {options.map(option => (
        <option key={option.UID_Team} value={option.UID_Team}>
          {option.Name_Team}
        </option>
      ))}
    </select>
  </Fragment>
);
