/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import IssueDetailsPage from "../Components/IssueDetailsPage.jsx";
import {
  getAlertDetails,
  createAlertComment
} from "../../../../actions/alertAction";
import PropTypes from "prop-types";
import { showError, showSuccess } from "../../../../helpers/Utility.jsx";
class IssueDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {},
      showIssueDetailsModal: false,
      issuesDataError: false
    };
    this.issuesData = [];
  }
  componentDidMount = () => {
    if (this.props.alertId) {
      this.props.dispatch(getAlertDetails(this.props.alertId));
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.issueDetailsData) {
      this.setState({
        issueData: nextProps.issueDetailsData
      });
    }
    if (
      nextProps.commentDataSuccess &&
      nextProps.commentDataSuccess !== this.props.commentDataSuccess
    ) {
      showSuccess(this.props.t("success.COMMENT_SUCCESS"));
      this.props.dispatch(getAlertDetails(this.props.alertId));
    }
  };
  createComment = (alertId, text) => {
    let formData = {
      alertId: alertId,
      comment: text
    };
    this.props.dispatch(createAlertComment(formData));
  };

  render() {
    return (
      <div>
        <IssueDetailsPage
          issueData={this.state.issueData}
          createComment={this.createComment}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    issueDetailsData: state.alert.issueDetailsData,
    commentDataSuccess: state.alert.commentDataSuccess
  };
}
IssueDetailsContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  alertId: PropTypes.string,
  issueDetailsData: PropTypes.object
};
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps)
)(IssueDetailsContainer);
