import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import NotificationsPage from "../Components/NotificationsPage.jsx";
import { getAlertDetails } from "../../../../actions/alertAction.js";
import {
  getNotifications,
  deleteNotification
} from "../../../../actions/notificationAction.js";
import PropTypes from "prop-types";
import {
  showError,
  showSuccess,
  getStartDateSevenDaysAgo
} from "../../../../helpers/Utility.jsx";
class NotificationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIssueDetailsModal: false,
      notificationsError: false,
      key: null,
      alertId: null,
      selectedDays: 7,
      startDate: getStartDateSevenDaysAgo(),
      isPushNotificatAlertId: null
    };
    this.notificationsData = [];
  }

  componentDidMount = () => {
    window.addEventListener("message", this.handlePushNotificationEvent);
    this.getData();
  };

  handlePushNotificationEvent = event => {
    try {
      const data = JSON.parse(event.data);

      if (data.type === "pushNotification") {
        const { notificationId } = data;
        this.showIssueModal(notificationId);
        this.setState({ isPushNotificatAlertId: notificationId });
      }
    } catch (error) {
      console.error("Error parsing push notification message data:", error);
    }
  };

  /*---------function for getting the Notifications data----*/
  getData = () => {
    const query = `?date=${this.state.startDate}`;
    this.props.dispatch(getNotifications(query));
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.notificationsData &&
      nextProps.notificationsData !== this.props.notificationsData
    ) {
      this.setState({
        notificationsData: nextProps.notificationsData
      });
    }

    this.setState({
      isRequestingTwo: nextProps.isRequestingTwo,
      isRequesting: nextProps.isRequesting
    });
    if (
      nextProps.notificationsError &&
      nextProps.notificationsError !== this.props.notificationsError
    ) {
      this.setState({
        notificationsError: true
      });
      showError("Error in getting notifications.");
    }
    /**issue details api response */
    if (
      nextProps.issueDetailsData &&
      nextProps.issueDetailsData !== this.props.issueDetailsData
    ) {
      this.setState({
        issueDetails: nextProps.issueDetailsData, //issue details data
        showIssueDetailsModal: true //here we are opening issue details modal
      });
    }

    if (
      nextProps.deleteNotificationSuccess &&
      nextProps.deleteNotificationSuccess !==
        this.props.deleteNotificationSuccess
    ) {
      showSuccess("Deleted Successfully");

      const query = `?date=${this.state.startDate}`;
      this.props.dispatch(getNotifications(query));
    }

    if (
      nextProps.updateNotificationSuccess &&
      nextProps.updateNotificationSuccess !==
        this.props.updateNotificationSuccess
    ) {
      const query = `?date=${this.state.startDate}`;
      this.props.dispatch(getNotifications(query));
    }
  };

  /*call this to show issue details modal */
  showIssueModal = (alertId, key) => {
    this.props.dispatch(getAlertDetails(alertId)); //based this api result we are opening modal
    this.setState({
      key: key,
      alertId: alertId
    });
  };

  handleModal = () => {
    this.setState({ showIssueDetailsModal: !this.state.showIssueDetailsModal });
  };

  /*------------call this function to remove notifications --------*/
  clearNotifications = () => {
    const confirm = window.confirm(
      "Are you sure you want to clear all notifications?"
    );
    if (confirm) {
      this.props.dispatch(deleteNotification());
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        <NotificationsPage
          stateValues={this.state}
          showIssueModal={this.showIssueModal}
          handleModal={this.handleModal}
          isRequesting={this.state.isRequesting}
          isRequestingTwo={this.state.isRequestingTwo}
          keyValue={this.state.key}
          alertId={this.state.alertId}
          notificationsData={this.state.notificationsData}
          clearNotifications={this.clearNotifications}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isRequesting: state.notifications.isRequesting,
    issueDetailsData: state.alert.issueDetailsData,
    isRequestingTwo: state.alert.isRequestingTwo,
    notificationsData: state.notifications.notificationsData,
    deleteNotificationSuccess: state.notifications.deleteNotificationSuccess,
    updateNotificationSuccess: state.notifications.updateNotificationSuccess
  };
}
NotificationsContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  issueDetailsData: PropTypes.object
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "issues-form"
  }),
  connect(mapStateToProps)
)(NotificationsContainer);
