import React from "react";
import { Container, Col, Row } from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import nowLogo from "assets/img/png/Argos.png";
function InformationPage() {
  return (
    <div>
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row className="mt-4 d-lg-none sidebar-btn-landscape">
              <Col>
                <SideBarToggle />
              </Col>
            </Row>
            <Row className="margin-top-style">
              <Col lg={12}>
                <div className="text-center change-password-logo">
                  <img src={nowLogo} alt="now-logo" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center font-weight-bold">
                Information Page
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
InformationPage.propTypes = {};
export default InformationPage;
