import { browserHistory } from "react-router";
import { createBrowserHistory } from "history";
import { refreshTokenAndRetry } from "../actions/apiEndPoint";

export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});
}

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    return reducer ? reducer(state, action.payload) : state;
  };
}

export const checkHttpStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    if (response.data.error.error === 403) {
      const updatedResponse = await refreshTokenAndRetry(response.config);
      return updatedResponse;
    }
    return response;
  } else if (response.status === 401 || response.status === 403) {
    const updatedResponse = await refreshTokenAndRetry(response.config);
    return updatedResponse;
  } else {
    throw response;
  }
};

export function handleLoginRedirect(data) {
  localStorage.setItem("authToken", data.token);
  localStorage.setItem("refreshToken", data.refreshToken);
  localStorage.setItem("userDetails", JSON.stringify(data.user));
  // this.props.history.push("/account");
  return true;
}

export function handleLogoutRedirect() {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  createBrowserHistory.push("/pages/login-page");
}

export function parseJSON(response) {
  return response.data;
}

export function setModuleWeights(response) {
  localStorage.setItem("moduleWeights", JSON.stringify(response.data));
  return response;
}
// export function handleRedirect() {
//   localStorage.removeItem("authToken");
//   localStorage.removeItem("index");
//   browserHistory.push("/signin");
// }
