/* eslint-disable react/no-unescaped-entities */
import React from "react";
import compose from "compose-function";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import UpdatePasswordPage from "../Components/UpdatePasswordPage";
import { showError, showSuccess } from "../../../../helpers/Utility";
import { updatePassword } from "../../../../actions/loginAction";
import { logout } from "../../../../actions/loginAction";
import PropTypes from "prop-types";
class UpdatePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatePassword: {},
      loading: false,
      updatedPassword: ""
    };
  }

  componentDidMount() {
    document.documentElement.classList.toggle("nav-open");
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.updatePasswordFailed &&
      nextProps.updatePasswordFailed !== this.props.updatePasswordFailed
    ) {
      showError(this.props.t("error.UPDATE_PASSWORD_ERROR"));
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updatePasswordNetworkFailed &&
      nextProps.updatePasswordNetworkFailed !==
        this.props.updatePasswordNetworkFailed
    ) {
      showError("Something went wrong");
      this.setState({
        loading: false
      });
    }
    if (
      nextProps.updatePasswordSuccess &&
      nextProps.updatePasswordSuccess !== this.props.updatePasswordSuccess
    ) {
      showSuccess(this.props.t("success.UPDATE_PASSWORD_SUCCESS"));
      // this.props.dispatch(logout());
      // localStorage.clear();
      // this.props.history.push("/pages/login-page");
      this.props.history.push("/dashboard/home");
      this.setState({
        loading: false
      });
    }
  };
  /**
   * call this function to validate password
   */
  validatePassword = (e, bool) => {
    let updatePassword = this.state.updatePassword;
    updatePassword["password"] = e.target.value;
    if (e.target.value.length >= 5) {
      if (bool) {
        updatePassword["oldPasswordState"] = "has-success";
      } else {
        this.setState({
          updatedPassword: e.target.value
        });
        updatePassword["passwordState"] = "has-success";
      }
    } else {
      if (bool) {
        updatePassword["oldPasswordState"] = "has-danger";
      } else {
        updatePassword["passwordState"] = "has-danger";
      }
    }
    this.setState({ updatePassword });
  };
  /**call this function to validate confirm password field */
  validateConfirmPassword = e => {
    let updatePassword = this.state.updatePassword;
    updatePassword["confirmPasswordState"] = e.target.value;
    if (e.target.value === this.state.updatedPassword) {
      updatePassword["confirmpasswordState"] = "has-success";
    } else {
      updatePassword["confirmpasswordState"] = "has-danger";
    }
    this.setState({ updatePassword });
  };
  /**
   * call this function to hit api.
   */
  onSubmit = formProps => {
    // this.setState({
    //   loading: true
    // });
    // validation for password,confirm password and hit api
    let password = this.state.updatePassword;
    let isValid = true;
    let fields = ["oldPasswordState", "passwordState"];
    fields.map(fieldName => {
      if (password[fieldName] !== "has-success") {
        isValid = false;
        password[fieldName] = "has-danger";
      }
    });
    if (!isValid) {
      this.setState({
        loading: false
      });
      return false;
    } else {
      if (formProps.newPassword === formProps.password) {
        this.setState({ showLoader: true });
        // we validating three fields but need two fields only.
        delete formProps["password"];
        this.props.dispatch(updatePassword(formProps));
      } else {
        showError("Please enter matching password");
      }
    }
  };
  render() {
    return (
      <div>
        <UpdatePasswordPage
          updatePassword={this.state.updatePassword}
          validatePassword={this.validatePassword}
          validateConfirmPassword={this.validateConfirmPassword}
          onSubmit={this.onSubmit}
          handleSubmit={this.props.handleSubmit}
          changePasswordScreen={this.props.changePasswordScreen}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    updatePasswordFailed: state.login.updatePasswordFailed,
    updatePasswordNetworkFailed: state.login.updatePasswordNetworkFailed,
    updatePasswordSuccess: state.login.updatePasswordSuccess
  };
}
UpdatePasswordContainer.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  updatePasswordSuccess: PropTypes.bool,
  updatePasswordFailed: PropTypes.bool,
  updatePasswordNetworkFailed: PropTypes.bool,
  changePasswordScreen: PropTypes.string
};
export default compose(
  translate,
  withRouter,
  reduxForm({
    form: "update-password-form"
  }),
  connect(mapStateToProps)
)(UpdatePasswordContainer);
