import React from "react";
import { Container, Col, Row, Button, Card, CardBody } from "reactstrap";
import SideBarToggle from "../../Common/SideBarToggle";
import NotificationIssueDetailsModal from "../../Modals/NotificationIssueDetailsModal";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import UserImage from "../../../../assets/img/png/DefaultUser.png";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import {
  deleteNotification,
  updateNotificationStatus
} from "../../../../actions/notificationAction";

class NotificationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsData: [],
      userData: JSON.parse(localStorage.getItem("userDetails")), //here we are getting user data from localStrorage
      notifications: []
    };
  }

  //this function we are using to show issues
  showIssuesDetails = notificationsList => {
    if (notificationsList && notificationsList.length) {
      // this logic for updating the notification status if already read by clicking on the push notification
      if (this.props.stateValues.isPushNotificatAlertId) {
        const alertId = this.props.stateValues.isPushNotificatAlertId;
        const foundItem = notificationsList.find(function(item) {
          return item.UID_Alert === alertId && item.Status !== "Read";
        });

        if (foundItem) {
          this.props.dispatch(
            updateNotificationStatus({
              notificationId: foundItem.UID_Notification,
              status: "Read"
            })
          );
        }
      }

      return notificationsList.map((item, key) => {
        return (
          <Container className={item.Status === "Unread" ? "un-read-notification box-style py-3 pl-3" : "box-style py-3 pl-3"} key={key}>
            {this.props.isRequestingTwo &&
            this.props.keyValue === key &&
            this.props.alertId === item.UID_Alert ? (
              <i className="fa fa-spinner fa-pulse" />
            ) : (
              ""
            )}
            <div className="text-center pd-3 mb-3 font-weight-bold">
              {item.Title_Notification}
            </div>
            <Row className="mb-2">
              <Col lg={10} md={10} xs={9}>
                <Row>
                  <Col lg={2} md={2} xs={3} className="notification-user-icon">
                    <span>
                      <img
                        src={
                          item.User
                            ? item.User.Profile_Image
                              ? item.User.Profile_Image
                              : UserImage
                            : UserImage
                        }
                        alt="user"
                        className="rounded-circle img-fluid user-css-styles"
                      />
                    </span>
                  </Col>
                  <Col lg={10} md={10} xs={9}>
                    <span>{item.Description_Notification}</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={2} md={2} xs={3}>
                <div>
                  <Button
                    className="btn-sm"
                    color="primary"
                    round
                    onClick={() => {
                      this.props.showIssueModal(item.UID_Alert, key);
                      if (item.Status !== "Read") {
                        this.props.dispatch(
                          updateNotificationStatus({
                            notificationId: item.UID_Notification,
                            status: "Read"
                          })
                        );
                      }
                    }}
                  >
                    <i
                      className="fa fa-eye fa-1x cursor-pointer"
                      aria-hidden="true"
                      text="white"
                    />
                  </Button>

                  <Button
                    className="btn-sm"
                    color="danger"
                    round
                    onClick={() =>
                      this.props.dispatch(
                        deleteNotification({
                          notificationId: item.UID_Notification
                        })
                      )
                    }
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        );
      });
    } else {
      return (
        <Container className="box-style py-3 pl-3">
          <Row>
            <Col className="text-center">
              <span className="text-center">
                {this.props.t("notifications.NO_DATA_AVAILABLE")}
              </span>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  render() {
    return (
      <div>
        {this.props.stateValues.showIssueDetailsModal && (
          <NotificationIssueDetailsModal
            isOpen={this.props.stateValues.showIssueDetailsModal}
            handleModal={this.props.handleModal}
            issueDetails={this.props.stateValues.issueDetails}
            userData={this.props.stateValues.userData}
          />
        )}
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                  <SideBarToggle />
                </Col>
              </Row>
              <Row className="mt-4 notification-head-card">
                <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                  <p className="font-weight-bold">
                    {this.props.t("notifications.TITLE")}
                  </p>
                </Col>

                <Col lg={2} md={3} xs={6} sm={6} className="p-3 notification-edit">
                  {this.props.notificationsData &&
                  this.props.notificationsData.notificationList.length ? (
                    <Button
                      color="primary"
                      onClick={() => this.props.clearNotifications()}
                    >
                      <span className="font-weight-bold">
                        {this.props.t("notifications.DELETE_ALL")}
                      </span>
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg={12} md={12}>
                  <Card>
                    <CardBody>
                      <span className="font-weight-bold mr-2">
                        {this.props.t("notifications.RECENTLY_VIEWED")}
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.props.stateValues.isRequesting ? (
                <Row className="mt-4">
                  <Col lg={12} md={12} xs={12}>
                    <img
                      className="loader-inside-tr"
                      src={LoaderIcon}
                      alt="loader"
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="mt-4">
                  <Col lg={12} md={12}>
                    <Card className="card-style">
                      {this.props.notificationsData &&
                        this.showIssuesDetails(
                          this.props.notificationsData.notificationList
                        )}{" "}
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#fff" }}
        />
      </div>
    );
  }
}
NotificationsPage.propTypes = {
  keyValue: PropTypes.number,
  alertId: PropTypes.string,
  showIssueDetailsModal: PropTypes.bool,
  handleModal: PropTypes.func,
  issueDetails: PropTypes.object,
  key: PropTypes.number,
  isRequestingTwo: PropTypes.bool,
  stateValues: PropTypes.object,
  notificationsData: PropTypes.array,
  clearNotifications: PropTypes.func,
  dispatch: PropTypes.func,
  t: PropTypes.func
};
export default compose(translate, connect())(NotificationsPage);
