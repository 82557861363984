import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  FormGroup,
} from "reactstrap";

import Button from "reactstrap-button-loader";
import { Field } from "redux-form";
import {
  renderTextField,
  renderSelectFieldThree,
} from "../../Common/InputRenderer";
import nowLogo from "assets/img/png/Argos.png";
import PropTypes from "prop-types";
import compose from "compose-function";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";
import { Link } from "react-router-dom";
import { getOrderedItems } from "../../../../helpers/Utility";
import ReactPhoneInput from "react-phone-input-2";
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <div className="for-registration-label">
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Row>
                <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Card className="card-login card-margin-bottom custom-card">
                      <CardHeader>
                        <div className="logo-container-x text-center">
                          <img src={nowLogo} alt="now-logo" />
                          <p className="font-weight-bold text-white mt-2">
                            {this.props.t("register.TITLE")}
                          </p>
                          <p className="custom-size">
                            {this.props.t("register.CREATE_ACCOUNT_TITLE")}
                          </p>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="10" xs="11" className="mx-auto">
                            <FormGroup
                              className={
                                this.props.register.firstNameState
                                  ? "has-label " +
                                    this.props.register.firstNameState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="text"
                                placeholder={this.props.t(
                                  "register.FIRST_NAME"
                                )}
                                name="firstName"
                                onFocus={(e) =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={(e) =>
                                  this.props.validateFirstName(e)
                                }
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.register.lastNameState
                                  ? "has-label " +
                                    this.props.register.lastNameState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="text"
                                placeholder={this.props.t("register.LAST_NAME")}
                                name="lastName"
                                onFocus={(e) =>
                                  this.setState({ lastnameFocus: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ lastnameFocus: false })
                                }
                                onChange={(e) => this.props.validateLastName(e)}
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.register.emailState
                                  ? "has-label " +
                                    this.props.register.emailState
                                  : "has-label"
                              }
                            >
                              <Input
                                type="text"
                                placeholder="Enter Email"
                                name="email"
                                onFocus={(e) =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={(e) => this.props.registerEmail(e)}
                                value={this.props.userEmail}
                                readOnly={true}
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.register.passwordState
                                  ? "has-label " +
                                    this.props.register.passwordState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="password"
                                placeholder={this.props.t("register.PASSWORD")}
                                name="password"
                                onFocus={(e) =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={(e) => this.props.validatePassword(e)}
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.register.confirmPasswordState
                                  ? "has-label " +
                                    this.props.register.confirmPasswordState
                                  : "has-label"
                              }
                            >
                              <Field
                                component={renderTextField}
                                type="password"
                                placeholder={this.props.t(
                                  "register.CONFIRM_PASSWORD"
                                )}
                                name="confirmPassword"
                                onFocus={(e) =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={(e) =>
                                  this.props.validateConfirmPassword(e)
                                }
                              />
                            </FormGroup>
                            <FormGroup
                              className={
                                this.props.register.contactNumberState
                                  ? "has-label" +
                                    this.props.register.contactNumberState
                                  : "has-label"
                              }
                            >
                              <ReactPhoneInput
                                inputExtraProps={{
                                  name: "phone",
                                  required: false,
                                  autoFocus: true,
                                }}
                                country={"gr"}
                                countryCodeEditable={false}
                                regions={["europe", "asia"]}
                                enableSearch={true}
                                value={this.props.phone}
                                onChange={this.props.handleOnChange}
                              />
                            </FormGroup>
                            {/* <FormGroup
                              className={
                                this.props.register.contactNumberState
                                  ? "countryCodeStyles has-label " +
                                    this.props.register.contactNumberState
                                  : "countryCodeStyles has-label "
                              }
                            >
                              <Field
                                className="w-25"
                                type="select"
                                name="countryCode"
                                component={renderSelectFieldThree}
                                options={getOrderedItems(
                                  this.props.countryCodeData,
                                  "Calling_Code"
                                )}
                              />
                              &nbsp;
                              <Field
                                className="w-75"
                                component={renderTextField}
                                placeholder="Please enter contact number"
                                name="contactNumber"
                                onFocus={e =>
                                  this.setState({ firstnameFocus: true })
                                }
                                onBlur={e =>
                                  this.setState({ firstnameFocus: false })
                                }
                                onChange={e =>
                                  this.props.validateContactNumber(e)
                                }
                              />
                            </FormGroup> */}
                            <InputGroup className="ml-4">
                              <Input
                                type="checkbox"
                                id="registerCheckBox"
                                onClick={
                                  (e) => this.props.handleDisable(e)
                                  // this.setState({
                                  //   disable: !this.state.disable
                                  // })
                                }
                                checked={this.props.stateValues.isChecked}
                              />
                              <div className="conditions-link">
                                <a href="javascript:void(0)" className="">
                                  <span className="text-white ">
                                    I accept the{" "}
                                  </span>
                                  <Link to={"/pages/privacy-policy"}>
                                    <span className="c-color">
                                      term & conditions
                                    </span>
                                  </Link>
                                </a>
                              </div>
                            </InputGroup>
                            <InputGroup className="no-border form-control-lg ">
                              <Button
                                block
                                color="primary"
                                className=""
                                type="submit"
                                disabled={this.props.disable}
                                loading={this.props.loading}
                              >
                                {this.props.t("register.SUBMIT")}
                              </Button>
                            </InputGroup>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <div className="footer-texts">
                          <h6>
                            <a
                              href="javascript:void(0)"
                              className="link footer-link"
                            >
                              Already have an account ?{" "}
                              <Link to={"/pages/login-page"}>Sign in</Link>
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundColor: "#40535D" }}
        />
      </div>
    );
  }
}
RegisterPage.propTypes = {
  register: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  validateConfirmPassword: PropTypes.func,
  registerEmail: PropTypes.func,
  validateFirstName: PropTypes.func,
  validateLastName: PropTypes.func,
  validatePassword: PropTypes.func,
  validateContactNumber: PropTypes.func,
  userEmail: PropTypes.string,
};
export default compose(
  translate,
  connect()
)(RegisterPage);
