/* eslint-disable react/display-name */
import React from "react";
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Label,
  FormGroup,
  Card,
  CardBody
} from "reactstrap";
import { Button } from "components";
import addUser from "assets/img/svg/add-user.svg";
import SideBarToggle from "../../Common/SideBarToggle";
import PropTypes from "prop-types";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import compose from "compose-function";
import { showRulesPriority } from "../../../../helpers/Utility";
import BreadCrumbComponent from "../../Common/BreadCrumbComponent";
import LoaderIcon from "../../../../assets/img/Loader.gif";
import queryString from "query-string";
import {
  confirmPermissions,
  tableSearchFunction
} from "../../../../helpers/Utility";
import CreateRuleModal from "../../Modals/CreateRuleModal";
import AssignRuleModal from "../../Modals/AssignRuleModal";
import ReactTable from "react-table";
import { getRulesByCriteria } from "../../../../actions/ruleAction";
import { getInstanceByCriteria } from "../../../../actions/instanceAction";

function rulesPage(props) {
  const showDashboard = (ruleId, ruleName) => {
    props.showDashboard(ruleId, ruleName);
  };
  //this function we are using to render rules
  // const showRules = () => {
  //   if (props.stateValues.rulesData && props.stateValues.rulesData.length) {
  //     return props.stateValues.rulesData.map((rule, key) => {
  //       // console.log(rule);
  //       return (
  //         <tr
  //           key={key}
  //           className="table-data-rows"
  //           // onClick={e => props.showDashboard(rule.UID_Rule, rule.Name_Rule)}
  //         >
  //           <td
  //             onClick={e =>
  //               showDashboard(
  //                 rule.UID_Rule,
  //                 rule.TeamRule ? rule.TeamRule.UID_TeamRule : null
  //               )
  //             }
  //           >
  //             {rule.Name_Rule}
  //           </td>
  //           <td
  //             onClick={e =>
  //               showDashboard(
  //                 rule.UID_Rule,
  //                 rule.TeamRule ? rule.TeamRule.UID_TeamRule : null
  //               )
  //             }
  //           >
  //             {rule.Description_Rule}
  //           </td>
  //           <td
  //             onClick={e =>
  //               showDashboard(
  //                 rule.UID_Rule,
  //                 rule.TeamRule ? rule.TeamRule.UID_TeamRule : null
  //               )
  //             }
  //           >
  //             {rule.Instance.Name_Instance}
  //           </td>
  //           <td
  //             className="text-center"
  //             onClick={e =>
  //               showDashboard(
  //                 rule.UID_Rule,
  //                 rule.TeamRule ? rule.TeamRule.UID_TeamRule : null
  //               )
  //             }
  //           >
  //             {rule.Rule_Enabled ? (
  //               <span className="badge badge-primary">active</span>
  //             ) : (
  //               <span className="badge badge-secondary background-style">
  //                 inactive
  //               </span>
  //             )}
  //           </td>
  //           <td
  //             className="text-center"
  //             onClick={e =>
  //               showDashboard(
  //                 rule.UID_Rule,
  //                 rule.TeamRule ? rule.TeamRule.UID_TeamRule : null
  //               )
  //             }
  //           >
  //             <i className={showRulesPriority(rule.Priority_Rule)} />
  //           </td>
  //           {queryString.parse(props.names).teamName && isDeletable ? (
  //             <td className="text-center">
  //               <Button
  //                 className="btn-sm"
  //                 color="danger"
  //                 round
  //                 onClick={e => props.unAssignRule(rule.TeamRule.UID_TeamRule)}
  //               >
  //                 <i className="fa fa-trash" aria-hidden="true" />
  //               </Button>
  //             </td>
  //           ) : (
  //             ""
  //           )}
  //         </tr>
  //       );
  //     });
  //   } else {
  //     if (props.stateValues.isRequesting) {
  //       return (
  //         <tr className="table-tr-loader">
  //           <img className="loader-inside-tr" src={LoaderIcon} alt="loader" />
  //         </tr>
  //       );
  //     } else {
  //       return (
  //         <tr className="table-tr-loader">
  //           <td className="no-data-found-style">No Data Found</td>
  //         </tr>
  //       );
  //     }
  //   }
  // };
  const permissions = props.wholeData ? props.wholeData.permissions : [];
  const isCreatable = confirmPermissions(
    permissions ? permissions : [],
    "isCreatable"
  );
  const isDeletable = confirmPermissions(
    permissions ? permissions : [],
    "isDeletable"
  );
  /*--------------function for show instance options in filter---------------- */
  const showOptions = () => {
    if (props.instancesData) {
      return props.instancesData.map((value, key) => {
        return (
          <option key={key} value={value.UID_Instance}>
            {value.Name_Instance}
          </option>
        );
      });
    }
  };
  /*---------------react-table header----------------------*/
  const headers = {
    Name_Rule: "Rule Name",
    description: "Description",
    Name_Instance: "Instance",
    status: "Status",
    priority: "Priority",
    unAssign: "Unassign"
  };
  /*---------------react-data------------------- */
  const data = props.stateValues.rulesData ? props.stateValues.rulesData : [];
  return (
    <div>
      {props.stateValues.showCreateRuleModal && (
        <CreateRuleModal
          isOpen={props.stateValues.showCreateRuleModal}
          handleModal={props.handleModal}
          name={queryString.parse(props.names).teamName ? "team" : "instance"}
          id={props.id}
          meta={props.stateValues.meta}
        />
      )}
      {props.stateValues.showAssignRuleModal && (
        <AssignRuleModal
          isOpen={props.stateValues.showAssignRuleModal}
          handleModal={props.handleAssignModal}
          id={props.id}
          instanceData={props.instancesData}
          meta={props.stateValues.meta}
        />
      )}
      <div className="full-page-content">
        <div className="login-page">
          <Container>
            <Row>
              <Col className="mt-4 d-lg-none sidebar-btn-landscape">
                <SideBarToggle />
              </Col>
              <Col className="d-lg-none d-md-none">
                {queryString.parse(props.names).teamName
                  ? isCreatable && (
                      <Button
                        color="primary"
                        onClick={() => props.showAssignModal()}
                        className="create-team-button"
                      >
                        <span className="font-weight-bold">Assign Rule</span>
                      </Button>
                    )
                  : isCreatable && (
                      <Button
                        color="primary"
                        onClick={() => props.showModal()}
                        className="create-team-button"
                      >
                        <span className="font-weight-bold">Create Rule</span>
                      </Button>
                    )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={2} md={2} xs={6} sm={6} className="padding-one">
                <p className="font-weight-bold">Rules</p>
              </Col>
              <Col lg={5} md={5} xs={6} sm={6} className="mr-auto padding-two">
                <InputGroup className="no-border">
                  <Input
                    id="myInput"
                    placeholder="Search for rule"
                    className="search-bar-css shadow-css"
                    onKeyUp={e => props.searchName(e)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      className="search-bar-css icon-css shadow-css cursor-pointer"
                      onClick={e => props.onClickSearchButton()}
                    >
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={12}
                sm={12}
                className="offset-sm-2 offset-xs-2 order-first order-lg-last order-md-last"
              >
                {queryString.parse(props.names).teamName
                  ? isCreatable && (
                      <Button
                        color="primary"
                        onClick={() => props.showAssignModal()}
                        className="create-team-button"
                      >
                        <span className="font-weight-bold">Assign Rule</span>
                      </Button>
                    )
                  : isCreatable && (
                      <Button
                        color="primary"
                        onClick={() => props.showModal()}
                        className="create-team-button"
                      >
                        <span className="font-weight-bold">Create Rule</span>
                      </Button>
                    )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xs={12} className="">
                <div>
                  <BreadCrumbComponent
                    active="Rules"
                    nonActive1={props.teamName}
                    nonActive1Href={
                      queryString.parse(props.names).teamName
                        ? "/dashboard/teamDashboard/" + props.id
                        : "/dashboard/instanceDashboard/" +
                          props.id +
                          "?customer=" +
                          queryString.parse(props.names).customerName +
                          "&instance=" +
                          queryString.parse(props.names).instanceName
                    }
                    nonActive2={props.customerName}
                    nonActive2Href={
                      "/dashboard/organisationDashboard/" + props.customerId
                    }
                  />
                </div>
              </Col>
              {queryString.parse(props.names).teamName ? (
                <Col lg={6} md={6} xs={12}>
                  <Row>
                    <Label xs={6} md={6} className="text-right">
                      Filter
                    </Label>
                    <Col xs={6} md={6}>
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          onChange={e => props.filterRules(e)}
                          id="exampleSelect"
                          // value={props.filterInstanceId}
                        >
                          {showOptions()}
                          <option value="instances">All Instances</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Card className="mt-4">
              <CardBody>
                <ReactTable
                  loading={!props.stateValues.isRequesting ? false : true}
                  loadingText={"Argos..."}
                  className="cursor-pointer -striped-highlight"
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: headers.Name_Rule,
                          accessor: "Name_Rule",
                          style: { whiteSpace: "unset" },
                          Cell: row => (
                            <div
                              onClick={() =>
                                props.showDashboard(
                                  row.original.unAssign.ruleId,
                                  row.original.unAssign.teamRuleId
                                    ? row.original.unAssign.teamRuleId
                                    : null
                                )
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.description,
                          accessor: "description",
                          style: { whiteSpace: "unset" },
                          Cell: row => (
                            <div
                              onClick={() =>
                                props.showDashboard(
                                  row.original.unAssign.ruleId,
                                  row.original.unAssign.teamRuleId
                                    ? row.original.unAssign.teamRuleId
                                    : null
                                )
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.Name_Instance,
                          accessor: "Name_Instance",
                          Cell: row => (
                            <div
                              onClick={() =>
                                props.showDashboard(
                                  row.original.unAssign.ruleId,
                                  row.original.unAssign.teamRuleId
                                    ? row.original.unAssign.teamRuleId
                                    : null
                                )
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  {row.value}
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.status,
                          accessor: "status",
                          Cell: row => (
                            <div
                              onClick={() =>
                                props.showDashboard(
                                  row.original.unAssign.ruleId,
                                  row.original.unAssign.teamRuleId
                                    ? row.original.unAssign.teamRuleId
                                    : null
                                )
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    {" "}
                                    {row.value ? (
                                      <span className="badge badge-primary">
                                        active
                                      </span>
                                    ) : (
                                      <span className="badge badge-secondary background-style">
                                        inactive
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          sortable: false,
                          Header: headers.priority,
                          accessor: "priority",
                          Cell: row => (
                            <div
                              onClick={() =>
                                props.showDashboard(
                                  row.original.unAssign.ruleId,
                                  row.original.unAssign.teamRuleId
                                    ? row.original.unAssign.teamRuleId
                                    : null
                                )
                              }
                            >
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    <img
                                      src={showRulesPriority(row.value)}
                                      width="20"
                                      alt="PriorityImage"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        },
                        {
                          show:
                            queryString.parse(props.names).teamName &&
                            isDeletable
                              ? true
                              : false,
                          sortable: false,
                          Header: headers.unAssign,
                          accessor: "unAssign",
                          Cell: row => (
                            <div>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="">
                                    <Button
                                      className="btn-sm"
                                      color="danger"
                                      round
                                      onClick={() =>
                                        props.unAssignRule(row.value.teamRuleId)
                                      }
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      ]
                    }
                  ]}
                  manual
                  page={props.stateValues.meta.page}
                  onPageChange={page => props.pageChange(page)}
                  pages={props.stateValues.meta.pageTotal}
                  defaultPageSize={props.stateValues.meta.pageSize}
                  pageSizeOptions={props.stateValues.meta.pageSizeOptions}
                  onFetchData={(state, instance) => {
                    let qString;
                    if (state.sorted.length) {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${
                        state.page
                      }&field=${state.sorted[0].id}&order=${
                        state.sorted[0].desc ? "desc" : "asc"
                      }`;
                    } else {
                      qString = `&pageSize=${state.pageSize}&pageIndex=${state.page}`;
                    }
                    if (props.id && qString) {
                      let formData = {
                        name: queryString.parse(props.names).teamName
                          ? "team"
                          : "instance",
                        id: props.id
                      };
                      // console.log(qString);
                      props.dispatch(
                        getRulesByCriteria(formData, false, qString)
                      );
                    }
                    // let data = {
                    //   name: "customer",
                    //   id: queryString.parse(props.names).customerId
                    // };
                    // props.dispatch(getInstanceByCriteria(data, qString));
                  }}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundColor: "#fff" }}
      />
    </div>
  );
}
rulesPage.propTypes = {
  stateValues: PropTypes.object,
  searchName: PropTypes.func,
  onClickSearchButton: PropTypes.func,
  rulesData: PropTypes.array,
  teamId: PropTypes.string,
  userDataPermission: PropTypes.object,
  error: PropTypes.bool,
  wholeData: PropTypes.object,
  showModal: PropTypes.func,
  showCreateRuleModal: PropTypes.bool,
  handleModal: PropTypes.func,
  dispatch: PropTypes.func,
  id: PropTypes.string
};
export default compose(translate, connect())(rulesPage);
