import Members from "../views/Components/MemberComponent/Index";
import roleIcon from "assets/img/svg/role.svg";
import UserRole from "../views/Components/UserRolesComponent";
import Bell from "../assets/img/svg/alarm.svg";
import AlertsIcon from "assets/img/png/alertIcon.png";
import membersIcon from "assets/img/svg/value.svg";
import passwordIcon from "assets/img/png/password.png";
import DashboardIcon from "assets/img/svg/value.svg";
import ChangePassword from "../views/Components/UpdatePasswordComponent";
import Dashboard from "../views/Components/MainDashboardComponent";
import Partner from "../views/Components/PartnerComponent";
import PartnerUsers from "../views/Components/PartnerComponent/PartnerUsersComponent";
import Organisation from "../views/Components/OrganisationComponent";
import organisationIcon from "assets/img/svg/company.svg";
import OrganisationDashboard from "../views/Components/OrganisationComponent/Dashboard";
import Team from "../views/Components/TeamComponent";
import TeamsDashboard from "../views/Components/TeamComponent/Dashboard";
import TeamUsers from "../views/Components/TeamMembersComponent";
import Rules from "../views/Components/RulesComponent";
import RulesDashboard from "../views/Components/RulesComponent/Dashboard";
import GlobalUsersIcon from "assets/img/svg/globalUser.svg";
import IssuesComponent from "../views/Components/IssuesComponent";
import IssueDetailsComponent from "../views/Components/IssueDetailsComponent";
import Instance from "../views/Components/InstanceComponent";
import InstanceDashboard from "../views/Components/InstanceComponent/Dashboard";
import Agent from "../views/Components/AgentComponent";
import Notification from "../views/Components/NotificationComponent";
import UserProfile from "../views/Components/UserProfileComponent";
import Information from "../views/Components/InformationComponent";
import Reporting from "../views/Components/ReportComponent";
import Notifications from "../views/Components/Notifications";
// import AgentDashboard from "../views/Components/AgentComponent/Dashboard";

import Perspectives from "../views/Components/PerspectivesComponent/Index";
let dashRoutes = [
  {
    path: "/dashboard/home", //for dashboard purpose
    name: "Dashboard",
    mini: "DP",
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    path: "/dashboard/partner", //for partners tab purpose
    name: "Partners",
    mini: "PP",
    icon: roleIcon,
    component: Partner,
  },
  {
    path: "/dashboard/organisations/:id", //for partners to organisations purpose
    mini: "CP",
    icon: roleIcon,
    component: Organisation,
  },
  {
    path: "/dashboard/organisations", //for only organisations tab purpose
    name: "Organisations",
    mini: "CP",
    icon: organisationIcon,
    component: Organisation,
  },
  {
    path: "/dashboard/organisationDashboard/:id", //for organisation dashboard purpose
    mini: "CP",
    icon: roleIcon,
    component: OrganisationDashboard,
  },
  {
    path: "/dashboard/teams/:id", //for teams purpose
    mini: "CP",
    icon: DashboardIcon,
    component: Team,
  },
  {
    path: "/dashboard/teamDashboard/:id", //for teams dashboard purpose
    mini: "MP",
    icon: membersIcon,
    component: TeamsDashboard,
  },
  {
    path: "/dashboard/roles/:role/:id", //for roles purpose
    mini: "RP",
    icon: roleIcon,
    component: UserRole,
  },
  {
    path: "/dashboard/members/:role/:id", //for members purpose
    mini: "MP",
    icon: membersIcon,
    component: Members,
  },
  {
    path: "/dashboard/teamUsers/:id", //for team users purpose
    mini: "MP",
    icon: membersIcon,
    component: TeamUsers,
  },
  {
    path: "/dashboard/issues/:id", //for issues purpose
    mini: "MP",
    icon: membersIcon,
    component: IssuesComponent,
  },
  {
    path: "/dashboard/issues", //for issues page
    name: "Alerts",
    mini: "CP",
    icon: AlertsIcon,
    component: IssuesComponent,
  },
  {
    path: "/dashboard/perspectives", // for perspectives page
    name: "Perspectives",
    mini: "CP",
    icon: membersIcon,
    component: Perspectives,
  },
  {
    path: "/dashboard/notifications", // for notifications page
    name: "Notifications",
    mini: "CP",
    icon: Bell, 
    component: Notifications,
    count: "Bubble"
  },
  // {
  //   path: "/dashboard/globalusers", //for global user tab purpose
  //   name: "Global Users",
  //   mini: "MP",
  //   icon: GlobalUsersIcon,
  //   component: Members
  // },
  {
    path: "/dashboard/issueDetails/:id", //for issue details purpose
    mini: "MP",
    icon: membersIcon,
    component: IssueDetailsComponent,
  },
  {
    path: "/dashboard/instances/:id", //for instance purpose
    mini: "CP",
    icon: DashboardIcon,
    component: Instance,
  },
  {
    path: "/dashboard/InstanceDashboard/:id", //for instance dashboard purpose
    mini: "MP",
    icon: membersIcon,
    component: InstanceDashboard,
  },
  {
    path: "/dashboard/rules/:id", //for rules with id purpose
    mini: "MP",
    icon: membersIcon,
    component: Rules,
  },
  {
    path: "/dashboard/ruleDashboard/:id", //for rules dashboard purpose
    mini: "MP",
    icon: membersIcon,
    component: RulesDashboard,
  },
  {
    path: "/dashboard/notification/:id", //for notification purpose
    mini: "NC",
    icon: membersIcon,
    component: Notification,
  },
  {
    path: "/dashboard/agents/:id", //for agent purpose
    mini: "CP",
    icon: DashboardIcon,
    component: Agent,
  },
  // {
  //   path: "/dashboard/agentsDashboard/:id", //for agent dashboard purpose
  //   mini: "MP",
  //   icon: membersIcon,
  //   component: AgentDashboard
  // },
  {
    path: "/dashboard/partnerUsers/:id",
    mini: "PUP",
    icon: roleIcon,
    component: PartnerUsers,
  },
  {
    path: "/dashboard/teams",
    mini: "CP",
    icon: DashboardIcon,
    component: Team,
  },
  {
    path: "/dashboard/update-password", //for update password
    name: "Change password",
    mini: "CP",
    icon: passwordIcon,
    component: ChangePassword,
  },
  {
    path: "/dashboard/user-profile", //for user profile
    mini: "UP",
    icon: passwordIcon,
    component: UserProfile,
  },
  {
    path: "/dashboard/info/:id", //for information page
    mini: "IP",
    icon: roleIcon,
    component: Information,
  },
  {
    path: "/dashboard/report/:id", //for report page
    mini: "RP",
    icon: roleIcon,
    component: Reporting,
  },
  //{ redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;
